import clsx from 'clsx';

import styles from './Styles.module.scss';

export const NoAccess = (params: {artispackage?: string}) => {

	const { artispackage } = params;

	return <div className={styles.container}>
		<h1 className={clsx(
			'iconoir-warning-triangle icon--medium',
			styles.heading
		)}>No Access to this package</h1>
		<div>It appears you are trying to access a package which you don’t have a subscription for. If you believe this is in error, please get in touch via the support email address on <a href={`mailto:support@obxlive.com?subject=Request%20Access:%20${artispackage}`}>support@obxlive.com</a> </div>
	</div>
}

export default NoAccess;
