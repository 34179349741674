import { CSSProperties, useEffect, useState } from 'react';
import { useMediaQuery } from "react-responsive";

import { clsx } from 'clsx';

import { DataTable, DataTableExpandedRows, DataTableRowToggleEvent  } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { ReadableDate } from 'helpers/DataTable/Templates/ColumnTemplates';

import { useGetLatestModData } from 'modules/Rates/Commodities/Services/hooks';
import { IEAModItem, JodiSources } from 'modules/Rates/Commodities/Models/IEAMods';
import { Frequency } from 'modules/Rates/Commodities/Models/Enums';
import { NumericalUnit } from 'modules/Rates/Commodities/Templates/NumericalUnit';
import { Grouping } from '../../CommoditiesData';

import { IModGridBaseParams } from './SimpleGrid';
import './Grids.scss';

// type Grouping = "location" | "shortName";

interface IModGridParams extends IModGridBaseParams {
	group: Grouping,
}

const GroupableGrid = (props: IModGridParams) => {

	const { 
		query,
		source,
		group,
		dimensions,
		unitAsDecimal,
		feed,
		selectedRows,
		setSelectedRows,
		changeFrequency
	} = props;

	const MAX_COLUMNS_BEFORE_PINNED: number = 4;

	const { data, error, isLoading } = useGetLatestModData(feed, source, query)
	const [ columns, setColumns ] = useState<{name: string, label: string, unit?: string}[]>([]);
	const [ rows, setRows ] = useState<any[]>([]);
	// const [ grouping, setGrouping ] = useState<any>(null);
	const [ expandedRows, setExpandedRows ] = useState<DataTableExpandedRows | any[]>([])
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" })

	
	let transformer: (s: string) => string;
	let decimalPlaces: number = 3;

	if (unitAsDecimal) {
		transformer = (s: string) => {
			//	TODO - this is a touch too tightly coupled to the one GWH instance
			//	so probably a more elegant way to make this change. WIll suffice for now
			switch(s) {
				case "GWh":
					return "kwh";
				default:
					return s.substring(1)
			}
		}
		decimalPlaces = 0
	}

	useEffect(() => {
		if (!data) return;

		const { columns, rows, frequency } = data;

		setColumns(columns);
		setRows(rows);

		//	Depending on the response - reset the frequency in the parent
		//	TODO - as any is hacky…
		const frq: Frequency = +Frequency[frequency as any];
		changeFrequency(frq);

	}, [data])

	if (error) {
		setRows([]);
	}

	useEffect(() => {
		if (!group) return;
		setExpandedRows([]);

	}, [group])

	const tableprops = {
		className: clsx(
			'grow-to-fill',
			'mod-data__grid',
			(feed === 'jodi' || source === 'ELECTRICITY') && group.key === 'location' && 'mod-data__grid--extrawide'
		),
		scrollable: true,
		scrollHeight: 'flex',
		loading: isLoading,
		rowGroupMode: "subheader",
		rowGroupHeaderTemplate: (data: any) => <div>{data[group.key]}</div>,
		expandedRows,
		expandableRowGroups: true, 
		onRowToggle: (e: DataTableRowToggleEvent) => setExpandedRows(e.data),
		selection: selectedRows!,
		onSelectionChange: (e: any) => setSelectedRows(e.value)
	}

	//	TODO - has to be a better way to force the grouping change to work
	//	better than re-render the entire grid like below. API doesn't provide
	//	obvious way to externally force a re-rot though 🤷‍♀️

  return <>
		{ rows && 
			<>
				{ group.key === 'location' && 
					<DataTable
						{...tableprops}
						value={rows}
						removableSort
						sortField={group.key}
						sortOrder={1}
						groupRowsBy={group.key}
						emptyMessage={() => <div>
							<h2>No results</h2>
							Date period dimensions for this source must be { dimensions } values only
						</div>}
						rowGroupFooterTemplate={<></>}
						tableStyle={{'--columns': `${columns.length ? columns.length : 1}`} as CSSProperties}
					>
						<Column
							selectionMode="multiple"
							headerClassName='content--hidden'
							frozen={true}
						/>
						<Column
							header={'Product'}
							field={'shortName'}
							className='no-border'
							frozen={columns.length > MAX_COLUMNS_BEFORE_PINNED || isTabletOrMobile}
						/>
						<Column 
							header='Period'
							field='period'
							frozen={columns.length > MAX_COLUMNS_BEFORE_PINNED && !isTabletOrMobile}
						/>
						{ columns.map((col, i) => 
							<Column 
								key={`index-${i}`}
								header={col.name}
								field={col.name}
								body={(d, c) => NumericalUnit(d, c, {alignment: 'right', unit: col.unit, multiplier: unitAsDecimal ? 1000 : 1, transformer, decimalPlaces})}
							/>
						)}
						<Column
							header="Last Updated"
							field='lastUpdated'
							frozen={columns.length > MAX_COLUMNS_BEFORE_PINNED && !isTabletOrMobile}
							alignFrozen='right'
							body={ReadableDate<IEAModItem>}>
						</Column>
					</DataTable>
				}
				{ group.key === 'shortName' && 
					<DataTable
						{...tableprops}
						value={rows}
						removableSort
						sortField={group.key}
						sortOrder={1}
						groupRowsBy={group.key}
						emptyMessage={() => <div>
							<h2>No results</h2>
							Date period dimensions for this source must be {dimensions} values only
						</div>}
						rowGroupFooterTemplate={<></>}
						tableStyle={{'--columns': `${columns.length ? columns.length : 1}`} as CSSProperties}
					>
						<Column 
							selectionMode="multiple"
							frozen={true}
						/>
						<Column
							header={'Location'}
							field={'location'}
							className='no-border'
							frozen={columns.length > MAX_COLUMNS_BEFORE_PINNED || isTabletOrMobile}
						/>
						<Column 
							header='Period'
							field='period'
							frozen={columns.length > MAX_COLUMNS_BEFORE_PINNED && !isTabletOrMobile}
						/>
						{ columns.map((col, i) => 
							<Column 
								key={`index-${i}`}
								header={col.name}
								field={col.name}
								body={(d, c) => NumericalUnit(d, c, {alignment: 'right', unit: col.unit, multiplier: unitAsDecimal ? 1000 : 1, transformer, decimalPlaces})}
							/>
						)}
						<Column
							header="Last Updated"
							field='lastUpdated'
							frozen={columns.length > MAX_COLUMNS_BEFORE_PINNED && !isTabletOrMobile}
							alignFrozen='right'
							body={ReadableDate<IEAModItem>}>
						</Column>
					</DataTable>
				}
			</>		
		}
	</>;
}

export default GroupableGrid;
