import { useState } from 'react';
import clsx from 'clsx';
import { Message, MessageProps } from 'primereact/message';

import './Ribbon.scss';

interface RibbonProps extends MessageProps {
    closable?: boolean;
    containerClassName?: string;
};

export default function Ribbon(props: RibbonProps): JSX.Element {
    const { className, containerClassName = 'grow-to-fill', closable = true, ...otherProps } = props;

    const [visible, setVisible] = useState(true);

    const handleClose = () => {
		setVisible(false);
	}

    if (!visible) {
        return <></>;
    }

    return <div className={clsx('ribbon__container', containerClassName)}>
        <Message className={clsx("ribbon grow-to-fill", className)} {...otherProps} />
        {closable && <div onClick={handleClose} className='ribbon__close-icon iconoir-xmark icon--medium'></div>}
    </div>;
}