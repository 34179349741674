import { DateTime } from 'luxon';

import styles from './ReadableDate.module.scss';

export const ReadableDate = <T,>(data: T, config: {field: string}, format?: string): JSX.Element => {

  const field = config.field;
  const date: DateTime = data[field as keyof T] as DateTime;

  return <div>
    { date.isValid
      ? <span>{ date.toFormat(format ?? 'dd LLL yyyy, hh:mm')}</span>
      : <i className={styles.unknownDate}>Unknown</i>
    }
  </div>;
};