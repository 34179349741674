export enum GlobalDialogDisplayEvents {
  DISPLAY = 'showDialog',
  HIDE = 'hideDialog',
}

export type DialogSize = 'small' | 'medium' | 'large';

export type DialogPosition =
  | 'top'
  | 'center'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'
  | undefined;

export interface IDialogDisplayEventDetails {
  footer: JSX.Element;
  body: JSX.Element;
  size: DialogSize;
  position?: DialogPosition;
  header?: string;
  onHide?: () => void;
}
