import { EntitySearchGroupEnum, EntitySearchFieldsEnum } from "./Enums";

export type SearchFieldsRequest = {
    searchGroup: EntitySearchGroupEnum
}

export type SearchSuggestionsRequest = {
    searchGroup: EntitySearchGroupEnum;
    searchFields: EntitySearchFieldsEnum;
    searchTerm: string;
    maxItems: number;
}

export type SearchSuggestionsGroupedRequest = SearchSuggestionsRequest & {
    searchFields: EntitySearchFieldsEnum[];
}

export type SearchSuggestionBaseResponse = {
    value: string;
    searchEntityId: string;
    searchField: EntitySearchFieldsEnum;
    searchGroupType: EntitySearchGroupEnum;
		searchTerm: string;
}

export type SearchSuggestionsResponse = SearchSuggestionBaseResponse & {
    metaData: {key: string, value: string}[];
};

export type SearchSuggestionLocation = SearchSuggestionsResponse & {
	CountryCodeISO3: string;
	SignalOceanPortId: string;
}

export type SearchSuggestionsGroupedResponse = {
    searchFieldId: EntitySearchFieldsEnum,
    searchFieldName: "LoadPort" | "DischargePort" | "CommodityType" | "VesselSize" | "Vessel" | "Charterer" | "Owner" | "LoadRegion" | "DischargRegion" | "LoadCountry" | "ImoNumber";
    values: SearchSuggestionsResponse[]
}

//  what is returned from the actual service T should extend SearchSuggestionBaseResponse
export type SearchSuggestionsParsedResponse<T> = {
    searchFieldId: EntitySearchFieldsEnum,
    searchFieldName: "LoadPort" | "DischargePort" | "CommodityType" | "VesselSize" | "Vessel" | "Charterer" | "Owner" | "LoadRegion" | "DischargRegion" | "LoadCountry" | "ImoNumber";
    values: T[]
}

// debouncing delay for the 'search request' sending (in ms)
export const SEARCH_DEBOUNCE_DELAY = 300;

export {}