import RouteSettings from '../RouteSettings';
import CalculationResults from '../CalculationResults';

import Loader from 'components/Loader';

import type { CalculationRequest } from 'modules/DistanceCalc/Models/CalculationRequests';
import type { CalcParamChange } from 'modules/DistanceCalc/Models/CalcParamsChange';
import type { CalculationResult } from 'modules/DistanceCalc/Models/CalculationResponse';

interface ResultsPanelProps {
    calcParams: CalculationRequest,
    calcResults: CalculationResult | undefined;
    callback: (args: CalcParamChange) => void;
    dataIsLoading: boolean;
}

export default function ResultsPanel(props: ResultsPanelProps): JSX.Element {
    const { calcParams, calcResults, callback, dataIsLoading } = props;

    return (
        <>
            <section className="general-route-settings-container">
                <RouteSettings
                    calcParams={calcParams}
                    callback={callback}
                />
            </section>
            <section className="calculation-results-container grow-to-fill">
                {dataIsLoading
                    ? <Loader />
                    : <CalculationResults
                        calcParams={calcParams}
                        calcResults={calcResults}
                        callback={callback}
                    />
                }
            </section>
        </>
    );
}