import { clsx } from "clsx";

import { CargoMovementSearchResult } from "../Models/CargoMovements";
import { ColumnBodyOptions } from "primereact/column";

import { Flag } from 'components/Flag';

import styles from "helpers/DataTable/Templates/ColumnTemplates/DoubleLine.module.scss";

/**
 * Custom cell rendering function for the <CargoDataTable> instance. Used to display
 * port and terminals names, plus a flag based on the contents of the [] brackets
 * in the passed port name string. The passed config.field property should be a
 * comma dilimite pair of CargoMovementSearchResult.prop keys
 *
 * @param {CargoMovementSearchResult} data
 * @param {ColumnBodyOptions} config
 *
 */
export const cargoFlowLocation = (data: CargoMovementSearchResult, config: ColumnBodyOptions) => {

    const [ upperKey, lowerKey ] = config.field.split(",");

    const upper: string = data[upperKey as keyof CargoMovementSearchResult] as string;
    const lower: string = data[lowerKey as keyof CargoMovementSearchResult] as string;

    const [ fulltext, label, flag ] = getCargoFlowLocationData(upper);

    return <div className={clsx(
        "align-items--start",
        styles.cell,
        styles.flagged
    )}>
        <span title={fulltext} className={clsx("upper", styles.upper, { 'unknown': !label })}>{label || 'Unknown'}</span>
        <span title={lower} className={clsx("lower", styles.lower)}>{lower}</span>
        {data[flag as keyof CargoMovementSearchResult] !== "UNK" &&
            <Flag code={flag} />
        }
    </div>
}

export const getCargoFlowLocationData = (item: string): RegExpMatchArray =>
    [...item.matchAll(/(.*?)\[(.*?)\]/g)][0] ?? [item, item, null];

