import { ReactNode } from 'react';
import { isRouteErrorResponse } from 'react-router';
import { AxiosError, InternalAxiosRequestConfig, isAxiosError } from 'axios';
import { ErrorResponse } from '@remix-run/router';

import CodeSnippet from 'components/CodeSnippet';

import './ErrorCode.scss';

interface ErrorCodeProps {
    error: AxiosError | Error | ErrorResponse | unknown;
    header?: ReactNode;
}

export default function ErrorCode(props: ErrorCodeProps): JSX.Element {
    const {
        error,
        header = <>Please let support know via email <a href="mailto:support@obxlive.com">support@obxlive.com</a> with a copy of the error shown below…</>
    } = props;

    let title = null;
    let strigifiedError = '';

    if (isAxiosError(error)) {
	    const { name, code, config, response, message } = error as AxiosError;
        let { baseURL, data, method, url } = config as InternalAxiosRequestConfig<any>;
    
        data = JSON.parse(data);
    
        strigifiedError = JSON.stringify({ baseURL, method, url, request: data });
        title = <strong>{code} {response ? `code: ${response?.status}` : `message: ${message}`} name: {name}</strong>;
    } else if (isRouteErrorResponse(error)) {
        // useless ErrorResponseImpl type because of private modifier for error field
        // that is why type extended here like that
        const { data, error: err, status, statusText } = error as ErrorResponse & { error: Error };

        strigifiedError = JSON.stringify({ data, status, message: err?.message, stack: err?.stack });
        title = <strong>{status} {statusText}: {err?.message}</strong>;
    } else {
        const { name, message, stack } = error as Error;

        strigifiedError = JSON.stringify({ name, message, stack });
        title = <strong>message: { message } name: { name }</strong>;
    }

    return <div className="error-code__container">
        <p>{header}</p>
        <CodeSnippet code={strigifiedError} title={title} />
    </div>
}