import { Flag } from 'components/Flag';

import './LegHeader.scss';

export interface LegHeaderProps {
    icon: string;
    label: string;
    sublabel?: string;
}

export default function LegHeader (props: LegHeaderProps): JSX.Element {
    const { icon, label, sublabel } = props;

    return <div className="leg-header__container">
        <h4 className="leg-header__label">
            {label}
            <Flag code={icon} />
        </h4>
        {sublabel && <h5 className="leg-header__sublabel">{sublabel}</h5>}
    </div>
}