import type { SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';

import styles from './Suggestion.module.scss';

const EmailSuggestion = (i: SearchSuggestionsResponse & { ClientName: string; }): JSX.Element => {
  return <div className={styles.suggestionItem}>
    <span>{i.ClientName}</span>
    <span>{i.value}</span>
  </div>;
};

export default EmailSuggestion;