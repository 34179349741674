import axios, { type AxiosError } from 'axios';
import fileTypeChecker from 'file-type-checker';
import useSWR, { type Key } from 'swr';
import useSWRMutation from 'swr/mutation';

import ErrorToastService from 'components/Errors/ErrorToast/Services/ErrorToastService';

import { StateRequestItems } from '../../../Services/AuditService';

import { parsePropsToDateTime } from 'helpers/Utils/misc';
import type { ResultsResponse } from 'modules/Surveillance/Models/ReportsResponse';

import type {
  SurveillanceActivityResponse,
  SurveillanceDetailsRequestParams,
  SurveillanceDetailsResponse,
  SurveillanceMessagesRequestParams,
  SurveillanceRecordingRequestParams,
  SurveillanceStoreCopiedDetailsRequestParams,
  SurveillanceStorePlayedToEndRequestParams,
  SurveillanceTranscriptionCreateParams,
} from '../Models';
import type {
  SurveillanceMessageResponse,
  SurveillanceMessageResult,
} from '../Models/Response';

export const useFile = (
  params: SurveillanceRecordingRequestParams | null,
  worksheetId?: string | null
): {
  isLoading: boolean;
  isValidating: boolean;
  url?: string | null;
  mime?: string | null;
  error?: AxiosError;
} => {
  const { data, error, isLoading, isValidating } = useSWR(
    params && worksheetId
      ? { key: 'surveillance-details-audio', params }
      : null,
    ({ params }) =>
      SurveillanceDetailsAPI.getFileStream(params, worksheetId || ''),
    { revalidateOnFocus: false, shouldRetryOnError: false }
  );
  const url = data?.fileContent && URL.createObjectURL(data.fileContent);

  return { url, mime: data?.mimeType, error, isLoading, isValidating };
};

export const useDetails = (
  body: SurveillanceDetailsRequestParams,
  worksheetId?: string | null
): {
  details: SurveillanceDetailsResponse | undefined;
  error?: AxiosError;
  isLoading: boolean;
  isValidating: boolean;
  mutate: (data?: SurveillanceDetailsRequestParams) => void;
} => {
  const {
    data: details,
    error,
    isLoading,
    isValidating,
    mutate,
  } = useSWR<SurveillanceDetailsResponse, AxiosError | undefined>(
    body && worksheetId
      ? { key: 'surveillance-details', body, worksheetId }
      : null,
    () =>
      SurveillanceDetailsAPI.getDetails({
        body,
        worksheetId,
      }),
    { revalidateOnFocus: false }
  );

  return {
    details,
    error,
    isLoading,
    isValidating,
    mutate: mutate as (data?: SurveillanceDetailsRequestParams) => void,
  };
};

export const useActivity = (
  body: StateRequestItems,
  worksheetId?: string | null
): {
  activity: SurveillanceActivityResponse[] | undefined;
  error?: AxiosError;
  isLoading: boolean;
  isValidating: boolean;
  mutate: (data?: StateRequestItems) => void;
} => {
  const {
    data: activity,
    error,
    isLoading,
    isValidating,
    mutate,
  } = useSWR<SurveillanceActivityResponse[], AxiosError | undefined>(
    body && worksheetId
      ? { key: 'surveillance-activity', body, worksheetId }
      : null,
    () =>
      SurveillanceDetailsAPI.getActivity({
        body,
        worksheetId,
      }),
    { revalidateOnFocus: false }
  );

  return {
    activity,
    error,
    isLoading,
    isValidating,
    mutate: mutate as (data?: StateRequestItems) => void,
  };
};

export const useMessages = (
  body?: SurveillanceMessagesRequestParams,
  worksheetId?: string | null
): {
  messages?: SurveillanceMessageResult[] | null;
  totalPages?: number | null;
  error?: AxiosError;
  isLoading: boolean;
} => {
  const { data, error, isLoading } = useSWR<
    SurveillanceMessageResponse,
    AxiosError | undefined
  >(
    body && worksheetId
      ? {
        key: 'surveillance-messages',
        body,
        worksheetId,
      }
      : null,
    () =>
      SurveillanceDetailsAPI.getMessages({
        body,
        worksheetId,
      }),
    { revalidateOnFocus: false }
  );

  return {
    messages: data?.results,
    totalPages: data?.totalPages,
    error,
    isLoading,
  };
};

export const useStoreCopiedDetails = (): {
  trigger: (
    arg: SurveillanceStoreCopiedDetailsRequestParams
  ) => Promise<string | undefined>;
} => {
  const { trigger } = useSWRMutation<
    string,
    AxiosError,
    Key,
    SurveillanceStoreCopiedDetailsRequestParams
  >('surveillance-details-copied', SurveillanceDetailsAPI.storeCopiedDetails);

  return { trigger };
};

export const useStoreRecordingPlayedToEndFlag = (): {
  trigger: (
    arg: SurveillanceStorePlayedToEndRequestParams
  ) => Promise<string | undefined>;
} => {
  const { trigger } = useSWRMutation<
    string,
    AxiosError,
    Key,
    SurveillanceStorePlayedToEndRequestParams
  >(
    'surveillance-store-played-to-end-flag',
    SurveillanceDetailsAPI.storeRecordingPlatedToEndFlag
  );

  return { trigger };
};

export const useCreateTranscriptionVersion = (): {
  trigger: (
    arg: SurveillanceTranscriptionCreateParams
  ) => Promise<ResultsResponse | undefined>;
} => {
  const { trigger } = useSWRMutation<
    ResultsResponse,
    AxiosError,
    Key,
    SurveillanceTranscriptionCreateParams
  >(
    'surveillance-create-transcription-version',
    SurveillanceDetailsAPI.createTranscriptionVersion
  );

  return { trigger };
};

export class SurveillanceDetailsAPI {
  static getDetails = async (params: {
    body: SurveillanceDetailsRequestParams;
    worksheetId?: string | null;
  }): Promise<SurveillanceDetailsResponse> =>
    axios
      .post<SurveillanceDetailsResponse>(
        'Audit/Surveillance/Details',
        params.body,
        {
          headers: { worksheetId: params.worksheetId },
        }
      )
      .then(response => response.data);

  static getActivity = async (params: {
    body: StateRequestItems;
    worksheetId?: string | null;
  }): Promise<SurveillanceActivityResponse[]> =>
    axios
      .post<SurveillanceActivityResponse[]>(
        `audit/surveillance/${ params.worksheetId }/activity`,
        params.body
      )
      .then(response =>
        response.data.map(d => parsePropsToDateTime(d, ['updatedAt']))
      );

  static getMessages = async (params: {
    body?: SurveillanceMessagesRequestParams;
    worksheetId?: string | null;
  }): Promise<SurveillanceMessageResponse> =>
    axios
      .post<SurveillanceMessageResponse>(
        'audit/surveillance/search/siblings',
        params.body,
        {
          headers: { worksheetId: params.worksheetId },
        }
      )
      .then(response => response.data);

  static getFileStream = async (
    params: SurveillanceRecordingRequestParams,
    worksheetId: string
  ): Promise<{ fileContent?: Blob | null; mimeType?: string | null } | null> =>
    axios
      .post<Blob>('Audit/Surveillance/fileStream', params, {
        headers: { worksheetId },
        responseType: 'blob',
      })
      .then(response =>
        response.data
          .arrayBuffer()
          .then(buf => ({ fileContent: response.data, buf }))
      )
      .then(({ fileContent, buf }) => {
        if (buf) {
          const { mimeType } = fileTypeChecker.detectFile(buf) || {};

          return { fileContent, mimeType };
        }
        return { fileContent, mimeType: null };
      })
      .catch(() => null);

  static createTranscriptionVersion = async (
    url: string,
    params: { arg: SurveillanceTranscriptionCreateParams }
  ): Promise<ResultsResponse> =>
    axios
      .post('audit/surveillance/transcription/version', params.arg)
      .then(response => response.data)
      .catch(e => {
        ErrorToastService.handleError(e);

        throw e;
      });

  static storeCopiedDetails = async (
    url: string,
    params: { arg: SurveillanceStoreCopiedDetailsRequestParams }
  ): Promise<string> =>
    axios
      .post('audit/surveillance/details/copied', params.arg)
      .then(response => response.data)
      .catch(() => null);

  static storeRecordingPlatedToEndFlag = async (
    url: string,
    params: { arg: SurveillanceStorePlayedToEndRequestParams }
  ): Promise<string> =>
    axios
      .post('audit/surveillance/recording/playedtoend', params.arg)
      .then(response => response.data)
      .catch(() => null);
}
