import { ColumnSortEvent } from 'primereact/column';

import F from 'types/generic-type';

export const sortByNumerical = (event: ColumnSortEvent, sortByField: string): F<number>[] => {

    const { data, order } = event;

    return data.sort((a: F<number>, b: F<number>) => {

			const first: number = isNaN(a[sortByField]) ? 1: a[sortByField];
			const second: number = isNaN(b[sortByField]) ? 1 : b[sortByField];

			const diff = first - second;

			return Math.sign(diff) * (order as number);
    });
}