import { type ChangeEvent, useState } from "react";
import { DropdownChangeEvent } from 'primereact/dropdown';

import { WeatherUnitEnum } from "modules/DistanceCalc/Models/Enums";
import { MAX_WEATHER_MARGIN, MIN_WEATHER_MARGIN } from 'modules/DistanceCalc/Models/Consts';
import SwitchableUnitField from "components/SwitchableUnitField";
import { getClampedValue } from 'helpers/Utils/misc';

import type { LocationWeather } from "modules/DistanceCalc/Models/CalculationRequests";

interface WeatherMarginFieldProps {
    handleWeatherParamsChange: (arg: LocationWeather) => void;
    initialValue: LocationWeather | undefined;
}

export default function WeatherMarginField(props: WeatherMarginFieldProps): JSX.Element {
    const { handleWeatherParamsChange, initialValue } = props;

    const [ unit, setUnit ] = useState<WeatherUnitEnum>(initialValue?.unit ?? WeatherUnitEnum.Percentage);
    const [ value, setValue ] = useState(initialValue?.value ?? 0);

    const handleValueChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = getClampedValue(+Number.parseFloat(e.target.value).toFixed(1) || MIN_WEATHER_MARGIN, MIN_WEATHER_MARGIN, MAX_WEATHER_MARGIN);

        setValue(value);
        handleWeatherParamsChange({ value, unit });
    }

    const handleUnitChange = (e: DropdownChangeEvent): void => {
        setUnit(e.value);
        handleWeatherParamsChange({ value, unit: e.value });
    }

    return <SwitchableUnitField
        className="weather-margin__container"
        inputProps={{
            value: `${value ?? ''}`,
            onChange: handleValueChange,
            type: 'number',
            keyfilter: 'num',
            min: MIN_WEATHER_MARGIN,
            max: MAX_WEATHER_MARGIN
        }}
        inputType="number"
        dropdownProps={{
            onChange: handleUnitChange,
            options: [{ label: "%", value: WeatherUnitEnum.Percentage }, { label: "Day", value: WeatherUnitEnum.Days }],
            value: unit,
        }}
        label="Weather Margin"
    />
}