import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { eventBus } from 'server/EventBus';

import { GridConfigPanelEvents } from 'components/GridColumnConfiguration';
import { PriceSearchError } from '../Models/Enums';

import styles from './Styles.module.scss';


type ComponentParams = {
	artispackage: string;
	type: PriceSearchError | undefined
}

export const EmptyState = (params: ComponentParams): JSX.Element => {

	const { artispackage } = params;
	
	const renderHeadline = (errorCode: PriceSearchError | undefined): JSX.Element => {
		switch(errorCode) {
			case PriceSearchError.EmptyResults:
				return <>It appears we dont have any prices for these products</>
			case PriceSearchError.NoProducts:
			default:
				return <>No products have currently been selected for the { artispackage } package</>
		}
	}
	
	const message: JSX.Element = renderHeadline(params.type);
	
	return <div className={styles.empty}>
		<h4>{ message }</h4>
		Please add one or more products using 
		<Button
			size="small"
			text
			icon='iconoir-grid-plus icon--small'
			onClick={() => {
				eventBus.dispatch(
					GridConfigPanelEvents.PANEL_VISIBILITY_CHANGE, 
					{ panel: "column" }
				)
			}}
			className={clsx(
				'no-background'
			)}
			>
			Add Product
		</Button>
		button or opening the column configuration panel from the tab on the right-hand-side of the screen
	</div>	
}