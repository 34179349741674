import axios from "axios";
import useSWR from 'swr';
import { EmbedParamsResponse } from "../Models/EmbedParamsResponse";

import { ReportResponse } from "../Models/ReportResponse";

export const useGetReports = (): { data: ReportResponse[] | undefined, error: any, isLoading: any } => {
    const { data, error, isLoading } = useSWR(`ReportGenerator-Reports`,
        () => ReportApi.getReports(),
        { revalidateOnFocus: false });
    return { data, error, isLoading }
}

export class ReportApi {

    static getReports = () => {
        const method: string = 'GET';

        return axios.request({
            url: 'ReportGenerator/Reports',
            method
        })
            .catch(e => {
                console.log('The back-end barfed', e);
                return;
            })
            .then(r => r?.data as ReportResponse[])

    }

    static getReport = (reportId: string) => {
        const method: string = 'GET';

        return axios.request({
            url: `ReportGenerator/Pbi/${reportId}`,
            method
        })
            .catch(e => {
                console.log('The back-end barfed', e);
                return;
            })
            .then(r => r?.data as EmbedParamsResponse)

    }

}
