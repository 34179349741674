import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

interface authWrapperProps {
    children?: React.ReactNode
    config: any;
}

export default function Auth0Wrapper(props: authWrapperProps) {

    const { config } = props;

    const navigate = useNavigate();

    const onRedirectCallback = (appState?: AppState) => {
        const location = appState?.target || (window.location.pathname + window.location.search);
        window.history.pushState(
            {}, document.title, location
        );
        navigate(location);
    };

    return <>
        <Auth0Provider
            domain={config.uiAuthDomain}
            clientId={config.uiAuthClientId}
            authorizationParams={{
                redirect_uri: window.location.origin
            }}
            onRedirectCallback={onRedirectCallback}>
            {props.children}
        </Auth0Provider>
    </>


}