import { SignalRApi } from 'server/signalR-api';

import { getValueCollection } from 'helpers/Utils/enum'
import { ArtisPricesSignalRMessages } from 'modules/ArtisCharting/Models/Enums';

export class ArtisPricesSocket {

	private static _instance: ArtisPricesSocket;

	static get instance() {
		return this._instance || (this._instance = new this())
	}

	init = async (api: SignalRApi): Promise<void> => {
		const events = [
			...getValueCollection(ArtisPricesSignalRMessages, false)
		]

		events.map( e => api.dispatchEventOnMessage(e.key) )
	}
}