import { type ChangeEvent, type FocusEvent, useMemo, useState } from 'react';
import { Checkbox, type CheckboxChangeEvent } from 'primereact/checkbox';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';
import { DateTime } from 'luxon';
import clsx from 'clsx';

import SwitchableUnitField from 'components/SwitchableUnitField';
import SingleEntitySearch from 'components/EntitySearch/SingleEntitySearch';
import DateTimeRange from 'components/DateTimeRange';
import TextInput from 'components/TextInput';
import { getAutocompleteValue } from 'components/Autocomplete/Helpers';
import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import { humanDate } from 'helpers/Utils/formatters';
import { insertItemAt, removeItemAt } from 'helpers/Utils/collections';
import { DEFAULT_PRICE, QUANTITY_UNITS, TRADE_TYPES } from 'modules/Blotter/Models/Consts';
import { getFixedValue } from 'modules/Blotter/Helpers';
import { TradeType } from 'modules/Blotter/Models/Enums';
import InstrumentSuggestion from 'modules/Blotter/Components/TradeDetails/Templates/InstrumentSuggestion';

import type { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';
import type { SectionProps } from 'modules/Blotter/Models/SectionProps';
import type { SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';
import type { TradeLeg, TradesDataResponse } from 'modules/Blotter/Models/BlotterResponse';

interface MainSectionProps extends SectionProps {
  isCleared: boolean;
  originalData: TradesDataResponse | null;
  setIsDateParsing: (arg: boolean) => void;
};

export function MainSection(props: MainSectionProps): JSX.Element {
  const {
    errors,
    isCleared, // keep track of the original "Cleared" value because once the trade is cleared it can never be un-cleared
    mutate,
    originalData,
    request,
    setIsDateParsing,
    shouldShowError,
  } = props;

  const [showDateTimeError, setShowDateTimeError] = useState<boolean>(false);

  const quantityOptions = useMemo(() => {
    if (!originalData || QUANTITY_UNITS.find(u => u === originalData.quantity.unit)) {
      return QUANTITY_UNITS;
    }

    // add original unit as an option
    return [...QUANTITY_UNITS, originalData.quantity.unit];
  }, [originalData]);

  const onTradeTypeChange = (e: DropdownChangeEvent): void => {
    const { value } = e;
    let legs: TradeLeg[];

    switch (value) {
      case TradeType.Spread:
        const leg1 = request.legs[0];
        // TODO: update here (add more items) if more than one Leg is introduced
        legs = insertItemAt(request.legs, 1, {
          ...leg1,
          number: 2,
          price: DEFAULT_PRICE,
          buyer: { ...leg1.seller },
          seller: { ...leg1.buyer }
        });
        break;
      case TradeType.Outright:
        legs = removeItemAt(request.legs, 1);
        break;
      default:
        legs = request.legs;
    }

    mutate({ type: value, legs }, 'type');
  }

  return <section>
    <div className='section--main'>
      <SingleEntitySearch
        allowCustomSelection
        callback={(change?: SearchSuggestionsResponse | string): void => mutate({ instrument: getAutocompleteValue(change) })}
        onInputClear={(): void => mutate({ instrument: undefined })}
        label='Instrument*'
        chunkSize={10}
        errorVisibleAfterTouch={false}
        showError={shouldShowError('instrument')}
        fields={EntitySearchFieldsEnum.Instrument}
        module={EntitySearchGroupEnum.Blotter}
        initialTerm={request.instrument}
        itemTemplate={InstrumentSuggestion}
        onFocusMethod={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
        placeholder='Search or create Instrument'
      />
      <div className="form-input__container">
        <label htmlFor='trade__type'>Type</label>
        <div className={clsx('p-inputgroup', { 'p-invalid': shouldShowError('type') })}>
          <Dropdown
            id='trade__type'
            value={request.type}
            onChange={onTradeTypeChange}
            options={TRADE_TYPES}
          />
        </div>
        {shouldShowError('type') && <small className='message-invalid'>Required field</small>}
      </div>
      <SwitchableUnitField
        className='form-input__container'
        inputType='number'
        label='Quantity*'
        inputProps={{
          className: clsx({
            'p-invalid': shouldShowError('quantity.amount')
          }),
          showError: shouldShowError('quantity.amount'),
          keyfilter: 'pnum',
          type: 'number',
          value: `${request.quantity.amount ?? ''}`,
          placeholder: 'Enter Value',
          onChange: (e: ChangeEvent<HTMLInputElement>) => mutate({
            quantity: {
              ...request.quantity,
              amount: getFixedValue(e.target.value)
            }
          }, 'quantity.amount'),
        }}
        dropdownProps={{
          className: clsx({
            'p-invalid': shouldShowError('quantity.unit')
          }),
          showError: shouldShowError('quantity.unit'),
          error: errors?.['quantity.unit'],
          onChange: (e: DropdownChangeEvent) => mutate({ quantity: { ...request.quantity, unit: e.value } }, 'quantity.unit'),
          options: quantityOptions,
          value: request.quantity.unit,
        }}
      />
      <DateTimeRange
        label='Trade Date, Time*'
        key={`trade__trade-datetime-${request.id}`}
        showNowButton
        keepLocal
        onEmptyValue={(): void => {
          mutate({ dateTime: null });
          setIsDateParsing(false);
        }}
        onParsingStart={(): void => {
          setIsDateParsing(true);
          setShowDateTimeError(false);
        }}
        onDateParsed={(date: ParssedDateTimeResult): void => {
          mutate({ dateTime: DateTime.fromISO(date.fromString) });
          setIsDateParsing(false);
        }}
        onDateParseError={(value: string) => {
          mutate({ dateTime: value });
          setIsDateParsing(false);
          setShowDateTimeError(true);
        }}
        defaultValue={request.dateTime ? humanDate((request.dateTime as DateTime)?.setZone('local'), { time: true }) : ''}
        showErrorMessage={shouldShowError('dateTime') || showDateTimeError}
        placeholder='Enter Date, Time'
        required
      />
      <div className='form-input__container direction--row trade-input--single-line'>
        <Checkbox
          checked={request.nextDayPriced}
          value={request.nextDayPriced}
          inputId={`trade__next-day-priced-checkbox`}
          onChange={(e: CheckboxChangeEvent): void => mutate({ nextDayPriced: e.checked }, 'nextDayPriced')}
        />
        <label htmlFor='trade__next-day-priced-checkbox'>Next Day Priced</label>
      </div>
      <div className='form-input__container'>
        <label htmlFor='trade__clearing-id'>Clearing ID</label>
        <TextInput
          id='trade__clearing-id'
          defaultValue={request.clearing.id ?? ''}
          onChange={(value: string): void => mutate({
            clearing: {
              ...request.clearing,
              id: value,
              cleared: isCleared || !!(request.clearing.house && value) // update 'cleared' value since it depends on id and house values
            }
          }, 'clearing.id')}
          showError={shouldShowError('clearing.id')}
          placeholder='Enter an ID'

        />
      </div>
      <div className='form-input__container'>
        <label htmlFor='trade__clearing-house'>Clearing House</label>
        <TextInput
          id='trade__clearing-house'
          defaultValue={request.clearing.house ?? ''}
          onChange={(value: string): void => mutate({
            clearing: {
              ...request.clearing,
              house: value,
              cleared: isCleared || !!(request.clearing.id && value) // update 'cleared' value since it depends on id and house values
            }
          }, 'clearing.house')}
          showError={shouldShowError('clearing.house')}
          placeholder='Enter House'
        />
      </div>
    </div>
  </section>;
}
