import './SecureEmailPage.scss';
import Loader from 'components/Loader';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { EmailStatusResponse } from './Models/EmailStatusResponse';
import { EmailAPI } from './Services/EmailAPI';
import { SignalRApi } from 'server/signalR-api';
import { BsFillPencilFill } from 'react-icons/bs';
import { SiMinutemailer } from 'react-icons/si';
import { AiOutlineMonitor } from 'react-icons/ai';
import PillsInput from './Components/PillsInput';

function SecureEmailPage() {

    const emailApi: EmailAPI = new EmailAPI();

    const { messageId } = useParams();
    const [emailStatus, setEmailStatus] = useState<EmailStatusResponse | undefined>();
    const [signalREmailStatus, setSignalREmailStatus] = useState<EmailStatusResponse | undefined>();
    const [showLoader] = useState<boolean>(false);  // Removed setter as it wasn't used
    const [emailId, setEmailId] = useState<string>();
    const refreshTimer = useRef<NodeJS.Timer>();

    const signalRApi: SignalRApi = new SignalRApi();

    useEffect(() => {

        signalRApi.startConnection();

        signalRApi.handleMessage("EmailProjectionChange", (emailProjection) => {
            let ep = JSON.parse(emailProjection);
            setSignalREmailStatus(ep);
        });

        return () => {
            signalRApi.closeConnection();
        }
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        console.log(emailStatus, signalREmailStatus);
        if (emailStatus?.id === signalREmailStatus?.id) {
            setEmailStatus(signalREmailStatus);
        }
        // eslint-disable-next-line
    }, [signalREmailStatus])

    useEffect(() => {
        setEmailId(messageId);
        // eslint-disable-next-line
    }, [messageId])

    useEffect(() => {
        loadEmailStatus();
        // eslint-disable-next-line
    }, [emailId]);

    function findEvents(emailAddress: string, valid: boolean): string[] {
        let events: string[] = [];
        let validEvents = ["open", "processed", "delivered", "AttachmentOpen", "click"]
        let invalidEvents = ["bounced", "deferred"]
        if (emailStatus?.activities) {
            for (let i = 0; i < emailStatus.activities.length; i++) {
                if (emailStatus.activities[i].emailAddress.toLocaleLowerCase() === emailAddress.toLocaleLowerCase()) {
                    if (valid && validEvents.indexOf(emailStatus.activities[i].activity) !== -1) {
                        if (events.indexOf(emailStatus.activities[i].activity) === -1) {
                            events.push(emailStatus.activities[i].activity);
                        }
                    } else if (!valid && invalidEvents.indexOf(emailStatus.activities[i].activity) !== -1) {
                        if (events.indexOf(emailStatus.activities[i].activity) === -1) {
                            events.push(emailStatus.activities[i].activity);
                        }
                    }
                }
            }
        }
        return events;
    }

    function loadEmailStatus() {

        if (emailId) {

            emailApi.getStatus(emailId).subscribe(r => {
                setEmailStatus(r);
                refreshTimer.current = setTimeout(loadEmailStatus, 5000);

            });


        }
    }

    if (emailStatus) {
        return <div className='securemail-page'>
            
            <div className='summary'>
                <h3>Summary</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Subject</th>
                            <th>Requested By</th>
                            <th>Sent By</th>
                            <th>Processed</th>
                        </tr>
                    </thead>
                    <tr>
                        <td>{emailStatus.subject}</td>
                        <td>{emailStatus.requestingUserEmailAddress}</td>
                        <td>{emailStatus.sender}</td>
                        <td>{emailStatus.alreadyProcessed ? 'Yes' : 'No'}</td>
                    </tr>
                </table>
            </div>
            <div className='summary'>
                <h3>Attachments</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    {
                        emailStatus.attachments.map((result, index) => {
                            return <tr className={index % 2 === 0 ? 'alt' : ''} key={index}>
                                <td>{result.name}</td>
                                <td>{result.mimeType}</td>
                            </tr>
                        })
                    }
                </table>
            </div>
            {
                emailStatus.recipients.map((result, index) => {
                    return <div key={index} className='summary'>
                        <div className='email-address'>{result.emailAddress}</div>
                        <PillsInput enableInputBox={false} enablePillDelete={false} validPills={findEvents(result.emailAddress, true)} invalidPills={findEvents(result.emailAddress, false)}></PillsInput>
                        <table>
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Description</th>
                                    <th>Timestamp</th>
                                </tr>
                            </thead>

                            {
                                emailStatus.activities.filter(x => x.emailAddress.toLocaleLowerCase() === result.emailAddress.toLocaleLowerCase()).map((tResult, tIndex) => {
                                    return <tr key={tIndex}>
                                        <td>{tResult.activity}</td>
                                        <td>{tResult.description}</td>
                                        <td>{tResult.timestamp}</td>
                                    </tr>
                                }
                                )}
                        </table>
                    </div>
                })
            }

        </div>
    } else if (showLoader) {
        return <div className='securemail-page'>
            <Loader />
        </div>
    } else {
        return <div className='securemail-page'>
            <Loader />
        </div>
    }
}

export default SecureEmailPage;

