import React, { useEffect, useRef, useState } from 'react';
import './PillsInput.scss';
import { FiDelete } from 'react-icons/fi';

interface PillsInputProps {
    onInputDataAvailable?: Function
    deleteInvalidPill?: Function
    deleteValidPill?: Function
    validPills: string[]
    invalidPills: string[]
    enablePillDelete?: boolean
    enableInputBox?: boolean
}

function PillsInput(props: PillsInputProps) {

    const [validPills, setValidPills] = useState<string[]>([]);
    const [invalidPills, setInvalidPills] = useState<string[]>([]);
    const inputBox = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setValidPills(props.validPills);
        setInvalidPills(props.invalidPills);
    }, [props.validPills, props.invalidPills])

    function inputKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.code === "Enter") {
            inputValueChanged();
        }
    }

    function onKeyboardPast(e: React.ClipboardEvent<HTMLInputElement>) {
        var data = e.clipboardData.getData("text");
        if (props.onInputDataAvailable) {
            props.onInputDataAvailable(data)
        }

        window.setTimeout(() => {
            if (inputBox.current) {
                inputBox.current.value = "";
            }
        }, 500);

    }

    function inputValueChanged() {
        if (inputBox.current) {
            if (props.onInputDataAvailable) {
                props.onInputDataAvailable(inputBox.current.value)
            }
            inputBox.current.value = "";
        }
    }

    function deleteInvalidPill(pillIndex: number) {
        if (props.deleteInvalidPill)
            props.deleteInvalidPill(pillIndex);
    }

    function deleteValidPill(pillIndex: number) {
        if (props.deleteValidPill)
            props.deleteValidPill(pillIndex);
    }

    return <div className='pills-input'>
        <div className='pills'>
            {
                validPills.map((result, index) => {
                    return <div key={index} className='pill'>{result}
                        {(props.enablePillDelete ?? true) &&
                            <a onClick={() => deleteValidPill(index)} className='delete'><FiDelete></FiDelete></a> // eslint-disable-line
                        }
                    </div>
                })
            }
            {
                invalidPills.map((result, index) => {
                    return <div key={index} className='pill invalid'>{result}
                        {(props.enablePillDelete ?? true) &&
                            <a onClick={() => deleteInvalidPill(index)} className='delete'><FiDelete></FiDelete></a> // eslint-disable-line
                        }
                    </div>
                })
            }
        </div>
        {(props.enableInputBox ?? true) &&
            <input ref={inputBox} onKeyUp={(e) => inputKeyUp(e)} onPaste={(e) => onKeyboardPast(e)} className='pills-input-box' placeholder='Enter email addresses, either manually or pasting' type="text"></input>
        }
    </div>
}

export default PillsInput;