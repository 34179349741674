import clsx from 'clsx';

import { Flag } from 'components/Flag';

import { spaceToDash } from 'helpers/Utils/string';

import type { SuggestionItem } from '../Autocomplete';

import './ItemTemplate.scss';

export interface ItemTemplateProps extends SuggestionItem {};

export default function ItemTemplate (props: ItemTemplateProps, mapper?: Function): JSX.Element {

	if (typeof mapper === "function") {
		//	We've been passed a function that will help map the label + icon keys…
		props = mapper(props)
	}
	
	const { group, label, subLabel, captionLabel, groupLabel, name, value, icon } = props;

	return (
			<div className={clsx(`item-template item-template--${ spaceToDash(group) }`,
				captionLabel && 'item-template--caption',
				groupLabel && groupLabel !== ' ' && 'item-template--group')}>
					{ groupLabel &&
						<span className="item-template-grouplabel">{groupLabel}</span>
					}
					<label className="item-template-label" htmlFor={`item-template-icon-${ spaceToDash(name ?? value) }`}>
						{ label ?? value }
						{ captionLabel &&
							<span className="item-template-captionlabel">{captionLabel}</span>
						}
					</label>
					{ subLabel && 
						<span className="item-template-sublabel">{subLabel}</span>
					}
					{ icon && 
						<Flag code={icon} />
					}
			</div>
	);
};