import Joi from 'joi';

import { Reason } from './Enums';

export const markAsReviewedValidator = Joi.object({
  reason: Joi.string().valid(...Object.values(Reason)).required(),
  notes: Joi.when('reason', {
    is: Reason.Other,
    then: Joi.string().required(),
    otherwise: Joi.string().optional().allow('', null)
  }),
}).unknown(true);