import { type UIEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DataTable, type DataTableStateEvent, type DataTablePropsSingle, type DataTableSelectionSingleChangeEvent } from 'primereact/datatable';
import { Column, type ColumnBodyOptions } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { Badge } from 'primereact/badge';
import { MenuItem } from 'primereact/menuitem';
import { Checkbox } from 'primereact/checkbox';
import { type VirtualScrollerChangeEvent } from 'primereact/virtualscroller';
import clsx from 'clsx';

import { ServiceCallError } from 'components/Errors/ServiceCalls';
import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';
import TradeActionMenu from '../TradeActionMenu/TradeActionMenu';
import { DoubleLineSimple, ReadableDate, SingleLineTruncated, SingleLineWithAddon } from 'helpers/DataTable/Templates/ColumnTemplates';
import { ServerSortHeader } from 'helpers/DataTable/Templates/Headers';
import TradeItem from 'modules/Blotter/Components/TradeDetails/Templates/TradeItem';
import { camelToSpace, formatName } from 'helpers/Utils/string';
import { removeItemAt, replaceItemAt } from 'helpers/Utils/collections';
import { getValueCollection } from 'helpers/Utils/enum';
import { DEFAULT_GRID_ROW_HEIGHT } from 'models/shared/consts';
import {
  MOBILE_GRID_ROW_HEIGHT,
  SEARCH_EMPTY_MESSAGE,
  TRADE_ITEM_MENU_ICONS,
} from 'modules/Blotter/Models/Consts';
import {
  SortableFieldName,
  SortOrder,
  TradeItemAction,
  TradeType,
} from 'modules/Blotter/Models/Enums';

import TypeChip from './Templates';

import type { TradesDataResponse } from 'modules/Blotter/Models/BlotterResponse';
import type { SearchRequest, SearchRequestFields, SortByFields } from 'modules/Blotter/Models/SearchRequest';
import type { AxiosError } from 'axios';

import './BlotterDataTable.scss';

interface BlotterDataTableProps {
  isLoading: boolean;
  onLazyLoad: (e: VirtualScrollerChangeEvent) => void;
  selectedTrade: TradesDataResponse | null;
  onTradeSelected: (arg: TradesDataResponse) => void;
  onTableScroll: (e: UIEvent<HTMLElement>) => void;
  trades: TradesDataResponse[] | undefined;
  orderByColumns: SortByFields[];
  setOrderByColumns: (arg: SortByFields[]) => void;
  searchItems: SearchRequest;
  handleSearchEntityCallback: (fields: SearchRequestFields[]) => void;
  onItemAction: (item: TradesDataResponse, action: TradeItemAction) => void;
  error?: AxiosError;
}

const BlotterDataTable = (props: BlotterDataTableProps): JSX.Element => {
  const {
    error,
    isLoading,
    onLazyLoad,
    onTableScroll,
    selectedTrade,
    onTradeSelected,
    orderByColumns,
    setOrderByColumns,
    searchItems,
    handleSearchEntityCallback,
    onItemAction,
    trades,
  } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const [actionItem, setActionItem] = useState<
    TradesDataResponse | undefined
  >();

  const cm = useRef<ContextMenu>(null);
  const contextMenuActions = useRef<ContextMenu>(null);

  const updateFilter = (value: string): void => {
    if (searchItems.searchRequestFields?.find(i => i.searchTerm === value)) {
      handleSearchEntityCallback(searchItems.searchRequestFields.filter(i => i.searchTerm !== value));
    } else {
      handleSearchEntityCallback([...searchItems.searchRequestFields ?? [], {
        searchTerm: value,
        searchField: EntitySearchFieldsEnum.TradeType,
        metaData: []
      }]);
    }
  };

  const contextMenuItems: MenuItem[] = useMemo(() => getValueCollection(TradeType, false).map(({ key, value }) => ({
    command: () => updateFilter(value as string),
    template: () => <>
      <Checkbox
        id={`blotter-table-filters--${key}`}
        checked={!!searchItems.searchRequestFields?.find(f => f.searchTerm === value && f.searchField === EntitySearchFieldsEnum.TradeType)}
      />
      <label htmlFor={`blotter-table-filters--${key}`}>{key}</label>
    </>
  })), [searchItems]);

  const contextMenuActionsItems = useMemo(() => {
    if (actionItem) {
      return getValueCollection(TradeItemAction, false).map(({ key }) => ({
        icon: `iconoir-${
          TRADE_ITEM_MENU_ICONS[key as TradeItemAction]
        } icon--small`,
        label: camelToSpace(key.toString(), { lower: true }).replace(/^.?/, m =>
          m.toUpperCase()
        ),
        command: (): void => onItemAction(actionItem, key as TradeItemAction),
      }));
    }
    return undefined;
  }, [actionItem, onItemAction]);

  const filterCount = useMemo(() =>
    searchItems.searchRequestFields?.filter(item => item.searchField === EntitySearchFieldsEnum.TradeType).length ?? 0,
    [searchItems.searchRequestFields]
  );

  const handleOrderChange = useCallback((event: DataTableStateEvent) => {
    let newSortOrder: SortByFields[];
    const sortField = event.sortField as SortableFieldName;
    const index = orderByColumns.findIndex(item => item.fieldName === sortField);
    const item = orderByColumns[index];

    //  This sortField doesn't exist in our ordering - so add it
    if (!item) {
      newSortOrder = [...orderByColumns, { fieldName: sortField, sortOrder: SortOrder.Asc }];
    } else {
      switch (item.sortOrder) {
        case SortOrder.Asc:
          //  Switch to descending
          newSortOrder = replaceItemAt<SortByFields>(orderByColumns, { fieldName: sortField, sortOrder: SortOrder.Desc }, index);
          break;
        case SortOrder.Desc:
          //  …or remove completely
          newSortOrder = removeItemAt<SortByFields>(orderByColumns, index);
          break;
        default:
          newSortOrder = orderByColumns;
      }
    }

    setOrderByColumns(newSortOrder);
  }, [orderByColumns, setOrderByColumns]);

  const sortOrder = useMemo(
    // map order to match <ServerSortHeader /> `current` prop type
    () => (orderByColumns ?? []).map(i => `${i.fieldName} ${SortOrder[i.sortOrder].toLowerCase()}`),
    [orderByColumns]
  );

  if (error) {
    return <ServiceCallError error={error} />;
  }

  const handleRowClick = (value: TradesDataResponse): void => {
    onTradeSelected(value);
  };

  const COMMON_PROPS = {
    className: 'blotter-data-table',
    dataKey: 'id',
    emptyMessage: SEARCH_EMPTY_MESSAGE,
    loading: isLoading,
    metaKeySelection: false,
    onSelectionChange: (e: DataTableSelectionSingleChangeEvent<TradesDataResponse[]>): void => onTradeSelected(e.value),
    scrollable: true,
    scrollHeight: 'flex',
    selection: selectedTrade,
    value: trades,
    virtualScrollerOptions: {
      autoSize: true,
      className: 'grow-to-fill',
      itemSize: DEFAULT_GRID_ROW_HEIGHT, // itemSize is required to display proper amount of items
      onLazyLoad,
      lazy: true
    }
  } as Partial<DataTablePropsSingle<TradesDataResponse[]>>;

  const MOBILE_PROPS = {
    cellClassName: () => 'no--padding',
    className: clsx(COMMON_PROPS.className, 'row--no-border'),
    selectionMode: 'single' as DataTablePropsSingle<TradesDataResponse[]>['selectionMode'],
    showHeaders: false,
    virtualScrollerOptions: {
      ...COMMON_PROPS.virtualScrollerOptions,
      itemSize: MOBILE_GRID_ROW_HEIGHT, // itemSize is required to display proper amount of items
      onScroll: onTableScroll,
    },
    children: (
      <Column
        body={(data: TradesDataResponse): JSX.Element =>
          TradeItem({
            data,
            handleRowClick,
            setActionItem,
            contextMenu: contextMenuActions.current,
          })
        }
      />
    ),
  } as Partial<DataTablePropsSingle<TradesDataResponse[]>>;

  const DESKTOP_PROPS = {
    selectionMode: 'single',
    onSort: handleOrderChange,
    children: [
      <Column
        key='instrument'
        field='instrument'
        body={(data, config) => SingleLineTruncated(data, config, true)}
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Instrument'
          by={SortableFieldName.Instrument}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.Instrument}
      />,
      <Column
        key='type'
        field='type'
        body={TypeChip}
        headerClassName='sorting--server-side'
        header={<>
          <ServerSortHeader
            label='Type'
            by={SortableFieldName.Type}
            current={sortOrder}
          />
          <div className='blotter-data-table__filter-icon--wrapper' onClick={cm.current?.show}>
            <i className='iconoir-filter icon--tiny' />
            {!!filterCount && <Badge value={filterCount} />}
            <ContextMenu className='blotter-data-table__filter-menu' ref={cm} model={contextMenuItems} />
          </div>
        </>}
        sortable
        sortField={SortableFieldName.Type}
      />,
      <Column
        body={(data: TradesDataResponse) => data.isImported ? data.buyerObBroker.userName : formatName(data.buyerObBroker.userName)}
        key='buyerObBroker.userName'
        field='buyerObBroker.userName'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Broker (Buy)'
          by={SortableFieldName.BuyerObBrokerUserName}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.BuyerObBrokerUserName}
      />,
      <Column
        body={DoubleLineSimple}
        key='buyerContactName,buyerCompany'
        field='buyerContactName,buyerCompany'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Buy Side'
          by={SortableFieldName.BuyerContactName}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.BuyerContactName}
      />,
      <Column
        body={(data: TradesDataResponse) => data.isImported ? data.sellerObBroker.userName : formatName(data.sellerObBroker.userName)}
        key='sellerObBroker.userName'
        field='sellerObBroker.userName'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Broker (Sell)'
          by={SortableFieldName.SellerObBrokerUserName}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.SellerObBrokerUserName}
      />,
      <Column
        body={DoubleLineSimple}
        key='sellerContactName,sellerCompany'
        field='sellerContactName,sellerCompany'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Sell Side'
          by={SortableFieldName.SellerContactName}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.SellerContactName}
      />,
      <Column
        body={(data: TradesDataResponse, config: ColumnBodyOptions) => SingleLineWithAddon(data, config, data.quantity.unit)}
        bodyClassName='no--padding blotter-data-table__column--quantity'
        key='quantity.amount'
        field='quantity.amount'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Quantity'
          by={SortableFieldName.QuantityAmount}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.QuantityAmount}
      />,
      <Column
        body={(data: TradesDataResponse, config: ColumnBodyOptions) => SingleLineWithAddon(data, config, data.price.unit)}
        bodyClassName='no--padding blotter-data-table__column--price'
        key='price.amount'
        field='price.amount'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Price'
          by={SortableFieldName.PriceAmount}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.PriceAmount}
      />,
      <Column
        body={(data: TradesDataResponse, config: ColumnBodyOptions) => ReadableDate(data, config, 'dd LLL yyyy, HH:mm ZZZZ')}
        key='dateTime'
        field='dateTime'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Trade Date, Time'
          by={SortableFieldName.DateTime}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.DateTime}
      />,
      <Column
        key='clearingId'
        field='clearingId'
        headerClassName='sorting--server-side'
        header={<ServerSortHeader
          label='Clearing ID'
          by={SortableFieldName.ClearingId}
          current={sortOrder}
        />}
        sortable
        sortField={SortableFieldName.ClearingId}
      />,
      <Column
        key='actions'
        body={(item: TradesDataResponse) => (
          <TradeActionMenu
            item={item}
            setActionItem={setActionItem}
            contextMenu={contextMenuActions.current}
          />
        )}
      />,
    ],
  } as Partial<DataTablePropsSingle<TradesDataResponse[]>>;

  return (
    <>
      <DataTable
        key={`${ isMobile }`}
        {...COMMON_PROPS}
        {...(isMobile ? MOBILE_PROPS : DESKTOP_PROPS)}
      />
      <ContextMenu className={!actionItem ? 'blotter-data-table__action-menu-init-state' : ''} ref={contextMenuActions} model={contextMenuActionsItems} />
    </>
  );
};

export { BlotterDataTable };
export default BlotterDataTable;
