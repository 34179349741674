import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DateTime, Duration } from 'luxon';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber, type InputNumberChangeEvent, type InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import clsx from 'clsx';

import DateTimeRange, { type ExternalHandles } from 'components/DateTimeRange';
import BorealisBar from 'components/BorealisBar';
import SingleEntitySearch from 'components/EntitySearch/SingleEntitySearch';
import TimeInput from 'components/TimeInput';
import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import { humanDate } from 'helpers/Utils/formatters';
import { DATA_DEFAULT_VALUES, FIELDS_CONFIG } from 'modules/DataModule/Models/Consts';
import { ClientSuggestion, EmailSuggestion } from 'modules/DataModule/Templates/Suggestion';
import { useGetRegions } from 'modules/DataModule/Services/DataModuleAPI';

import type { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';
import type { Option, SubscriptionRequest } from 'modules/DataModule/Models/SubscriptionRequest';
import type F from 'types/generic-type';
import type { SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';

type SubscriptionFormProps = {
  isEditMode: boolean;
  request: SubscriptionRequest;
  mutate: (mutation: Partial<SubscriptionRequest>) => void;
  errors: F<string> | null;
};

const SubscriptionFormItems = (props: SubscriptionFormProps): JSX.Element => {
  const { errors, isEditMode, request, mutate } = props;

  const [/* isDateParsing */, setIsDateParsing] = useState<boolean>(false);
  const [showStartDateTimeError, setShowStartDateTimeError] = useState<boolean>(false);
  const [showExpiryDateTimeError, setShowExpiryDateTimeError] = useState<boolean>(false);

  const expiryDateRef = useRef<ExternalHandles>(null);

  const { data: regions, isLoading: regionsLoading, isValidating: regionsValidating } = useGetRegions();

  useEffect(() => {
    if (regions) {
      // filter out invalid regions that are currently in the request
      mutate({ regions: request.regions.filter(r => regions.find(q => q.toLowerCase() === r.toLowerCase())) });
    }
    // eslint-disable-next-line
  }, [regions]);

  return <div className='subscription-details__form direction--column grow-to-fill position--relative' >
    <div className='form-input__container'>
      <label htmlFor='rate-grid__subscription-name'>Subscription Name*</label>
      <div className={clsx('p-inputgroup', { 'p-invalid': !!errors?.subscriptionName })}>
        <InputText
          id='rate-grid__subscription-name'
          value={request.subscriptionName}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => mutate({ subscriptionName: e.target.value })}
          keyfilter='alphanum' // don't allow special chars, space, etc.
        />
      </div>
      {errors?.subscriptionName && <small className='message-invalid'>Required field</small>}
    </div>
    <SingleEntitySearch
      allowCustomSelection
      completeMethod={(change) => mutate({ clientName: change })}
      callback={(change?: (SearchSuggestionsResponse & { ClientEmail: string; }) | string): void => {
        // mutate only if `change` is SearchSuggestion, 'custom selection' is handled by `completeMethod` prop
        if (typeof change !== 'string') {
          mutate({ clientName: change?.value, clientEmail: change?.ClientEmail });
        }
      }}
      onInputClear={(): void => mutate({ clientName: '', clientEmail: '' })}
      label='Client*'
      errorVisibleAfterTouch={false}
      showError={!!errors?.clientName}
      fields={EntitySearchFieldsEnum.SecuredResourceClientName}
      module={EntitySearchGroupEnum.DataManagement}
      initialTerm={request.clientName}
      itemTemplate={ClientSuggestion}
      onFocusMethod={e => e.target.select()}
    />
    <SingleEntitySearch
      allowCustomSelection
      completeMethod={(change) => mutate({ clientEmail: change })}
      callback={(change?: (SearchSuggestionsResponse & { ClientName: string; }) | string): void => {
        // mutate only if `change` is SearchSuggestion, 'custom selection' is handled by `completeMethod` prop
        if (typeof change !== 'string') {
          mutate({ clientEmail: change?.value, clientName: change?.ClientName });
        }
      }}
      onInputClear={(): void => mutate({ clientEmail: '' })}
      label='Client Email*'
      errorVisibleAfterTouch={false}
      showError={!!errors?.clientEmail}
      customErrorMessage={errors?.clientEmail}
      fields={EntitySearchFieldsEnum.SecuredResourceClientEmail}
      module={EntitySearchGroupEnum.DataManagement}
      initialTerm={request.clientEmail}
      itemTemplate={EmailSuggestion}
      onFocusMethod={e => e.target.select()}
    />
    <DateTimeRange
      label='Start Date*'
      onEmptyValue={(): void => {
        mutate({ startDate: null, expiryDate: null });
        setIsDateParsing(false);
      }}
      onParsingStart={(): void => {
        setIsDateParsing(true);
        setShowStartDateTimeError(false);
      }}
      onDateParsed={(date: ParssedDateTimeResult): void => {
        const startDate = DateTime.fromISO(date.fromString).toUTC();

        mutate({
          startDate,
          expiryDate: request.numberOfDays ?
            startDate.plus(Duration.fromObject({ days: request.numberOfDays })).toUTC() :
            null,
        });
        setIsDateParsing(false);
      }}
      onDateParseError={(value: string) => {
        mutate({ startDate: DateTime.fromISO(value) });
        setIsDateParsing(false);
        setShowStartDateTimeError(true);
      }}
      defaultValue={request.startDate ? humanDate(request.startDate.toString(), { time: true, toUTC: true }) : ''}
      showErrorMessage={showStartDateTimeError || !!errors?.startDate}
      required
    />
    <div className='form-input__container'>
      <label htmlFor='rate-grid__type'>Type</label>
      <div className='rate-grids--radio-buttons'>
        {(FIELDS_CONFIG.isTrial[request.dataSet]?.options as Option[]).map(opt => <>
          <RadioButton
            key={opt.label}
            inputId='rate-grid__type--radio'
            checked={request.isTrial === opt.value}
            value={opt.value}
            disabled={opt.disabled}
            onChange={(e) => {
              mutate({
                isTrial: e.value,
                numberOfDays: e.value ? DATA_DEFAULT_VALUES.trial.numberOfDays : DATA_DEFAULT_VALUES.full.numberOfDays,
                expiryDate: request.startDate ?
                  request.startDate.plus(Duration.fromObject({
                    days: e.value ? DATA_DEFAULT_VALUES.trial.numberOfDays : DATA_DEFAULT_VALUES.full.numberOfDays
                  })).toUTC() :
                  null,
                maxProducts: e.value ? DATA_DEFAULT_VALUES.trial.maxProducts : DATA_DEFAULT_VALUES.full.maxProducts,
                maxHistoryDays: e.value ? DATA_DEFAULT_VALUES.trial.maxHistoryDays : DATA_DEFAULT_VALUES.full.maxHistoryDays,
              });
            }}
          />
          <label htmlFor='rate-grid__type--radio'>
            {opt.label}
          </label>
        </>)}
      </div>
    </div>
    <div className='form-input__container'>
      <label htmlFor='rate-grid__number-of-days'>Number of Days*</label>
      <div className={clsx('p-inputgroup', { 'p-invalid': !!errors?.numberOfDays })}>
        <InputNumber
          id='rate-grid__number-of-days'
          disabled={!isEditMode}
          value={request.numberOfDays}
          onValueChange={(e: InputNumberValueChangeEvent) => {
            expiryDateRef.current?.reset();
            mutate({
              numberOfDays: e.value,
              expiryDate: (e.value || e.value === 0) ? request.startDate?.plus({ days: e.value }).toUTC() : null
            });
          }}
          onChange={(e: InputNumberChangeEvent): void => {
            expiryDateRef.current?.reset();
            mutate({
              numberOfDays: e.value,
              expiryDate: (e.value || e.value === 0) ? request.startDate?.plus({ days: e.value }).toUTC() : null
            });
          }}
          onFocus={e => e.target.select()}
          min={FIELDS_CONFIG.numberOfDays[request.dataSet]!.min}
        />
      </div>
      {errors?.numberOfDays && <small className='message-invalid'>Required field</small>}
    </div>
    {FIELDS_CONFIG.expiryDate[request.dataSet]?.display && <DateTimeRange
      ref={expiryDateRef}
      label='Expiry Date*'
      onEmptyValue={(): void => {
        mutate({ expiryDate: null, numberOfDays: null });
        setIsDateParsing(false);
      }}
      onParsingStart={(): void => {
        setIsDateParsing(true);
        setShowExpiryDateTimeError(false);
      }}
      onDateParsed={(date: ParssedDateTimeResult): void => {
        const expiryDate = DateTime.fromISO(date.fromString).toUTC();

        mutate({
          expiryDate,
          numberOfDays: request.startDate ? Math.floor(expiryDate.diff(request.startDate, ['days']).days) : null
        });
        setIsDateParsing(false);
      }}
      onDateParseError={(value: string) => {
        mutate({ expiryDate: DateTime.fromISO(value), numberOfDays: null });
        setIsDateParsing(false);
        setShowExpiryDateTimeError(true);
      }}
      defaultValue={request.expiryDate ? humanDate(request.expiryDate, { time: true, toUTC: true }) : ''}
      showErrorMessage={showExpiryDateTimeError || !!errors?.expiryDate}
      required
      disabled={!isEditMode}
    />}
    {FIELDS_CONFIG.timeWindow[request.dataSet]?.display && <div className='form-input__container'>
      <label htmlFor='rate-grid__time-window'>Time window*</label>
      <div className={clsx('p-inputgroup', { 'p-invalid': !!errors?.timeWindow })}>
        <MultiSelect
          display='chip'
          showSelectAll={false}
          panelHeaderTemplate={<></>}
          placeholder='Select time window'
          options={FIELDS_CONFIG.timeWindow[request.dataSet]?.options}
          onChange={(e) => mutate({ timeWindow: e.value })}
          value={request.timeWindow}
        />
      </div>
      {errors?.timeWindow && <small className='message-invalid'>{errors.timeWindow || 'Required field'}</small>}
    </div>}
    <div className='form-input__container'>
      <label htmlFor='rate-grid__calls-per-minute'>Calls per Minute*</label>
      <div className={clsx('p-inputgroup', { 'p-invalid': !!errors?.callsPerMinute })}>
        <InputNumber
          id='rate-grid__calls-per-minute'
          value={request.callsPerMinute}
          onChange={(e: InputNumberChangeEvent): void => mutate({ callsPerMinute: e.value })}
          onValueChange={(e: InputNumberValueChangeEvent): void => mutate({ callsPerMinute: e.value })}
          min={FIELDS_CONFIG.callsPerMinute[request.dataSet]!.min}
          onFocus={e => e.target.select()}
        />
      </div>
      {errors?.callsPerMinute && <small className='message-invalid'>Required field</small>}
    </div>
    <div className='form-input__container'>
      <label htmlFor='rate-grid__market-close'>Market Close (UTC)*</label>
      <div className={clsx('p-inputgroup', { 'p-invalid': !!errors?.marketClose })}>
        <TimeInput
          id='rate-grid__market-close'
          defaultValue={request.marketClose}
          callback={(value) => mutate({ marketClose: value })}
        />
      </div>
      {errors?.marketClose && <small className='message-invalid'>{errors.marketClose || 'Required field'}</small>}
    </div>
    {FIELDS_CONFIG.maxProducts[request.dataSet]?.display && <div className='form-input__container'>
      <label htmlFor='rate-grid__max-products'>Max Products*</label>
      <div className={clsx('p-inputgroup', { 'p-invalid': !!errors?.maxProducts })}>
        <InputNumber
          inputId='rate-grid__max-products'
          value={request.maxProducts}
          onValueChange={(e: InputNumberValueChangeEvent): void => mutate({ maxProducts: e.value })}
          onChange={(e: InputNumberChangeEvent): void => mutate({ maxProducts: e.value })}
          min={FIELDS_CONFIG.maxProducts[request.dataSet]!.min}
          max={FIELDS_CONFIG.maxProducts[request.dataSet]!.max}
          onFocus={e => e.target.select()}
        />
      </div>
      {errors?.maxProducts && <small className='message-invalid'>Required field</small>}
    </div>}
    {FIELDS_CONFIG.maxHistoryDays[request.dataSet]?.display && <div className='form-input__container'>
      <label htmlFor='rate-grid__max-history-days'>Max History Days*</label>
      <div className={clsx('p-inputgroup', { 'p-invalid': !!errors?.maxHistoryDays })}>
        <InputNumber
          inputId='rate-grid__max-history-days'
          value={request.maxHistoryDays}
          onValueChange={(e: InputNumberValueChangeEvent): void => mutate({ maxHistoryDays: e.value })}
          onChange={(e: InputNumberChangeEvent): void => mutate({ maxHistoryDays: e.value })}
          min={FIELDS_CONFIG.maxHistoryDays[request.dataSet]!.min}
          max={FIELDS_CONFIG.maxHistoryDays[request.dataSet]!.max}
          onFocus={e => e.target.select()}
        />
      </div>
      {errors?.maxHistoryDays && <small className='message-invalid'>Required field</small>}
    </div>}
    {FIELDS_CONFIG.subscriptions[request.dataSet]?.display && <div className='form-input__container'>
      <label htmlFor='rate-grid__subscriptions'>Subscriptions*</label>
      <div className={clsx('rate-grids--radio-buttons p-inputgroup', { 'p-invalid': !!errors?.subscriptions })}>
        {(FIELDS_CONFIG.subscriptions[request.dataSet]?.options as string[])?.map((opt) => <>
          <Checkbox
            key={opt}
            inputId={`rate-grid__subscriptions--checkbox-${opt}`}
            checked={!!request.subscriptions?.find(s => s.toLowerCase() === opt.toLowerCase())}
            value={opt}
            onChange={(e) => {
              mutate({
                subscriptions: e.checked ?
                  [...request.subscriptions, e.value] :
                  request.subscriptions.filter(it => it.toLowerCase() !== e.value.toLowerCase())
              });
            }}
          />
          <label htmlFor={`rate-grid__subscriptions--checkbox-${opt}`}>
            {opt}
          </label>
        </>)}
      </div>
      {errors?.subscriptions && <small className='message-invalid'>{errors.subscriptions || 'Required field'}</small>}
    </div>}
    {FIELDS_CONFIG.regions[request.dataSet]?.display && <div className='form-input__container'>
      <label htmlFor='rate-grid__regions'>Region*</label>
      <div className={clsx('rate-grids--radio-buttons p-inputgroup', { 'p-invalid': !!errors?.regions })}>
        {(regionsLoading || regionsValidating) ?
          <BorealisBar styleOverrides='regions--loader' /> :
          regions?.map(opt => <>
            <Checkbox
              key={opt}
              inputId={`rate-grid__regions--checkbox-${opt}`}
              checked={request.regions?.includes(opt.toUpperCase())}
              value={opt.toUpperCase()}
              onChange={(e) => {
                mutate({
                  regions: e.checked ?
                    [...request.regions, e.value] :
                    request.regions.filter(it => it !== e.value)
                });
              }}
            />
            <label htmlFor={`rate-grid__regions--checkbox-${opt}`}>
              {opt.toUpperCase()}
            </label>
          </>)}
        </div>
      {errors?.regions && <small className='message-invalid'>{errors.regions || 'Required field'}</small>}
    </div>}
    {FIELDS_CONFIG.groupable?.[request.dataSet]?.display && <div className='form-input__container'>
      <label htmlFor='rate-grid__group'>Group*</label>
      <div className='rate-grids--radio-buttons'>
        {(FIELDS_CONFIG.groupable[request.dataSet]?.options as Option[]).map(opt => <>
          <RadioButton
            key={opt.label}
            inputId={`rate-grid__group--radio-${opt.label}`}
            checked={request.groupable === opt.value}
            value={opt.value}
            onChange={(e) => mutate({ groupable: e.value })}
          />
          <label htmlFor={`rate-grid__group--radio-${opt.label}`}>
            {opt.label}
          </label>
        </>)}
      </div>
    </div>}
  </div>;
};

export default SubscriptionFormItems;