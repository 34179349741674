import { MouseEventHandler } from 'react';
import { Button } from 'primereact/button';

interface ExportButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  isLoading?: boolean;
  value?: string;
}

export const ExportButton = ({
  onClick,
  isDisabled = false,
  isLoading = false,
  value = 'Export',
}: ExportButtonProps): JSX.Element => (
  <Button
    size="small"
    className="surveillance-export"
    disabled={isDisabled}
    loading={isLoading}
    outlined
    onClick={onClick}
  >
    {value}
  </Button>
);
