import { DateTime } from 'luxon';
import { ColumnSortEvent, ColumnSortMetaData } from 'primereact/column';

import F, { FF } from 'types/generic-type';

export const sortByDateTime = (event: ColumnSortEvent, sortByField: string, key?: string): F<DateTime>[] => {
  const { data, order, multiSortMeta } = event;

  return data.sort((a: F<DateTime> | FF<DateTime>, b: F<DateTime> | FF<DateTime>) => {
    const aTyped = key !== undefined ? (a[sortByField] as F<DateTime>)[key] : a[sortByField] as DateTime;
    const bTyped = key !== undefined ? (b[sortByField] as F<DateTime>)[key] : b[sortByField] as DateTime;

    const { days } = aTyped.diff(bTyped, 'days');

    // If one of items or both are invalid - move them to the end
    if (!aTyped.isValid && bTyped.isValid) {
      return 1;
    } else if (!aTyped.isValid && !bTyped.isValid) {
      return 0;
    } else if (aTyped.isValid && !bTyped.isValid) {
      return -1;
    }

    if (multiSortMeta?.length) { // With grouping, first check group order
      return groupCompare(a, b, multiSortMeta) || singleCompare(days, multiSortMeta[1].order);
    }

    return singleCompare(days, order);
  });
};

const singleCompare = (days: number, order: 1 | 0 | -1 | undefined | null ): number =>
  Math.sign(isNaN(days) ? 0 : days) * (order as number);

const groupCompare = (a: F<DateTime> | FF<DateTime>, b:F<DateTime> | FF<DateTime>, multiSortMeta: ColumnSortMetaData[] ): number =>
  `${ a[multiSortMeta[0].field] }`.localeCompare(`${ b[multiSortMeta[0].field] }`) * (multiSortMeta[0].order as number);