import { useEffect, useState } from 'react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';

import type { ISearchFacet } from 'modules/CargoFlows/CargoFlows';
import type {
  ConditionalFilter,
  ConditionalFilterChange,
} from 'modules/CargoFlows/Models/CargoMovements';

enum StsOptions {
  Only = 'only',
  Exclude = 'exclude',
}

const GROUP_NAME = 'STS';

const FILTER_VALUES: { [key in StsOptions]: ConditionalFilter[] } = {
  [StsOptions.Only]: [
    { condition: '=', fieldName: 'HasSts', value: true, group: GROUP_NAME },
    {
      condition: '=',
      fieldName: 'IsStsLine',
      value: true,
      group: GROUP_NAME,
      operator: 'or',
    },
  ],
  [StsOptions.Exclude]: [
    { condition: '=', fieldName: 'HasSts', value: false, group: GROUP_NAME },
    { condition: '=', fieldName: 'IsStsLine', value: false, group: GROUP_NAME },
  ],
};

const TOKEN_VALUES: { [key in StsOptions]: ISearchFacet[] } = {
  [StsOptions.Only]: [
    {
      searchTerm: 'STS',
      searchField: null as any,
      context: 'Is',
      key: GROUP_NAME,
    },
  ],
  [StsOptions.Exclude]: [
    {
      searchTerm: 'STS',
      searchField: null as any,
      context: 'Is Not',
      key: GROUP_NAME,
    },
  ],
};

interface STSFilterProps {
  activeFilters: ConditionalFilter[];
  onChange: (arg: ConditionalFilterChange, groupName: string) => void;
}

export default function STSFilter(props: STSFilterProps): JSX.Element {
  const { activeFilters, onChange } = props;
  const options: StsOptions[] = [StsOptions.Only, StsOptions.Exclude];
  const [value, setValue] = useState<StsOptions | null | undefined>(undefined);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const stsFilter = activeFilters.find(item => item.group === GROUP_NAME); // take 1st - it's sufficient enough to check if "Only" option is selected

    if (stsFilter) {
      setChecked(true);

      const only = stsFilter.condition === '=' && stsFilter.value === true;
      setValue(only ? StsOptions.Only : StsOptions.Exclude);
    } else {
      setChecked(false);
      setValue(null);
    }
  }, [activeFilters]);

  const notifyValueChanged = (newValue: StsOptions | null) => {
    switch (newValue) {
      // just don't call callback if value is not set yet
      case undefined:
        break;
      case StsOptions.Only:
        onChange(
          {
            filters: FILTER_VALUES[StsOptions.Only],
            tokens: TOKEN_VALUES[StsOptions.Only],
          },
          GROUP_NAME
        );
        break;
      case StsOptions.Exclude:
        onChange(
          {
            filters: FILTER_VALUES[StsOptions.Exclude],
            tokens: TOKEN_VALUES[StsOptions.Exclude],
          },
          GROUP_NAME
        );
        break;
      case null:
      default:
        onChange({ filters: [], tokens: [] }, GROUP_NAME);
        break;
    }
  };
  const handleCheck = (e: CheckboxChangeEvent) => {
    const newValue = !e.checked ? null : options[0];
    setChecked(Boolean(e.checked));
    setValue(newValue);
    notifyValueChanged(newValue);
  };
  const selectionChanged = (event: SelectButtonChangeEvent): void => {
    setValue(event.value);
    notifyValueChanged(event.value);
  };

  return (
    <div className="additional-filters__container--internal selectable-filter__container">
      <div className="additional-filters__checkbox">
        <Checkbox
          inputId="sts_filter_checkbox"
          checked={checked}
          onChange={handleCheck}
        />
        <label htmlFor="sts_filter_checkbox">Ship To Ship (STS)</label>
      </div>
      <SelectButton
        disabled={!checked}
        onChange={selectionChanged}
        options={options}
        unselectable={false}
        value={value}
      />
      <p className="additional-filters__info">
        {value === StsOptions.Only &&
          'Will only INCLUDE results which are marked a STS activity'}
        {value === StsOptions.Exclude &&
          'Will explicitly EXCLUDE activity marked as STS from returned results'}
      </p>
    </div>
  );
}
