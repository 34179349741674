import * as React from 'react';
import { Outlet } from "react-router-dom";

import SecondaryNavigation from "components/SecondaryNavigation";

interface ITestingModuleProps  {
	items: {path: string, label: string, element: any }[]
}

function InternalTesting(params: ITestingModuleProps) {

	const { items } = params;

	

  return <>
	{/* <header>header</header> */}
	<nav>
		<SecondaryNavigation items={items}/>
	</nav>
	<main>
		<Outlet />
	</main>
  </>;
}

export default InternalTesting;
