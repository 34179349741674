import { useState, useEffect } from "react";
import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { clsx } from "clsx";

import { camelToSpace } from "helpers/Utils/string";

import { useSearchSuggestions } from "./Services/SearchEntitiesAPI";
import { EntitySearchGroupEnum, EntitySearchFieldsEnum } from "./Models/Enums";


interface ISingleEntitySearchParams {
  label: string,
  module: EntitySearchGroupEnum;
  fields: EntitySearchFieldsEnum | EntitySearchFieldsEnum[];
  callback: Function;
  chunkSize?: number,
  field?: string;
  icon?: string;
  group?: boolean;
  color?: string;
  placeholder?: string;
  itemTemplate?: any // TODO - get a type for this…
}

function GroupedEntitySearch(params: ISingleEntitySearchParams) {

  const { module, fields, chunkSize, icon, group, color, placeholder, callback, itemTemplate, field  } = params;
  const [ term, setTerm ] = useState("");
  const [ filtered, setFiltered] = useState<any[]>([]);

  const { data, error, isLoading } = useSearchSuggestions<string>({module, fields, term, chunkSize})

  const others = {
    itemTemplate, placeholder, field,
    onBlur: (e: any) => setTerm(""),

  }

  if (error) {
    //  TODO - created a filtered set that shows as empty?
  }

  useEffect(() => {
		console.log("owt come bacj", data)
    if (!data) return;
    setFiltered(data ?? []);
  }, [data])

  const groupedItemTemplate = (item: any) => {
    return (
      <div>{camelToSpace(item.searchFieldName)}</div>
    );
  };

  return <>
    <div className={clsx({"p-inputgroup": group })}>
      { group &&
        <span className={clsx(
          `p-inputgroup-addon`,
          color && `addon-color--${color}`
        )}>
          <i className={`iconoir-${icon} icon--small`}></i>
        </span>
      }
      <AutoComplete
        value={term}
        suggestions={[...filtered]} // use spread operator to force AutoComplete to rerender and show `suggestions`, even if input is the same
        emptyMessage={isLoading ? "Searching…" : "Sorry. No matches for that search…"}
        showEmptyMessage
				minLength={3}
				completeMethod={(e: AutoCompleteCompleteEvent) => {
				//	placeholder…
				}}
				loadingIcon={<></>}
				onChange={(e) => {
					setTerm(e.value)
				}}
        optionGroupLabel="searchFieldName"
        optionGroupChildren="values"
        optionGroupTemplate={groupedItemTemplate}
        scrollHeight="40dvh"
        onSelect={(e) => {
          //  dont need the search term any more
          setTerm("");

          //  Trigger the callback method
          callback(e.value)
        }}
        panelClassName={clsx("autocomplete-entity autocomplete-entity-grouped", !filtered.length && "empty")}
        {...others} />
    </div>
  </>;
}

export default GroupedEntitySearch;
