import Ribbon from "../Ribbon";

export default function AlphaReleaseRibbon(): JSX.Element {
    
	return <Ribbon
		className="mobile-version-in-development-ribbon"
		icon="iconoir-tools"
		severity="info"
		text="The module is an early alpha release, and still in active development"
	/>
}
