import { DateTime } from "luxon";
import { clsx } from "clsx";
import { ColumnBodyOptions } from "primereact/column";

import { formatName } from "helpers/Utils/string";
import { humanDate } from "helpers/Utils/formatters";

import layouts from "./DoubleLine.module.scss";
import styles from "./ReadableDate.module.scss";

export const DoubleLineUpdatedAuthorAndDate = <T,>(data: T, config: ColumnBodyOptions): JSX.Element => {

	let [upper, lower] = config.field.split(",");
	let dateUpper = false;

	// switch fields if date should be upper
	// check if upper is DateTime or is valid ISO string
	if (DateTime.isDateTime(data[upper as keyof T]) || DateTime.fromISO(data[upper as keyof T] as string).isValid) {
		[lower, upper] = config.field.split(",");
		dateUpper = true;
	}

	const date = DateTime.fromISO(data[lower as keyof T] as string) as DateTime;
	const name = formatName(data[upper as keyof T] as string);

	if (date?.isValid) {
		return <div className={clsx(
			'direction--column align-items--start',
			layouts.cell
		)}>
			<span className={dateUpper ? 'lower' : clsx('upper', layouts.upper)} title={data[upper as keyof T] as string}>{name}</span>
			<time className={dateUpper ? clsx('upper text-primary', layouts.upper) : 'lower'} dateTime={date.toISO() as string}>{humanDate(date ?? "", { time: true, toUTC: true, dayName: false })} GMT</time>
		</div>;
	} else {
		return <i className={styles.unknownDate}>Unknown</i>;
	}
};