export enum SubscriptionStatus {
  Active = 'Active',
  Expired = 'Expired',
  Pending = 'Pending',
};

export enum SubscriptionDataset {
  User = 'User',
  DerivativesExchanges = 'DerivativesExchanges',
  ArtisPackage = 'ArtisPackage',
  ExcelApi = 'ExcelApi',
  BalticPackage = 'BalticPackage',
  RateGridsPackage = 'RateGridsPackage',
};
