import { ColumnSortEvent } from 'primereact/column';

import F from 'types/generic-type';

export const sortBySelectedField = (event: ColumnSortEvent, sortByField: string): F<string>[] => {

    const { data, order } = event;

    return data.sort((a: F<string>, b: F<string>) => {
    
        const first: string = a[sortByField] ?? "";
        const second: string = b[sortByField] ?? "";

        return first.localeCompare(second) * (order as number);
    });
}