import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { TabPanel, TabView } from 'primereact/tabview';

import Loader from 'components/Loader';
import { ToastSeverity } from 'components/ToastMessage';

import { SurveillanceEntityStatus } from '../../Models/Enums';

import ContentComponent from './Components/ContentComponent';
import DetailsComponent from './Components/DetailsComponent';
import MetadataView from './Components/MetadataView';
import { isMessagesEqual } from './Models/Helpers';
import { SurveillanceMessageResult } from './Models/Response';
import {
  SurveillanceDetailsAPI,
  useCreateTranscriptionVersion,
  useDetails,
  useMessages,
} from './Services/SurveillanceDetailsAPI';
import ActivityView from './ActivityView';
import {
  ContentComponentMessage,
  ContentComponentProps,
  DetailsAreaProps,
  DetailsPanelState,
  DetailsTabs,
  RequestSiblingMessagesDirection,
  TranscriptionState,
} from './Models';

import { formatName } from 'helpers/Utils/string';
import { SurveillanceMediaRecording } from 'modules/Surveillance/Models/ReportsResponse';

import styles from './DetailsArea.module.scss';

const MESSAGES_COUNT = 5;
const audioTypes = Object.keys(SurveillanceMediaRecording);

const Details = ({
  activeWorksheetId,
  record,
  setRecordSelected,
  selectedMessages,
  setSelectedMessages,
  selectedContentMessages,
  setSelectedContentMessages,
  panelState,
  setPanelState,
  toastRef,
  searchRequestFields,
  resultsMode,
}: DetailsAreaProps): JSX.Element => {
  const { id, partitionKey, provider } = record;

  const [activeTab, setActiveTab] = useState(0);
  const [topRibbon, setTopRibbon] = useState<JSX.Element>();
  const [isEditing, setIsEditing] = useState(false);
  const [msgDirection, setMsgDirection] =
    useState<RequestSiblingMessagesDirection | null>(null);
  const [hasNoNextMessages, setHasNoNextMessages] = useState<boolean | null>(
    null
  );
  const [hasNoPrevMessages, setHasNoPrevMessages] = useState<boolean | null>(
    null
  );
  const [prevMessagesPage, setPrevMessagesPage] = useState<number>(0);
  const [nextMessagesPage, setNextMessagesPage] = useState<number>(0);
  const [messagesList, setMessagesList] = useState<
    ContentComponentMessage[] | undefined
  >(undefined);

  const scrollDetailsRef = useRef<HTMLDivElement>(null);
  const scrollExpandedDetailsRef = useRef<HTMLDivElement>(null);
  const contentComponentRef = useRef<HTMLDivElement>(null);
  const contentComponentExpandedRef = useRef<HTMLDivElement>(null);

  const mapMessagesList = useCallback(
    (message: SurveillanceMessageResult): ContentComponentMessage => ({
      ...message,
      isEditable: false,
    }),
    []
  );

  const scrollTo = useCallback((id: string, partitionKey: string): void => {
    const observer = new MutationObserver((_, obs) => {
      obs.disconnect();

      const top = document.getElementById(`${ id }-${ partitionKey }`)?.offsetTop;

      scrollDetailsRef.current?.scrollTo({ top });
      scrollExpandedDetailsRef.current?.scrollTo({ top });
    });
    // Observe changes in component's subtree
    contentComponentRef.current &&
      observer.observe(contentComponentRef.current.getRootNode(), {
        childList: true,
        subtree: true,
      });
    contentComponentExpandedRef.current &&
      observer.observe(contentComponentExpandedRef.current.getRootNode(), {
        childList: true,
        subtree: true,
      });
  }, []);

  const {
    details,
    isLoading,
    mutate: getDetails,
  } = useDetails(
    {
      id,
      partitionKey,
      providerName: provider,
      searchRequestFields,
    },
    activeWorksheetId
  );

  const mainMessage = useMemo(
    () => ({
      ...record,
      highlights: details?.highlights,
      versions: details?.versions || [],
      isEditable: details?.media && audioTypes.includes(details?.media),
    }),
    [record, details]
  );

  const checkActivity = useCallback(async () => {
    const activity = await SurveillanceDetailsAPI.getActivity({
      body: { id: record.id, partitionKey: record.partitionKey },
      worksheetId: activeWorksheetId,
    });

    const result = activity.find(
      item =>
        item.status === SurveillanceEntityStatus.Escalated &&
        item.status !== item.previousStatus
    );

    setTopRibbon(
      result ? (
        <Message
          className={styles.topRibbon}
          text={
            <>
              This was escalated and forwarded to
              <br />
              {formatName(result.assignedUserName ?? '')}
              <br />
              {result.updatedAt.toFormat('HH:mm:ss LLL dd yyyy (ZZZZ)')}
            </>
          }
        />
      ) : undefined
    );
  }, [activeWorksheetId, record]);

  useEffect(() => {
    if (record.status === SurveillanceEntityStatus.Escalated) {
      checkActivity();
    } else {
      setTopRibbon(undefined);
    }
  }, [checkActivity, record]);

  const { trigger: saveTranscription } = useCreateTranscriptionVersion();

  const { messages, totalPages } = useMessages(
    msgDirection !== null
      ? {
        id,
        partitionKey,
        direction: msgDirection,
        pageNumber:
            msgDirection === RequestSiblingMessagesDirection.Previous
              ? prevMessagesPage + 1
              : nextMessagesPage + 1,
        pageSize: MESSAGES_COUNT,
      }
      : undefined,
    activeWorksheetId
  );

  // reset state
  useEffect(() => {
    setActiveTab(0);
    setIsEditing(false);
    setMsgDirection(null);
    setHasNoNextMessages(null);
    setHasNoPrevMessages(null);
    setPrevMessagesPage(0);
    setNextMessagesPage(0);
    setMessagesList([]);
  }, [id]);

  useEffect(() => {
    if (details) {
      setMessagesList([mainMessage]);
      setHasNoNextMessages(null);
      setHasNoPrevMessages(null);
      setPrevMessagesPage(0);
      setNextMessagesPage(0);
      setMsgDirection(null);
    }
  }, [details, record, mainMessage]);

  useEffect(() => {
    if (messages) {
      // scroll to main message in case of messages are empty
      const messageToScroll = messages[0] || mainMessage;
      if (msgDirection === RequestSiblingMessagesDirection.Next) {
        scrollTo(messageToScroll.id, messageToScroll.partitionKey);
        setNextMessagesPage(n => ++n);
        setMessagesList(m => [...(m || []), ...messages.map(mapMessagesList)]);
        if (nextMessagesPage + 1 === totalPages || !messages.length) {
          setHasNoNextMessages(true);
        }
      } else if (msgDirection === RequestSiblingMessagesDirection.Previous) {
        scrollTo(messageToScroll.id, messageToScroll.partitionKey);
        setPrevMessagesPage(n => ++n);
        setMessagesList(m => [...messages.map(mapMessagesList), ...(m || [])]);
        if (prevMessagesPage + 1 === totalPages || !messages.length) {
          setHasNoPrevMessages(true);
        }
      }
      setMsgDirection(null);
    }
  }, [
    mainMessage,
    messages,
    msgDirection,
    nextMessagesPage,
    prevMessagesPage,
    totalPages,
    scrollTo,
    mapMessagesList,
  ]);

  useEffect(() => {
    if (selectedMessages.find(m => isMessagesEqual(m, mainMessage))) {
      setSelectedContentMessages(items => [
        ...items.filter(i => !isMessagesEqual(i, mainMessage)),
        mainMessage,
      ]);
    } else {
      setSelectedContentMessages(items => [
        ...items.filter(
          i =>
            !isMessagesEqual(i, mainMessage) &&
            i.parent &&
            !isMessagesEqual(i.parent, mainMessage)
        ),
      ]);
    }
  }, [mainMessage, selectedMessages, setSelectedContentMessages]);

  const saveTranscriptionVersion = useCallback(
    async (transcription: string): Promise<void> => {
      await saveTranscription({
        id,
        partitionKey,
        transcription,
      }).then(() => {
        toastRef?.current?.replace({
          severity: ToastSeverity.SUCCESS,
          title: 'Changes saved',
          message: 'Success',
        });
        record.content = transcription;
        /**
         * force update results grid item with
         * changed content value without reload
         */
        setRecordSelected({ ...record });
        getDetails();
      });
    },
    [
      id,
      partitionKey,
      record,
      toastRef,
      getDetails,
      saveTranscription,
      setRecordSelected,
    ]
  );

  const onTranscriptionState = useCallback(
    (_: string, state: TranscriptionState): void => {
      switch (state) {
      case TranscriptionState.Edit:
        setIsEditing(true);
        break;
      default:
        setIsEditing(false);
        break;
      }
    },
    []
  );

  const contentComponentProperties: ContentComponentProps = useMemo(
    () => ({
      className: styles.itemContainer,
      worksheetId: activeWorksheetId,
      messagesPerPage: MESSAGES_COUNT,
      mainMessage,
      messages: messagesList,
      selectedMessages,
      setSelectedMessages,
      selectedSiblingMessages: selectedContentMessages,
      setSelectedSiblingMessages: setSelectedContentMessages,
      hasNoNextMessages,
      hasNoPrevMessages,
      panelState,
      onTranscriptionState,
      saveTranscriptionVersion,
      setMsgDirection,
      setPanelState,
      resultsMode,
    }),
    [
      activeWorksheetId,
      mainMessage,
      messagesList,
      selectedMessages,
      setSelectedMessages,
      selectedContentMessages,
      setSelectedContentMessages,
      hasNoNextMessages,
      hasNoPrevMessages,
      panelState,
      onTranscriptionState,
      saveTranscriptionVersion,
      setMsgDirection,
      setPanelState,
      resultsMode,
    ]
  );

  if (isLoading) {
    return (
      <div className={styles.flexCenterParent}>
        <div className={styles.flexCenterChild}>
          <Loader className='small' />
        </div>
      </div>
    );
  }

  return (
    <main
      className={
        panelState === DetailsPanelState.expanded ? 'drawer--active' : undefined
      }
      data-cols={panelState === DetailsPanelState.expanded ? '8,4' : undefined}
      data-drawer-style='slide'
      data-drawer-position='alongside-right'
    >
      <aside>
        <TabView
          renderActiveOnly={true}
          activeIndex={activeTab}
          onTabChange={({ index }): void => setActiveTab(index)}
        >
          <TabPanel
            contentClassName={clsx(
              !isEditing ? styles.scroll : '',
              'direction--column'
            )}
            header={DetailsTabs.Details}
          >
            <div
              ref={scrollDetailsRef}
              className={clsx(
                styles.detailsPanel,
                'grow-to-fill direction--column'
              )}
            >
              {topRibbon}
              <DetailsComponent
                hidden={panelState === DetailsPanelState.collapsed && isEditing}
                className={styles.itemContainer}
                companyName={record.company}
                details={details}
              />
              {panelState === DetailsPanelState.collapsed && (
                <ContentComponent
                  ref={contentComponentRef}
                  {...contentComponentProperties}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel
            visible={Boolean(details?.metadata)}
            header={DetailsTabs.Metadata}
          >
            <MetadataView
              metadata={details?.metadata}
              record={record}
              toastRef={toastRef}
              topRibbon={topRibbon}
            />
          </TabPanel>
          <TabPanel header={DetailsTabs.Activity}>
            <ActivityView
              activeWorksheetId={activeWorksheetId}
              record={record}
              toastRef={toastRef}
              topRibbon={topRibbon}
            />
          </TabPanel>
        </TabView>
        <Button
          text
          icon='iconoir-xmark icon--tiny'
          className={styles.closeButton}
          onClick={(): void => setRecordSelected(null)}
        />
      </aside>
      {panelState === DetailsPanelState.expanded && (
        <aside>
          <ContentComponent
            ref={contentComponentExpandedRef}
            scrollExpandedDetailsRef={scrollExpandedDetailsRef}
            {...contentComponentProperties}
          />
        </aside>
      )}
    </main>
  );
};

export { Details };
export default Details;
