//  TODO - better nameing to make this explicity about search trsults.
import { Flag } from 'components/Flag';

import styles from "./BasicTextWithFlag.module.scss";

export const BasicTextWithFlag = (data: any): JSX.Element  => {

    const { value } = data;

    const [ fulltext, label, flag ] = [...value.matchAll(/(.*?)\[(.*?)\]/g)][0] ?? [value, value, null];

    return <div className={styles.item}>
        <label title={fulltext}>{label}</label>
        <Flag code={flag} />
    </div>
}