import { InputText } from 'primereact/inputtext';
import { ChangeEvent, FocusEvent } from 'react';

import WeatherMarginField from '../../WeatherMarginField';

import SpeedField from 'components/SpeedField';
import { replaceItemAt } from 'helpers/Utils/collections';
import { getClampedValue } from 'helpers/Utils/misc';
import { MAX_HOURS_IN_PORT, MIN_HOURS_IN_PORT } from 'modules/DistanceCalc/Models/Consts';

import type { CalculationWaypoint } from 'modules/DistanceCalc/Models/CalculationResponse';
import type { CalculationRequest, LocationWeather } from 'modules/DistanceCalc/Models/CalculationRequests';
import type { CalcParamChange } from 'modules/DistanceCalc/Models/CalcParamsChange';

import './LegParameters.scss';

export interface LegParametersProps {
    calcParams: CalculationRequest,
    callback: (args: CalcParamChange) => void;
    waypoint: CalculationWaypoint;
}

export default function LegParameters (props: LegParametersProps): JSX.Element | null {
    const { calcParams, callback, waypoint } = props;

    const location = calcParams.locations.find(location => {
        // const group = location.group.toLowerCase();

        // return location.name === waypoint.location.name && WAYPOINT_TYPE_TO_GROUP_MAP[waypoint.type] === group;
        // TODO: rework this!
        return location.SignalOceanPortId === waypoint.location.searchEntityId;
    });

    const handleParamChange = (value: number | LocationWeather, paramName: string): void => {
        const index = calcParams.locations.findIndex(loc => loc === location);

        if (index > -1) {
            callback({
                name: 'locations',
                value: replaceItemAt(calcParams.locations, { ...location, [paramName]: value }, index)
            });
        }
    }

    const handleWeatherParamsChange = (value: LocationWeather): void => {
        handleParamChange(value, 'weather');
    }

    const handleSpeedChange = (value: number | null): void => {
        handleParamChange(value!, 'speed');
    }

    const handleHoursInPortChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = getClampedValue(+Number.parseInt(e.target.value).toFixed(0) || MIN_HOURS_IN_PORT, MIN_HOURS_IN_PORT, MAX_HOURS_IN_PORT);

        handleParamChange(value, 'hoursInPort');
    }

    if (!location) {
        return null;
    }

    return <div className="leg-parameters__container">
        <WeatherMarginField initialValue={location.weather} handleWeatherParamsChange={handleWeatherParamsChange} />
        <SpeedField initialValue={location.speed} onValueUpdate={handleSpeedChange} />
        <div className="hours-in-port-field__container">
            <label htmlFor="hours-in-port-field">
                Hours in port
            </label>
            <div className="p-inputgroup" >
                <InputText
                    id="hours-in-port-field"
                    keyfilter='num'
                    type='number'
                    name="hours-in-port"
                    onChange={handleHoursInPortChange}
                    onFocus={(e: FocusEvent) => (e.target as HTMLInputElement).select()}
                    value={`${location.hoursInPort ?? MIN_HOURS_IN_PORT}`}
                />
                <span className="p-inputgroup-addon">HRS</span>
            </div>
        </div>
    </div>
}