import axios, { type AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import fileDownload from 'js-file-download';

import ErrorToastService from 'components/Errors/ErrorToast/Services/ErrorToastService';
import { parsePropsToDateTime } from 'helpers/Utils/misc';

import { apiWorkflowResponse, apiWorkflowStartRequest,
	apiWorkflowTaskResponse, apiworkflowSearchRequest, 
	apiWorkflowSetTitle, apiWorkflowTaskUpdate, AddTaskUpdatePayload,
	apiWorkflowSetCommissionRate, WorkflowTemplateResponse, apiSupportedProvider } from '../Models';

import type { apiWorkflowTemplateResponse, Workflow } from '../Models';

import { notNil, Modify } from 'helpers/Utils/misc';

export class WorkflowApi {

	static getProviders = () => axios.request<null, { data: apiSupportedProvider[] }>({ 
			url: 'audit/workflow/providers',
			method: 'GET'
		})
		.then( ({data}) => data);

	static getTemplates = () => {

		return axios.request<null, { data: apiWorkflowTemplateResponse[]; }>({
			url: 'Audit/Workflow/Templates',
			method: 'GET'
		})
		.then( ({data}) => data.map(t => {
			const { template } = t;
			return {...t, template: {
				...parsePropsToDateTime(template, ['createdUtc']),
				properties: template.properties.map(
					prop => ({
						...prop, 
						control: { 
							...prop.control, 
							display: notNil(prop.control.display) 
								? prop.control.display 
								: true
						}}))
			}} as WorkflowTemplateResponse
		}))
		.catch( e => {
			console.log('The back-end barfed', e);
			throw e;
		})
	}

	static searchWorkflows = (url: string, params: { arg: Modify<apiworkflowSearchRequest, {workflowProvider: number}> }) => {
		
		const { workflowProvider, searchTerm, justMyWorkflows, justMyTasks } = params.arg;



		return axios.request<apiworkflowSearchRequest, { data: apiWorkflowResponse[]; }>({
			url: `Audit/Workflow/Search/${workflowProvider}`,
			method: 'POST',
			data: { searchTerm, justMyTasks, justMyWorkflows }
		})
		.then((r) => r.data.map(item => WorkflowApi.parseWorkflow(item)))
		.catch(e => {
				console.log('The back-end barfed', e);
				throw e;
		});
	}

	static addTaskUpdate(url: string, params: { arg: AddTaskUpdatePayload }): Promise<Workflow> {
		
		const { provider, workflowId, comment } = params.arg;
		const { assignedToId: assigneeId, currentStatus: taskStatus, taskId } = params.arg.task
		const method: string = 'PATCH';

		return axios.request<null, AxiosResponse<apiWorkflowResponse>, apiWorkflowTaskUpdate>({
			url: `Audit/Workflow/${workflowId}/${provider}/Task/${taskId}`,
			method: method,
			data: {
				assigneeId,	taskStatus,
				comments: comment ? [comment] : []
			}
		})
		.then(r => WorkflowApi.parseWorkflow(r.data) as Workflow)
		.catch(e => {
				console.log('The back-end barfed', e);
				throw e;
		})
	}


	static startWorkflow = (url: string, params: { arg: apiWorkflowStartRequest; }) => {
		const { properties, workflowTemplateName } = params.arg;

		return axios.request<apiWorkflowStartRequest, { data: apiWorkflowResponse; }>({
			url: `Audit/Workflow`,
			method: 'PUT',
			data: { workflowTemplateName, properties }
		})
		.then(r => WorkflowApi.parseWorkflow(r.data))
		.catch(e => {
				ErrorToastService.handleError(e, [400, 500, 503], 'Sorry, something has gone wrong. Please try again later.');
		})
  }

	static deleteWorkflow = (url: string, params: { arg: Workflow }): Promise<Workflow> => axios.request({
		url: `Audit/Workflow/${ params.arg.id }`,
		method: 'DELETE',
	}).then(() => params.arg );

	static exportWorkflow = (url: string, params: { arg: Workflow }): Promise<Blob> => {
		
		const { id, title } = params.arg;

		return axios.request({
				url: `Audit/Workflow/Export/${ id }`,
				method: 'GET',
				responseType: 'blob'
		}).then((d) => {
				//trigger download of file naming it using current date and time
				const dt: DateTime = DateTime.now();
				
				fileDownload(
					d.data, 
					`${title}-${dt.toISODate()}-${dt.hour}-${dt.minute}.xlsx`, 
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				);

				return d.data;
		});
	}

    static renameWorkflow = (url: string, params: { arg: Modify<apiWorkflowSetTitle, {workflowid: string}>; }) => {

			const { workflowid, title } = params.arg;

			return axios.request({
				url: `Audit/Workflow/${workflowid}/update-title`,
				method: 'PATCH',
				data: { title }
			})
			.then(r => WorkflowApi.parseWorkflow(r.data) as Workflow)
			.catch(e => {
					console.log('The back-end barfed', e);
					throw e;
			});

		};

		static updateCommissionRate = (url: string, params: { arg: Modify<apiWorkflowSetCommissionRate, {id: string}> }) => {

			const { id, ...rest } = params.arg;

			return axios.request({
				url: `Audit/Workflow/${id}/commission-rate`,
				method: 'PATCH',
				data: { ...rest }
			})
			.then(r => WorkflowApi.parseWorkflow(r.data))
			.catch(e => {
					console.log('The back-end barfed', e);
					throw e;
			});
		};

	static parseWorkflow = (data: apiWorkflowResponse): Workflow => ({
		...parsePropsToDateTime<Workflow>(data, ['createdUtc', 'lastModified']),
		tasks: data.tasks.map((t: apiWorkflowTaskResponse) => ({
				...parsePropsToDateTime(t, ['createdUtc']),
				statusTimeStamps: t.statusTimeStamps.map(ts => parsePropsToDateTime(ts, ['value']))
		}))
	})
}
