import { camelToSpace } from "./string";

export type EnumKeys = {
  key: string;
  value: number | string;
}

export const getByNumber = <T>(e: T, key: number, humanise: boolean = false): string => {

	if (!e) throw Error(`${e} is not a valid Enum type`);

    // @ts-ignore
    return !humanise ? e[key] : camelToSpace(e[key]);
}

// @ts-ignore
export const getByString = <T>(e: T, key: string, value: boolean = false): typeof T => {

	if (!e) throw Error(`${e} is not a valid Enum type`);

  return value 
    ? getValueCollection(e, false).find(m => m.key === key)?.value
    : key as keyof T
}


/**
  * Given an Enum type return a collection of key/values in an EnumValue entity object
  *  @param {EnumType} e - The enum type to make into a collection
  *  @returns {EnumValue[]} - collection of EnumValue instances with name and value props
*/
export const getValueCollection = <T>(e: T, forceNubmer = true): EnumKeys[] => {

	if (!e) throw Error(`${e} is not a valid Enum type`);

	return Object.keys(e as object).reduce(
    (p, c) => {
      /* istanbul ignore else */
      if (typeof c === "string") {
        // p = [...p, new EnumValue(e[c], c)]
        p = [...p, {key: (e as any)[c], value: forceNubmer ? +c : c} as never]
      }
      return p;
    }, []
  )
}



/**
 * Given a Enum instance, will return an array containing the stirng keys for the
 * instance along with the numerical value. Optional 'humanise' param will
 * convert camel casing to human readable cap cased keys. eg FooBarFeeFum
 * becomes 'Foo Bar Fee Fum'
 *
 * @template T
 * @param {T} e
 * @param {boolean} [humanise=false]
 * @returns {EnumKeys[]}
 */
export const getKeyValuePairs = <T>(e: T, humanise: boolean = false): EnumKeys[] => {

	if (!e) throw Error(`${e} is not a valid Enum type`);

  return getValueCollection(e)
  .filter(v => (typeof v.value === "number") && !isNaN(v.value))
  .map(v => ({key: humanise ? camelToSpace(v.key) : v.key, value: v.value}));
}

export const getFilteredKeyValuePairs = <T>(e: T, humanise: boolean = false, keep: number[] = [], matchorder: boolean = false): EnumKeys[] => {
  
	if (!e) throw Error(`${e} is not a valid Enum type`);
	
	const items = getValueCollection(e)
  .filter(v => (typeof v.value === "number") && !isNaN(v.value) && keep.includes(v.value))
  .map(v => ({key: humanise ? camelToSpace(v.key) : v.key, value: v.value}))
  
  return !matchorder 
    ? items
    : items.sort(({value: a}, {value: b}) => { 
        
			const indexa = keep.findIndex(it => it === a);
        const indexb = keep.findIndex(it => it === b);
      
        return indexa - indexb
      })
}


export{}