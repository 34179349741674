import { DateTime } from 'luxon';

import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';

import type { apiCargoMovementResponse } from './apiResponse';
import type { apiConditionalFilters } from './apiRequest';

import { ISearchFacet } from '../CargoFlows';
import { Modify } from 'helpers/Utils/misc';

export type CargoMovementSearchParam = {
    searchTerm: string;
    searchField: EntitySearchFieldsEnum;
}

export type ConditionalFilter = Modify< apiConditionalFilters, {
    condition: '<' | '>' | '=' | '!=';
    operator?: 'or' | 'and';
    value: string | boolean | number | null;
}>

export type ConditionalFilterChange = {
    filters: ConditionalFilter[],
    tokens: ISearchFacet[]
}

export type CargoMovementRequest = {
    callbackId?: string;
    pageSize: number;
    pageNumber: number;
    searches: CargoMovementSearchParam[];
    conditionalFilters: ConditionalFilter[];
    orderByColumns: string[]
}

export type CargoMovementResponse = {
    originalRequest: CargoMovementRequest;
    results: CargoMovementSearchResult[];
    totalPages: number;
    totalResults: number;
}

export type CargoMovementSearchResult = Modify<apiCargoMovementResponse, {
  endUtc: DateTime;
  startUtc: DateTime;
}>

export enum CargoMovementOrderCriteriaEnum {
    VesselName = "VesselName",
    Status = "Status",
    LoadPort = "CalculatedLoadPort",
    DischargePort = "CalculatedDischargePort",
    Product = "CalculatedProduct",
    ProductGrade = "CalculatedProductGrade",
  Probability = "Probability",
    StartUtc = "StartUtc",
    EndUtc = "EndUtc",
    Owner = "CalculatedOwner",
    Charterer = "CalculatedCharterer",
    ProductCategory = "CalculatedProductCategory",
  Quantity = "Quantity",
}

export enum ProbabilityLevels {
    High = 0.9,
    Medium = 0.75,
    Low = 0,
}

export {}