import { useEffect, useRef, useCallback } from 'react';
import { Toast, ToastMessage } from 'primereact/toast';

import { ResponseError, ResponseWithError } from 'models/shared/error';

import ErrorToastService from './Services';

import './ErrorToast.scss';

interface ErrorToastContentProps {
    message: string;
}

const ERROR_STATUS_MAP: { [key: number]: string } = {
  403: 'Sorry - you can\'t update this item right now'
};

const ErrorToastContent = (props: ErrorToastContentProps) => <>
    <div className="error-toast-icon">
        <i className="pi pi-times-circle"></i>
    </div>
    <div className="error-toast-message">
        <div className="error-toast-summary">Error</div>
        <div className="error-toast-detail">{props.message}</div>
    </div>
</>

// If we plan some other toast (info/warning), make this more general as part of handling server side errors in U.S. #534
const ErrorToast = (): JSX.Element => {
    const toast = useRef<Toast>(null);
    // eslint-disable-next-line
    let currentMessages: { message: ToastMessage; }[] = [];

    const handleError = useCallback((e: ResponseWithError | undefined, message?: string) => {
        let error = e?.response?.data?.error ?? e;
        let errorMessage = message ?? (error as ResponseError)?.errorObject ?? error?.message;
        const errorStatus = e?.response?.status ?? (error as ResponseError)?.statusCode ?? 0;

        errorMessage = ERROR_STATUS_MAP[errorStatus] ?? errorMessage; // Map status codes to specific messages

        if (errorMessage) {
            const newMessage: ToastMessage = {
                id: errorMessage,
                severity: "error",
                life: 1000 * 6, // 6 sec,
                content: <ErrorToastContent message={errorMessage} />,
            };

            const index = currentMessages.findIndex(c => c.message.id === errorMessage);

            if (index === -1) {
                toast.current?.show(newMessage);
            } else {
                const item = currentMessages[index];
                // remove current message and show the new one.
                // Replace method doesn't fit here as it replaces all the items.
                toast.current?.remove(item as ToastMessage);
                toast.current?.show(newMessage);

            }
        };
    }, [currentMessages])

    useEffect(() => {
        ErrorToastService.subscribe(handleError);

        return () => ErrorToastService.unsubscribe(handleError);
    }, [handleError]);

    return <Toast
        className="error-toast"
        pt={{
            message(options) {
                // Primereact has shitty remove message handling.
                // "Toast.remove" method (https://primereact.org/toast/#api.Toast.methods.remove)
                // expects ToastMessage as a param but in the implementation there is ToastMessage but wrapped in obect with key `_pId` expected.
                // `{ _pId: number, message: ToastMessage }`
                // Method filters messages based on it's `_pId` value not ToastMessage object value
                // so it is necessary to get messages from internal state to be able to use it with `remove` method.
                currentMessages = options?.state.messages ?? [];
            },
        }}
        ref={toast}
    />;
};

export default ErrorToast;
