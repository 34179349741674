import { AxiosError } from "axios";

import ErrorCode from '../ErrorCode';

import styles from './Errors.module.scss';

interface IServiceErrorProps {
	error: AxiosError;
}

export const ServiceCallError = (props: IServiceErrorProps) => {
	return <>
		<div className={styles.container}>
			<h1>Oh,  this is awkward. Seems something has gone wrong…</h1>
			<h2>It's us, though. Not you…</h2>
			<ErrorCode error={props.error} />
		</div>
	</>
}
