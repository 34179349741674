import { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';

import { AccessLevel } from '../Models';
import { ShareClddDropdown } from '../ShareClddDropdown';
import { ShareAccessLevelDropdown } from '../Templates';

import styles from './GroupShareArea.module.scss';

interface GroupShareAreaProps {
  groupAccessLevel: AccessLevel;
  groupAccessLevelChanged: (accessLevel: AccessLevel) => void;
  clddAccessLevel: AccessLevel;
  setClddAccessLevel: Dispatch<SetStateAction<AccessLevel>>;
  clddValues: string[];
  setClddValues: Dispatch<SetStateAction<string[]>>;
}

const GroupShareArea = ({
  groupAccessLevel,
  groupAccessLevelChanged,
  clddAccessLevel,
  setClddAccessLevel,
  clddValues,
  setClddValues,
}: GroupShareAreaProps): JSX.Element | null => (
  <div className={clsx('p-dialog-default', styles.groupSharePopupContainer)}>
    <div className={styles.groupSharePopupContainerContent}>
      <i className="iconoir-group icon--medium" />
      <div className={styles.groupSharePopupContainerContentInfo}>
        <span>Everyone in my office</span>
      </div>
      <ShareAccessLevelDropdown
        hasRemoveOption={false}
        accessLevel={groupAccessLevel}
        accessLevelChanged={groupAccessLevelChanged}
      />
    </div>
    <div className={styles.groupSharePopupContainerContentCldd}>
      <ShareClddDropdown
        clddValues={clddValues}
        setClddValues={setClddValues}
      />
      <ShareAccessLevelDropdown
        hasRemoveOption={false}
        accessLevel={clddAccessLevel}
        accessLevelChanged={setClddAccessLevel}
      />
    </div>
  </div>
);

export default GroupShareArea;
