import type { SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';

import styles from './Suggestion.module.scss';

const ClientSuggestion = (i: SearchSuggestionsResponse & { ClientEmail: string; }): JSX.Element => {
  return <div className={styles.suggestionItem}>
    <span>{i.value}</span>
    <span>{i.ClientEmail}</span>
  </div>;
};

export default ClientSuggestion;