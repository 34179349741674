import { getValueByPath } from 'helpers/Utils/misc';

export const  SingleLineSibling = <T,>(data: T, config: { field: string }):JSX.Element => {
  const { field } = config;
  const value = getValueByPath<T>(data, field);
  const isSibling = getValueByPath<T>(data, 'isSibling');

  return <div className='single-line-sibling'>
    {isSibling && <i className='iconoir-long-arrow-down-right icon--small'/>}
    <span>{value}</span>
  </div>;
};


