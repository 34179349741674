export enum Group {
  Loading = 1,
  Via,
  Discharging,
  "Vessel Class",
  Avoiding,
}

export enum WeatherUnitEnum {
  Percentage = 1,
  Days = 2,
}

export enum CalculationDistanceUnit {
  Nm = 1,
  Km,
}
