import { humanDate } from 'helpers/Utils/formatters';
import { formatName } from 'helpers/Utils/string';

import type { apiWorkflowCommentResponse } from 'modules/Workflows/Models'

import styles from './Comment.module.scss';

interface CommentProps {
  comment: apiWorkflowCommentResponse;
}

export default function Comment(props: CommentProps): JSX.Element {
  const { comment } = props;

  return <div className={styles.container}>
    <div className={styles.title}>
      <div>{formatName(comment.createdByName)}</div>
      <div>{humanDate(comment.createdUtc, { time: true })}</div>
    </div>
    <div >{comment.comment}</div>
  </div>;
}