import Joi from 'joi';

import { Frequency } from './Enums';

export const optionalProductValidator = Joi.object({
	frequency: Joi.number().valid(Frequency.Yearly, Frequency.Monthly, Frequency.Quarterly).required(),
	period: Joi.number().min(1).required(),
	category: Joi.array().items(Joi.string()).min(1).required(),
	data: Joi.array().items(
		Joi.object({
			location: Joi.string().required(),
			product: Joi.string().optional()
		})
	).min(1).required()
})

export const mandatoryProductValidator = optionalProductValidator.append({
	data: Joi.array().items(
		Joi.object({
			location: Joi.string().required(),
			product: Joi.string().required()
		})
	).min(1).required()
})
