import './PlayPauseButton.scss';

interface PlayPauseButtonProps {
  isPlaying?: boolean;
  onClick?: () => void;
}

export default function PlayPauseButton({
  isPlaying = false,
  onClick,
}: PlayPauseButtonProps) {
  return (
    <div className="play-pause-btn" onClick={onClick}>
      <span className="play-pause-btn-icon icon-show">
        <input
          id="play-pause-state"
          type="checkbox"
          readOnly={true}
          checked={isPlaying}
        />
      </span>
    </div>
  );
}
