import { Chip } from 'primereact/chip';

import type { CargoMovementSearchResult } from '../Models/CargoMovements';

import './Supplemental.scss';

function StsChip ({ data }: { data: CargoMovementSearchResult }): JSX.Element {
    return data.isStsLine || data.hasSts ? <Chip label='STS' /> : <></>;
}

export function SupplementalBody <T, >(data: T, config: {field: string}): JSX.Element {
    return <div className='supplemental-body__container'>
        {/* STS chip requires special treatment as two properties are responsible for only one Chip */}
        <StsChip data={data as any} />
        {/* add other chips here */}
    </div>;
}