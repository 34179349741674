import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';

import { SurveillanceEntityStatus } from '../../Models/Enums';
import { ResultsResponse } from '../../Models/ReportsResponse';
import { StateRequest, useUpdateAuditState } from '../../Services/AuditService';

import { returnWithCommentsValidator } from './Models/Validators';

import styles from './ReturnWithCommentsPopup.module.scss';

export interface ReturnWithCommentsPopupReferenceProps {
  submit: () => Promise<StateRequest | undefined>;
}

export interface ReturnWithCommentsPopupProps {
  activeWorksheetId: string;
  selectedItems: ResultsResponse[];
}

const ReturnWithCommentsPopup = forwardRef<ReturnWithCommentsPopupReferenceProps, ReturnWithCommentsPopupProps>(
  ({
    activeWorksheetId,
    selectedItems,
  }, ref): JSX.Element => {
    const [ notes, setNotes] = useState<string>();
    const [ isValid, setIsValid ] = useState<boolean>(false);
    const [ isValidationVisible, setIsValidationVisible ] = useState<boolean>(false);
    const { trigger: triggerUpdate } = useUpdateAuditState(activeWorksheetId);

    useImperativeHandle(ref, () => ({
      submit: (): Promise<StateRequest | undefined> => {
        if (isValid) {
          return triggerUpdate({
            items: selectedItems.map(el => ({
              id: el.id,
              partitionKey: el.partitionKey,
            })),
            assignedUserId: null,
            status: SurveillanceEntityStatus.ActiveWithComments,
            reason: null,
            note: notes || '',
            worksheetId: activeWorksheetId || '',
          });
        } else {
          setIsValidationVisible(true);
          return new Promise((resolve, reject) => {
            reject('validator');
          });
        }
      },
    }));

    useEffect(() => {
      if (!activeWorksheetId) {
        return;
      }

      const result = returnWithCommentsValidator.validate(
        { notes },
        { convert: false }
      );

      setIsValid(!result.error);
    }, [activeWorksheetId, notes]);

    const notesErrorMessage = (): JSX.Element => (
      <small className="note-invalid">Please enter a note</small>
    );

    return (
      <form>
        <div className={styles.surveillanceReturnWithCommentsPopup}>
          <div className={styles.surveillanceReturnWithCommentsPopupNote}>
            <label>Note*</label>
            <InputTextarea
              id='rwc-note'
              autoResize
              placeholder='Please add a required note/comment'
              onFocus={(e): void => e.target.select()}
              rows={5}
              value={notes ?? ''}
              onChange={(e): void => setNotes(e.target.value)}
              className={isValidationVisible && (!notes || notes === '') ? 'p-invalid' : ''}
            />
            {isValidationVisible && (!notes || notes === '') && notesErrorMessage()}
          </div>
        </div>
      </form>
    );
  }
);

ReturnWithCommentsPopup.displayName = 'ReturnWithCommentsPopup';

export default ReturnWithCommentsPopup;
