import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';

import { uniqueId } from 'helpers/Utils/string';

enum FilterOptions {
  Only = 'only',
  Exclude = 'exclude',
}

const DEFAULT_VALUE: FilterOptions = FilterOptions.Only;

type NoteItem = {
  key: FilterOptions | null;
  value: string;
};

interface FilterProps {
  label: string;
  onChange: (value: boolean | null) => void;
  value?: boolean | null;
  notes?: NoteItem[];
  className?: string;
}

const ThreeStateFilter = (props: FilterProps): JSX.Element => {
  const { label, value, notes, onChange, className } = props;

  const uuid = uniqueId();
  const options: FilterOptions[] = Object.values(FilterOptions);

  const [option, setOption] = useState<FilterOptions | null | undefined>(
    undefined
  );
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const isFullfilled = value !== undefined && value !== null;

    setChecked(isFullfilled);
    if (value !== undefined) {
      switch (value) {
        case true:
          setOption(FilterOptions.Only);
          break;
        case false:
          setOption(FilterOptions.Exclude);
          break;
        default:
          setOption(null);
          break;
      }
    }
  }, [value]);

  const note = useMemo(
    () => notes?.find(note => note.key === option),
    [notes, option]
  );

  const notifyValueChanged = (newValue: FilterOptions | null): void => {
    switch (newValue) {
      // do not call callback if value is not set yet
      case undefined:
        break;
      case FilterOptions.Only:
        onChange(true);
        break;
      case FilterOptions.Exclude:
        onChange(false);
        break;
      case null:
      default:
        onChange(null);
        break;
    }
  };
  const handleCheck = (e: CheckboxChangeEvent): void => {
    const newValue = !e.checked ? null : DEFAULT_VALUE;
    setChecked(Boolean(e.checked));
    setOption(newValue);
    notifyValueChanged(newValue);
  };
  const selectionChanged = (event: SelectButtonChangeEvent): void => {
    setOption(event.value);
    notifyValueChanged(event.value);
  };

  return (
    <div
      className={clsx(
        'additional-filters__container--internal selectable-filter__container',
        className
      )}
    >
      <div className='additional-filters__checkbox'>
        <Checkbox
          inputId={`additional_filter_checkbox_${uuid}`}
          checked={checked}
          onChange={handleCheck}
        />
        <label htmlFor={`additional_filter_checkbox_${uuid}`}>{label}</label>
      </div>
      <SelectButton
        disabled={!checked}
        onChange={selectionChanged}
        options={options}
        allowEmpty={false}
        value={option}
      />
      <p className='additional-filters__info'>{note?.value || ''}</p>
    </div>
  );
};

export default ThreeStateFilter;
export { FilterOptions, ThreeStateFilter };
