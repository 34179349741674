import { useEffect, useState, CSSProperties } from 'react';
import { clsx } from 'clsx';
import { DateTime } from 'luxon';
import { Badge } from 'primereact/badge';

import { ColumnBodyOptions } from "primereact/column";
// import { useTimeout } from 'primereact/hooks';


import { ProductTenor } from "../Models/ArtisPrices";

import styles from './Styles.module.scss';

export const ArtisPriceCell = (data: ProductTenor, config: ColumnBodyOptions): JSX.Element => {

	// console.log('dd', data, config.field)
	

	const { field } = config;
	
	const [ diff, setDiff ] = useState<number | null>(null);
	const [ cellClassState, setCellClassState ] = useState<string | null>(null); 

	const { prices } = data;

	const speed: number = 1 / 1.618;
	const delay: number = 1.168

	let [current, change, direction, asOf, hasSubscriptions] = prices[field] ?? [];
	const isNegative: boolean = change < 0;

	const [ hasSubs, setHasSubs ] = useState<boolean>(hasSubscriptions);

	useEffect(() => {
		// change state only is `hasSubscriptions` is true or false.
		// That means flag is properly calculated, ignore other cases.
		if (typeof hasSubscriptions === 'boolean') {
			setHasSubs(hasSubscriptions);
		}
	}, [ hasSubscriptions ])

	const calcDiff = (from: DateTime): number => Math.abs((from.diffNow(["seconds"]).seconds));


	useEffect(() => {
		if (!asOf) return;
		
		setDiff(calcDiff(asOf));

		const animationEnded = setTimeout(
			() => {
				setDiff(null)
			}, 
			(delay + speed) *1000
		)

		return function () {
			clearTimeout(animationEnded);
		}
		// eslint-disable-next-line
	}, [asOf]);

	useEffect(() => {
		if (!diff) {
			setCellClassState(null);
			return;
		};

		setCellClassState(`${styles.pricechange} ${direction === -1 ? styles.down : styles.up}`);
	}, [diff, direction])


	if (!data) return <></>
	if (!current && !change) return <></>


	return <>
		{hasSubs && <Badge className={clsx('iconoir-bookmark icon--tiny', styles.subscriptionBadge)} />}
		<div
			style={{'--delay': `${delay}s`, '--speed': `${speed}s`} as CSSProperties}
			className={clsx(styles.cell, cellClassState	)}
		>
			<span>{current}</span>
			{ change !== 0 && 
				<span 
					className={clsx(
						'icon--tiny',
						isNegative && `${styles.negative} iconoir-nav-arrow-down`,
						!isNegative && `${styles.positive} iconoir-nav-arrow-up`
					)}>
						{change} 
				</span>
			}
		</div>
	</>

}





