import type F from 'types/generic-type';

export const Currency = (data: F<number>, config: {field: string}) => {

    let currencyFormatter = new Intl.NumberFormat(
        "en-US",
        {
            style: "decimal",
            minimumFractionDigits: data["precision"] ?? 2
        }
    )

    const { field } = config;

    return <div className="cell--aligned-right">
        {currencyFormatter.format(data[field])}
    </div>
}
