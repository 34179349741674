import { Badge } from 'primereact/badge';

import type { SubscriptionResponseFlat } from 'modules/DataModule/Models/SubscriptionResponse';

import styles from './TypeBadge.module.scss';

const TypeBadge = (data: SubscriptionResponseFlat): JSX.Element => {
  const { isTrial } = data;

  return <Badge
    className={styles.typeBadge}
    value={isTrial ? 'Trial' : 'Full'}
    severity={isTrial ? 'danger' : 'success'}
  />;
};

export default TypeBadge;