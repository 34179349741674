import axios from 'axios';
import useSWR from 'swr';

import ErrorToastService from 'components/Errors/ErrorToast/Services/ErrorToastService';

export interface ParsedRangeResult {
  original: string;
  from: number;
  to: number;
}

export const useQuantityStringConvert = (value: string | null | undefined) => {
  const { data, error } = useSWR(value, QuantityParserApi.getParsedRange);

  return { data, error };
};

export class QuantityParserApi {

  static getParsedRange = (value: string): Promise<void | { results: ParsedRangeResult }> => {
    return axios.request({ url: 'search/parse/quantity', data: { value: value }, method: 'POST' })
      .then(r => {
        const { from, to, original } = r.data;

        const results: ParsedRangeResult = { original, from, to };

        return { results };
      })
      .catch((e):void => {
        ErrorToastService.handleError(e, [500, 503]);

        throw e;
      });
  };
}