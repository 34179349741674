import { Dispatch, SetStateAction, useMemo } from 'react';
import { MultiSelect } from 'primereact/multiselect';

import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';

import styles from '../GroupShareArea/GroupShareArea.module.scss';

interface ShareAccessLevelDropdownProps {
  clddValues: string[];
  setClddValues: Dispatch<SetStateAction<string[]>>;
}

export const ShareClddDropdown = ({
  clddValues,
  setClddValues,
}: ShareAccessLevelDropdownProps): JSX.Element => {
  const { obxuser } = useLoggedInUser();
  const clddOptions = useMemo(
    () =>
      obxuser?.cldds.filter(item => item.isSelectable).map(item => item.code) ??
      [],
    [obxuser?.cldds]
  );

  return (
    <MultiSelect
      value={clddValues}
      options={clddOptions}
      onChange={ (e) => setClddValues(e.value) }
      placeholder='Share with CLDD'
      display='chip'
      removeIcon='iconoir-xmark icon--tiny'
      panelHeaderTemplate={<></>}
      className={styles.groupSharePopupContainerContentClddDropdown}
    />
  );
};
