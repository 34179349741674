interface ExpectedValueAndIcon {
  expectedValue: string;
  icon: string | JSX.Element;
}

export const  SingleLineBadge = <T,>(data: T, config: {field: string}, expected: ExpectedValueAndIcon[]): JSX.Element => {

  const field = config.field;
  const index = expected.findIndex(el =>  data[field as keyof T] === el.expectedValue);

  return <>
    {index > -1 && <span className='sky-badge'>{expected[index].icon}</span>}
  </>;
};
