import { useEffect } from "react";

import { DataTable, DataTableSelectionSingleChangeEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';

import Loader from "components/Loader";
import { DEFAULT_GRID_ROW_HEIGHT } from 'models/shared/consts';

import SanctionStatus from '../../Templates/SanctionStatus';
import { ResultsModeEnum } from "../../Models/Enums";
import { useSanctionsApi } from "../../Services/SanctionsAPI";

import type { ISanctionsDataTableParams } from 'modules/Sanctions/Models/SanctionsDataTable';

import { apiLegalEntityResponse } from "modules/Sanctions/Models";

import "./SanctionedEntityDataTable.scss";

function SanctionedEntityDataTable(props: ISanctionsDataTableParams) {

  const { setSelectedItem, searchTerm, setDisplayLoader, isMobile, selectedItem } = props;
  const { data, isLoading } = useSanctionsApi(searchTerm, ResultsModeEnum.LegalEntitySanctions);
  // TODO: handle error state

  useEffect(() => { setDisplayLoader(isLoading) }, [isLoading]); // eslint-disable-line

  if (isLoading) {
    return <><Loader /></>
  }

  return <>
    { data &&
        <DataTable
        value={data.results}
        className="sanctions-table--entity grow-to-fill"
        dataKey="id"
        emptyMessage="Sorry, no results match that term. Only sanctioned legal entities will display in the search results"
        onSelectionChange={(e: DataTableSelectionSingleChangeEvent<apiLegalEntityResponse[]>) => setSelectedItem(e.value)}
        // removableSort
        scrollable scrollHeight="flex"
        selectionMode="single"
        selection={selectedItem}
        virtualScrollerOptions={{
          itemSize: DEFAULT_GRID_ROW_HEIGHT // itemSize is required to display proper amount of items
        }}
        // sortField="serverindex"
        // sortOrder={1}
        >
          <Column
            field="fullName"
            header="Company Name"
						className="overflow--elipsis overflow--elipsis--2-lines"
            // sortable
          />
          <Column
            field="countryName"
            header="Country"
            className="column__country"
						headerClassName="column__country"
            // sortable
          />
          <Column
            field="nationalityofRegistration"
            header={isMobile ? "Reg. Nat." : "Registration Nationality"}
						className="overflow--elipsis overflow--elipsis--2-lines"
            // sortable
          />
					{ !isMobile && 
					<Column
						field="providerName"
						header={"Source"}
					/>
					}
          <Column
            field="companyOverallComplianceStatus"
            body={SanctionStatus}
            header="Risk Level"
            className="sanction-status"
            // sortable
          />
        </DataTable>
    }
  </>;
}

export default SanctionedEntityDataTable;
