import { findCurrentSearch } from "helpers/Utils/misc";
import { clsx } from "clsx";

import styles from "./ServerSortHeader.module.scss";

interface ISortableHeaderParams {
    label: string;
    current: string[];
    by: string;
	isDoubleLined?: boolean;
}

const icons = {
    "asc": "sort-up",
    "desc": "sort-down",
}

export const ServerSortHeader = (params: ISortableHeaderParams) => {

    const { label, by, current, isDoubleLined } = params;

    const { item, index, exists } = findCurrentSearch(current, by);
    const [ , direction ] = item.split(" ");
	const [ top, bottom ] = label.split(",");

    return <>
        <div 
            data-sorting={direction}
            data-index={index + 1}
            className={clsx(styles.label, current.length >= 2 && exists && styles.badged)}
        >
		{(!isDoubleLined)
			?  <>{top}</> 
			:  <>
				<label>{top}</label>
				<summary>{bottom}</summary>
			</>
		}
        </div>
        <i className={
           clsx("icon--tiny", 
		   styles.icon,
           `iconoir-${icons[direction as "asc" | "desc"] ?? "data-transfer-both"}`
        )}/>
    </>
}

export default ServerSortHeader;