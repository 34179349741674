import * as React from 'react';
import { Tooltip } from 'primereact/tooltip';

const DroppedTooltip = (): JSX.Element => (
  <>
    <Tooltip target=".pi-question-circle" className="dl-dropped__tooltip" />
    <i
      className="pi pi-question-circle p-text-secondary"
      data-pr-tooltip={`We are unable to send emails to this recipient:
          \u2022 we may be blacklisted
          \u2022 we've been bounced too many times
        `}
    />
  </>
);

export default DroppedTooltip;