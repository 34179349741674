import { type FocusEvent } from 'react';
import { InputText, InputTextProps } from "primereact/inputtext";
import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea';
import { Dropdown, DropdownProps } from "primereact/dropdown";

import './SwitchableUnitField.scss';

interface SwitchableUnitFieldProps {
    className?: string;
    dropdownProps?: DropdownProps & { showError?: boolean; error?: string; };
    inputProps?: (InputTextProps | InputTextareaProps) & { showError?: boolean; error?: string; };
    inputType: 'number' | 'text' | 'textarea';
    label: string;
}

export default function SwitchableUnitField(props: SwitchableUnitFieldProps): JSX.Element {
    const {
        className,
        dropdownProps: { className: dropdownClassName, showError: dropdownShowError, error: dropdownError, ...dropdownProps } = {},
        inputProps: { className: inputClassName, showError, error, ...inputProps } = {},
        inputType,
        label,
    } = props;

    let Component: React.ElementType;

    switch (inputType) {
        case 'textarea':
            Component = InputTextarea;
            break;
        case 'number':
        case 'text':
        default:
            Component = InputText;
            break;
    }

    return <div className={`${className ?? ''} switchable-unit-field`}>
        <label className="switchable-unit-field__label" htmlFor="switchable-unit-field">{label}</label>
        <div className="p-inputgroup switchable-unit-field__wrapper">
            <Component
                className={`switchable-unit-field__input ${inputClassName ?? ''}`}
                inputId="switchable-unit-field"
                useGrouping={false}
                onFocus={ (e: FocusEvent) => (e.target as HTMLInputElement).select() }
                {...inputProps}
            />
            <Dropdown
                appendTo="self"
                className={`p-inputgroup-addon switchable-unit-field__dropdown ${dropdownClassName ?? ''}`}
                showOnFocus
                {...dropdownProps}
            />
        </div>
        {showError && <small className='message-invalid'>{error || 'Required field'}</small>}
        {dropdownShowError && <small className='message-invalid message-invalid__dropdown'>{dropdownError || 'Required field'}</small>}
    </div>

}