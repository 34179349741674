import { ReactNode, useState } from 'react';
import { Accordion, AccordionTab, AccordionTabChangeEvent } from 'primereact/accordion';
import { Timeline } from 'primereact/timeline';

import Leg from '../Leg';
import RouteResult from '../RouteResult';

import { WaypointType } from 'components/Map/Models/Enums';

import type { CalcParamChange } from 'modules/DistanceCalc/Models/CalcParamsChange';
import type { CalculationRequest } from 'modules/DistanceCalc/Models/CalculationRequests';
import type { CalculationResult, CalculationWaypoint } from 'modules/DistanceCalc/Models/CalculationResponse';

import './CalculationResults.scss';

const Marker = (item: CalculationWaypoint, index: number, arr: CalculationWaypoint[]): ReactNode => {
	// NOTE: this is proper version when BE sorts waypoint (Load -> Via -> Discharg)
	// switch (item.type) {
	// 	case WaypointType.Load:
	// 		return <img src="img/icons/leg_timeline_loading.svg" alt="" />
	// 	case WaypointType.Discharge:
	// 		return <img src="img/icons/leg_timeline_discharging.svg" alt="" />
	// 	case WaypointType.Via:
	// 		return <img src="img/icons/leg_timeline_via.svg" alt="" />
	// 	default:
	// 		return null;
	// }
	switch (index) {
		case 0:
			return <img src="img/icons/leg_timeline_loading.svg" alt="" />;
		case arr.length - 1:
			return <img src="img/icons/leg_timeline_discharging.svg" alt="" />;
		default:
			return <img src="img/icons/leg_timeline_via.svg" alt="" />;
	}
};

interface CalculationResultsProps {
    calcParams: CalculationRequest,
    calcResults: CalculationResult | undefined;
    callback: (args: CalcParamChange) => void;
};

export default function CalculationResults (props: CalculationResultsProps): JSX.Element | null {

	const { calcParams, calcResults, callback } = props;
	const [ activeIndex, setActiveIndex ] = useState<number[]>([0]);

	const handleStateChange = (e: AccordionTabChangeEvent): void => {
		setActiveIndex(e.index as number[]);
	}

	if (calcResults) {
		const mainWaypoints = calcResults.waypoints.filter(waypoint => waypoint.type !== WaypointType.Waypoint);

		return <>
			<div className="calculation-results__header">Calculation Results</div>
			<Accordion
				activeIndex={activeIndex}
				className="calculation-results__container grow-to-fill"
				onTabChange={handleStateChange}
				multiple
			>
				<AccordionTab
					contentClassName="grow-to-fill overflow--y"
					header={<RouteResult result={calcResults} />}
				>
					<Timeline
						content={(waypoint, index) =>
							<Leg
								calcParams={calcParams}
								callback={callback}
								hideDetails={index === mainWaypoints.length - 1}
								key={waypoint.sequence}
								waypoint={waypoint}
							/>
						}
						marker={(item, index) => Marker(item, index, mainWaypoints)}
						value={mainWaypoints}
					/>
				</AccordionTab>
			</Accordion>
		</>

	}

	return null;
}

