import { ContextMenu } from 'primereact/contextmenu';

import GridElement from 'components/GridElement';

import {
  DoubleLineSimple,
  ReadableDate,
  SingleLineWithAddon,
} from 'helpers/DataTable/Templates/ColumnTemplates';
import { formatName } from 'helpers/Utils/string';
import TypeChip from 'modules/Blotter/Components/BlotterDataTable/Templates';
import TradeActionMenu from 'modules/Blotter/Components/TradeActionMenu/TradeActionMenu';
import type { TradesDataResponse } from 'modules/Blotter/Models/BlotterResponse';

import type { ColumnBodyOptions } from 'primereact/column';

interface TradeItemProps {
  data: TradesDataResponse;
  handleRowClick: (value: TradesDataResponse) => void;
  setActionItem: (item: TradesDataResponse) => void;
  contextMenu?: ContextMenu | null;
}

const TradeItem = (props: TradeItemProps): JSX.Element => {
  const { data, handleRowClick, setActionItem, contextMenu } = props;

  return (
    <div className='trade-item' onClick={(): void => handleRowClick(data)}>
      <GridElement className='grid-element__instrument' header='Instrument' body={data.instrument} />
      <GridElement className='grid-element__type' header='' body={TypeChip(data, { field: 'type' } as ColumnBodyOptions)} />
      <GridElement header='Broker (Buy)' body={data.isImported ? data.buyerObBroker.userName : formatName(data.buyerObBroker.userName)} />
      <GridElement header='Buy Side' body={DoubleLineSimple(data, { field: 'buyerContactName,buyerCompany' } as ColumnBodyOptions)} />
      <GridElement header='Broker (Sell)' body={data.isImported ? data.sellerObBroker.userName : formatName(data.sellerObBroker.userName)} />
      <GridElement header='Sell Side' body={DoubleLineSimple(data, { field: 'sellerContactName,sellerCompany' } as ColumnBodyOptions)} />
      <GridElement className='grid-element__quantity' header='Quantity' body={SingleLineWithAddon(data, { field: 'quantity.amount' } as ColumnBodyOptions, data.quantity.unit)} />
      <GridElement className='grid-element__price' header='Price' body={SingleLineWithAddon(data, { field: 'price.amount' } as ColumnBodyOptions, data.price.unit)} />
      <GridElement header='Trade Date, Time' body={ReadableDate(data, { field: 'dateTime' } as ColumnBodyOptions, 'dd LLL yyyy, HH:mm ZZZZ')} />
      <GridElement className='grid-element__clearing-id' header='Clearing ID' body={data.clearingId} />
      <GridElement
        className='grid-element__actions'
        header=''
        body={
          <TradeActionMenu
            className='margin--right--small'
            item={data}
            setActionItem={setActionItem}
            contextMenu={contextMenu}
          />
        }
      />
    </div>
  );
};

export { TradeItem };
export default TradeItem;
