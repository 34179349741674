import { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { ColumnBodyOptions } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';

import useDimensions from 'helpers/Hooks/Dimensions';

import type F from 'types/generic-type';

import styles from './General.module.scss';

/**
 * General custom cell rendering function for the <DataTable> instances. Used to display
 * a single point of data that requires truncation if total length exceeds available
 * ohrizontal space
 *
 * @param {F<string>} data
 * @param {ColumnBodyOptions} config
 * @param {boolean} showOverflowTooltip
 *
 */
export const SingleLineTruncated = (data: F<string>, config: ColumnBodyOptions, showOverflowTooltip: boolean = false):JSX.Element => {
  const { field, rowIndex } = config;

  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);
  const spanRef = useRef<HTMLSpanElement>(null);
  const { width, scrollWidth } = useDimensions(spanRef);

  useEffect(() => {
    setIsOverflowed(width < scrollWidth);
  }, [scrollWidth, width]);

  return <div className="overflow--hidden">
    {showOverflowTooltip && isOverflowed && <Tooltip target={`.${ field }-${ rowIndex }`} content={data[field]} />}
    <span className={clsx(styles.truncated, 
      'truncated',
      { [styles.truncatedTooltip] : showOverflowTooltip && isOverflowed },
      { 'unknown' : !data[field] }, 
      `${ field }-${ rowIndex }`)} 
    ref={spanRef}>
      {data[field] ?? 'Unknown'}
    </span>
  </div>;
};

