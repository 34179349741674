import { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { DateTime } from 'luxon';

import TypeBadge from 'modules/DataModule/Templates/TypeBadge';
import Status from 'modules/DataModule/Templates/Status';
import NotAvailable from 'components/NotAvailable';

import type { SubscriptionResponseFlat } from 'modules/DataModule/Models/SubscriptionResponse';

type DataModuleTableProps = {
  subscriptions: SubscriptionResponseFlat[] | undefined;
  isLoading: boolean;
  selectedSubscription: SubscriptionResponseFlat | null;
  setSelectedSubscription: (arg: SubscriptionResponseFlat | null) => void;
};

const DataModuleTable = (props: DataModuleTableProps): JSX.Element => {
  const { subscriptions, isLoading, selectedSubscription, setSelectedSubscription } = props;

  const [expandedRows, setExpandedRows] = useState<SubscriptionResponseFlat[]>([]);

  useEffect(() => {
    if (subscriptions) {
      // find first items of each row and set them as expanded rows (that's how PrimeReact's `expandedRows` prop works)
      setExpandedRows(subscriptions.reduce((acc, curr) =>
        acc.find(p => p.clientName === curr.clientName) ? acc : [...acc, curr],
        [] as SubscriptionResponseFlat[]
      ));
    }
  }, [subscriptions]);

  return <DataTable
    className='data-module__table'
    dataKey='id'
    expandableRowGroups
    expandedRows={expandedRows}
    groupRowsBy='clientName'
    rowGroupMode='subheader'
    rowGroupHeaderTemplate={(data) => data.clientName ?? <NotAvailable label='Unknown' />} // TODO: is <NotAvailable /> needed?
    loading={isLoading}
    onRowToggle={(e: { data: SubscriptionResponseFlat[]; }) => setExpandedRows(e.data)}
    scrollable
    scrollHeight='flex'
    selection={selectedSubscription}
    selectionMode='single'
    onRowSelect={(e) => setSelectedSubscription(e.data)}
    value={subscriptions}
  >
    <Column
      header='Subscription'
      field='subscriptionName'
    />
    <Column
      header='Type'
      field='isTrial'
      body={TypeBadge}
    />
    <Column
      className='align--right'
      header='Number of Days'
      field='numberOfDays'
    />
    <Column
      header='Start Date'
      field='startDate'
      body={(data) => DateTime.fromISO(data.startDate).toFormat('dd MMM yyyy')}
    />
    <Column
      header='Status'
      field='status'
      body={Status}
    />
    <Column
      header='Expiry Date'
      field='expiryDate'
      body={(data) => DateTime.fromISO(data.expiryDate).toFormat('dd MMM yyyy')}
    />
  </DataTable>;
};

export default DataModuleTable;