import clsx from 'clsx';
import { Message } from 'primereact/message';
import { Panel } from 'primereact/panel';

import styles from './NoMessagesInfo.module.scss';

const NoMessagesInfoComponent = (): JSX.Element => (
  <Panel toggleable={false} className={clsx('info-message', styles.infoPanel)}>
    <Message text='Sorry, there are no more messages to load' />
  </Panel>
);

export { NoMessagesInfoComponent };
export default NoMessagesInfoComponent;
