import { useMemo } from 'react';
import { clsx } from 'clsx';

import GridElement from 'components/GridElement';

import QuantityBody from '../QuantityBody';

import { SingleLineFlagged } from 'helpers/DataTable/Templates/ColumnTemplates';
import { laycanStyleRange } from 'helpers/Utils/formatters';
import { notNil } from 'helpers/Utils/misc';
import { camelToSpace } from 'helpers/Utils/string';
import type { CargoTrackerResponse } from 'modules/CargoTracker/Models/CargoTrackerResponse';
import { Commodity, COMMODITY_KEYS } from 'modules/CargoTracker/Models/CargoTrackerResponse';
import { StatusEnum } from 'modules/CargoTracker/Models/Enums';

interface SpotItemProps {
  data: CargoTrackerResponse;
  handleRowClick: (value: CargoTrackerResponse) => void;
}

export default function SpotItem(props: SpotItemProps): JSX.Element {
  const { data, handleRowClick } = props;

  const commodityInitialValue: string = useMemo(
    (): string => {
      const foundKey = COMMODITY_KEYS.find(key => notNil(data.commodity?.[key as keyof Commodity]));
      return foundKey ? `${ data.commodity?.[foundKey as keyof Commodity] }` : '';
    },
    [data.commodity]
  );

  return <div className={clsx('cargo-element', data.isUrgent && 'cargo-element--urgent')} onClick={():void => handleRowClick(data)}>
    <GridElement header="Commodity" body={commodityInitialValue} />
    <GridElement header="Laycan" body={laycanStyleRange(data?.laycan?.fromDateParsed ?? '', data?.laycan?.toDateParsed ?? '')} />
    <GridElement header="Loading" body={SingleLineFlagged(data.loading, {field:'flagCode,portName,portCountry'})} />
    <GridElement header="Discharging" body={SingleLineFlagged(data.discharging, {field:'flagCode,portName,portCountry'})} />
    <GridElement header="Quantity" body={QuantityBody(data)} />
    <GridElement header="Status" body={camelToSpace(StatusEnum[data.status])} />
  </div>;
}