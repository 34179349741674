export const Flag = (props: {code: string}): JSX.Element => {

	const { code } = props;

  if (!code) return <></>;

	const len: number = code.length;

	return <img 
		className="country-flag"
		src={`/img/flags/${len === 2 ? "2char/" : ""}${code.toLocaleLowerCase()}.svg`}
		alt={`flag by ISO code ${code}`}
	/>
}