import { useEffect, useState } from 'react';
import { Accordion, AccordionTab, AccordionTabChangeEvent } from 'primereact/accordion';
import { useMediaQuery } from 'react-responsive';

import SpeedField from 'components/SpeedField';

import Avoidings from '../Avoidings';
import DateTimeRange from 'components/DateTimeRange/DateTimeRange';
// import TimeZonePicker from 'components/TimeZonePicker';
import { laycanStyleRange } from 'helpers/Utils/formatters';

import type { CalcParamChange } from 'modules/DistanceCalc/Models/CalcParamsChange';
import type { CalculationRequest } from 'modules/DistanceCalc/Models/CalculationRequests';

import './RouteSettings.scss';

interface RouteSettingsProps {
    calcParams: CalculationRequest,
    callback: (args: CalcParamChange) => void;
}

export default function RouteSettings(props: RouteSettingsProps): JSX.Element {
    const { calcParams, callback } = props;
    const [activeIndex, setActiveIndex] = useState([0]);

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

    useEffect(() => {
        setActiveIndex(isTabletOrMobile ? [] : [0]);
    }, [isTabletOrMobile]);

    function handleTabChange(e: AccordionTabChangeEvent): void {
        setActiveIndex(e.index as number[]);
    }

  return <Accordion
    activeIndex={activeIndex}
    className="route-settings-container"
    multiple
    onTabChange={handleTabChange}
  >
    <AccordionTab header="General Route Settings">
      {/* Start data, speed, etc. here */}
      <div className="parameters__container">
        <DateTimeRange
					retainOriginal={true}
          defaultValue={
            () => calcParams.startDate ?
              // we are only interested in start date, not a range here
              laycanStyleRange(calcParams.startDate.from, calcParams.startDate.from) :
              ''
          }
          label="Start date"
          onDateParsed={(value) => callback({ name: 'startDate', value })}
          onEmptyValue={() => callback({ name: 'startDate', value: null })}
          required={false}
        />
        <SpeedField initialValue={calcParams.speed} onValueUpdate={(value) => callback({ name: 'speed', value })} />
        {/* Don't show TimeZonePicker for now
        (https://dev.azure.com/oilbrokerage/OBXchange/_boards/board/t/OBXchange%20Development%20Team/Stories/?workitem=1289#397399) */}
        {/* <TimeZonePicker
          initialValue={calcParams.timeZone}
          onValueUpdate={(value): void => callback({ name: 'timeZone', value })} /> */}
      </div>
      <Avoidings
        initialValue={ calcParams.avoiding }
        handleAreasToAvoidChange={(value) => callback({ name: 'avoiding', value })}
      />
    </AccordionTab>
  </Accordion>
}