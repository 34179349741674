import { useMemo } from 'react';
import clsx from 'clsx';
import { Accordion, AccordionTab } from 'primereact/accordion';

import {
  SurveillanceDetailsResponse,
  TranscriptionDetailsProps
} from '../../../Models';
import DetailsComponent from '../../DetailsComponent';

import styles from './TranscriptionDetails.module.scss';

const TranscriptionDetails = ({
  messageValue: mv
}: TranscriptionDetailsProps): JSX.Element | null => {

  const details: SurveillanceDetailsResponse = useMemo(() => {

    const cp = mv.counterParty.split(',');

    const participants = cp.length <= 2 ?
      mv.counterParty :
      `${ cp[0] }, ${ cp[1] } (+${ cp.length - 2 } more)`;

    return {
      username: mv.userName,
      participants: participants,
      providerName: mv.provider,
      startTime: mv.startTime.toString(),
      versions: mv.versions,
      media: mv.media,
      isAttachment: mv.isAttachment,
      highlights: mv.highlights,
      line: mv.groupName,
      metadata: {
        'record id': mv.id,
        number: mv.number,
        email: mv.email,
        group: mv.groupName,
        'utc offset': '', // No data in siblings
        'call status': '', // No data in siblings
      }
    };
  }, [mv]);

  return (
    <footer className={clsx(styles.container, 'no-background')}>
      <Accordion className={styles.pAccordion}>
        <AccordionTab
          header="Details"
          contentClassName={styles.pAccordionContent}
          headerClassName={styles.pAccordionHeader}
        >
          <DetailsComponent
            className={styles.itemContainer}
            companyName={mv.company}
            details={details}
          />
        </AccordionTab>
      </Accordion>
    </footer>
  );
};

export { TranscriptionDetails };
export default TranscriptionDetails;
