import { notNil } from 'helpers/Utils/misc';

export const  SingleLineOptional = <T,>(data: T, config: {field: string}):JSX.Element => {
  const keys = config.field.split(',');
  const foundKey = keys.find(key => notNil(data?.[key as keyof T]));
  const value: string | number = foundKey ? `${ data[foundKey as keyof T] }` :  '';

  return <div className="space-between grow-to-fill">
    <span>{value}</span>
  </div>;
};


