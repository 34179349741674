import { type FocusEvent, type ChangeEvent, useState, useEffect, useRef, KeyboardEvent } from 'react';
import { Checkbox, type CheckboxChangeEvent } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import clsx from 'clsx';

import SingleEntitySearch, { ExternalHandles } from 'components/EntitySearch/SingleEntitySearch';
import { getAutocompleteValue } from 'components/Autocomplete/Helpers';
import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import { SearchEntitiesApi } from 'components/EntitySearch/Services/SearchEntitiesAPI';
import { capFirstLetter, formatName } from 'helpers/Utils/string';
import { replaceItemAt } from 'helpers/Utils/collections';
import { ValidationMode } from 'modules/Blotter/Models/Enums';
import { getFixedValue } from 'modules/Blotter/Helpers';
import RelativeAutocomplete, { type RelativeAutocompleteSuggestionItem } from 'modules/Blotter/Components/RelativeAutocomplete';

import type { AxiosError } from 'axios';
import type { SectionProps } from 'modules/Blotter/Models/SectionProps';
import type { SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';
import type { TradeSide } from 'modules/Blotter/Models/BlotterResponse';
import type F from 'types/generic-type';
import type { KeyValue } from 'types/generic-type';

interface TradeSideSectionProps extends SectionProps {
  legIndex: number;
  sectionKey: 'buyer' | 'seller';
  validationMode: ValidationMode;
  touchedFields: string[];
};

export function TradeSideSection(props: TradeSideSectionProps): JSX.Element {
  const { errors, shouldShowError, mutate, request, legIndex, sectionKey, validationMode, touchedFields } = props;

  const leg = request.legs[legIndex];
  const keyPrefix = `legs.${legIndex}.${sectionKey}`;

  const mutateTradeLeg = (mutation: Partial<TradeSide>, key: string | string[]): void => {
    let mutatedLegs = replaceItemAt(request.legs, {
      ...leg,
      [sectionKey]: {
        ...leg[sectionKey],
        ...mutation
      }
    }, legIndex);

    // update other Legs with new values from 'Leg 1'
    if (legIndex === 0) {
      const [leg1] = request.legs;
      const sectionToUpdate = sectionKey === 'buyer' ? 'seller' : 'buyer';

      mutatedLegs = mutatedLegs.map((leg, index) => {
        if (index === 0) {
          return leg;
        }

        return {
          ...leg,
          [sectionToUpdate]: {
            // TODO: for now let's copy all data from counterparty section
            ...leg1[sectionKey],
            ...mutation
          },
        };
      });
    }

    mutate({ legs: mutatedLegs }, key);
  };

  const [traders, setTraders] = useState<RelativeAutocompleteSuggestionItem[]>([]);
  const [accounts, setAccounts] = useState<RelativeAutocompleteSuggestionItem[]>([]);
  const [customErrors, setCustomErrors] = useState<F<string>>({});

  const companyInputRef = useRef<ExternalHandles>(null);

  const selectOnFocus = (e: FocusEvent<HTMLInputElement>): void => e.target.select();

  const onObBrokerCompleteMethod = (): void =>
    // keep userId empty until it's selected from the dropdown options
    mutateTradeLeg({ obBroker: { ...leg[sectionKey].obBroker, userId: '' } }, [`${keyPrefix}.obBroker.userName`, `${keyPrefix}.obBroker.userId`]);

  const onObBrokerInputClear = (): void =>
    mutateTradeLeg({ obBroker: { userId: '', userName: '' } }, [`${keyPrefix}.obBroker.userName`, `${keyPrefix}.obBroker.userId`]);

  const onObBrokerChange = (change?: SearchSuggestionsResponse): void =>
    mutateTradeLeg({ obBroker: { userId: change?.searchEntityId ?? '', userName: change?.value ?? '' } }, [`${keyPrefix}.obBroker.userName`, `${keyPrefix}.obBroker.userId`]);

  const onCompanyChange = async (change?: SearchSuggestionsResponse | string): Promise<void> => {
    const value = getAutocompleteValue(change);

    if (typeof change === 'object') {
      const [traders, accounts] = await getLinkedItems(change.value);

      mutateTradeLeg({
        company: value,
        contactName: traders?.find(i => i.IsLastUsed === 'true')?.value,
        tradingAccount: accounts?.find(i => i.IsLastUsed === 'true')?.value,
      }, [`${keyPrefix}.company`, `${keyPrefix}.contactName`, `${keyPrefix}.tradingAccount`]);
    } else {
      // Unlikely to happen
      mutateTradeLeg({ company: value }, `${keyPrefix}.company`);
    }

    setCustomErrors({});
  };

  const updateOrValidateCompany = async (value: string): Promise<void> => {
    const suggestion = companyInputRef.current?.suggestions.find(s => s.value === value);

    // if there is inputted value in the suggestions list -> make it selected
    if (suggestion) {
      // do nothing if current company name is equal to inputted value
      if (leg[sectionKey].company !== value) {
        onCompanyChange(suggestion);
      }
    } else if (value) {
      const createdSuggestion = await validateSuggestion(value, EntitySearchFieldsEnum.TradeCompany, []);
      // if the new company has been created then save it to the request
      if (createdSuggestion) {
        onCompanyChange(createdSuggestion);
      }

      return;
    }

    setCustomErrors({});
  };

  const onCompanyKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    // If tab has been clicked then set or validate company.
    // This case is needed for scenario when mouse hovers suggestion item on the list
    // but 'Tab' button has been pressed only. It's due the workaround of `blur` event on suggestion click below.
    if (e.key === 'Tab') {
      updateOrValidateCompany((e.target as HTMLInputElement).value);
    }
  };

  const onCompanyBlur = (event: FocusEvent<HTMLInputElement>): void => {
    // If any of panel items has hover that means that 'onSelect' event has been fired.
    // Hint from: https://github.com/primefaces/primeng/issues/10808#issuecomment-1447237284
    if (!document.querySelectorAll('.p-autocomplete-panel ul li:hover').length) {
      updateOrValidateCompany(event.target.value);
    }
  };

  const onRelativeInputChange = (value: string | undefined, key: string): void => {
    mutateTradeLeg({ [key]: value }, `${keyPrefix}.${key}`);
    setCustomErrors(errs => ({ ...errs, [key]: '' }));
  };

  const onRelativeInputBlur = async (e: FocusEvent<HTMLInputElement>, searchField: EntitySearchFieldsEnum, items: RelativeAutocompleteSuggestionItem[]): Promise<void> => {
    const { value } = e.target;

    // If the suggestions list contains item with value equal to inputted value
    // then we can omit validation of the suggestion since it's already on the list (means it's valid).
    // No need to `mutate` request either because it's already saved via `onChange` event.
    // If any of panel items has hover that means that 'onSelect' event has been fired.
    // Hint from: https://github.com/primefaces/primeng/issues/10808#issuecomment-1447237284
    if (!items.find(s => s.value === value) && !document.querySelectorAll('.p-autocomplete-panel ul li:hover').length) {
      const { company } = leg[sectionKey];

      if (company) {
        await validateSuggestion(value, searchField, [{ key: 'TradeCompany', value: company }]);
        await getLinkedItems(company); // re-fetch linked items so they can be displayed on the list
      }
    }
  };

  const sortSuggestions = (item: RelativeAutocompleteSuggestionItem) => item.IsLastUsed === 'true' ? -1 : 0;

  const fetchLinkedItems = async (searchField: EntitySearchFieldsEnum, companyName: string): Promise<RelativeAutocompleteSuggestionItem[]> =>
    SearchEntitiesApi.searchSuggestions(`Search/Suggestions/${EntitySearchGroupEnum.Blotter}/${searchField}/*/1000?additionalFilter=${encodeURIComponent(`TradeCompany=${companyName}`)}`);

  const getLinkedItems = async (companyName: string): Promise<[RelativeAutocompleteSuggestionItem[], RelativeAutocompleteSuggestionItem[]]> => {
    const [ts, as] = await Promise.allSettled([
      fetchLinkedItems(EntitySearchFieldsEnum.TradeContactName, companyName),
      fetchLinkedItems(EntitySearchFieldsEnum.TradeAccount, companyName)
    ]);

    const traders = ts.status === 'fulfilled' ? ts.value : [];
    const accounts = as.status === 'fulfilled' ? as.value : [];

    setTraders(traders.sort(sortSuggestions));
    setAccounts(accounts.sort(sortSuggestions));

    return [traders, accounts];
  };

  const searchFieldToKey = (searchField: EntitySearchFieldsEnum): string => {
    switch (searchField) {
      case EntitySearchFieldsEnum.TradeCompany:
        return 'company';
      case EntitySearchFieldsEnum.TradeAccount:
        return 'tradingAccount';
      case EntitySearchFieldsEnum.TradeContactName:
        return 'contactName';
      default:
        return '';
    }
  };

  const validateSuggestion = async (value: string, searchField: EntitySearchFieldsEnum, metaData: KeyValue<string, string>[]): Promise<SearchSuggestionsResponse | null> => {
    try {
      return await SearchEntitiesApi.createSuggestion(`Search/Suggestions/${EntitySearchGroupEnum.Blotter}/${searchField}`, {
        value,
        metaData
      });
    } catch (e) {
      if ((e as AxiosError).status === 409) {
        const fieldName = searchFieldToKey(searchField);

        // show error if field has been in fact changed
        if (leg[sectionKey][fieldName as keyof TradeSide] !== value || touchedFields.includes(`${keyPrefix}.${fieldName}`)) {
          setCustomErrors(errs => ({ ...errs, [fieldName]: 'The value you entered is already in the list' }));
        }
      }

      return null;
    }
  };

  useEffect(() => {
    const { company } = leg[sectionKey];

    // fetch linked items so they can be shown immediately as suggestions
    if (company) {
      getLinkedItems(company);
    }
  }, [leg[sectionKey].company]);

  return <section>
    <header><h2>{capFirstLetter(sectionKey)}</h2></header>
    <div className={`section--${sectionKey}`}>
      <SingleEntitySearch
        ref={companyInputRef}
        callback={onCompanyChange}
        onFocusMethod={selectOnFocus}
        onBlurMethod={onCompanyBlur}
        autocompletePt={{
          root: () => ({
            onKeyDown: onCompanyKeyDown
          })
        }}
        onInputClear={(): void => mutateTradeLeg({ company: '' }, `${keyPrefix}.company`)}
        label='Company*'
        errorVisibleAfterTouch={false}
        showError={!!customErrors.company || shouldShowError(`${keyPrefix}.company`) || (validationMode === ValidationMode.CompanyOnly && !!errors?.[`${keyPrefix}.company`])} // show error immidiatelly if buyer.company === seller.company
        customErrorMessage={customErrors.company || errors?.[`${keyPrefix}.company`]}
        fields={EntitySearchFieldsEnum.TradeCompany}
        module={EntitySearchGroupEnum.Blotter}
        initialTerm={leg[sectionKey].company}
        itemTemplate={(i: SearchSuggestionsResponse): string => i.value}
        placeholder='Search or create Company'
      />
      <RelativeAutocomplete
        id={`relative-autocomplete-${legIndex}-${sectionKey}-trader`}
        label='Trader*'
        placeholder={(leg[sectionKey].company && !traders.length) ? 'Please add new Trader' : 'Select or create Trader'}
        disabled={!leg[sectionKey].company}
        items={traders}
        onFocus={selectOnFocus}
        onBlur={(e: FocusEvent<HTMLInputElement>) => onRelativeInputBlur(e, EntitySearchFieldsEnum.TradeContactName, traders)}
        onChange={(value) => onRelativeInputChange(value, 'contactName')}
        value={leg[sectionKey].contactName}
        error={customErrors.contactName || (shouldShowError(`${keyPrefix}.contactName`) ? errors?.[`${keyPrefix}.contactName`] : undefined)}
      />
      <RelativeAutocomplete
        id={`relative-autocomplete-${legIndex}-${sectionKey}-account`}
        label='Trading Account*'
        placeholder={(leg[sectionKey].company && !accounts.length) ? 'Please add new Account' : 'Select or create Account'}
        disabled={!leg[sectionKey].company}
        items={accounts}
        onFocus={selectOnFocus}
        onBlur={(e: FocusEvent<HTMLInputElement>) => onRelativeInputBlur(e, EntitySearchFieldsEnum.TradeAccount, accounts)}
        onChange={(value) => onRelativeInputChange(value, 'tradingAccount')}
        value={leg[sectionKey].tradingAccount}
        error={customErrors.tradingAccount || (shouldShowError(`${keyPrefix}.tradingAccount`) ? errors?.[`${keyPrefix}.tradingAccount`] : undefined)}
      />
      <SingleEntitySearch
        callback={onObBrokerChange}
        onInputClear={onObBrokerInputClear}
        completeMethod={onObBrokerCompleteMethod}
        label='OB Broker*'
        errorVisibleAfterTouch={false}
        showError={shouldShowError(`${keyPrefix}.obBroker.userName`) || shouldShowError(`${keyPrefix}.obBroker.userId`)}
        customErrorMessage={errors?.[`${keyPrefix}.obBroker.userId`]} // if text is inputted but not an option selected then show validation error
        fields={EntitySearchFieldsEnum.User}
        module={EntitySearchGroupEnum.Users}
        initialTerm={request.isImported ? leg[sectionKey].obBroker.userName : formatName(leg[sectionKey].obBroker.userName)}
        itemTemplate={(i: SearchSuggestionsResponse): string => formatName(i.value)}
        onFocusMethod={selectOnFocus}
        placeholder='Search OB Broker'
      />
      <div className='form-input__container direction--row trade-input--single-line'>
        <Checkbox
          checked={leg[sectionKey].paysBrokerage}
          value={leg[sectionKey].paysBrokerage}
          inputId={`trade__${[sectionKey]}-pays-brokerage-checkbox`}
          onChange={(e: CheckboxChangeEvent): void => mutateTradeLeg(
            {
              paysBrokerage: e.checked,
              rate: e.checked ? leg[sectionKey].rate : '', // reset rate and obBroker on uncheck
            },
            [`${keyPrefix}.paysBrokerage`, `${keyPrefix}.rate`]
          )}
        />
        <label htmlFor={`trade__${[sectionKey]}-pays-brokerage-checkbox`}>Pays Brokerage</label>
      </div>
      {leg[sectionKey].paysBrokerage &&
        <div className='form-input__container'>
          <label htmlFor={`trade__${[sectionKey]}-rate`}>Rate*</label>
          {/* Use `InputText` isntead of `InputNumber` because of bug on mobile Android with entering decimal numbers */}
          <div className={clsx('p-inputgroup', { 'p-invalid': shouldShowError(`${keyPrefix}.rate`) })}>
            <InputText
              id={`trade__${[sectionKey]}-rate`}
              className='align--right'
              keyfilter='pnum'
              type='number'
              value={`${leg[sectionKey].rate ?? ''}`}
              placeholder='Enter Value'
              onChange={(e: ChangeEvent<HTMLInputElement>): void => mutateTradeLeg({
                rate: getFixedValue(e.target.value)
              }, `${keyPrefix}.rate`)}
              min={0}
            />
          </div>
          {shouldShowError(`${keyPrefix}.rate`) && <small className='message-invalid'>Required field</small>}
        </div>}
    </div>
  </section>;
};