import { WaypointType } from 'components/Map/Models/Enums';
import { Group } from './Enums';

export const DEFAULT_SPEED = 14;
export const MAX_SPEED = 40;
export const MIN_SPEED = 1;
export const MAX_HOURS_IN_PORT = 999999;
export const MIN_HOURS_IN_PORT = 0;
export const MAX_WEATHER_MARGIN = 999999;
export const MIN_WEATHER_MARGIN = 0;

export const INPUT_PLACEHOLDER = 'Calculate by loading, via, discharging, vessel class';
export const INPUT_PLACEHOLDER_MOBILE = 'Loading, bunkering, via, vess...';

export const TOKENS_LIMIT_NUMBER = {
  [Group.Loading]: 1,
  [Group.Via]: 1,
  [Group.Discharging]: 1,
  [Group['Vessel Class']]: 1,
  [Group.Avoiding]: 3,
};

export const WAYPOINT_TYPE_TO_GROUP_MAP: { [key in WaypointType]: string }  = {
  [WaypointType.Load]: 'loading',
  [WaypointType.Discharge]: 'discharging',
  [WaypointType.Via]: 'via',
  [WaypointType.Waypoint]: 'waypoint',
  [WaypointType.Vessel]: '',
  [WaypointType.OpenLocation]: ''
};

export const WAYPOINT_GROUP_TO_TYPE_MAP: { [K in keyof typeof Group]?: WaypointType } = {
  Loading: WaypointType.Load,
  Via: WaypointType.Via,
  Discharging: WaypointType.Discharge
};
