import { useState, useEffect, ChangeEvent, MutableRefObject } from "react";
import { clsx } from "clsx"
import { DateTime } from "luxon";

import { InputText } from 'primereact/inputtext';

import { useSaveNewRate } from 'modules/Rates/Grids/Services/RateGridHooks';

import { IRateGridRouteValueResponse, RateDataTypeEnum } from 'modules/Rates/Grids/Models/RateGridResponse';

import { humanDate } from "helpers/Utils/formatters";

import "./RateCell.scss";


export interface IRateCellParams {
		data: IRateGridRouteValueResponse[];
		maxDataAge: number;
		name: string;
		index: number;
		opts?: any;
		table?: MutableRefObject<any>;
}

export const RateCell = (props: IRateCellParams) => {

		const { editorCallback } = props.opts;
		const { table, maxDataAge, name, index, data } = props;

		const { updateRate, error, isMutating } = useSaveNewRate();

		const [ cellData, setCellData ] = useState<IRateGridRouteValueResponse>(props.data[index]);

		//	Keep a copy of the initial cellData state in case we need to abandon
		//	any edits and restore the original state
		const [ initalValue, setInitialValue ] = useState<IRateGridRouteValueResponse>(props.data[index]);
		const [ minsElapsed, setMinutes ] = useState<number>(60);
		const [ isValidValue, setIsValidValue ] = useState<boolean>(true);
		const [ isOutdated, setIsOutdated ] = useState<boolean>(false);

		useEffect(() => {

			//	guard for the first render now having a value
			if (!cellData) return;

			const { asOf } = cellData;
			const { validationRegex } = cellData.behaviour;

			const { minutes } = asOf.diffNow(["minutes"]);
			setMinutes(Math.abs(minutes));
			
			const { hours } = asOf.diffNow(["hours"]);
			setIsOutdated(Math.abs(hours) > (maxDataAge ?? 0));

			//	If we have a regex defined in the expected behaviour, validate that
			//	the current value of the cell if formatted in a permitted way
			if (validationRegex) {
				setIsValidValue(new RegExp(validationRegex).test(cellData.value));
			}

		}, [cellData, maxDataAge]);

		useEffect(() => {
			setCellData(data[index]);
		}, [index, data])

    if (!cellData) {
			//	we dont have any price/value for this route market so just return
			//	an empty fragment
			return <></>
		}

		if (error) {
			//	TODO - handle a failed mutation state
		}

		const onLocalCellEditComplete = async (newdata: IRateGridRouteValueResponse) => {
			
			setCellData(c => ({
				...newdata,
				asOf: DateTime.now().toUTC(),
				valueSetByDisplayName: 'You'
			}));			
      
			const { grid, columnGroup, columnName, value } = newdata;

			//	Send the mutation to the server via out updateRate trigger method
			updateRate({
				grid,
				data: { columnGroup, columnName, route: name, value }
			})

			//	Reset the initial value needs setting to the new state of cellData
			//	incase we want to go back and make subsequent changes in the 
			//	current session
			setInitialValue(cellData);
		}


		return <>
			<div className={clsx(
				"grow-to-fill", 
				cellData.dataType === RateDataTypeEnum.Sentiment ? 'cell__rate-grids--sentiment' : 'cell__rate-grids--value'
			)}>
				<em>
					<i className={clsx({
						"pi processing pi-spinner pi-spin": isMutating,
						"iconoir-priority-high icon--small icon--red": !isValidValue 
					})} />
				</em>

				<data className={clsx({
					"invalid": !isValidValue,
					"updated": isValidValue && minsElapsed <= 1,
					"outdated": isValidValue && isOutdated,
					'align--right': cellData.dataType === RateDataTypeEnum.Value,
					"no--padding": editorCallback 
				})}>
					{ cellData.dataType === RateDataTypeEnum.Value && 
						<form>
							<InputText
								className="p-inputtext--in-cell align--right" 
								value={cellData.value}
								onFocus={(e: any) => e.target.setSelectionRange(0, e.target.value.length)}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
										setCellData({...cellData, value: e.target.value});
								}}
								tabIndex={0}
								onKeyDown={(e) => {
									switch(e.key) {
										case "Escape":
											//	pressing escape should abaondon any changes
											setCellData(initalValue);
											break;
										case "Enter":
											//	The input is wrapped in a form tab b/c Android swallows
											//	ENTER key press events when there are multuple input
											//	items in a page
											e.preventDefault();
											(e.target as HTMLInputElement).blur();
											onLocalCellEditComplete(cellData);
											break;
										case "Tab":
											if ((e.target as any).value !== initalValue.value) {
												onLocalCellEditComplete(cellData);
											}
											break;
									}
								}}
							/>
						</form>
					}
					{
						cellData.dataType === RateDataTypeEnum.Sentiment &&
						<>
							<select
								value={cellData.value}
								tabIndex={0}
								onChange={(e) => {
									const { value } = e.target;
									setCellData({...cellData, value});
									onLocalCellEditComplete({...cellData, value});
								}}
								className="p-dropdown"
							>
								{ cellData.behaviour?.allowedValues?.map( inst => (
									<option value={inst} key={inst}>{inst}</option>
								))
								}
							</select>
						</>
					}
				</data>

				{ cellData.dataType === RateDataTypeEnum.Value &&
					<var>{ cellData.unit }</var>
				}
				{ cellData.valueSetByDisplayName && 
						<address data-type="author">{cellData.valueSetByDisplayName}</address>
				}
				{ cellData.asOf &&
						<time dateTime={ cellData.asOf.toISODate() as string }>
							{ humanDate(cellData.asOf, {time: true, toUTC: true, dayName: false}) } GMT
						</time>
				}
				{ !isValidValue && 
						<strong>{cellData.behaviour?.validationMessage}</strong>
				}
			</div>
		</>


}

export default RateCell;
