import { clsx } from 'clsx';
import { ColumnBodyOptions } from 'primereact/column';

import { getValueByPath } from 'helpers/Utils/misc';

import styles from './DoubleLine.module.scss';
import general from './General.module.scss';

/**
 * General custom cell rendering function for the <DataTable> instances. Used to display
 * 2 points of related data (eg vessel name, and IMO Number) over two lines.
 *
 * @param {T} data
 * @param {ColumnBodyOptions} config
 *
 */
export const DoubleLineSimple = <T,>(data: T, config: ColumnBodyOptions) => {

  const [upper, lower] = config.field.split(',');

  // get value by path in case that field is provided with dot (.), ex. `data.name`
  const up = getValueByPath(data, upper);
  const low = getValueByPath(data, lower);

  return <div className={clsx(
    'direction--column align-items--start',
    styles.cell
  )}>
    <span className={clsx(
      'upper',
      styles.upper,
      general.truncated)}
    >
      {up}
    </span>
    <span title={low} className={clsx('lower', styles.lower)}>{low}</span>
  </div>;
}

