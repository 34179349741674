import { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import Ribbon from 'components/Ribbons/Templates/Ribbon';

import { ResultsModeEnum, SanctionStatusEnum, SanctionTypeAPI } from '../../Models/Enums';
import { LegalEntitySanctionResult } from '../../Models/LegalEntitySanctionResult';
import { IVesselSanctionResult, VesselSanctionResult } from '../../Models/VesselSanctionResult';
import { useAuditSanctionsTrack } from '../../Services/SanctionsAPI';
import SanctionStatus from '../../Templates/SanctionStatus';

import { apiLegalEntityResponse } from 'modules/Sanctions/Models';

import './SanctionDetail.scss';

interface IProps {
  selectedItem: IVesselSanctionResult | apiLegalEntityResponse;
  resultsMode: ResultsModeEnum;
}

function SanctionDetails(props: IProps) {

  const { selectedItem, resultsMode } = props;
  const [ textProps, setTextProps ] = useState<Map<string, string | undefined>>();
  const [ statusProps, setStatusProps ] =  useState<{key: string, value: SanctionStatusEnum | undefined}[]>();
  const { trigger } = useAuditSanctionsTrack(selectedItem?.id);

  useEffect(() => {
    if (!selectedItem) return;

    const textFunc: Function = resultsMode === ResultsModeEnum.VesselSanctions ? VesselSanctionResult.generateTextPropMap : LegalEntitySanctionResult.generateTextPropMap;
    const statusFunc: Function = resultsMode === ResultsModeEnum.VesselSanctions ?
    VesselSanctionResult.generateStatusPropMap : LegalEntitySanctionResult.generateStatusPropMap;

    setTextProps(textFunc(selectedItem));

    setStatusProps(
      Array.from(statusFunc(selectedItem), ([key, value], index) => ({key, value, index})
    ))

    // Notify BE about user entering Details
    trigger({
      id: selectedItem.id,
      ProviderName: selectedItem.providerName ?? selectedItem.partitionKey,
      SanctionType: SanctionTypeAPI[ResultsModeEnum[resultsMode] as keyof typeof ResultsModeEnum]
    });
  }, [resultsMode, selectedItem, trigger]);

  if (!selectedItem || !textProps || !statusProps) {
    return <>
      <div>Please select at item from the table…</div>
    </>
  }

  return <>
    { resultsMode === ResultsModeEnum.VesselSanctions &&
      (selectedItem as IVesselSanctionResult).shipDetailsNoLongerMaintained === 1 &&
      <Ribbon
        className='sanction-details__no-data-ribbon'
        closable={false}
        severity='info'
        icon={<i className='iconoir-info-circle icon--tiny' />}
        text='Vessel Data is no longer maintained'
    /> }
    <div className="definition-list__container">
			{ Array.from(textProps.keys() ?? [])
			.filter(key => textProps.get(key) !== undefined)
			.map((key, index) =>
				<div key={index} className="definition-list__item">
					<dt>{key}</dt>
					<dd>{textProps.get(key)}</dd>
				</div>
			)}
    </div>
    <div className="grow-to-fill overflow--hidden">
      <DataTable
          value={statusProps}
          dataKey="index"
          removableSort
          className="sanctions-table--detail grow-to-fill header--micro"
          scrollable scrollHeight="flex"
        >
        <Column
          field="key"
          header="Type"
        />
        <Column
          field="value"
          body={SanctionStatus}
          header="Risk Level"
          className="sanction-status"
        />
      </DataTable>
    </div>
  </>;
}

export default SanctionDetails;
