import { useEffect, useState } from 'react';

import clsx from 'clsx';
import { Button } from 'primereact/button';

import { eventBus } from 'server/EventBus';

import { GridConfigPanelEvents } from './Enums';
import styles from './ConfigurationTabs.module.scss';

export enum GridConfigurationType {
	Column = 0,
	Filter = 1,
	Both = 2,
}

type ComponentParams = {
	type: GridConfigurationType;
	className?: string;
}

type TabComponnet = {
	children: any;
}

const Tab = (params: TabComponnet): JSX.Element => {
	
	const { children } = params;

	const [ state, setState ] = useState<boolean>(false);

	useEffect(() => {
		eventBus.dispatch(
			GridConfigPanelEvents.PANEL_VISIBILITY_CHANGE, 
			{ panel: "column" }
		)
	}, [state])

	return <Button
		icon="iconoir-nav-arrow-right icon--tiny"
		className={clsx(styles.button, 'p-button--vertical-tab', state && styles.ste)}
		onClick={() => setState(c => !c)}
		>
			{ children }
		</Button>
}


export const ConfigurationTabs = (params: ComponentParams) => {

	const { className, type } = params;

	return <>
		<div 
			id='configuration'
			className={clsx(styles.tab, className, 'no-background')}
		>
			{ type !== GridConfigurationType.Filter && 
				<Tab>
					<label className={`iconoir-view-columns-3 icon--tiny`}>Columns</label>
				</Tab>
			}
			{ type !== GridConfigurationType.Column && 
				<div>Show Columns</div>
			}
		</div>
	</>
		
}