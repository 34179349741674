import { DateTime } from 'luxon';

import type { DateObjectUnits } from 'luxon';
import type { ColumnSortEvent } from 'primereact/column';
import type F from 'types/generic-type';

export const sortByDateObjectInArray = (event: ColumnSortEvent, sortByField: string) => {
    const { data, order } = event;

    return data.sort((a: F<DateObjectUnits[]>, b: F<DateObjectUnits[]>) => {
        const first = a[sortByField]?.[0];
        const second = b[sortByField]?.[0];

        // empty strings goes to the end of the list
        // sometimes data object might be empty {} - check if there is at least year
        if (!first || !first.year) {
            return 1;
        } else if (!second || !second.year) {
            return -1;
        }

        let { days } = DateTime.fromObject(first).diff(DateTime.fromObject(second), 'days');

        return Math.sign(isNaN(days) ? 0 : days) * (order as number);
    });
}