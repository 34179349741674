import * as ColorString from "color-string";
import { TooltipPosition } from "chart.js";

export const chartFillGradient = (ctx: any, chartArea: any, color: string) => {
    const { top, bottom } = chartArea;

    const [r, g, b] = ColorString.get.rgb(color); // Use [r, g, b, a] if Alpha channel is needed

    const gradientBackground = ctx.createLinearGradient(0, top, 0, bottom);
    gradientBackground.addColorStop(0, color);
    gradientBackground.addColorStop(1, ColorString.to.rgb([r, g,  b, 0]));

    return gradientBackground;
}

export const positionAtTop = (elements: any, position: any): TooltipPosition => {

    const { x } = position;

    return {
        x,
        y: 0,
        yAlign: "top",
    }
}

export const toolTipLine = (chart: any) => {
    // let x = chart.

    const { ctx, scales, tooltip } = chart;
    const { y } = scales;
    
    if (!tooltip._active.length) return;
        
    const { element } = tooltip._active[0];
    const { x } = element;

    // let yAxis = chart.scales.y;
                
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x, y.top);
    ctx.lineTo(x, y.bottom);
    ctx.setLineDash([2, 3]);
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#7E868C';
    ctx.stroke();
    ctx.restore();

    console.log("tooTipLine")
}