import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';

import ItemTemplate, { ItemTemplateProps } from 'components/Autocomplete/Templates/ItemTemplate';
import DateTimeRange from 'components/DateTimeRange';
import { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';
import {
  EntitySearchFieldsEnum,
  EntitySearchGroupEnum,
} from 'components/EntitySearch/Models/Enums';
import SingleEntitySearch from 'components/EntitySearch/SingleEntitySearch';
import { Flag } from 'components/Flag';
import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';
import QuantityParser from 'components/QuantityParser/QuantityParser';
import TextInput from 'components/TextInput';

import {PositionConfidentialityEnum} from '../../../PositionsList/Models/Enums';
import { CargoUpdateRequest } from '../../Models/CargoTrackerRequest';
import { StatusEnum, UnitEnum } from '../../Models/Enums';
import GroupedSearch, {
  SuggestionResponse,
  SuggestionResponseLocation
} from '../GroupedSearch/GroupedSearch';

import {EnumKeys, getKeyValuePairs} from 'helpers/Utils/enum';
import { laycanStyleRange } from 'helpers/Utils/formatters';
import { formatName } from 'helpers/Utils/string';
import {LocationType, PetroleumProductEnum, PP_TEXT_MAP} from 'modules/CargoTracker/Models/CargoTrackerResponse';
import { chartererEntityMapper, zoneLocationEntityMapper } from 'modules/CargoTracker/Models/Mappers';
import AssignToMe from 'components/AssignToMe';

import type { AutoCompleteCompleteEvent } from 'primereact/autocomplete';

interface ICargoEditTimeCharterProps {
  request: CargoUpdateRequest;
  mutateCargo: (mutation: Partial<CargoUpdateRequest>) => void;
  showErrors: boolean;
  setIsParsing: Dispatch<SetStateAction<boolean>>;
}

const CargoEditTimeCharter = (props: ICargoEditTimeCharterProps):JSX.Element => {
  const { request, mutateCargo, showErrors, setIsParsing } = props;
  
  const selectedPetroliumProdRef = useRef<RadioButton>(null);

  const [tempValue, setTempValue] = useState<string | undefined>(undefined);
  const statuses: EnumKeys[]  = getKeyValuePairs(StatusEnum, true);
  const { obxuser } = useLoggedInUser();
  const petroleumProduct: EnumKeys[]  = getKeyValuePairs(PetroleumProductEnum);

  const handleLocationChange = (change: SuggestionResponseLocation | undefined, key: 'loading' | 'discharging'): void => {
    const location = request[key];

    if (!change) {
      mutateCargo({ [key]: {
        locationType: location?.locationType ?? LocationType.Search
      }});

      return;
    }

    if (location?.locationType !== LocationType.Text) {
      mutateCargo({ [key]: {
        locationType: location?.locationType ?? LocationType.Search,
        portSignalOceanId: change.SignalOceanPortId,
        flagCode: change.CountryCodeISO3,
        portName: change.searchField === EntitySearchFieldsEnum.Port ? change.value : undefined,
        portCountry: change.searchField === EntitySearchFieldsEnum.Country ? change.value : undefined
      }});
    } else {
      mutateCargo({ [key]: {
        locationType: location.locationType,
        zone: { value: change.value, searchId: change.searchEntityId }
      }});
    }
  };

  const handleChartererChange = (change?: SuggestionResponse): void => {
    mutateCargo({
      charterer: change?.value ?? change?.searchTerm ?? '',
      chartererSearchId: change?.searchEntityId ?? null,
    });
  };

  const handleChartererChangeFreeInput = (change?: string): void => {
    mutateCargo({
      charterer: change ?? '',
      chartererSearchId: null,
    });
  };

  const handleLoadingChange = (change?: SuggestionResponseLocation): void => {
    handleLocationChange(change, 'loading');
  };

  const handleDischargingChange = (change?: SuggestionResponseLocation): void => {
    handleLocationChange(change, 'discharging');
  };

  // If C/D Petroleum Product Type is N/A -> update do default CPP/TankerClean
  useEffect(() => {
    if (request.petroleumProductType === PetroleumProductEnum.NA) {
      mutateCargo({petroleumProductType: PetroleumProductEnum.TankerClean});
    }
  }, [mutateCargo, request.petroleumProductType]);

  useEffect(() => {
    // Add temp value first to update initialTerm (issue when selecting same values but with different IDs)
    if (tempValue) {
      setTempValue(undefined);
    }
  }, [tempValue]);

  useEffect(() => {
    if(request.id) {
      selectedPetroliumProdRef.current?.focus();
    }
  }, [request]);

  return <>
    <div className="form-input__container">
      <label htmlFor="ce-pp">C/D</label>
      <div className="form-input--radio-buttons">
        { petroleumProduct.filter(item => item.value !== PetroleumProductEnum.NA).map(t => {
          const { key , value } = t;
          return <div key={`${ key }-${ value }`}>
            <RadioButton
              id={`radio-${ key }`}
              ref={request.userRights?.canEditVisibility === false && value === request.petroleumProductType ? selectedPetroliumProdRef : null}
              value={value}
              name="ce-pp"
              tabIndex={1}
              checked={value === request.petroleumProductType}
              onChange={(e: RadioButtonChangeEvent):void => mutateCargo({ petroleumProductType: e.value })}
            />
            <label htmlFor={`radio-${ key }`}>{PP_TEXT_MAP[PetroleumProductEnum[key as keyof typeof PetroleumProductEnum]]}</label>
          </div>;
        })}
      </div>
    </div>
    <div className="form-input__container">
      <label htmlFor="ct-delivery">Delivery*</label>
      <GroupedSearch
        inputProps={{
          // Autocomplete doesn't trigger onSelect event when free text (custom selection) is inputted
          // trigger `mutate` to set the current value and it has to be handled properly
          // as it's only input text that is passed.
          completeMethod: (e: AutoCompleteCompleteEvent):void => {
            if (request.loading?.locationType === LocationType.Text) {
              handleLoadingChange({ value: e.query } as SuggestionResponseLocation);}
          }
        }}
        allowCustomSelection={request.loading?.locationType === LocationType.Text}
        dropdownOptions={{
          className: 'cargo-edit__location-dropdown',
          onChange: (e):void => mutateCargo({ loading: { locationType: e.value }}),
          options: [{ value: LocationType.Search, label: 'Port/Country' }, { value: LocationType.Text, label: 'Zone' }],
          value: request.loading?.locationType || LocationType.Search,
        }}
        id="ct-delivery"
        panelClassName="cargo-edit__autocomplete"
        module={request.loading?.locationType !== LocationType.Text ?
          EntitySearchGroupEnum.PositionList :
          EntitySearchGroupEnum.CargoTracker
        } // yeah... that seems to be unclear a bit but some searches requires different context
        fields={request.loading?.locationType !== LocationType.Text ?
          [ EntitySearchFieldsEnum.Port, EntitySearchFieldsEnum.Country ] :
          [ EntitySearchFieldsEnum.Zone ]
        }
        initialTerm={request.loading?.portName ?? request.loading?.portCountry ?? request.loading?.zone?.value}
        mutate={handleLoadingChange}
        mapper={zoneLocationEntityMapper}
        addOn={ request?.loading?.flagCode ? <Flag code={request.loading.flagCode} /> : null }
        showError={showErrors}
      />
    </div>
    <DateTimeRange
      label='Delivery Date*'
      key={`delivery-date-${ request.id }`}
      onEmptyValue={(value: string):void => {
        mutateCargo({ laycan: { original: value, fromDate: '', toDate: '' } });
        setIsParsing(false);
      }}
      onDateParsed={(m: ParssedDateTimeResult):void => {
        mutateCargo({ laycan: {
          original: m.original,
          fromDate: m.fromString,
          toDate: m.toString,
          fromDateParsed: m.from,
          toDateParsed: m.to
        }});
        setIsParsing(false);
      }}
      defaultValue={
        request?.laycan?.fromDateParsed && request?.laycan?.toDateParsed ?
          laycanStyleRange(request?.laycan?.fromDateParsed, request?.laycan?.toDateParsed) :
          (request?.laycan?.original ?? '')
      }
      showErrorMessage={showErrors}
      onFocus={():void => setIsParsing(true)}
    />
    <div className="form-input__container">
      <label htmlFor="ct-re-delivery">Re-Delivery*</label>
      <GroupedSearch
        inputProps={{
          // Autocomplete doesn't trigger onSelect event when free text (custom selection) is inputted
          // trigger `mutate` to set the current value and it has to be handled properly
          // as it's only input text that is passed.
          completeMethod: (e: AutoCompleteCompleteEvent): void => {
            if (request.discharging?.locationType === LocationType.Text) {
              handleDischargingChange({ value: e.query } as SuggestionResponseLocation);}
          }
        }}
        allowCustomSelection={request.discharging?.locationType === LocationType.Text}
        dropdownOptions={{
          className: 'cargo-edit__location-dropdown',
          onChange: (e):void => mutateCargo({ discharging: { locationType: e.value }}),
          options: [{ value: LocationType.Search, label: 'Port/Country' }, { value: LocationType.Text, label: 'Zone' }],
          value: request.discharging?.locationType || LocationType.Search,
        }}
        id="ct-re-delivery"
        panelClassName="cargo-edit__autocomplete"
        module={request.discharging?.locationType !== LocationType.Text ?
          EntitySearchGroupEnum.PositionList :
          EntitySearchGroupEnum.CargoTracker
        }
        fields={request.discharging?.locationType !== LocationType.Text ?
          [ EntitySearchFieldsEnum.Port, EntitySearchFieldsEnum.Country ] :
          [ EntitySearchFieldsEnum.Zone ]
        }
        initialTerm={request?.discharging?.portName ?? request?.discharging?.portCountry ?? request.discharging?.zone?.value}
        mutate={handleDischargingChange}
        mapper={zoneLocationEntityMapper}
        addOn={ request?.discharging?.flagCode ? <Flag code={request?.discharging?.flagCode} /> : null }
        showError={showErrors}
      />
    </div>
    <div className="form-input__container">
      <label htmlFor="ct-charter-period">Charter Period*</label>
      <TextInput
        id="ct-charter-period"
        defaultValue={request?.charterPeriod ?? ''}
        onChange={(value):void => mutateCargo({ charterPeriod: value })}
        showError={showErrors}
      />
    </div>
    <div className="form-input__container">
      <label htmlFor="ct-vessel-size">Vessel Size*</label>
      <QuantityParser
        id="ct-vessel-size"
        key={`ct-vessel-size-${ request.id }`}
        defaultValue={request?.quantity?.original ?? null}
        onRangeParsed={(parsed):void => {
          mutateCargo(parsed ? { quantity:
              { ...request?.quantity, ...parsed, unit: request.quantity?.unit ?? UnitEnum.MT }
          } : { quantity: undefined });
          setIsParsing(false);
        }}
        addonText={UnitEnum[request?.quantity?.unit ?? UnitEnum.MT]}
        showError={showErrors}
        onFocus={():void => setIsParsing(true)}
      />
    </div>
    <div className="form-input__container">
      <label htmlFor="ct-vessel-type">Vessel Type</label>
      <GroupedSearch
        id="ct-vessel-type"
        panelClassName="cargo-edit__autocomplete"
        module={EntitySearchGroupEnum.PositionList} // CargoTracker?
        fields={[ EntitySearchFieldsEnum.VesselType, EntitySearchFieldsEnum.VesselSubType ]}
        initialTerm={request?.vesselType?.type ?? request?.vesselType?.subType}
        mutate={(change?:SuggestionResponse):void => mutateCargo(change ? { vesselType:
              change.searchField === EntitySearchFieldsEnum.VesselType ? { type: change.value } : { subType: change.value }
        } : { vesselType: undefined})}
      />
    </div>
    <SingleEntitySearch
      label='Charterer'
      module={EntitySearchGroupEnum.CargoTracker}
      fields={EntitySearchFieldsEnum.Charterer}
      initialTerm={request?.charterer ?? ''}
      itemTemplate={(i: ItemTemplateProps):JSX.Element => ItemTemplate(i, chartererEntityMapper)}
      onInputClear={():void => mutateCargo({ charterer: '', chartererSearchId: null })}
      callback={handleChartererChange}
      panelClassName="tc-charterer"
      completeMethod={handleChartererChangeFreeInput}
    />
    <div className="form-input__container">
      <label htmlFor="ct-status">Status</label>
      <Dropdown
        id="ct-status"
        value={request?.status ?? StatusEnum.New}
        onChange={(e:DropdownChangeEvent):void => mutateCargo({ status: e.value })}
        options={statuses}
        optionLabel='key'
      />
    </div>
    {/* // Disabled Ability to Edit isUrgent
    <ToggleSwitch
      checked={request?.isUrgent ?? false}
      callback={(e: boolean):void => mutateCargo({ isUrgent: e })}
      label="right"
      align="left">
      Urgent
    </ToggleSwitch>
    */}
    <SingleEntitySearch
      customItems={[{
        value: obxuser?.name ?? '',
        searchEntityId: obxuser?.userId ?? '',
        template: AssignToMe
      }]}
      showCustomItem={!request.assignedUser || request.assignedUser.userName !== obxuser?.name}
      label='Assignee'
      isDisabled={request.confidentialityIndicator === PositionConfidentialityEnum.VPNC}
      module={EntitySearchGroupEnum.Users}
      fields={EntitySearchFieldsEnum.User}
      initialTerm={formatName(request.assignedUser?.userName ?? '')}
      itemTemplate={(i: SuggestionResponse):string => formatName(i.value)}
      onInputClear={():void => mutateCargo({ assignedUser: undefined })}
      callback={(change?: SuggestionResponse | null):void => {
        setTempValue(' ');
        mutateCargo({ assignedUser: { userId: change?.searchEntityId ?? '', userName: change?.value ?? '' }});
      }}
    />
    <div className="form-input__container">
      <label htmlFor="ct-notes">Comments</label>
      <InputTextarea
        id="ct-notes"
        autoResize
        onFocus={(e):void => e.target.select()}
        value={request?.notes ?? ''}
        onChange={(e):void => mutateCargo({ notes: e.target.value })}
      />
    </div>
  </>;
};

export default CargoEditTimeCharter;