import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Options } from 'highcharts';
import * as Highcharts from 'highcharts';
import exportingModule from 'highcharts/modules/exporting';
import HighchartsReact, {
  HighchartsReactRefObject,
} from 'highcharts-react-official';
import { DateTime } from 'luxon';
import { Dropdown } from 'primereact/dropdown';

import ChartActionsMenu from 'components/ChartActionsMenu';
import { ChartingDisplayState } from 'components/ColumnLayoutSelector';
import { ToastMessageRef } from 'components/ToastMessage';

import styles from './Chart.module.scss';

type ChartingProps = {
  sourceName: string;
  feed: string;
  size: ChartingDisplayState;
  series?: Record<string, Highcharts.SeriesLineOptions> | null;
  toastRef?: RefObject<ToastMessageRef>;
};

export type ChartReferenceProps = {
  resetRequest: () => void;
};

const DEFAULT_CHART_TYPE = 'Monthly';

exportingModule(Highcharts);

const Chart = (props: ChartingProps): JSX.Element => {
  const { series = {}, feed, sourceName, size, toastRef } = props;

  const [selectedChart, setSelectedChart] =
    useState<string>(DEFAULT_CHART_TYPE);

  const hc = useRef<HighchartsReactRefObject>(null);

  const [options, setOptions] = useState<Options | null>();

  const getExportFileName = useCallback(
    () =>
      `${ feed.toUpperCase() } - ${ sourceName } - ${ DateTime.now().toFormat(
        'yyyy-dd-MM HH:mm'
      ) }`,
    [feed, sourceName]
  );

  const periods = Object.keys(series || {});

  const selectedSeries = useMemo(
    () => (series ? series[selectedChart] : undefined),
    [series, selectedChart]
  );

  useEffect(() => {
    setOptions({
      series: selectedSeries && [selectedSeries],
      chart: {
        backgroundColor: 'transparent',
        styledMode: true,
        style: {
          fontFamily: 'OpenSans',
        },
      },
      title: {
        text: undefined,
      },
      yAxis: {
        title: { text: undefined },
      },
      xAxis: {
        type: 'category',
        labels: {
          step: Math.round(
            (ChartingDisplayState.Full - size) *
              ((selectedSeries?.data?.length || 0) / 10)
          ),
        },
      },
      exporting: {
        enabled: false,
        fallbackToExportServer: false,
      },
    });
  }, [series, selectedChart, selectedSeries, size]);

  return (
    <div className={styles.container}>
      <div>
        <Dropdown
          className="grow-to-fill"
          options={periods}
          value={selectedChart}
          onChange={({ value }): void => setSelectedChart(value)}
        />
        <ChartActionsMenu
          fileName={getExportFileName}
          highchartRef={hc}
          isDisabled={!selectedSeries?.data?.length}
          toastRef={toastRef}
        />
      </div>
      {options && (
        <HighchartsReact
          ref={hc}
          highcharts={Highcharts}
          options={options}
          allowChartUpdate={true}
          {...props}
        />
      )}
    </div>
  );
};

export default Chart;
