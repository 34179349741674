import { FocusEvent, FormEvent, useState } from 'react';
import { InputText, InputTextProps } from 'primereact/inputtext';

const HOURS_PART_REGEX = /^([0-9]|0[0-9]|1[0-9]|2[0-3]|[0-9]:)$/;
const HOURS_OR_HOUR_AND_MINUTE_REGEX = /^(([0-9]:[0-9]|0[0-9]|1[0-9]|2[0-3])|(0[0-9]|1[0-9]|2[0-3]):)$/;
const HOURS_AND_MINUTES_REGEX = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9])$/;

interface TimeInputProps extends InputTextProps {
  callback: (arg: string) => void;
}

const TimeInput = (props: TimeInputProps): JSX.Element => {
  const { callback, ...inputProps } = props;
  const [prevValue, setPrevValue] = useState<string>('');

  const onFocus = (e: FocusEvent<HTMLInputElement>): void => {
    e.target.select();
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const splitted = value.split(':');

    if (splitted[0].length === 1) {
      splitted[0] = `0${splitted[0]}`; // add leading 0: ex. '9' -> '09'
    }

    if (!splitted[1] || splitted[1].length === 0) {
      splitted[1] = '00'; // if no minutes part -> add '00'
    } else if (splitted[1].length === 1) {
      splitted[1] = `0${splitted[1]}`; // add leading 0: ex. '11:9' -> '11:09'
    }

    e.target.value = splitted.join(':');
    callback(e.target.value);
  };

  const test = (regex: RegExp, target: HTMLInputElement): void => {
    if (regex.test(target.value)) {
      setPrevValue(target.value);
      callback(target.value);
    } else {
      target.value = prevValue;
    }
  };

  const onInput = (e: FormEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    const { value } = target;

    switch (value.length) {
      case 0: // ''
        setPrevValue(value);
        callback(value);
        break;
      case 1: // ex. '1'
      case 2: // ex. '11' or '1:'
        test(HOURS_PART_REGEX, target);
        break;
      case 3: // ex. '11:' or '1:1'
        test(HOURS_OR_HOUR_AND_MINUTE_REGEX, target);
        break;
      case 4: // ex. '11:0' or '2:01'
      case 5: // ex. '11:11'
        test(HOURS_AND_MINUTES_REGEX, target);
        break;
      default:
        // Set previous valid value
        target.value = prevValue;
    }
  };

  return <InputText
    onInput={onInput}
    onBlur={onBlur}
    onFocus={onFocus}
    validateOnly
    {...inputProps}
  />;
};

export default TimeInput;