import { useMemo, SetStateAction, Dispatch } from 'react';
import { useMediaQuery } from 'react-responsive';

import { clsx } from 'clsx';
import { Button } from 'primereact/button';

import { getKeyValuePairs, EnumKeys } from 'helpers/Utils/enum';

import styles from './CommoditiesCharting.module.scss';

type ChartingPanelProps = {
	state: ChartingDisplayState;
	changeState: Dispatch<SetStateAction<ChartingDisplayState>>;
	openInNewWindow?: () => void;
}

export enum ChartingDisplayState {
	Hidden = -1,
	Small = 0,
	Medium = 1,
	Full = 2,
}


const ColumnLayoutSelector = (props: ChartingPanelProps) : JSX.Element => {

	const { state, changeState, openInNewWindow } = props;
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

	const availableLayouts = useMemo<EnumKeys[]>(() => {

		return getKeyValuePairs(ChartingDisplayState).filter(r => +r.value >= 0).sort((a, b) => a.value > b.value ? -1 : 1)

	}, [])

  return <>
    {!isTabletOrMobile &&
		  <div>
			  {availableLayouts.map(l => (
				  <Button
					  key={l.value}
					  text
					  icon={`iconoir-layout-columns-${l.key.toLowerCase()} icon--tiny wider-icon`}
					  className={clsx(
						  styles.iconButton,
						  state === l.value && styles.active)}
					  onClick={(e) => changeState(+l.value)}
				  />
        ))}
        {openInNewWindow && <Button
          text
          size='small'
          icon='iconoir-open-in-browser icon--small'
          className={clsx(styles.iconButton, styles.openInNewWindowButton)}
          onClick={openInNewWindow}
        />}
      </div>
    }
	  <Button text icon="iconoir-xmark icon--tiny"
		  className={clsx(styles.iconButton)}
		  onClick={(e) => {
			  //	Close the charting panel completely
			  changeState(ChartingDisplayState.Hidden);
		  }}
	  />
	</>;
}

export default ColumnLayoutSelector;
