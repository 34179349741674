import { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Duration } from 'luxon';
import { ProgressBar } from 'primereact/progressbar';
import { Slider, SliderProps } from 'primereact/slider';

import BorealisBar from 'components/BorealisBar';

import PlayPauseButton from './PlayPauseButton';

import './AudioPlayer.scss';

interface AudioPlayerComponentProps {
  className?: string;
  source?: string | null;
  onPlay?: () => void;
  onEnd?: () => void;
}

const AudioPlayer = ({
  className,
  source,
  onPlay = (): void => {},
  onEnd = (): void => {},
}: AudioPlayerComponentProps): JSX.Element => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentBufferTime, setCurrentBufferTime] = useState<Duration>(
    Duration.fromMillis(0)
  );
  const [totalTime, setTotalTime] = useState<Duration>(Duration.fromMillis(0));

  const audio = useMemo(() => {
    const audio = new Audio();

    audio.preload = 'metadata';

    return audio;
  }, []);

  useEffect(() => {
    if (source) {
      audio.src = source;
    } else {
      /**
       * ensure audio is not playing on source empty
       */
      setIsPlaying(false);
      setTotalTime(Duration.fromMillis(0));
      audio.src = '';
    }
  }, [audio, source]);

  const progress = Number.isFinite(totalTime.seconds)
    ? totalTime.seconds !== 0
      ? (audio.currentTime / totalTime.seconds) * 100
      : 0
    : 0;
  const bufferProgress =
    Number.isFinite(totalTime.seconds) &&
    Number.isFinite(currentBufferTime.seconds)
      ? totalTime.seconds !== 0
        ? (currentBufferTime.seconds / totalTime.seconds) * 100
        : 100
      : 100;

  useEffect(() => {
    const loadedMetadata: () => void = () => {
      setTotalTime(Duration.fromObject({ seconds: audio.duration }));
    };
    const timeUpdate: () => void = () => {
      try {
        setCurrentBufferTime(
          Duration.fromObject({
            seconds: audio.buffered.end(audio.buffered.length - 1),
          })
        );
      } catch {}
    };
    const onProgress = (): void => setIsLoading(false);
    const onStalled = (): void => setIsLoading(true);
    const ended: () => void = () => {
      setIsPlaying(false);

      if (typeof onEnd === 'function') {
        onEnd();
      }
    };

    audio.addEventListener('loadedmetadata', loadedMetadata);
    audio.addEventListener('timeupdate', timeUpdate);
    audio.addEventListener('progress', onProgress);
    audio.addEventListener('stalled', onStalled);
    audio.addEventListener('ended', ended);
    audio.addEventListener('play', onPlay);

    return () => {
      audio.pause();
      audio.removeEventListener('loadedmetadata', loadedMetadata);
      audio.removeEventListener('timeupdate', timeUpdate);
      audio.removeEventListener('progress', onProgress);
      audio.removeEventListener('stalled', onStalled);
      audio.removeEventListener('ended', ended);
      audio.removeEventListener('play', onPlay);
    };
  }, [audio, onPlay, onEnd]);

  useEffect(() => {
    if (isPlaying) {
      audio.play().catch(() => {
        onPlay();
      });
    } else {
      audio.pause();
    }
  }, [isPlaying, audio, onPlay]);

  const keyPressHandler = (ev: KeyboardEvent<HTMLDivElement>): void => {
    const { key } = ev;

    switch (key) {
      case ' ':
        setIsPlaying(!isPlaying);
        return;
      case 'ArrowRight':
        audio.currentTime += 10;
        return;
      case 'ArrowLeft':
        audio.currentTime -= 10;
        return;
      default:
        return;
    }
  };

  const progressChanged = ({ value }: SliderProps): void => {
    if (Number.isFinite(totalTime.seconds) && value !== undefined) {
      audio.currentTime = (totalTime.seconds / 100) * +value;
    }
  };

  const playPauseBtnClicked = (): void => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className={clsx('audio-player-container', className)}>
      {isLoading && (
        <BorealisBar styleOverrides='audio-player-container-loading-bar' />
      )}
      <div
        className='audio-player-component'
        tabIndex={0}
        onKeyDown={keyPressHandler}
      >
        <div className='audio-player-component-controls'>
          <PlayPauseButton
            isPlaying={isPlaying}
            onClick={playPauseBtnClicked}
          />
        </div>
        <div className='audio-player-component-progress'>
          <Slider
            className='audio-player-component-progress-slider'
            value={progress}
            max={100}
            onChange={progressChanged}
          />
          <ProgressBar
            className='audio-player-component-progress-buffer'
            showValue={false}
            value={bufferProgress}
          />
        </div>
        <div className='audio-player-component-timer'>{`${Duration.fromObject({
          seconds: audio.currentTime,
        }).toFormat('mm:ss')} / ${totalTime.toFormat('mm:ss')}`}</div>
      </div>
    </div>
  );
};

export default AudioPlayer;
