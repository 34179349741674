import { useCallback, useEffect, useRef, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { DataTable, DataTableSortMeta } from 'primereact/datatable';
import { MenuItem } from 'primereact/menuitem';

import Loader from 'components/Loader';
import { UISettings } from 'components/OBXUser/Model/Enums';
import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';

import {
  MutationType,
  WorksheetInternalEventTypes,
  WorksheetSignalMessageEventTypes,
  WorksheetStores,
} from './Models/Enums';
import {
  useAllWorksheets,
  useNukeWorksheet,
  useShareWorksheet,
  useUpdateLocalWorksheetList,
} from './Services/WorksheetHooks';
import {
  ShareWorksheetDialogBody,
  ShareWorksheetDialogFooter,
  WorksheetAction,
  WorkSheetName,
} from './Templates';

import { GlobalDialogDisplayEvents } from 'models/shared/DialogDisplay';
import { eventBus } from 'server/EventBus';

import type { WorksheetResponse } from './Models/WorksheetResponse';
import type { KeyedMutator } from 'swr';

import styles from './Worksheets.module.scss';

interface IAllWorksheetsProps {
  activeWorksheetId: string | null | undefined;
  setActiveWorksheetId: (arg: string | null | undefined) => void;
  store: WorksheetStores;
  currentRoute: string;
  setting: UISettings;
  preventDelete?: boolean;
  preventSharing?: boolean; // Sharing won't display
  preventSharingByNonOwners?: boolean; // Share active also for shared section. Sharing active only for creator
  onShareDialog?: (worksheet: WorksheetResponse) => void;
  setActiveWorksheetName?: (arg: string | null | undefined) => void;
  workSheetResultsFilter?: (item: WorksheetResponse) => boolean;
  worksheetUpdatedHandler?: (
    updatedWorksheet: Partial<WorksheetResponse>,
    storedWorksheets: WorksheetResponse[],
    updateSheetsLocally: KeyedMutator<any>,
    reloadWorksheetsList: KeyedMutator<WorksheetResponse[]>
  ) => void;
}

export const WORKSHEET_MULTISORT_META: DataTableSortMeta[] = [
  { field: 'isPrivate', order: -1 },
  { field: 'name', order: 1 },
];

export const AllWorksheets = (props: IAllWorksheetsProps): JSX.Element => {
  const {
    activeWorksheetId,
    setActiveWorksheetId,
    setActiveWorksheetName,
    store,
    currentRoute,
    setting,
    preventDelete = false,
    preventSharing = false,
    preventSharingByNonOwners = false,
    onShareDialog,
    workSheetResultsFilter,
    worksheetUpdatedHandler,
  } = props;

  const navigate: NavigateFunction = useNavigate();
  const { obxuser } = useLoggedInUser();

  const {
    sheets,
    error,
    isLoading,
    mutate: reloadWorksheets,
  } = useAllWorksheets(store, setting, workSheetResultsFilter);
  const { updateSheets } = useUpdateLocalWorksheetList(store);
  const { deleteWorksheet } = useNukeWorksheet(store);
  const { shareWorksheet } = useShareWorksheet(store);

  const [expandedRows, setExpandedRows] = useState<WorksheetResponse[]>([]);
  const [contextMenuItems, setContextMenuItems] = useState<MenuItem[]>([]);
  const [shouldShowShare, setShouldShowShare] = useState(false);

  const cm = useRef(null);
  const dt = useRef<DataTable<any>>(null);

  const handleShareSheet = useCallback(
    (worksheet: WorksheetResponse) => {
      if (!dt.current) {
        return;
      }

      //	hide the kebab menu for the current row
      try {
        dt.current
          .getTable()
          .querySelector('.hover')
          ?.classList.remove('hover');
      } catch (e) {
        //	 TODO…
      }

      const { name, worksheetId } = worksheet;
      const onShare = async (): Promise<void> => {
        await shareWorksheet({
          store: store,
          id: worksheetId,
        });
      };

      if (onShareDialog) {
        onShareDialog(worksheet);
      } else {
        eventBus.dispatch(GlobalDialogDisplayEvents.DISPLAY, {
          body: <ShareWorksheetDialogBody name={name} />,
          footer: <ShareWorksheetDialogFooter onShare={onShare} />,
          size: 'medium',
        });
      }
    },
    [store, onShareDialog, shareWorksheet]
  );

  useEffect(() => {
    /**
     * show share popup if it has been called via event bus
     * only when sheets have been loaded
     * otherwise share popup will be shown empty
     */
    if (sheets && shouldShowShare) {
      const worksheet = sheets.find(
        sheet => sheet.worksheetId === activeWorksheetId
      );
      if (worksheet) {
        const isCurrentUser = obxuser?.userId === worksheet?.createdBy;
        const isShareDisabled = preventSharingByNonOwners
          ? !isCurrentUser
          : !worksheet?.isPrivate;
        if (!isShareDisabled) {
          handleShareSheet(worksheet);
        }
      }
      setShouldShowShare(false);
    }
  }, [
    obxuser,
    activeWorksheetId,
    sheets,
    shouldShowShare,
    preventSharingByNonOwners,
    handleShareSheet,
  ]);

  useEffect(() => {
    // console.log("loaded sheets", sheets);
    if (!sheets) {
      return;
    }

    setExpandedRows(c => {
      let expanded: WorksheetResponse[] = [];

      const privateRow = sheets.find(s => s.isPrivate);
      const sharedRow = sheets.find(s => !s.isPrivate);

      if (privateRow) {
        expanded = [...expanded, privateRow];
      }
      if (sharedRow) {
        expanded = [...expanded, sharedRow];
      }
      return expanded;
    });
  }, [sheets]);

  useEffect(() => {
    const onWorksheetClosed = (payload: CustomEvent<string>): void => {
      //	A sheet has been closed so remove the badge showing adjacent to
      //	the worksheet in the list of items
      updateSheets(
        sheets?.map(s =>
          s.worksheetId === payload.detail ? { ...s, currentlyOpen: false } : s
        ),
        { revalidate: false }
      );
    };

    const onWorksheetUpdated = (
      payload: CustomEvent<Partial<WorksheetResponse>>
    ): void => {
      if (!sheets) {
        return;
      }

      if (worksheetUpdatedHandler) {
        worksheetUpdatedHandler(
          payload.detail,
          sheets,
          reloadWorksheets,
          updateSheets
        );
      } else {
        updateSheets(
          sheets.map(s =>
            s.worksheetId === payload.detail.worksheetId
              ? { ...s, ...payload.detail }
              : s
          ),
          { revalidate: false }
        );
      }
    };

    const onSharePopupShow = (): void => setShouldShowShare(true);

    eventBus.on(
      WorksheetSignalMessageEventTypes.WORKSHEET_CLOSED,
      onWorksheetClosed
    );
    eventBus.on(
      WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED,
      onWorksheetUpdated
    );
    eventBus.on(
      WorksheetInternalEventTypes.WORKSHEET_SHOW_SHARE_POPUP,
      onSharePopupShow
    );

    return () => {
      eventBus.remove(
        WorksheetSignalMessageEventTypes.WORKSHEET_CLOSED,
        onWorksheetClosed
      );
      eventBus.remove(
        WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED,
        onWorksheetUpdated
      );
      eventBus.remove(
        WorksheetInternalEventTypes.WORKSHEET_SHOW_SHARE_POPUP,
        onSharePopupShow
      );
    };
    // eslint-disable-next-line
  }, [sheets]);

  useEffect(() => {
    const updatedWorksheet = sheets?.find(
      sheet => sheet.worksheetId === activeWorksheetId
    );
    if (setActiveWorksheetName) {
      setActiveWorksheetName(updatedWorksheet?.name);
    }
  }, [activeWorksheetId, sheets, setActiveWorksheetName]);

  const updateRowTabIndexes = (): void => {
    if (dt.current) {
      const table = dt.current.getTable();

      // DataTable sets tabindex="0" attribute on clicked row by default.
      // Restore proper tabindexes once the row is selected from the outside.
      table?.querySelector('[tabindex = "0"]')?.setAttribute('tabindex', '-1');
      table?.querySelector(`.${ styles.active }`)?.setAttribute('tabindex', '0');
    }
  };

  useEffect(() => {
    updateRowTabIndexes();
    // eslint-disable-next-line
  }, [dt.current]);

  const handleItemClick = (id: string, name?: string): void => {
    navigate(`${ currentRoute }/${ id }`);
    updateSheets(
      sheets?.map(s =>
        s.worksheetId === id ? { ...s, currentlyOpen: true } : s
      ),
      { revalidate: false }
    );
    setActiveWorksheetId(id);
  };

  const handleDeleteWorksheet = async (worksheetId: string) => {
    if (!dt.current) {
      return;
    }

    console.log('deleting', worksheetId, store);
    await deleteWorksheet({ store, worksheetId });

    // handleRowInteraction(dt.current.getTable().querySelector(".hover"));
    dt.current.getTable().querySelector('.hover')?.classList.remove('hover');

    eventBus.dispatch(WorksheetSignalMessageEventTypes.WORKSHEET_DELETED, {
      worksheetId,
      mutation: MutationType.Delete,
    });
  };

  const handleRenameWorksheet = (worksheetId: string): void => {
    if (!dt.current) {
      return;
    }

    eventBus.dispatch(
      WorksheetSignalMessageEventTypes.WORKSHEET_START_NAME_CHANGE,
      { worksheetId }
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <>-- TODO -- handle error with retrieving list of worksheets</>;
  }

  return (
    <div className={styles.panel}>
      {sheets && (
        <>
          <ContextMenu ref={cm} model={contextMenuItems} />
          <DataTable
            value={sheets}
            ref={dt}
            // editMode="cell"
            groupRowsBy='isPrivate'
            rowGroupMode='subheader'
            className={clsx(
              'grow-to-fill',
              'row--no-border',
              'row--narrow',
              styles.worksheettable
            )}
            rowClassName={(data: WorksheetResponse) =>
              data.worksheetId === activeWorksheetId ? styles.active : ''
            }
            expandableRowGroups
            sortMode='multiple'
            multiSortMeta={WORKSHEET_MULTISORT_META}
            showHeaders={false}
            selectionMode='single'
            selectOnEdit={false}
            scrollable
            style={{ height: '100%' }}
            onRowClick={e => {
              // Wait until table is re-rendered and set proper tabIndexes.
              // That is helpful when ex. clicking inside the row but
              // outside of the `name` column (where 'handleItemClick' is not called).
              setTimeout(updateRowTabIndexes, 0);
            }}
            onRowToggle={e => {
              setExpandedRows(e.data as WorksheetResponse[]);
            }}
            expandedRows={expandedRows}
            expandedRowIcon='iconoir-nav-arrow-down icon--small icon--secondary'
            collapsedRowIcon='iconoir-nav-arrow-right icon--small icon--secondary'
            rowGroupHeaderTemplate={data => (
              <div>{data.isPrivate ? 'Private' : 'Shared'}</div>
            )}
            rowGroupFooterTemplate={<></>}
          >
            <Column
              className={styles.badge}
              body={(d: WorksheetResponse) => {
                const { currentlyOpen } = d;
                return currentlyOpen ? (
                  <i className={styles.currentlyOpen} />
                ) : (
                  <></>
                );
              }}
            />
            <Column
              header='Worksheet'
              body={(data, config) => (
                <WorkSheetName
                  data={data}
                  config={config}
                  store={store}
                  handler={handleItemClick}
                />
              )}
            ></Column>
            <Column
              body={(data, a) => (
                <WorksheetAction
                  contextMenu={cm.current}
                  setMenuItems={setContextMenuItems}
                  data={data}
                  actions={{
                    deleteSheet: handleDeleteWorksheet,
                    shareSheet: handleShareSheet,
                    renameSheet: handleRenameWorksheet,
                  }}
                  preventDelete={preventDelete}
                  preventSharing={preventSharing}
                  preventSharingByNonOwners={preventSharingByNonOwners}
                />
              )}
            />
          </DataTable>
        </>
      )}
    </div>
  );
};

export default AllWorksheets;
