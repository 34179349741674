import { getByNumber } from 'helpers/Utils/enum';
import { distanceFormatter, humanDate } from 'helpers/Utils/formatters';

import { Flag } from 'components/Flag';
import { CalculationDistanceUnit  } from 'modules/DistanceCalc/Models/Enums';
import { WaypointType } from 'components/Map/Models/Enums';

import type { CalculationResult } from 'modules/DistanceCalc/Models/CalculationResponse';

import styles from './RouteResult.module.scss';

interface IRouteResultParams {
	result: CalculationResult
}

const ResultLocation = ({ name, countryCode }: { name: string; countryCode: string; }) => {
	return <div className={styles.location}>
		<label>{name}</label>
		<Flag code={countryCode} />
	</div>
}

const RouteResult = (params: IRouteResultParams) => {

	// const { result } = params;
	const { totalDistance, totalDuration, arrivalDate, waypoints } = params.result;
	const first = waypoints.find(wp => wp.type !== WaypointType.Waypoint)!.location;
	const last = waypoints.findLast(wp => wp.type !== WaypointType.Waypoint)!.location;

  return <>
	<summary className={styles.result}>
		<div>
			<ResultLocation name={first.name} countryCode={first.countryCode}/> -
			<ResultLocation name={last.name} countryCode={last.countryCode}/>
		</div>
		<div className={styles.datapoint}>
			<label>Distance:</label>
			{ distanceFormatter(totalDistance.value) } { getByNumber<typeof CalculationDistanceUnit>(CalculationDistanceUnit, totalDistance.unit).toUpperCase() }
		</div>
		<div className={styles.datapoint}>
		<label>Time:</label> { `${totalDuration.days} Days ${totalDuration.hours} Hours` }
		</div>
		<div className={styles.datapoint}>
		<label>Arrival Date:</label> { humanDate(arrivalDate, { time: true }) }
		</div>
	</summary>
	</>
}

export default RouteResult;
