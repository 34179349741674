import type { ResponseWithError } from 'models/shared/error';

type ErrorType = ResponseWithError | undefined;
type Subscription = (e: ErrorType, message?: string) => void;

export default class ErrorToastService {

    static subscriptions: Subscription[] = [];

    /**
     * Checks if error should be handled based on array with error statuses.
     * Pass empty array if no error should be handled.
     * Pass undefined if all errors should be handled.
     * @param error Error to be handled
     * @param errorsToHandleArr Array with error numberic statuses that should be handled or undefined for all errors should be handled.
     * @returns True if error should be handled. False otherwise.
     */
    private static shouldHandle = (error: ErrorType, errorsToHandleArr?: number[]): boolean => {
        return !!(!error || !errorsToHandleArr || (errorsToHandleArr?.length && error.response?.status && errorsToHandleArr.includes(error.response.status)));
    };

    static handleError = (err: ErrorType, errorsToHandleArr?: number[], customMessage?: string) => {
        if (this.shouldHandle(err, errorsToHandleArr)) {
            this.subscriptions.forEach(s => s(err, customMessage));
        }
    };

    static subscribe = (callback: Subscription) => {
        if (!this.subscriptions.includes(callback)) {
            this.subscriptions.push(callback);
        }
    };

    static unsubscribe = (callback: Subscription) => {
        this.subscriptions = this.subscriptions.filter(s => s !== callback);
    };
}