import { SuggestionItem } from 'components/Autocomplete';
import type { ItemTemplateProps } from 'components/Autocomplete/Templates/ItemTemplate';

import { SuggestionResponse } from '../Components/GroupedSearch';

import type F from 'types/generic-type';

/**
 * function to map properties of a Cargo Tracker zone/location search to the approriate keys
 * required by a <ItemTemplate> instance
 *
 * @param {*} item
 * @returns {ItemTemplateProps}
 */

export type SuggestionResponseCharterer = SuggestionResponse & {
  Source?: string;
}

export type CommodityItemTemplateProps = ItemTemplateProps & {
  Source?: string;
  IsAlias?: boolean;
  FullName?: string;
}

export type SuggestionResponseCommodity = SuggestionResponse & {
  Source?: string;
  IsAlias?: boolean;
  FullName?: string;
}

export const zoneLocationEntityMapper = (item: SuggestionItem & F<any>): ItemTemplateProps => ({
	...item,
	icon: item.CountryCodeISO3,
	subLabel: item.Source,
});

export const chartererEntityMapper = (item: SuggestionResponseCharterer): ItemTemplateProps => ({
  ...item,
  label: `${ item.value }`,
  subLabel: item.Source ?? undefined,
  group: '',
  name: ''
});

export const commodityEntityMapper = (item: CommodityItemTemplateProps): ItemTemplateProps => ({
  ...item,
  label: `${ item.value }`,
  subLabel: item.Source ?? undefined,
  captionLabel: item.IsAlias ? `alias for ${ item.FullName }` : undefined
});