import { useState, useEffect } from 'react';
import JsonFormatter from 'react-json-formatter';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useSaveUserSetting, useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';

import styles from './UserSettingsPlayground.module.scss';

const UserSettingsPlayground = () => {

  const { trigger, data } = useSaveUserSetting();
  const [ settingName, setSettingName] = useState('');
  const [ settingValue, setSettingValue] = useState('');

  const [ settingKey, setSettingKey ] = useState('');
  const [ loadedSetting, setLoadedSetting ] = useState('');

  const { getSetting } = useLoadUserSettings();

  useEffect(() => {
    console.log('Saved…', data);
  }, [data])
  
  return <section className={styles.section}>
    <div className='direction--column'>
      <h3>Save A Setting</h3>
      <div className={styles.group}>
        <div className='direction--column'>
          <label htmlFor="SettingName">Setting Name</label>
          <InputText 
            id="SettingName"
            value={settingName} 
            onChange={(e) => setSettingName(e.target.value)}
          />
        </div>
        <div className='direction--column'>
          <label htmlFor="SettingValue">Value</label>
          <InputText
            id="SettingValue"
            value={settingValue}
            onChange={(e) => setSettingValue(e.target.value)}
          />
        </div>
        <Button 
					size="small"
					onClick={
          () => trigger({
            setting: settingName,
            data: JSON.parse(settingValue)
          })}>
          Save
        </Button>
      </div>
        
      

      <br/>
      <h3>Load A Setting</h3>
      <div className={styles.group}>
				<div className='direction--column'>
          <label htmlFor="settingKey">Key of setting to load</label>
					<InputText
						id="settingKey" 
						value={settingKey}
						onChange={(e) => setSettingKey(e.target.value)}
					/>
				</div>
        <Button
					size="small"
          onClick={() => setLoadedSetting( getSetting(settingKey) )}>
          Load
        </Button>
				Result: <div className={styles.result}>
					{ loadedSetting && 
        		<JsonFormatter json={JSON.stringify(loadedSetting)} />
					}
				</div>
      </div>

			<br/>
      <h3>Delete A Setting</h3>
      <div className={styles.group}>
				<div className='direction--column'>
          <label htmlFor="settingKey">Key of setting to Delete</label>
					<InputText
						id="settingKey" 
						value={settingKey}
						onChange={(e) => setSettingKey(e.target.value)}
					/>
				</div>
        <Button
					size="small"
          onClick={() => 
						trigger({setting: settingKey, method: 'DELETE'})
					}>
          Delete Setting
        </Button>

      </div>
      
    </div>
  </section>

}

export default UserSettingsPlayground;
