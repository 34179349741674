import { useRef, RefObject, useEffect } from 'react';
import { Button } from 'primereact/button';

import { humanDate } from 'helpers/Utils/formatters';
import { formatName } from 'helpers/Utils/string';

import GridElement from 'components/GridElement';
import SwipeableHiddenOptions from 'components/SwipeableHiddenOptions';

import eventBus from 'server/EventBus';

import WorkflowProgressBar from 'modules/Workflows/Components/WorkflowProgressBar/WorkflowProgressBar';
import type { Workflow } from 'modules/Workflows/Models';
import { WorkflowEvents } from 'modules/Workflows/Models/Enums';
import { useDeleteWorkflow } from 'modules/Workflows/Services/hooks';

import './GridItemTemplate.scss';

interface GridItemTemplateProps {
  item: Workflow;
  // deleteWorkflow: (id: Workflow) => Promise<void>;
  selectWorkflow: (item: Workflow) => void;
  scrollableContainerRef: RefObject<HTMLElement>;
}

export default function GridItemTemplate(props: GridItemTemplateProps): JSX.Element {
  const { item, scrollableContainerRef, selectWorkflow } = props;
  const elRef = useRef<HTMLDivElement>(null);
	const { deleteWorkflow, isDeleting } = useDeleteWorkflow();

	useEffect(() => {
		eventBus.dispatch(WorkflowEvents.Mutation, { isMutating: isDeleting})
	}, [isDeleting])

  return <SwipeableHiddenOptions
    key={item.id}
    className='workflows-page__grid-item-swipeable-options'
    elementRef={elRef}
    scrollableContainerRef={scrollableContainerRef}
    moveByPixels={80}
    options={<Button
      text
      className='workflow-page__delete-button-mobile no--background grow-to-fill'
      icon='iconoir-trash icon--primary'
      onClick={() => deleteWorkflow(item)}
      // required to prevent swiping back on option click
      onPointerDown={(e) => e.preventDefault()}
    />}
  >
    <div
      className='workflows-page__grid-item grow-to-fill'
      onClick={() => selectWorkflow(item)}
      ref={elRef}
    >
      <GridElement header='Title' body={item.title} />
      <GridElement header='Requested' body={<div className='direction--column'>
        <strong>{humanDate(item.createdUtc, { time: true })} GMT</strong>
        <div className='workflows-page__grid-item--requested-name'>{formatName(item.requestedByName)}</div>
      </div>} />
      <GridElement
        header='Progress'
        body={
          <WorkflowProgressBar
            className='p-workflow-progressbar'
            statuses={item.taskStatusCounts}
            totalCount={item.totalCount}
          />
        }
      />
    </div>
  </SwipeableHiddenOptions>;
}
