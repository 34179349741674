import { RefObject, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import type { Suggestion, SuggestionItem } from 'components/Autocomplete';
import Autocomplete from 'components/Autocomplete';
import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import { SearchSuggestionsParsedResponse } from 'components/EntitySearch/Models/SearchEntities';
import { useSearchSuggestions } from 'components/EntitySearch/Services/SearchEntitiesAPI';

import { SurveillanceSearchGroup } from '../../Models/Enums';
import { SearchRequest } from '../../Models/ReportsRequest';

import { groupedEntityMapper, selectedItemsMapper, SuggestionResponseSurveillanceSearch } from './Models/Parsers';

const ATTACHMENTS_TOKEN_LBL = 'Attachments';
const EMPTY_TOKEN_LBL = 'Empty';

interface ISearchBarEntityProps {
  onRequestChanged: (seaarchRequest: Partial<SearchRequest>) => void;
  searchItems: SearchRequest | undefined;
  searchContainerRef: RefObject<HTMLElement>;
  className?: string;
}

const SurveillanceSearchEntity = (
  param: ISearchBarEntityProps
): JSX.Element => {
  const {
    onRequestChanged,
    className,
    searchItems,
    searchContainerRef,
  } = param;
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [value, setValue] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const { data, /* error,*/ isLoading, isValidating } = useSearchSuggestions({
    module: EntitySearchGroupEnum.Surveillance,
    fields: [
      EntitySearchFieldsEnum.SurveillanceUserName,
      EntitySearchFieldsEnum.SurveillanceCompany,
      EntitySearchFieldsEnum.SurveillanceMedia,
    ],
    term: value,
  });

  const handleSelectedItemsChange = (
    items: SuggestionItem[],
    removedItem?: SuggestionItem
  ): void => {
    if (!removedItem || removedItem.external) {
      const searchRequest = Object.values(items).reduce(
        (acc, item) => {
          const { searchRequestFields = [] } = acc;
          const onlyAttachments =
            item.external && item.name === ATTACHMENTS_TOKEN_LBL
              ? (item.value as boolean)
              : acc.onlyAttachments;
          const onlyEmpty =
            item.external && item.name === EMPTY_TOKEN_LBL
              ? (item.value as boolean)
              : acc.onlyEmpty;

          if (!item.external || ![EMPTY_TOKEN_LBL, ATTACHMENTS_TOKEN_LBL].includes(item.name)) {
            searchRequestFields.push({
              searchTerm: item.searchTerm ?? '',
              searchField: item.searchField ?? EntitySearchFieldsEnum.Owner,
              metaData: [],
            });
          }

          return { ...acc, onlyAttachments, onlyEmpty };
        },
        {
          searchRequestFields: [],
          onlyAttachments: null,
          onlyEmpty: null,
        } as Partial<SearchRequest>
      );

      onRequestChanged(searchRequest);
    }
  };

  useEffect(() => {
    const d =
      data as SearchSuggestionsParsedResponse<SuggestionResponseSurveillanceSearch>[];
    const suggestions: Record<
      'users' | 'companies' | 'media',
      SuggestionResponseSurveillanceSearch[]
    > = {
      users: [],
      companies: [],
      media: [],
    };

    const itemsWithGroup: Suggestion[] = [{
      group: SurveillanceSearchGroup[SurveillanceSearchGroup.Content],
      items: [{
        label: value,
        name: value,
        group: SurveillanceSearchGroup[SurveillanceSearchGroup.Content],
        searchTerm: value,
        searchField: EntitySearchFieldsEnum.SurveillanceKeyword
      }],
    },{
      group: SurveillanceSearchGroup[SurveillanceSearchGroup.Raw],
      items: [{
        label: value,
        name: value,
        group: SurveillanceSearchGroup[SurveillanceSearchGroup.Raw],
        searchTerm: value,
        searchField: EntitySearchFieldsEnum.SurveillanceRawSearch
      }],
    },{
      group: SurveillanceSearchGroup[SurveillanceSearchGroup.Counterpart],
      items: [{
        label: value,
        name: value,
        group: SurveillanceSearchGroup[SurveillanceSearchGroup.Counterpart],
        searchTerm: value,
        searchField: EntitySearchFieldsEnum.SurveillanceCounterParty
      }],
    }];

    if (data) {
      d.forEach(item => {
        switch (item.searchFieldId) {
        case EntitySearchFieldsEnum.SurveillanceUserName:
          suggestions.users.push(...item.values);
          break;
        case EntitySearchFieldsEnum.SurveillanceCompany:
          suggestions.companies.push(...item.values);
          break;
        case EntitySearchFieldsEnum.SurveillanceMedia:
          suggestions.media.push(...item.values);
          break;
        }
      });

      if (suggestions.users.length) {
        itemsWithGroup.push({
          group: SurveillanceSearchGroup[SurveillanceSearchGroup.User],
          items: suggestions.users.map(
            groupedEntityMapper(
              SurveillanceSearchGroup[SurveillanceSearchGroup.User]
            )
          ),
        });
      }

      if (suggestions.companies.length) {
        itemsWithGroup.push({
          group: SurveillanceSearchGroup[SurveillanceSearchGroup.Company],
          items: suggestions.companies.map(
            groupedEntityMapper(
              SurveillanceSearchGroup[SurveillanceSearchGroup.Company]
            )
          ),
        });
      }

      if (suggestions.media.length) {
        itemsWithGroup.push({
          group: SurveillanceSearchGroup[SurveillanceSearchGroup.Media],
          items: suggestions.media.map(
            groupedEntityMapper(
              SurveillanceSearchGroup[SurveillanceSearchGroup.Media]
            )
          ),
        });
      }
    }

    setSuggestions(itemsWithGroup);
  }, [data, value]);

  const selectedItems: SuggestionItem[] = useMemo(() => searchItems?.searchRequestFields?.map(
    field => {
      let group = '';
      switch(field.searchField) {
      case EntitySearchFieldsEnum.SurveillanceUserName:
        group = SurveillanceSearchGroup[SurveillanceSearchGroup.User];
        break;
      case EntitySearchFieldsEnum.SurveillanceCounterParty:
        group = SurveillanceSearchGroup[SurveillanceSearchGroup.Counterpart];
        break;
      case EntitySearchFieldsEnum.SurveillanceKeyword:
        group = SurveillanceSearchGroup[SurveillanceSearchGroup.Content];
        break;
      case EntitySearchFieldsEnum.SurveillanceRawSearch:
        group = SurveillanceSearchGroup[SurveillanceSearchGroup.Raw];
        break;
      case EntitySearchFieldsEnum.SurveillanceCompany:
        group = SurveillanceSearchGroup[SurveillanceSearchGroup.Company];
        break;
      case EntitySearchFieldsEnum.SurveillanceMedia:
        group = SurveillanceSearchGroup[SurveillanceSearchGroup.Media];
        break;
      }

      return selectedItemsMapper(field, group);
    // Filter out report state (results / reviewed)
    }).filter(field => field.searchField !== EntitySearchFieldsEnum.SurveillanceReportState) ?? [],
  [searchItems?.searchRequestFields]
  );
  const additionalTokens = useMemo(() => {
    const tokens = [];
    if (typeof searchItems?.onlyAttachments === 'boolean') {
      tokens.push({
        group: searchItems?.onlyAttachments === true ? 'Has' : 'Has No',
        label: ATTACHMENTS_TOKEN_LBL,
        name: ATTACHMENTS_TOKEN_LBL,
        value: searchItems?.onlyAttachments,
        external: true,
      });
    }
    if (typeof searchItems?.onlyEmpty === 'boolean') {
      tokens.push({
        group: searchItems?.onlyEmpty === true ? 'Is' : 'Is Not',
        label: EMPTY_TOKEN_LBL,
        name: EMPTY_TOKEN_LBL,
        value: searchItems?.onlyEmpty,
        external: true,
      });
    }
    return tokens;
  }, [searchItems]);

  return <Autocomplete
    handleInputValueChange={setValue}
    handleSelectedItemsChange={handleSelectedItemsChange}
    isLoading={isLoading || isValidating}
    selectedItems={[...selectedItems, ...additionalTokens]}
    suggestions={suggestions}
    className={className}
    placeholder="Search by content, counterpart, media, user"
    autoExpandOnFocus={isMobile}
    useToggle={!isMobile}
    containerRef={searchContainerRef}
  />;
};

export default SurveillanceSearchEntity;
