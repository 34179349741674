import { ChangeEvent, FocusEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { InputText, type InputTextProps } from 'primereact/inputtext';
import { clsx } from "clsx";

import { DEFAULT_SPEED, MAX_SPEED, MIN_SPEED } from 'modules/DistanceCalc/Models/Consts';
import { getClampedValue } from 'helpers/Utils/misc';

import type { CalculationRequest } from "modules/DistanceCalc/Models/CalculationRequests";

interface SpeedFieldProps extends InputTextProps {
    initialValue: CalculationRequest["speed"] | null;
    onValueUpdate?: (arg: number | null) => void;
    hideLabel?: boolean;
    inputClassName?: string;
    className?: string;
    max?: number;
    min?: number;
}

export default function SpeedField (props: SpeedFieldProps): JSX.Element {
    const { initialValue = DEFAULT_SPEED, max = MAX_SPEED, min = MIN_SPEED, onValueUpdate, hideLabel, inputClassName, id = `speed-field-${Math.random()}`, className, ...inputProps } = props;

    const [value, setValue] = useState<CalculationRequest["speed"]|null>(initialValue);
    const [error, setError] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue])

    function handleValueChange(e: FocusEvent<HTMLInputElement>): void {
        const value = getClampedValue(+e.target.value, min, max);

        setValue(value);

        if (typeof onValueUpdate === 'function') {
            onValueUpdate(value);
        }

        clearErrorAfterTimeout();
    }

    function handleChangeFromMixed(e: ChangeEvent<HTMLInputElement>) {
        const parsedValue = Number.parseFloat(e.target.value);

        if (isNaN(parsedValue)) {
            setValue("Mixed");
        } else {
            setValue(parsedValue);

            // wait for input field to be rendered
            setTimeout(() => {
                const input = inputRef.current;

                if (input) {
                    // restore focus when number input is re-rendered
                    input.focus();
                }
            }, 0);
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (typeof inputProps.onChange === 'function') {
            inputProps.onChange(e);
        }

        const value = +Number.parseFloat(e.target.value).toFixed(1) || DEFAULT_SPEED;

        setValue(value);

        if (value > max) {
            setError(`Max value is ${max} KTS`);
        } else if (value < min) {
            setError(`Min value is ${min} KTS`);
        } else {
            setError('');
        }

    };

    const clearErrorAfterTimeout = (): void => {
        if (error) {
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    }

    function renderInputField(): JSX.Element {
        // If `value` is "Mixed" replace the component to the "text input" - it will allow to display string.
        // Otherwise, render "number input" so the validation and other things work nicely.
        if (value === 'Mixed') {
            return <InputText
                id={id}
                className='align--right'
                ref={inputRef}
                name="speed"
                onChange={handleChangeFromMixed}
                onFocus={ (e) => (e.target as HTMLInputElement).select() }
                value={value}
            />
        }

        return <InputText
            id={id}
            keyfilter='num'
            type='number'
            name="speed"
            onChange={handleChange}
            onBlur={handleValueChange}
            onFocus={(e) => (e.target as HTMLInputElement).select()}
            value={`${value}`}
            min={min}
            max={max}
            className={clsx(inputClassName, { 'p-invalid': !!error }, 'align--right')}
            {...inputProps}
        />
    }

    const label: ReactNode = <><strong>Speed</strong> ({min}-{max} KTS)</>;

    return <div className={clsx("form-input__container", className)}>
        { !hideLabel &&
            <label htmlFor={id}>{label}</label>
		}
		<span>
			<div className={`p-inputgroup ${error && 'p-invalid'}`} >
				{renderInputField()}
				<span className="p-inputgroup-addon">KTS</span>
			</div>
			{error && <small>{error}</small>}
		</span>
	</div>
}