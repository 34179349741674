import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {

    AuthenticationType,
    AzureMap,
    AzureMapDataSourceProvider,
    AzureMapFeature,
    AzureMapHtmlMarker,
    AzureMapLayerProvider,
    AzureMapsContext,
    type IAzureMapControls,
    type IAzureMapHtmlMarkerEvent,
    type IAzureMapsContextProps } from 'react-azure-maps';
import { useNavigate } from 'react-router-dom';
import {
    AuthenticationOptions,
    data as AzureMapData,
    type CameraBoundsOptions,
    type CameraOptions,
    type MapErrorEvent,
  } from 'azure-maps-control';
import clsx from 'clsx';

import { useLoadUserSettings, useSaveUserSetting } from 'components/OBXUser/Services/ProfileHooks';
import { uniqueId } from 'helpers/Utils/string';
import { ConfigContext } from 'index';

import {
    LAT,
    LINE_OPTIONS,
    LNG,
    MAP_CONTROLS,
    MAP_STYLE,
    MAP_STYLE_SETTINGS_KEY,
    MAP_STYLES,
    ZOOM_DESKTOP,
    ZOOM_MOBILE } from './Models/MapSettings';
import { WaypointType } from './Models/Enums';
import MapError from './Templates/MapError';
import ColorVars from './MapColors.module.scss';

import type { MapWaypoint } from './Models/MapWaypoint';

import './Map.scss';

interface MapProps {
    lineCoordinates: number[][] | null;
    waypoints: MapWaypoint[];
    getMarkerClassName: (type: WaypointType, mapStyle: string) => string;
    getLabel?: (type: WaypointType) => string;
    getLabelOffset?: (type: WaypointType) => [number, number] | undefined;
    getMarkerOffset?: (type: WaypointType) => [number, number] | undefined;
    getMarkerVisibilityClassName?: (type: WaypointType, zoom: number) => string;
    className?: string;
    controls?: IAzureMapControls[];
}

export default function Map (props: MapProps): JSX.Element {
    const {
        className,
        controls = MAP_CONTROLS,
        lineCoordinates,
        waypoints,
        getMarkerClassName,
        getLabel = () => void 0,
        getLabelOffset = () => void 0,
        getMarkerOffset = () => void 0,
        getMarkerVisibilityClassName = () => void 0
    } = props;
    const [ random, setRandom ] = useState(uniqueId());
    const [ currentMapStyle, setCurrentMapStyle ] = useState<typeof MAP_STYLES[number]>(MAP_STYLE);
    const [ lineColor, setLineColor ] = useState('black');
    const [ isMapError, setIsMapError ] = useState(false);

    const { mapRef, isMapReady } = useContext<IAzureMapsContextProps>(AzureMapsContext);
    const config = useContext(ConfigContext);
    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });
    const { trigger: saveUserSettings } = useSaveUserSetting();
    const { getSetting: getUserSettings } = useLoadUserSettings();

    const eventToMarker: Array<IAzureMapHtmlMarkerEvent> = [{
        eventName: 'click', callback: (e): void => {
            console.log('You clicked on marker', e); // Just as example, remove later
        }
    }];

    const option: AuthenticationOptions = {
        authOptions: {
            authType: AuthenticationType.subscriptionKey,
            subscriptionKey: config?.azureMapsKey
        },
        center: [LNG, LAT],
        zoom: isTabletOrMobile ? ZOOM_MOBILE : ZOOM_DESKTOP, // Show whole world, zoom out max on mobile
        view: 'Auto',
        style: getUserSettings(MAP_STYLE_SETTINGS_KEY) ?? MAP_STYLE,
        showLogo: false,
        showFeedbackLink: false,
        renderWorldCopies: false, // disable showing multiple "worlds" and routes on max zoom out
        language: 'en-GB',
    };

    const bbox: AzureMapData.BoundingBox | null = useMemo(() => {
        // center map on the point
        if (waypoints.length === 1) {
            return [Number(waypoints[0].long), Number(waypoints[0].lat)];
        }

        // calculate bounds based on all waypoints (lines and points) to center map properly
        const waypointCoordinates = waypoints.map(location => [location.long ?? 0, location.lat ?? 0]);

        return AzureMapData.BoundingBox.fromPositions([...lineCoordinates ?? [], ...waypointCoordinates]);
    }, [lineCoordinates, waypoints]);

    const handleStyle = useCallback((): void => {
        if (mapRef) {
            const style = mapRef.getStyle().style as typeof MAP_STYLES[number] ?? MAP_STYLE;
            setCurrentMapStyle(style);
            const color = ColorVars[`${ style }_line_color`].replace(/var\(|\)/g, ''); // Convert var(--color-name) to --color-name
            setLineColor(getComputedStyle(document.body).getPropertyValue(color));

            // Save style in user settings
            saveUserSettings({
                setting: MAP_STYLE_SETTINGS_KEY,
                data: style
            });
        }
    }, [mapRef, saveUserSettings]);

    const handleError = useCallback((error: (Error & { status?: number })): void => {
        console.error(error);
        // On Map type satellite there is some mapbox error: "The layer 'National or state park' does not exist in the map's style and cannot be queried for features."
        // On Map type terra / road_shaded_relief, when zooming in, often there are tile error status 429 (name: wt, Too Many Requests)
        if (error.message.search(/^The layer '.*' does not exist .*$/) === -1 &&
            error.message.search(/^Failed to fetch$/) === -1 &&
            error.status !== 429) {
                setIsMapError(true);
        }
    }, []);

    /* This takes coords array [[lng1, lat1], [lng2, lat2], [lng3, lat3]] and converts it to
        array of coords pairs [[[lng1, lat1], [lng2, lat2]], [[lng2, lat2], [lng3, lat3]]]
        and if applicable, corrects one, that is crossing antimeridan  / date line */
    const antimeridianConvert = useCallback((c: number[][] | null): number[][][] | null => c?.map((el, i) => {
        let newEl = c[i];
        const prevEl = c[i-1] ? c[i-1] : c[0];
        const diff = prevEl[0] - c[i][0];

        if (diff > 180){
            //Line going left to right, but should go opposite direction
            newEl = [c[i][0] + 360, c[i][1]];
        } else if (diff < -180) {
            //Line going right to left, but should go opposite direction
            newEl = [c[i][0] - 360, c[i][1]];
        }

        return [prevEl, newEl];
    }) ?? null, []);

    const isCrossingAntimeridian = useMemo(() => bbox && AzureMapData.BoundingBox.crossesAntimeridian(bbox), [bbox]);

    const calculatePadding = useCallback((): number => {
        // use canvasContainer instead of canvas bacause on mobile screens canvas' attributes `width` and `height` are 2x larger
        // than actuall width and height (CSS pixels rather than physical?).
        // Due this width and height used for padding calculation are too big and map can't be rendered properly.
        // https://dev.azure.com/oilbrokerage/OBXchange/_workitems/edit/1678
        const { clientWidth, clientHeight } = mapRef!.getCanvasContainer();

        // 25% of smaller dimenssion.
        // Dimensions has to be campared as smaller has to be choosen because for screens with much greater width
        // map cannot be rendered because padding takes to much space.
        const padding = clientHeight < clientWidth ? (clientHeight / 100 * 25) : (clientWidth / 100 * 25);

        return padding;
    }, [mapRef]);

    useEffect(() => {
        if (isMapReady && mapRef && bbox) {
            setRandom(uniqueId());
            // When route is crossing date line, show world copy so route is continuous
            mapRef.setStyle({
                renderWorldCopies: isCrossingAntimeridian,
            });

            try {
                const cameraOptions: CameraOptions | CameraBoundsOptions = {};
                if (bbox.length === 2) {
                    cameraOptions.center = bbox;
                } else if (bbox.length === 4) {
                    cameraOptions.bounds = bbox;
                    cameraOptions.padding = calculatePadding();
                }

                mapRef.setCamera(cameraOptions);
            } catch (error: any) {
                handleError(error);
            }
        }
    }, [bbox, calculatePadding, handleError, isCrossingAntimeridian, isMapReady, isTabletOrMobile, mapRef]);

    // When switching from other route with left menu open -> container is growing when redirecting so need to resize
    // Other option is to setTimeout to resize after animation ends from left-menu__items (&__items)
    const mapContainer = mapRef?.getMapContainer();
    useEffect(() => {
        if (isMapReady && mapRef && mapContainer) {
            mapRef.resize();
        }
    }, [isMapReady, mapRef, mapContainer]);

    return <div className={clsx(className, 'map-container')}>{
        isMapError ?
        <MapError tryAgainCallback={(): void => navigate(0)} /> :
        <AzureMap
            options={option}
            controls={controls}
            containerClassName={`map-style-${ currentMapStyle } mapboxgl-map atlas-map grow-to-fill`}
            events={{
                styledata: handleStyle,
                error: (e: MapErrorEvent) => handleError(e.error),
            }}>
                <AzureMapDataSourceProvider id='Lines Provider'>
                    <AzureMapLayerProvider
                        options={{...LINE_OPTIONS, strokeColor: lineColor}}
                        id='shape'
                        type='LineLayer'
                    />
                    {isCrossingAntimeridian ?
                        lineCoordinates && <AzureMapFeature
                            id={random}
                            key={random}
                            type='MultiLineString'
                            multipleCoordinates={antimeridianConvert(lineCoordinates) ?? []}
                        /> :
                        lineCoordinates && <AzureMapFeature
                            id={random}
                            key={random}
                            type='LineString'
                            coordinates={lineCoordinates}
                        />}
                </AzureMapDataSourceProvider>
                <AzureMapDataSourceProvider id='Points Provider'>
                    {waypoints.map((waypoint, index) => (
                        waypoint.long && waypoint.lat ?
                            <span key={`group_${ index }`}>
                                <AzureMapHtmlMarker
                                    markerContent={
                                        <div style={{ transform: waypoint.rotation ? `rotate(${waypoint.rotation}deg)`: '' }}
                                            className={clsx('map-point',
                                                getMarkerClassName(waypoint.type, currentMapStyle),
                                                getMarkerVisibilityClassName(waypoint.type, mapRef?.getCamera().zoom ?? -1)
                                            )}
                                        />
                                    }
                                    options={{
                                        position: [waypoint.long, waypoint.lat],
                                        pixelOffset: getMarkerOffset(waypoint.type)
                                    }}
                                    events={eventToMarker}
                                />
                                {waypoint.type !== WaypointType.Waypoint &&
                                    <AzureMapHtmlMarker
                                        markerContent={
                                            <div className="map-label">
                                                { getLabel(waypoint.type) }
                                            </div>
                                        }
                                        options={{
                                            position: [waypoint.long, waypoint.lat],
                                            pixelOffset: getLabelOffset(waypoint.type)
                                        }}
                                        events={eventToMarker}
                                />}
                            </span> :
                            <></>
                        )) ?? null}
                </AzureMapDataSourceProvider>
        </AzureMap>}
    </div>
}