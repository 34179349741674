export enum CargoTrackerModeEnum {
	TC = 1, // Time Charter
	Spot
}

// TODO: update
export enum UnitEnum {
	MT = 1,
}

export enum StatusEnum {
	New = 1,
	Negotiating,
	OnSubs,
	Fixed,
	Cancelled,
	Archived
}

export enum CargoTrackerGroupBy {
	None = 0,
	Status
}

export enum CargoTrackerGroupByFieldName {
	unknown = 0,
	status
}
