import { camelToSpace, spaceToDash } from 'helpers/Utils/string';

import type { Suggestion } from '../Autocomplete';

interface GroupedItemTemplateProps extends Suggestion {};

export default function GroupedItemTemplate(props: GroupedItemTemplateProps): JSX.Element {
    const { items, group } = props;

    if (!items.length) {
        return <></>
    }

    return (
        <div className={`grouped-item-template grouped-item-template--${ spaceToDash(group) }`}>
            {camelToSpace(`${ group }`)}
        </div>
    );
}