import * as React from "react";
import { useMediaQuery } from "react-responsive";

import { clsx } from "clsx";
import axios from "axios";
import fileDownload from "js-file-download";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import { ResultsModeEnum } from "../../Models/Enums";

interface ISanctionsProps {
  displayLoader: Boolean;
  currentSearchMode: ResultsModeEnum;
  currentSearchTerm: string;
  setSearchTerm: Function;
}


function SanctionsSearch(props: ISanctionsProps) {

  const { displayLoader, currentSearchMode, currentSearchTerm, setSearchTerm } = props;
  const menu = React.useRef<Menu>(null);
  const searchBox = React.useRef<HTMLInputElement>(null);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });
  const [ value, setValue ] = React.useState<string>("");


  const downloadFile = (path: string, file: string) => {

    //  path should be root relative so if doesn"t start and end with a /
    //  then lets add them
    path = (path.match(/(^\/.*\/$)+/g)) ? path : `/${path}/`;

    const url: string = `${window.location.origin}${path}${file}`
    axios({
      url,
      method: "GET",
      responseType: "blob"
    })
    .then(r => fileDownload(r.data, file))
      .catch((e) => {
        ErrorToastService.handleError(e, [500, 503]);

        // handle error state
        throw e;
      });
  }

  React.useEffect(() => {
    if (currentSearchTerm !== value) {
      setSearchTerm(value);
    }
  // eslint-disable-next-line
  }, [value]);

  React.useEffect(() => {
    if (currentSearchTerm) setValue(currentSearchTerm);
  // eslint-disable-next-line
  }, [])

  const downloadItems = [
    { label: "Sanctions Procedure",
      command: () => {
        downloadFile("/", "Oil Brokerage - Ship Brokering Sanctions Procedure.pdf");
      }
    },
    { label: "Russian Trade Form",
      command: () => {
        downloadFile("/", "Russian – General Trade Licence Record Form.pdf");
      }
    },
    { label: "UML Price Cap Compliance",
      command: () => {
        downloadFile("/", "UML - Oil Price Cap Sanctions Compliance 03.03.2023.pdf");
      }
    },

  ]

  const placeholderSearchModeString = React.useMemo(() => {
    switch (currentSearchMode) {
      case ResultsModeEnum.IndividualSanctions:
        return 'an Individual';
      case ResultsModeEnum.LegalEntitySanctions:
        return 'an Entity';
      case ResultsModeEnum.VesselSanctions:
      default:
        return 'a Vessel';
    }
  }, [currentSearchMode]);

  return <>
    <div className="p-input-icon-left grow-to-fill">
      <i className={clsx("pi", {
          "pi-spinner pi-spin": displayLoader,
          "pi-search": !displayLoader
      })} />
      <InputText
          placeholder={`Search for ${placeholderSearchModeString}`}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            setValue(ev.target.value)
          }}
          onFocus={(ev: React.FocusEvent<HTMLInputElement>) => {
            ev.target.select();
          }}
          ref={searchBox}
          className="grow-to-fill"
          value={value}
      />
    </div>
    <Menu
        model={downloadItems}
        popup
        ref={menu}
    />
    <Button
        icon={clsx(
          "icon--small icon--ob-orange",
          {
            "iconoir-nav-arrow-down icon--right": !isTabletOrMobile,
            "iconoir-download": isTabletOrMobile
        })}
        size="small"
        iconPos="right"
				outlined
        onClick={(e) => (menu.current as Menu).toggle(e)}
    >
      { !isTabletOrMobile &&
        <>
        <i className="iconoir-download icon--small icon--ob-orange"/>
        Downloads
        </>
      }
    </Button>
  </>;
}

export default SanctionsSearch;
