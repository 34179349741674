import { DateTime } from "luxon";
import { clsx } from "clsx";
import { ColumnBodyOptions } from "primereact/column";

import layouts from "./DoubleLine.module.scss";
import styles from "./ReadableDate.module.scss";

export const ReadableDateTime = <T,>(data: T, config: ColumnBodyOptions): JSX.Element => {

    const [ field, format ] = config.field.split(",");
    const date: DateTime = data[field as keyof T] as DateTime;

	if (date.isValid) {
		return <div className={clsx(
			"direction--column align-items--start",
			layouts.cell
		)}>
			<span className={clsx("upper", layouts.upper)}>{ date.toFormat(format ?? "dd LLL yy")}</span>
			<span className={clsx("lower")}>{ date.toFormat('t').toLocaleLowerCase()}</span>
		</div>
	} else {
		return <i className={styles.unknownDate}>Unknown</i>
	}

}


