import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';

import { WorksheetStores } from 'components/Worksheets/Models/Enums';
import { WorksheetMetaProps } from 'components/Worksheets/Models/WorksheetResponse';
import {
  useLoadWorksheet,
  useMutateWorksheet
} from 'components/Worksheets/Services/WorksheetHooks';

import { CargoTrackerGroupBy } from '../../Models/Enums';
import { cargoSearchWorksheetParsers } from '../CargoSearch/Models/Parsers';

import { getKeyValuePairs } from 'helpers/Utils/enum';

interface CargoGroupByProps {
  activeWorksheet: string;
  groupBy: string;
  setGroupBy: Dispatch<SetStateAction<string>>;
}

const CargoGroupBy = (props: CargoGroupByProps): JSX.Element => {
  const { activeWorksheet, groupBy, setGroupBy  } = props;

  // Worksheet data load
  const { data } = useLoadWorksheet(
    WorksheetStores.CargoTracker,
    activeWorksheet,
    cargoSearchWorksheetParsers
  );

  // Worksheet mutate loader
  const { mutateTokens } = useMutateWorksheet(
    WorksheetStores.CargoTracker,
    activeWorksheet
  );

  const handleChange = useCallback((e: DropdownChangeEvent): void => {
    setGroupBy(e.value);
    mutateTokens([{cargoGroupBy: e.value}] as WorksheetMetaProps[]);
  }, [mutateTokens, setGroupBy]);

  useEffect(() => {
    if (!data || data.store !== WorksheetStores.CargoTracker) {
      return;
    }
    const metaData = data.fields?.[0]?.metaData?.[0];
    if (metaData?.key === 'cargoGroupBy') {
      setGroupBy(`${ metaData?.value }`);
    }
  }, [data, activeWorksheet, setGroupBy]);

  return (
    <div className="cargo-tracker__group no-background">
      <label>Group by:</label>
      <Dropdown
        value={groupBy}
        className="direction--row"
        options={getKeyValuePairs(CargoTrackerGroupBy).map(
          i => i.key
        )}
        onChange={handleChange}
      />
    </div>
  );
};

export default CargoGroupBy;