import { useSearchParams } from 'react-router-dom';

import BorealisBar from 'components/BorealisBar';
import NotePanel, { NotePanelType } from 'components/NotePanel/NotePanel';
import UserRecord from 'components/OBXUser/Components/UserRecord/UserRecord';
import { useValidateEmails } from 'components/OBXUser/Services/ProfileHooks';

import styles from './ShareDialog.module.scss';

interface IComponentParams {
  name: string;
}

const ShareCargoFlowsWorksheetDialogBody = ({
  name,
}: IComponentParams): JSX.Element => {
  const [searchParams] = useSearchParams();
  const emails = searchParams.get('users');

  const { data: users, isLoading } = useValidateEmails(
    emails ? [emails] : null
  );

  if (isLoading) {
    return <BorealisBar />;
  }

  return (
    <>
      <strong>Share {name}</strong>
      {users?.validEmailsDetails?.length && (
        <NotePanel
          type={NotePanelType.Info}
          header='You are about to share this worksheet with:'
          body={
            <div className={styles.notes}>
              <UserRecord
                name={users.validEmailsDetails[0].name}
                email={users.validEmailsDetails[0].email}
              />
              <UserRecord name='Everyone in your office' />
            </div>
          }
        />
      )}
      Please note that if you choose to share this sheet, you won&apos;t have
      option to delete.
    </>
  );
};

export { ShareCargoFlowsWorksheetDialogBody };
export default ShareCargoFlowsWorksheetDialogBody;
