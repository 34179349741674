import * as React from 'react';

import styles from './ShippingFixtures.module.scss';

function ShippingFixtures() {

	console.log('SHIPPING FIXTURES $$$$')

  return <div className={styles.container}>Here be dragons</div>;
}

export default ShippingFixtures;
