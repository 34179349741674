import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { clsx } from 'clsx';
import { DateTime } from 'luxon';

import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';
import { ToastMessageRef } from 'components/ToastMessage';

import { SurveillanceEntityStatus, SurveillanceModeEnum } from '../../Models/Enums';
import { SearchRequest } from '../../Models/ReportsRequest';
import { ResultsResponse } from '../../Models/ReportsResponse';
import DetailsArea from '../DetailsArea';
import {
  DetailsPanelState,
  type SelectedContentMessage,
} from '../DetailsArea/Models';
import { isMessagesEqual } from '../DetailsArea/Models/Helpers';
import Results from '../Results';

import './SupervisorView.scss';

interface SupervisorViewProps {
  activeWorksheetId: string;
  searchItems?: SearchRequest;
  setSearchItems?: Dispatch<SetStateAction<SearchRequest | undefined>>;
  lastModified?: DateTime;
  toastRef?: RefObject<ToastMessageRef>;
  batchId?: string;
}

const SupervisorView = ({
  activeWorksheetId,
  searchItems,
  setSearchItems,
  lastModified,
  toastRef,
  batchId
}:SupervisorViewProps): JSX.Element => {
  const [resultSelected, setResultSelected] = useState<ResultsResponse | null>(
    null
  );
  const [escalatedSearchItems, setEscalatedSearchItems] = useState<SearchRequest>();
  const [detailsPanelState, setDetailsPanelState] = useState<DetailsPanelState>(
    DetailsPanelState.collapsed
  );
  const [selectedMessages, setSelectedMessages] = useState<ResultsResponse[]>(
    []
  );
  const [selectedContentMessages, setSelectedContentMessages] = useState<
    SelectedContentMessage[]
  >([]);

  const escalatedReportState = useCallback(
    (items: SearchRequest) => {
      // Search in searchRequestFields for report state and remove if there is already searchRequestFields with Report State
      const searchRequestFields = items.searchRequestFields?.filter(
        srf =>
          srf.searchField !== EntitySearchFieldsEnum.SurveillanceReportState
      );

      // Add Escalated Report State into searchRequestFields
      const newSearchRequestFields = 
        [{searchTerm: SurveillanceEntityStatus.Escalated,
          searchField: EntitySearchFieldsEnum.SurveillanceReportState,
          entityId: activeWorksheetId ?? '',
          metaData: [],
        }];

      return {
        ...items,
        searchRequestFields: [
          ...(searchRequestFields ?? []),
          ...newSearchRequestFields,
        ],
      };
    },
    [activeWorksheetId]
  );

  // Mutate worksheet with data
  useEffect(() => {
    if (searchItems) {
      setEscalatedSearchItems(escalatedReportState(searchItems));
    }
  }, [escalatedReportState, searchItems]);

  useEffect(() => {
    setSelectedContentMessages(items => [
      ...items.filter(
        i =>
          selectedMessages.find(m => isMessagesEqual(i, m)) ||
          (i.parent &&
            selectedMessages.find(
              m => i.parent && isMessagesEqual(i.parent, m)
            ))
      ),
    ]);
  }, [selectedMessages]);

  return <div className='supervisor-view-container module__canvas'>
    <main
      className={clsx('grow-to-fill', {
        'drawer--active': Boolean(resultSelected),
      })}
      data-cols={
        Boolean(resultSelected) &&
        (detailsPanelState === DetailsPanelState.collapsed ? '9,3' : '5,7')
      }
      data-drawer-style={Boolean(resultSelected) && 'slide'}
      data-drawer-position={Boolean(resultSelected) && 'alongside-right'}
    >
      <section className="grow-to-fill overflow--hidden">
        <Results
          isLoadingWorksheet={false}
          isFooterVisible={false}
          selectedMessages={selectedMessages}
          setSelectedMessages={setSelectedMessages}
          resultSelected={resultSelected}
          setResultSelected={setResultSelected}
          selectedSiblingMessages={selectedContentMessages.filter(m =>
            Boolean(m.parent)
          )}
          searchItems={escalatedSearchItems}
          setSearchItems={setSearchItems}
          lastModified={lastModified}
          activeWorksheetId={activeWorksheetId}
          toastRef={toastRef}
          resultsMode={SurveillanceModeEnum.Escalated}
          batchId={batchId}
        />
      </section>
      {resultSelected && (
        <DetailsArea
          activeWorksheetId={activeWorksheetId}
          record={resultSelected}
          setRecordSelected={setResultSelected}
          selectedMessages={selectedMessages}
          setSelectedMessages={setSelectedMessages}
          selectedContentMessages={selectedContentMessages}
          setSelectedContentMessages={setSelectedContentMessages}
          panelState={detailsPanelState}
          setPanelState={setDetailsPanelState}
          toastRef={toastRef}
          searchRequestFields={searchItems?.searchRequestFields}
          resultsMode={SurveillanceModeEnum.Escalated}
        />
      )}
    </main>
  </div>;
};

export default SupervisorView;
