export enum Reason {
  FalsePositive = 'FalsePositive',
  Duplicate = 'Duplicate',
  Other = 'Other'
}

export const SURV_REASON_TEXT_MAP: { [key in Reason]: string } = {
  [Reason.FalsePositive]: 'False Positive',
  [Reason.Duplicate]: 'Duplicate',
  [Reason.Other]: 'Other',
};