import axios, { AxiosResponse } from 'axios';
import { Observable } from 'rxjs';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import ErrorToastService from 'components/Errors/ErrorToast/Services';


import { ResultsModeEnum } from '../Models/Enums';

import { apiVesselSanctionResponse, apiSearchResponse,
	apiIndividualSanction, apiTrackSanction
 } from '../Models';


import type { ResponseError } from 'models/shared/error';

interface ISearchParams {
    searchTerm: string;
    pageSize: number;
    pageNumber: number;
    callbackId?: string;
}

const getFetcherByProp = (mode: ResultsModeEnum) => {
	switch (mode) {
		case ResultsModeEnum.VesselSanctions:
			return SanctionsApi.searchByVessel;
		case ResultsModeEnum.LegalEntitySanctions:
			return SanctionsApi.searchByEntity;
		case ResultsModeEnum.IndividualSanctions:
			return SanctionsApi.searchByIndividual;
	}
}

export const useSanctionsApi = <T = any>(searchTerm: string, mode: ResultsModeEnum) => {

	const shouldFetch: boolean = searchTerm.length >= 1;

	const func = getFetcherByProp(mode);

	const { data, error, isLoading } = useSWR<apiSearchResponse<T>>(
		shouldFetch 
			? `${mode}.${searchTerm}`
			: null,
		() => func({ searchTerm, pageSize: 1000, pageNumber: 1 })
	);

  return { data, error, isLoading };
}

export const useIndividualDetailsApi = (id?: string, listName?: string): { data?: apiIndividualSanction; error: ResponseError; isLoading: boolean; } => {
    const shouldFetch = !!(id && listName);

    const { data, error, isLoading } = useSWR(
        shouldFetch ? `individualDetails/${listName}/${id}` : null,
        () => SanctionsApi.getIndividualDetails(id!, listName!),
        { revalidateIfStale: false, revalidateOnFocus: false }
    );

    return { data, error, isLoading };
}

export const useAuditSanctionsTrack = (
  id: string
): {
  data: AxiosResponse<any, any> | undefined | void;
  trigger: (extraArgument: apiTrackSanction | any) => Promise<AxiosResponse<any> | undefined | void>;
  isMutating: boolean;
} => {
  const { trigger, data, isMutating } = useSWRMutation(
    `audit/${ id }`,
    SanctionsApi.auditSanctionsTrack
  );

  return { trigger, data, isMutating };
};

export class SanctionsApi {

	static searchByVessel = (props: ISearchParams) => axios.post<any, { data: apiSearchResponse<apiVesselSanctionResponse>}>('sanctions/vessel/search', props)
	.then( r => {
		let { data } = r;
		let { results } = data;
		data = {...data, results: results.map((item: any, serverindex: number) => ({...item, serverindex})) };
		return {...r, data }
	})
	.then(r => r.data)
	.catch((e) => {
			ErrorToastService.handleError(e, [500, 503]);

			throw e;
	});

	static searchByEntity = (props: ISearchParams) => axios.post(
		'sanctions/legalentity/search', 
		props)
	.then( r => r.data )
	.catch((e) => {
		ErrorToastService.handleError(e, [500, 503]);

		throw e;
	});

    static searchByIndividual = (props: ISearchParams) => axios.post('sanctions/individual/search', props)
        .then( r => r.data )
        .catch((e) => {
            ErrorToastService.handleError(e, [500, 503]);

            throw e;
        });

	static getIndividualDetails = (id: string, listName: string) => axios.get(`sanctions/individual/${listName}/${id}`)
	.then(r => r.data)
	.catch((e) => {
		ErrorToastService.handleError(e, [500, 503]);

		throw e;
	});

  static auditSanctionsTrack = (url: string, params: { arg: apiTrackSanction }): Promise<AxiosResponse | void> => {
    const data = params.arg;

    return  axios.request({
      url: 'audit/sanctions/track',
      data,
      method: 'POST'
    })
      .then(results => results)
      .catch(e => { return; });
  };

}
