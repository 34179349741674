import { clsx } from "clsx"

import { currencyFormatter, humanDate } from "helpers/Utils/formatters";

import "./PriceHistorySummaryCard.scss";

interface IArgs {
  first: any,
  last: any,
  results: any,
  market: any,
  period: any,
}

const rangeMessage = (key: string): string => {
  switch(key) {
    case "5D":
      return "past 5 days"; 
    case "1M":
      return "past month";
    case "6M":
      return "past 6 months";
    case "YTD":
      return "in the year to date";
    case "1Y":
    default:
      return "past year";
  }
}

function PriceHistorySummaryCard(args: IArgs) {

  const { first, last, results, period } = args;

  return <>
  { results &&
    <div className="price-history__summary">
      <div className="price-history__value">
        <strong>{ last?.value }</strong><em>{results?.unit}</em>
      </div>
      { first && last && 
        <div className={clsx({
          "price-history__change": true,
          "up": first?.value < last?.value,
          "down": first?.value > last?.value
        })}>
          { currencyFormatter(last.value - first.value, results.precision ?? 2)} 
          {" ("}
          { currencyFormatter(((last.value - first.value) / first.value ) * 100, 2)} {" %) "}
          <span className={clsx({
            "iconoir-arrow-up-circle":first.value < last.value,
            "iconoir-arrow-down-circle":first.value > last.value,
          })}/>
          { rangeMessage(period.key)}
        </div>
      }
      <time dateTime={results.endDate} className="price-history__time-stamp">
        Prices last Updated: { humanDate(results.endDate, { time: true }) } 
      </time>
    </div>
  }
  </>;
}

export default PriceHistorySummaryCard;


        
