export enum TemplateControlTypes {
	TextInput = 'TextInput',
	Dropdown = 'Dropdown',
	DateInput = 'DateInput',
	TextField = 'TextField',
	NumberInput = 'NumberInput'
}

export enum WorkflowStatusTypeEnum {
	NotStarted = 0,
	InProgress = 1,
	WaitingOnRequestor = 2,
	Approved = 3,
	Rejected = 4,
	WaitingForCustomer = 5,
	Completed = 6
}

export enum TaskChangeType {
	Status = 'Status',
	Assignee = 'Assignee'
}

export enum WorkflowEvents {
	Mutation = 'Mutation',
}

export enum ControlBehaviour {
	Show = 'show',
	Hide = 'hide'
}

export enum WorkflowDisplayMode {
	None = 0,
	Create = 1,
	Edit = 2,
	NoWorkflowSelected = 3
}

export enum WorkflowProvider {
	Generic = 0,
	Onboarding = 1,
	BackOffice = 2
}

export enum CommissionRateType {
  Standard = 'Standard',
  Custom = 'Custom',
};

