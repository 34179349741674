import { Button } from 'primereact/button';

import UserRecord from 'components/OBXUser/Components/UserRecord/UserRecord';
import { UserEntity } from 'components/OBXUser/Model/ProfileResult';

import { AccessLevel, UserShareAccess } from '../Models';

import UserAutocomplete from './UserAutocomplete';

import { removeItemAt } from 'helpers/Utils/collections';

import styles from './UsersShareArea.module.scss';

interface UsersShareAreaProps {
  users?: UserShareAccess[];
  usersListUpdated: (users: UserShareAccess[]) => void;
}

const UsersShareArea = ({
  users,
  usersListUpdated,
}: UsersShareAreaProps): JSX.Element => {
  const onRemoveUser = (index: number): void =>
    usersListUpdated(
      Array.isArray(users) && users.length ? removeItemAt(users, index) : []
    );
  const onUsersSelected = (usersAdded: UserEntity[]): void => {
    const newUsers = usersAdded.map(user => ({
      ...user,
      access: AccessLevel.Edit,
    }));
    usersListUpdated([...(users || []), ...newUsers]);
  };
  const filterSuggestions = (item: UserEntity): boolean =>
    !users?.find(user => user.email === item.email);

  return (
    <div className={styles.usersShareContainer}>
      {Array.isArray(users) && users.length ? (
        <div className={styles.usersShareContainerList}>
          {users
            .filter(user => !user.isExternal)
            .map((user, index) => (
              <UserRecord
                key={`user-record-${ user.id }`}
                name={user.name}
                email={user.email}
              >
                <Button
                  icon='iconoir-trash icon--small'
                  className='p-button-text'
                  size='small'
                  onClick={(): void => onRemoveUser(index)}
                />
              </UserRecord>
            ))}
        </div>
      ) : null}
      <UserAutocomplete
        onSelect={onUsersSelected}
        onFilterSuggestions={filterSuggestions}
      />
    </div>
  );
};

export { UsersShareArea };
export default UsersShareArea;
