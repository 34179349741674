import { useEffect, useState, CSSProperties, SetStateAction, Dispatch } from 'react';
import { useMediaQuery } from "react-responsive";

import { clsx } from 'clsx';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { ReadableDate } from 'helpers/DataTable/Templates/ColumnTemplates';

import { useGetLatestModData } from 'modules/Rates/Commodities/Services/hooks';
import { IEAModItem, IEAModSources, JodiSources } from 'modules/Rates/Commodities/Models/IEAMods';
import { Frequency } from 'modules/Rates/Commodities/Models/Enums';
import { NumericalUnit } from 'modules/Rates/Commodities/Templates/NumericalUnit';

import './Grids.scss';

export interface IModGridBaseParams {
	query: string | undefined;
	source: IEAModSources | JodiSources,
	dimensions?: string;
	unitAsDecimal: boolean,
	feed: 'iea' | 'jodi',
	selectedRows: BasicRow[],
	setSelectedRows: Dispatch<SetStateAction<BasicRow[]>>,
	changeFrequency: (n: number) => void
}

//	TODO - reference correct type
export type BasicRow = {
	location: string;
	shortName: string;
	hash: string
}

const SimpleGrid = (props: IModGridBaseParams) => {

	const MAX_COLUMNS_BEFORE_PINNED: number = 4;

	const { 
		query,
		source,
		dimensions,
		unitAsDecimal,
		feed,
		selectedRows,
		setSelectedRows,
		changeFrequency 
	} = props;

	const { data, error, isLoading } = useGetLatestModData(feed, source, query)
	const [ columns, setColumns ] = useState<{name: string, label: string}[]>([]);
	const [ rows, setRows ] = useState<BasicRow[]>([]);

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" })

	let transformer: (s: string) => string;
	let decimalPlaces: number = 3;

	if (unitAsDecimal) {
		transformer = (s: string) => s.substring(1)
		decimalPlaces = 0;
	}


	useEffect(() => {
		// console.log("Returned data is", data);
		if (!data) return;

		const { columns, rows, frequency } = data;


		setColumns(columns);
		setRows(rows);

		//	Depending on the response - reset the frequency in the parent
		//	TODO - as any is hacky…
		const frq: Frequency = +Frequency[frequency as any];
		changeFrequency(frq);

	}, [data])

	if (error) {
		//	TODO - handle error state of response.
	}


  return <>
		{ rows && 
			<DataTable
				value={rows}
				className={clsx(
					'grow-to-fill', `mod-data__grid`
				)}
				loading={isLoading}
				scrollable scrollHeight="flex"
				removableSort
				selectionMode={'multiple'}
				selection={selectedRows!}
				onSelectionChange={(e: any) => setSelectedRows(e.value)}
				emptyMessage={() => <div>
					<strong>No results</strong><br/>
					Date period dimensions for this source must be {dimensions} values only
				</div>}
				tableStyle={{'--columns': `${columns.length ? columns.length : 1}`} as CSSProperties}
			>
				<Column 
					selectionMode="multiple"
					headerClassName='content--hidden'
					frozen={true}
				/>
				<Column
					header='Location'
					field='location'
					sortable={true}
					className='no-border'
					frozen={columns.length > MAX_COLUMNS_BEFORE_PINNED || isTabletOrMobile}
				/>
				<Column 
					header='Period'
					field='period'
					frozen={columns.length > MAX_COLUMNS_BEFORE_PINNED && !isTabletOrMobile}
				/>
				{ columns.map((col, i) => 
					<Column 
						key={`index-${i}`}
						header={col.name}
						field={col.name}
						sortable={true}
						body={(d, c) => NumericalUnit(d, c, {alignment: 'right', unit: 'kt', multiplier: unitAsDecimal ? 1000 : 1, decimalPlaces, transformer})}
					/>
				)}
				<Column
					header="Last Updated"
					field='lastUpdated'
					className='no-border'
					frozen={columns.length > MAX_COLUMNS_BEFORE_PINNED && !isTabletOrMobile}
					alignFrozen='right'
					body={ReadableDate<IEAModItem>}>
				</Column>
			</DataTable>
		}
	</>;
}

export default SimpleGrid;
