import { getValueCollection } from 'helpers/Utils/enum';

import { SignalRApi } from 'server/signalR-api';

export enum CargoTrackerSignalEventTypes {
  // eslint-disable-next-line no-unused-vars
  CARGO_TRACKER_CHANGED = 'cargoUpdated',
  // eslint-disable-next-line no-unused-vars
  CARGO_TRACKER_DELETED = 'cargoDeleted'
}

export class CargoTrackerSocket {

  private static _instance: CargoTrackerSocket;

  static get instance():CargoTrackerSocket {
    return this._instance || (this._instance = new this());
  }
  init = async (sr: SignalRApi): Promise<void> => {

    const events = [
      ...getValueCollection(CargoTrackerSignalEventTypes, false)
    ];

    events.map(event => sr.dispatchEventOnMessage(event.key));
  };
}