import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { Accordion, AccordionTab, AccordionTabOpenEvent } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import { InputText } from 'primereact/inputtext';

import GridElement from 'components/GridElement';
import BorealisBar from 'components/BorealisBar';
import { useGetRecipients } from 'modules/DistList/Services/DistListService';
import { replaceItemAt } from 'helpers/Utils/collections';

import { DL_MESSAGES_STATUS_LABEL, DLMessagesStatus } from '../../Models/Enums';
import CommonFields from '../CommonFields';

import CreateNewButton from './CreateNewButton';
import DroppedTooltip from './DroppedTooltip';
import EmptyMessage from './EmptyMessage';
import HeaderTemplate from './HeaderTemplate';
import ResendButton from './ResendButton';

import type {
  DistributionListMessagesSearchResponse,
  DistributionListMessagesSearchResponseFlat,
  MessageRecipients
} from '../../Models/distribution-list-response';
import type { DistributionList } from 'modules/DistList/Models/distribution-list-response';

interface MessageMobileProps {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setSubjectSearch: Dispatch<SetStateAction<string | undefined>>;
  isLoading: boolean;
  isCreateDisabled: boolean;
  handleClose: () => void;
  setMessages: Dispatch<SetStateAction<DistributionListMessagesSearchResponse[] | undefined>>;
  setActiveDetailTab: Dispatch<SetStateAction<number>>;messages?: DistributionListMessagesSearchResponse[];
  activeDlId?: DistributionList['id'];
  dlName?: string;
  activeDetailTab?: number;
}

const MessageMobile = (props: MessageMobileProps): JSX.Element => {
  const {
    messages,
    setMessages,
    searchValue,
    setSearchValue,
    setSubjectSearch,
    isLoading,
    isCreateDisabled,
    handleClose,
    activeDlId,
    dlName,
    activeDetailTab,
    setActiveDetailTab
  } = props;

  const [messagesWithKey, setMessagesWithKey] = useState<DistributionListMessagesSearchResponse[] | undefined>(undefined);

  const { trigger: getRecipients, isMutating: getRecipientsMutating, error: getRecipientsError } = useGetRecipients();

  useEffect(() => {
    if (messages && messages.length > 0) {
      // Add idKey to recipients for resend action (to know which element to update)
      const withKey = messages.map(el => {
        const recipients = el.recipients?.map((recipient, recipientIndex) =>
          ({ ...recipient, idKey: `${el.id}_${recipientIndex}`, recipientIndex: recipientIndex })) ?? [];
        return {...el, recipients};
      });

      setMessagesWithKey(withKey);
    }
  }, [messages]);

  const itemTemplate = (item: MessageRecipients, parent: DistributionListMessagesSearchResponse): JSX.Element => {
    // @ts-ignore
    const parentFlat: DistributionListMessagesSearchResponseFlat = { ...parent, ...item };

    return (
      <div className={clsx('list-element')} key={item.idKey}>
        <GridElement header="Recipient" body={item.emailAddress} />
        <GridElement header="Status" body={<span className="list-element-slanted">
          {[DLMessagesStatus.dropped].includes((item.status as DLMessagesStatus) ?? DLMessagesStatus.null) ?
            (<>DL_MESSAGES_STATUS_LABEL[DLMessagesStatus.dropped]<DroppedTooltip /></>) :
            DL_MESSAGES_STATUS_LABEL[item.status as DLMessagesStatus ?? DLMessagesStatus.null]}
        </span>} />
        <GridElement header="Attachment" body={<span className="list-element-slanted">{item.attachmentDownloaded ? 'Downloaded' : 'NOT downloaded'}</span>} />
        {![undefined, DLMessagesStatus.null, DLMessagesStatus.processed, DLMessagesStatus.deferred].includes(item.status as DLMessagesStatus) &&
          <GridElement header="" body={<ResendButton data={parentFlat} messages={messages} setMessages={setMessages} rowIndex={item.recipientIndex}/>} />}
      </div>
    );};

  const onTabOpen = async (e: AccordionTabOpenEvent): Promise<void> => {
    if (messagesWithKey) {
      const res = await getRecipients({ id: messagesWithKey[e.index].id, distributionListId: messagesWithKey[e.index].distributionListId });

      setMessages(msgs => {
        if (msgs) {
          const index = msgs.findIndex(p => p.id === messagesWithKey[e.index].id);
          return replaceItemAt(msgs, res, index);
        }

        return msgs;
      });
    }
  };

  const renderContent = (item: DistributionListMessagesSearchResponse): ReactNode => {
    if (getRecipientsMutating) {
      return <div className='dl-messages__loader'><BorealisBar /></div>;
    } else if (getRecipientsError) {
      return <div className='dl-messages__error-message'>Cannot load recipients</div>;
    } else {
      return <DataView
        value={item.recipients}
        itemTemplate={(el): JSX.Element => itemTemplate(el, item)}
        className='distlist__view grow-to-fill no-background'
      />;
    }
  }

  return (
    <>
      <Button
        size="small"
        text
        className="plain-text back-button"
        icon={'iconoir-nav-arrow-left icon--small'}
        onClick={handleClose}
      >
        Back to list
      </Button>
      <div className="distlist-dl-panel__messages position--relative">
        {activeDlId && <CommonFields
          dlName={dlName}
          activeDetailTab={activeDetailTab}
          setActiveDetailTab={setActiveDetailTab}
        />}
        <div className="dl-messages-container grow-to-fill">
          <Accordion className="dl-messages__accordion">
            <AccordionTab className="dl-messages__accordion-tab search-tab"
              headerClassName="dl-messages__accordion-header"
              header="Search"
            >
              <div className="dl-messages__accordion-container">
                <div className="dl-messages-search-container">
                  <span className="p-input-icon-left grow-to-fill">
                    <i
                      className={clsx('pi', {
                        'pi-spinner pi-spin': isLoading,
                        'pi-search': !isLoading,
                      })}
                    />
                    <InputText
                      placeholder="Search by subject"
                      value={searchValue}
                      onChange={(e): void => setSearchValue(e.target.value)}
                      onBlur={(e): void => setSubjectSearch(e.target.value)}
                      onKeyUp={(e): boolean | void => e.key === 'Enter' && (e.target as HTMLInputElement).blur()}
                      className="dl-search-input"
                    />
                  </span>
                </div>
              </div>
            </AccordionTab>
          </Accordion>

          <Accordion
            className="dl-messages__accordion"
            onTabOpen={onTabOpen}
          >
            {messagesWithKey?.map(item => (
              <AccordionTab className="dl-messages__accordion-tab"
                headerClassName="dl-messages__accordion-header"
                header={():JSX.Element => HeaderTemplate(item)}
                key={item.id}
              >
                <div className='dl-messages__accordion-container'>
                  {renderContent(item)}
                </div>
              </AccordionTab>))}
          </Accordion>
          {messages?.length === 0 && <EmptyMessage activeDlId={activeDlId} isCreateDisabled={isCreateDisabled} setActiveDetailTab={setActiveDetailTab} />}
        </div>
      </div>
      <footer className="distlist-dl-panel__footer">
        <CreateNewButton activeDlId={activeDlId} isDisabled={isCreateDisabled} />
      </footer>
    </>
  );
};

export default MessageMobile;
