import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';

import styles from './ContactSales.module.scss';

const SALES_EMAIL_ADDRESS = 'data.sales@obxlive.com';

export default function ContactSales(): JSX.Element {
  const { moduleName } = useParams();
  const [opening, setOpening] = useState<boolean>(false);

  const onContactSalesClick = (): void => {
    // disabling/loading is not required, but JUST SHOW to the user that something happens
    setOpening(true);

    window.open(`mailto:${SALES_EMAIL_ADDRESS}?subject=Upgrade ${moduleName ?? ''}`);

    setTimeout(() => {
      setOpening(false);
    }, 500);
  };

  return <div className={styles.container}>
    <img src='/img/sales-page.svg' alt='sales-page' className={styles.image} />
    <div className={styles.content}>
      <h1>Upgrade to access this module</h1>
      <p>Unfortunately you don't have access to this module. Please contact the Sales Team and start using all the features it offers.</p>
    </div>
    <Button
      size='small'
      onClick={onContactSalesClick}
      loading={opening}
    >
      Contact Sales
    </Button>
  </div>;
}