import { Button } from 'primereact/button';
import { useNavigate, useRouteError } from 'react-router-dom';

import ErrorCode from '../ErrorCode';

import './ErrorPage.scss';

export default function ErrorPage (): JSX.Element {
    const error = useRouteError();
    const navigate = useNavigate();

    const handleReload = (): void => {
        navigate(0);
    }

    const handleGoBack = (): void => {
        navigate(-1);
    }

    return <main className="error-page__container direction--column">
        <img alt="" src="/img/icons/something_went_wrong.svg"></img>
        <div className="error-page__action direction--column">
            <h2 className="error-page__title">
                Sorry, something has gone wrong
            </h2>
            <h5 className="error-page__sub-title">
                Please try refreshing the page or navigating back to the previous page. If the problem continues to appear, please contact our support team. We appreciate your patience and understanding.
            </h5>
            <div className="action__buttons">
                <Button className="p-button-filled" onClick={handleReload}>Try to reload</Button>
                <Button outlined onClick={handleGoBack}>Go back</Button>
            </div>
        </div>
        <ErrorCode error={error} />
    </main>
  }