import clsx from 'clsx';
import { DateTime } from 'luxon';

import {
  SURVEILLANCE_MEDIA_LABEL,
  SurveillanceMedia,
} from '../../../../Models/ReportsResponse';
import { DetailsComponentProps } from '../../Models';

import styles from './DetailsComponent.module.scss';

const DetailsComponent = ({
  className,
  companyName,
  details,
  hidden,
}: DetailsComponentProps): JSX.Element => {
  if (hidden) {
    return <></>;
  }
  return (
    <div className={clsx(styles.container, className)}>
      {details && (
        <div className={styles.content}>
          <div className={styles.recordDetailsContainer}>
            <dl>
              <dt>Company</dt>
              <dd>{companyName}</dd>
              <dt>User</dt>
              <div className={clsx(styles.recordDetailsContainerCounterpart)}>
                {details.username && (
                  <div>
                    <div className={clsx(styles.lineParticipantsLeftColUser)}>
                      Name:
                    </div>
                    <dd className={clsx(styles.lineParticipantsRightCol)}>
                      {details.username}
                    </dd>
                  </div>
                )}
                {details.metadata.number && (
                  <div>
                    <div className={clsx(styles.lineParticipantsLeftColUser)}>
                      Number:
                    </div>
                    <div>
                      <dd className={clsx(styles.lineParticipantsRightCol)}>
                        {details.metadata.number}
                      </dd>
                    </div>
                  </div>
                )}
                {details.metadata.email && (
                  <div>
                    <div className={clsx(styles.lineParticipantsLeftColUser)}>
                      Email:
                    </div>
                    <div>
                      <dd className={clsx(styles.lineParticipantsRightCol)}>
                        {details.metadata.email}
                      </dd>
                    </div>
                  </div>
                )}
              </div>
              <dt>Counterpart</dt>
              <div className={clsx(styles.recordDetailsContainerCounterpart)}>
                {details.line && (
                  <div>
                    <div className={clsx(styles.lineParticipantsLeftCol)}>
                      Line:
                    </div>
                    <dd className={clsx(styles.lineParticipantsRightCol)}>
                      {details.line}
                    </dd>
                  </div>
                )}
                <div>
                  {details.line && (
                    <div className={clsx(styles.lineParticipantsLeftCol)}>
                      Participants:
                    </div>
                  )}
                  <dd className={clsx(styles.lineParticipantsRightCol)}>
                    {details.participants}
                  </dd>
                </div>
              </div>
              {details.media && (
                <>
                  <dt>Media</dt>
                  <dd>
                    {
                      SURVEILLANCE_MEDIA_LABEL[
                        details.media as SurveillanceMedia
                      ]
                    }
                  </dd>
                </>
              )}
              <dt>Date</dt>
              <dd>
                {DateTime.fromISO(details.startTime)
                  .setZone('UTC')
                  .toFormat('dd LLL yyyy, HH:mm:ss')}{' '}
                UTC
              </dd>
            </dl>
          </div>
        </div>
      )}
    </div>
  );
};

export { DetailsComponent };
export default DetailsComponent;
