import { type RefObject } from 'react';
import { Button } from 'primereact/button';

import { type ToastMessageRef, ToastSeverity } from 'components/ToastMessage';
import { useExportTrades } from 'modules/Blotter/Services/BlotterAPI';

interface ButtonExportProps {
  toastRef: RefObject<ToastMessageRef>;
  worksheetId: string | undefined;
};

export default function ButtonExport(props: ButtonExportProps): JSX.Element {
  const { toastRef: toast, worksheetId } = props;

  const { isMutating: isExporting, trigger: exportTrades } = useExportTrades();

  const handleExport = async (): Promise<void> => {
    try {
      if (worksheetId) {
        await exportTrades({ worksheetId });
      } else {
        throw new Error('No worksheet ID'); // unlikely to happen
      }
    } catch (e) {
      toast.current?.replace({
        title: 'Error',
        message: 'Sorry, can\'t export the trades. Please try again later.',
        severity: ToastSeverity.ERROR,
      });
    }
  };

  return <Button
    outlined
    size='small'
    icon='iconoir-download icon--small'
    loading={isExporting}
    disabled={isExporting}
    onClick={handleExport}
  >
    Export
  </Button>;
}