import axios from "axios";
import { Observable } from "rxjs";

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import { EmailStatusResponse } from "../Models/EmailStatusResponse";
import { MailSecureGenerateRequest } from "../Models/MailSecureGenerateRequest";

export class EmailAPI {
    public generateSecureEmailAddress(recipients: string[]): Observable<EmailStatusResponse> {
        const request: MailSecureGenerateRequest = new MailSecureGenerateRequest({ recipients: recipients });

        const observable$ = new Observable<EmailStatusResponse>(function subscribe(subscriber) {
            axios.request<any>({
                url: "mail/securesending/requestemail", method: 'POST',
                data: request
            }).then(r => {
                var response: EmailStatusResponse = r.data;
                subscriber.next(response);
            })
            .catch((e) => {
                ErrorToastService.handleError(e, [500, 503]);
    
                throw e; 
            });
        });
        return observable$;
    }

    public getStatus(emailId: string): Observable<EmailStatusResponse> {

        const observable$ = new Observable<EmailStatusResponse>(function subscribe(subscriber) {
            axios.request<any>({
                url: `mail/status/${emailId}`, method: 'GET'
            }).then(r => {
                var response: EmailStatusResponse = r.data;
                subscriber.next(response);
            })
            .catch((e) => {
                ErrorToastService.handleError(e, [500, 503]);
    
                throw e; 
            });
        });
        return observable$;
    }

}