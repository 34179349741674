import { Accordion, AccordionTab } from 'primereact/accordion';

interface TradeSearchContainerProps {
  children: JSX.Element;
  isMobile: boolean;
}

export default function TradeSearchContainer(props: TradeSearchContainerProps): JSX.Element {
  const { children, isMobile } = props;

  if (isMobile) {
    return <Accordion activeIndex={0} className='blotter-trade-search__accordion'>
      <AccordionTab header='Search'>
        {children}
      </AccordionTab>
    </Accordion>;
  }

  return children;
}