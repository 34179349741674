import axios from 'axios';
import useSWR from 'swr';
import { DateTime } from 'luxon';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import { Group } from '../Models/Enums';
import { TimeZonesEnum } from 'components/TimeZonePicker/TimeZonePicker';

import type {
    CalculationRequest,
    ExtendedCalculationRequest,
    ExtendedCalculationRequestLocation
} from '../Models/CalculationRequests';
import type { 
	CalculationResponse, 
	CalculationResult } from '../Models/CalculationResponse';
	
import type { AreasToAvoidResponse } from '../Models/AreasToAvoidResponse';

export const useCalculateDistance = (random: number, params: CalculationRequest) => {
    const paramsFiltered = CalculateDistancesApi.filterCalculationRequestParams(params);

    const url: [string, number] | null = (paramsFiltered.locations.length < 2) ? null : ['distcalc/calculate', random];
    const { data, error, isLoading } =
        useSWR(
            url,
            () => CalculateDistancesApi.getCalculation(paramsFiltered),
            { revalidateOnFocus: false }
        );

    console.log("params is", paramsFiltered.locations.length, isLoading, {originalParams: params, filteredParams: paramsFiltered});

    return { data, error, isLoading }
}

export const useAreasToAvoid = () => {

    const url: string = "/distcalc/avoidingareas";
    const id = "areas-to-avoid";

    const { data, error, isLoading } = useSWR(
        id,
        () => CalculateDistancesApi.getAreasToAvoid({ url }),
        { revalidateOnFocus: false });

    return { data, error, isLoading };
}

export class CalculateDistancesApi {

  static convertDate = (date: DateTime, timeZone: TimeZonesEnum): DateTime => {

    switch (timeZone) {
      case TimeZonesEnum.UTC:
        return date.toUTC();
      case TimeZonesEnum['Local to User']:
        return date.toLocal();
      /*case TimeZonesEnum["Local at Port"]: // TODO: uncomment when Local at port BE part will be done and we will add FE implementation
        return date.toUTC();*/
    }
  };

	static getCalculation = (value: ExtendedCalculationRequest) => axios.request({ url: 'distcalc/calculate', data: value, method: "POST" })
	.then((response: CalculationResponse) => {

			const { data } = response;
			const parsed = {
				...data,
				arrivalDate: this.convertDate(DateTime.fromISO(`${data.arrivalDate}`), value.timeZone),
				waypoints: data.waypoints.map(
						wp => ({
								...wp,
								arrivalDate: this.convertDate(DateTime.fromISO(`${wp.arrivalDate}`), value.timeZone),
						})
				),
			};

			return parsed as CalculationResult;
	}).catch((error) => {
			ErrorToastService?.handleError(error, [400, 500, 503]);

			throw error;
	});

	static getAreasToAvoid = async ({ url }:  { url: string; }): Promise<AreasToAvoidResponse> => axios.request({url, method: "GET"})
	.then((result) => result.data.results)
	.catch((e) => {
		ErrorToastService.handleError(e, [500, 503]);

		throw e;
	})

    static filterCalculationRequestParams = (params: CalculationRequest): ExtendedCalculationRequest => {
        let locationsFiltered: ExtendedCalculationRequestLocation[] = [];
        let sequence = 0;
        let vessel: number | null = null;
        const date: string | undefined = params.startDate?.from.toISO() ?? DateTime.now().toUTC().toISO() ?? undefined;

        const { avoiding , speed, timeZone } = params

        params.locations.forEach((location) => {
            const group = typeof location.group === 'string' ? Group[location.group] : location.group as number;

            if ([Group.Loading, Group.Via, Group.Discharging].includes(group as Group)) { // Omit Vessel and Avoiding
                locationsFiltered.push({
                  hoursInPort: location.hoursInPort,
                  speed: location.speed,
                  sequence: sequence,
                  group: group as Group,
                  searchEntityId: location.SignalOceanPortId ?? '',
                  weather: location.weather,
                });
                sequence++;
            } else if (group === Group["Vessel Class"]) { // Vessel
                vessel = parseInt(location.searchEntityId, 10);
            }
        });

        return {
            locations: locationsFiltered,
            avoiding: {
                // overrideEnabled is true if any of checkboxes has been touched
                overrideEnabled: avoiding ? avoiding.some((a) => a.overridden) : false,
                avoidingAreas: avoiding ?? []
            },
            vessel: vessel,
            timeZone: timeZone,
            startDate: date,
            // send null if speed === 'Mixed' so BE can handle request properly. 'Mixed' is not allowed (number and null only)
            speed: speed === 'Mixed' ? null : speed,
        }
    }
}