import { DateTime } from 'luxon';

export type AvailableRateGridsResponse = {
	name: string;
	description: string;
	id: string;
	sortOrder?: number;
	dimensions?: {
		annual: boolean;
		monthly: boolean;
		quarterly: boolean;
	}
}

export type RateGridApiColumn = {
	columnGrouping: string;
	displayOrder: number;
	name: string;
}

export interface IRateBheaviour {
	allowedValues?: string[];
	unit?: string;
	validationMessage?: string;
	validationRegex?: string;
}

export interface IRateGridApiRouteValue {
	asOf: string;
	dataType: RateDataTypeEnum;
	value: string;
	valueSetByDisplayName: string;
	behaviour: IRateBheaviour;
	columnGroup: string;
	columnName: string;
}

export type RateGridRoute<T> = {
	name: string;
	values: T[]
}

export type ApiData = {
	data: RateGridAPIResponse
}

export type RateGridAPIResponse = {
	id: string;
	lastModified: string;
	columns: RateGridApiColumn[];
	dataAgeProfileInHours: number;
	provider: string;
	routes: RateGridRoute<IRateGridApiRouteValue>[];
}

export interface IRateGridRouteValueResponse extends Omit<IRateGridApiRouteValue, 'asOf'> {
	asOf: DateTime;
	unit: string | undefined;
	grid: string
}

export interface ISignalRRateGridValue extends Omit<IRateGridApiRouteValue, 'behaviour' | 'grid'> {
	route: string;
	linkedRateGridId: string;
}

export type RateGridResponse = Omit<RateGridAPIResponse, 'routes'> & {
	lastModified: DateTime;
	routes: RateGridRoute<IRateGridRouteValueResponse>[],
}


export enum RateDataTypeEnum {
	Sentiment = 0,
	Value = 1,
}