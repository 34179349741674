import { useMemo, useRef } from 'react';
import { Badge } from 'primereact/badge';
import { Checkbox } from 'primereact/checkbox';
import { ContextMenu } from 'primereact/contextmenu';
import { MenuItem } from 'primereact/menuitem';

type ContextHeaderItem = {
  label: string;
  value: boolean;
  onChanged: (value: boolean) => void;
}

type ContextHeaderProps = {
  label: string;
  items?: ContextHeaderItem[];
  className?: string;
  menuClassName?: string;
}

const MAX_FILTERS_COUNT = 99;

const FilteringHeader = ({ label, items, className, menuClassName }: ContextHeaderProps): JSX.Element => {
  const cm = useRef<ContextMenu>(null);

  const filtersCount = useMemo(() => items?.filter(({ value }) => value).length, [items]);
  const contextMenuItems: MenuItem[] | undefined = useMemo(() => items?.map(item => ({
    command: () => item.onChanged(!item.value),
    template: () => <>
      <Checkbox checked={item.value} />
      <label>{item.label}</label>
    </>
  })
  ), [items]);

  return (
    <div
      className={className}
      onClick={items?.length ? cm.current?.show : undefined}
      onContextMenu={items?.length ? cm.current?.show : undefined}
    >
      <ContextMenu className={menuClassName} ref={cm} model={contextMenuItems} />
      <div>{label}</div>
      <i className="p-overlay-badge iconoir-filter icon--small" />
      {!!filtersCount && <Badge value={filtersCount > MAX_FILTERS_COUNT ? `${ MAX_FILTERS_COUNT }+` : filtersCount} />}
    </div>
  );
};

export default FilteringHeader;
