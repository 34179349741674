import { SanctionStatusEnum } from './Enums';

import { humanDate } from 'helpers/Utils/formatters';

export interface IVesselSanctionResult {
  id: string;
  partitionKey: string;
  createdOn: string;
  designatedDate?: string;
  imoNumber?: string;
  classificationSociety?: string;
  classificationSocietyCode?: string;
  coreShipInd?: string;
  docCompany?: string;
  docCompanyCountryOfDomicile?: string;
  docCountryOfControl?: string;
  docCountryOfRegistration?: string;
  dateOfBuild?: string;
  documentOfComplianceDOCCompanyCode?: string;
  exName?: string;
  flagCode?: string;
  flagName?: string;
  groupBeneficialOwner?: string;
  groupBeneficialOwnerCompanyCode?: string;
  legalOverall?: number;
  operator?: string;
  registeredOwner?: string;
  registeredOwnerCode?: string;
  shipBESSanctionList?: number;
  shipDarkActivityIndicator?: number;
  shipDetailsNoLongerMaintained?: number;
  shipEUSanctionList?: number;
  shipFlagDisputed?: number;
  shipFlagSanctionedCountry?: number;
  shipHistoricalFlagSanctionedCountry?: number;
  shipManager?: string;
  shipName?: string;
  shipOFACNonSDNSanctionList?: number;
  shipOFACSSIList?: number;
  shipOFACSanctionList?: number;
  shipOverallComplianceStatus?: number;
  shipOwnerAustralianSanctionList?: number;
  shipOwnerBESSanctionList?: number;
  shipOwnerCanadianSanctionList?: number;
  shipOwnerEUSanctionList?: number;
  shipOwnerFATFJurisdiction?: number;
  shipOwnerHistoricalOFACSanctionedCountry?: number;
  shipOwnerOFACSanctionList?: number;
  shipOwnerOFACSanctionedCountry?: number;
  shipOwnerParentCompanyNonCompliance?: number;
  shipOwnerSwissSanctionList?: number;
  shipOwnerUAESanctionList?: number;
  shipOwnerUNSanctionList?: number;
  shipSTSPartnerNonComplianceLast12m?: number;
  shipSanctionedCountryPortCallLast12m?: number;
  shipSanctionedCountryPortCallLast3m?: number;
  shipSanctionedCountryPortCallLast6m?: number;
  shipSecurityLegalDisputeEventLast12m?: number;
  shipOwnerParentFATFJurisdiction?: number;
  shipOwnerParentOFACSanctionedCountry?: number;
  shipStatus?: string;
  shipSwissSanctionList?: number;
  shipUNSanctionList?: number;
  shipUSTreasuryOFACAdvisoryList?: number;
  technicalManager?: string;
  providerName?: string;
}

export class VesselSanctionResult {
  static generateTextPropMap(
    data: IVesselSanctionResult
  ): Map<string, string | undefined> {
    const m = new Map<string, string | undefined>();
    m.set('Name', data.shipName);
    m.set('IMO', data.imoNumber);
    m.set('Registered Owner', data.registeredOwner);
    m.set('Group Owner', data.groupBeneficialOwner);
    m.set('Current Operator', data.operator);
    m.set('Status', data.shipStatus);
    m.set(
      'Designated date',
      data.designatedDate
        ? humanDate(data.designatedDate, { dayName: false })
        : 'Unknown'
    );
    m.set(
      'Created On',
      data.createdOn ? humanDate(data.createdOn, { dayName: false }) : 'Unknown'
    );
    return m;
  }

  static generateStatusPropMap(data: IVesselSanctionResult): Map<string, SanctionStatusEnum> {
      const m = new Map<string, SanctionStatusEnum>();
      m.set("Overall Status", data.legalOverall ?? -1);
      m.set("EU Sanction List", data.shipEUSanctionList ?? -1);
      m.set("BES Sanction List", data.shipBESSanctionList ?? -1);
      m.set("OFAC Sanction List", data.shipOFACSanctionList ?? -1);
      m.set("Swiss Sanction List", data.shipSwissSanctionList ?? -1);
      m.set("Owner Australian Sanction List", data.shipOwnerAustralianSanctionList ?? -1);
      m.set("Owner UN Sanction List", data.shipOwnerUNSanctionList ?? -1);
      m.set("Owner BES Sanction List", data.shipOwnerBESSanctionList ?? -1);
      m.set("Owner OFAC Sanction List", data.shipOwnerOFACSanctionList ?? -1);
      m.set("Owner OFAC Sanctioned Country", data.shipOwnerOFACSanctionedCountry ?? -1);
      m.set("Owner FAFT Jurisdiction", data.shipOwnerFATFJurisdiction ?? -1 );
      m.set("Parent Owner FAFT Jurisdiction", data.shipOwnerParentFATFJurisdiction ?? -1 );
      m.set("Parent Owner OFAC Sanctioned Country", data.shipOwnerParentOFACSanctionedCountry ?? -1 );
      m.set("Dark Activity Indicator", data.shipDarkActivityIndicator ?? -1);
      m.set("Owner Parent Company Non Compliance", data.shipOwnerParentCompanyNonCompliance ?? -1);
      m.set("STS Partner Non Compliance Last 12m", data.shipSTSPartnerNonComplianceLast12m ?? -1);
      m.set("Sanctioned Country Port CallLast 3m", data.shipSanctionedCountryPortCallLast3m ?? -1);
      m.set("Sanctioned Country Port CallLast 6m", data.shipSanctionedCountryPortCallLast6m ?? -1);
      m.set("Sanctioned Country Port CallLast 12m", data.shipSanctionedCountryPortCallLast12m ?? -1);
      m.set("Flag Sanctioned Country", data.shipFlagSanctionedCountry ?? -1);
      m.set("Flag Disputed", data.shipFlagDisputed ?? -1);
      m.set("Historical Flag Sanctioned Country", data.shipHistoricalFlagSanctionedCountry ?? -1);
      return m;
  }
}
