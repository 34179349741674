import axios from "axios";
import useSWR from "swr";

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import type { EntitySearchGroupEnum, EntitySearchFieldsEnum } from "../Models/Enums";
import type { SearchSuggestionsGroupedResponse, SearchSuggestionsResponse, SearchSuggestionsParsedResponse } from "../Models/SearchEntities";
import type { KeyValue } from 'types/generic-type';

const DEFAULT_RESULTS_CHUNK_SIZE: number = 5;

export interface ISuggestionsParams {
    module: EntitySearchGroupEnum,
    fields: EntitySearchFieldsEnum | EntitySearchFieldsEnum[],
    term: string,
    chunkSize?: number;
	preventRun?: boolean;
}

interface CreateSuggestionPayload {
  value: string;
  metaData: KeyValue<string, string>[];
};


/**
 * custom hook which if passed an appropriate _EntitySearchGroupEnum_ module value
 * will return an array of entity _EntitySearchFieldsEnum_ values the current 
 * module can search by
 *
 * @param {EntitySearchGroupEnum} module
 * @returns {{ data: EntitySearchFieldsEnum; error: any; isLoading: boolean; }}
 */
export const useSearchableFields = (module: EntitySearchGroupEnum) => {
    const { data, error } = useSWR(`Search/Fields/${module}`, SearchEntitiesApi.getFieldsForModule);

    return { data, error };
}


/**
 * Custom hook which will return a collection of results that match the criteria
 * of a passed search term. Is contextually aware of the module and subject matter
 * types being search based on passed parama
 *
 * @template T expected return type of the collection
 * @param {ISuggestionsParams} params
 */
export const useSearchSuggestions = <T, R = any>(params: ISuggestionsParams) => {
	
	const { module, fields, term, chunkSize, preventRun } = params;

	const isGrouped: boolean = Array.isArray(fields);
	const path: string = isGrouped ? "SuggestionsGrouped" : "Suggestions";

	const trimmedTerm = (!preventRun)
		? term && `${term}`.trim().replace(/\s\s+/g, ' ') // remove also start, end and multiple spaces
		: "";

	const { data, error, isLoading, isValidating } = useSWR<R>(
		trimmedTerm?.length >= 2
			? `Search/${path}/${module}/${fields}/${encodeURIComponent(trimmedTerm)}/${chunkSize ?? DEFAULT_RESULTS_CHUNK_SIZE}`
			: null,
	
		isGrouped 
			? SearchEntitiesApi.searchSuggestionsGrouped<T>
			: SearchEntitiesApi.searchSuggestions<T>
	);

	return { data, error, isLoading, isValidating }
}




export class SearchEntitiesApi {


    static getFieldsForModule = (url: string) => {
        return axios.request({url, method: "GET"})
        .then((result) => result.data as EntitySearchFieldsEnum[])
        .catch((e) => {
            ErrorToastService.handleError(e, [500, 503]);

            throw e; 
        });
    }

		static searchSuggestions = <T>(url: string) => {
			return axios.request({url, method: "GET"})
			.then((result) => {
				const { data } = result;
				const items = data.map((i: SearchSuggestionsResponse) => {
					let { metaData, searchGroupType, searchTerm, ...rest } = i;
					const props = metaData.reduce(
						(acc: any, c) => ({...acc, [c.key]: c.value}),
						{}
					)
					
					return {...rest, searchTerm, ...props} as T;
				})

				return items;
			}) 
		}


    /**
     * Given a search term will get a set of matching results. Autmatically filters out any
     * empty groups and hydrated items in the values array with key/value props returned in
     * all search results metaData property
     *
     * @template T
     * @param {string} url
     * @returns {*}
     */
    static searchSuggestionsGrouped = <T>(url: string) => {
        return axios.request({url, method: "GET"}) 
        .then((result) => result.data.filter((group: SearchSuggestionsGroupedResponse) => !!group.values.length))
        .then((result) => result.map(
					(item: SearchSuggestionsGroupedResponse) =>
					({...item, 
						values: item.values.filter(i => i.value !== undefined).map( // Remove elements without value
							(i: SearchSuggestionsResponse) => {
								let { metaData, searchGroupType, searchTerm, ...rest } = i;
								const props = metaData.reduce(
										(acc: any, c, i: number) => ({...acc, [c.key]: c.value}),
										{}
								)
								if (!searchTerm) { 
									searchTerm = i.value;
								};
								return {...rest, searchTerm, ...props} as T;   
						})
					})    
        ))
        .then((result) => {
            return result as SearchSuggestionsParsedResponse<T>[]
        })
        .catch((e) => {
            ErrorToastService.handleError(e, [500, 503]);

            throw e; 
        });
    }

  static createSuggestion = (url: string, arg: CreateSuggestionPayload): Promise<SearchSuggestionsResponse> => {
    return axios.request({
      url,
      method: 'POST',
      data: arg
	}).then(result => result.data);
  };
}


