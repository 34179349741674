import { getValueCollection } from 'helpers/Utils/enum';

import type { SignalRApi } from 'server/signalR-api';

export enum BlotterSignalEventTypes {
  BLOTTER_TRADE_UPDATED = 'tradeUpdated',
  BLOTTER_TRADE_DELETED = 'tradeDeleted'
}

export class BlotterSocket {

  private static _instance: BlotterSocket;

  static get instance(): BlotterSocket {
    return this._instance || (this._instance = new this());
  }
  init = async (sr: SignalRApi): Promise<void> => {

    const events = [
      ...getValueCollection(BlotterSignalEventTypes, false)
    ];

    events.map(event => sr.dispatchEventOnMessage(event.key));
  };
}