import { useState, useRef, useEffect, useCallback, ReactNode, RefObject } from 'react';
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteUnselectEvent } from 'primereact/autocomplete';
import { Button } from "primereact/button";
import { useMediaQuery } from "react-responsive";
import clsx from 'clsx';
import { EntitySearchFieldsEnum } from '../EntitySearch/Models/Enums';

import ChipTemplate from './Templates/ChipTemplate';
import GroupedItemTemplate from './Templates/GroupedItemTemplate';
import ItemTemplate from './Templates/ItemTemplate';
import RemoveChipIcon from './Components/RemoveChipIcon';

import './Autocomplete.scss';

export type SuggestionGroup = string | number;

export type SuggestionItem = {
  external?: boolean;
  group: SuggestionGroup;
  icon?: string;
  id?: string;
  label: ReactNode;
  name: string;
  searchTerm?: string // TODO - loose this with a token mapper
  searchField?: EntitySearchFieldsEnum;
  subLabel?: ReactNode;
  captionLabel?: ReactNode;
  groupLabel?: ReactNode;
  value?: string | boolean;
}

export type Suggestion = {
  group: SuggestionGroup;
  items: SuggestionItem[];
}

interface AutocompleteProps {
  handleInputValueChange: (value: string) => void;
  handleSelectedItemsChange: (items: SuggestionItem[], removedItem?: SuggestionItem) => void;
  isLoading?: boolean;
  selectedItems: SuggestionItem[];
  suggestions: Suggestion[];
  placeholder: string;
	mapper?: Function;
  autoExpandOnFocus?: boolean;
  autoFocus?: boolean;
	useToggle?: boolean;
  useSections?: boolean;
  className?: string;
  containerRef?: RefObject<HTMLElement>;
}

export default function Autocomplete(props: AutocompleteProps): JSX.Element {

	const {
    handleInputValueChange,
    handleSelectedItemsChange,
    isLoading,
    selectedItems,
    suggestions,
    placeholder,
    autoExpandOnFocus,
    className,
    useToggle = true,
    autoFocus = true,
    containerRef,
    useSections
  } = props;

  const isMobile = useMediaQuery({ query: "(max-width: 960px)" });
  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(isMobile);
  const [chipCount, setChipCount] = useState<number>(0);
  const [hiddenChipCount, setHiddenChipCount] = useState<number>(0);
  const [ulSize, setUlSize] = useState<number>(0);
  const [isExpandActionActive, setIsExpandActionActive] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const chipCounterRef = useRef<HTMLDivElement | null>(null);

  let otherProps;

  if (autoExpandOnFocus) {
    //  additional component behaviours if we want it to auto-expand when the
    //  field get focus
    otherProps = {
      onFocus: (): void => { if (autoExpandOnFocus) setIsSearchExpanded(true);},
      onBlur: (): void => {
        // When clicking on remove token icon or on selecting element - autocomplete blur is executed instead of click for those items.
        if (isExpandActionActive) {
          setIsExpandActionActive(false);
        } else {
          setIsSearchExpanded(false);
        }
      },
    };
  }

  function handleChange(e: AutoCompleteChangeEvent) {
    const { mapper, ...others } = e.value;
    handleSelectedItemsChange(others);
    // reset value because it won't fetch new results when the same query is inputted
    handleInputValueChange('');
  }

  function handleUnselect(e: AutoCompleteUnselectEvent) {
    // AutoComplete doesn't trigger `onChange` when `value` is passed from model (programatically)
    handleSelectedItemsChange(selectedItems.filter(item => e.value !== item), e.value);
  }

  function handleExpand() {
    setIsSearchExpanded(!isSearchExpanded);
  }

  const updateHiddenChipCounter = useCallback(() => {
    if (chipCounterRef.current && hiddenChipCount > 0 && !isSearchExpanded) {
      chipCounterRef.current.textContent = `(+${hiddenChipCount} other)`;
      chipCounterRef.current.classList.remove('hidden');
    } else {
      chipCounterRef.current?.classList.add('hidden');
    }
  }, [hiddenChipCount, isSearchExpanded]);

  const checkChipOverflow = useCallback(() => {
    const ul = inputRef.current?.parentElement?.parentElement;
    if (!ul) return;

    const isUlOverflowed = ul.scrollWidth > ul.clientWidth;
    const container = containerRef?.current;
    const isContainerOverflowed = container ? container.scrollWidth > container.clientWidth : false;

    // If input overflowed and ul contents is bigger than visible ul bounds
    if ((isUlOverflowed || isContainerOverflowed || isMobile) && chipCount > 0) {
      let hasChipsUpdated = false;
      // Reverse loop without last li element (which is input)
      for (let i = ul.children.length - 2; i > 0; i--) {
        if (!ul.children[i].classList.contains('hidden-token')) {
          ul.children[i].classList.add('hidden-token');
          hasChipsUpdated = true;

          // Verify if both are not overflowed, if ok -> break
          const isUlNotOverflowed = ul.scrollWidth <= ul.clientWidth;
          const isContainerNotOverflowed = container ? container.scrollWidth <= container.clientWidth : true;
          if (isUlNotOverflowed && isContainerNotOverflowed) {
            break;
          }
        }
      }
      // If tokens not updated but still container is overflowing -> expand autocomplete
      const verifyUlOverflowed = ul.scrollWidth > ul.clientWidth;
      const verifyContainerOverflowed = container ? container.scrollWidth > container.clientWidth : false;
      if (verifyContainerOverflowed && !verifyUlOverflowed && !hasChipsUpdated && useToggle) {
         setIsSearchExpanded(true);
      }

    // If input not overflowed try to unhide chip
    } else if (!isUlOverflowed && !isContainerOverflowed && !isMobile && hiddenChipCount > 0 && chipCount > 0) {

      // Reverse loop without last li element (which is input)
      for (let i = ul.children.length - 2; i > 0; i--) {
        if (ul.children[i].classList.contains('hidden-token')) {
          // Unhide chip
          ul.children[i].classList.remove('hidden-token');

          // Verify if it's not overflowing, if yes then undo
          const verifyUlOverflowed = ul.scrollWidth > ul.clientWidth;
          const verifyContainerOverflowed = container ? container.scrollWidth > container.clientWidth : false;
          if (verifyUlOverflowed || verifyContainerOverflowed) {
            ul.children[i].classList.add('hidden-token');
          }
          break;  // Stop at first hidden el.
        }
      }
    }

    // count hidden tokens
    setHiddenChipCount(Array.from(ul.children).filter(item => item.classList.contains('hidden-token')).length);
    updateHiddenChipCounter();

  }, [containerRef, isMobile, chipCount, hiddenChipCount, updateHiddenChipCounter, useToggle]);

  useEffect(() => {
    // On window resize verify chip overflow as in flex layout UL size can stay the same when Screen changes
    window.addEventListener('resize', checkChipOverflow);

    return () => {
      window.removeEventListener('resize', checkChipOverflow);
    };
  }, [checkChipOverflow]);

  useEffect(() => {
    setChipCount(selectedItems.length);
    checkChipOverflow();
  }, [selectedItems, checkChipOverflow]);

  useEffect(() => {
    // Create node before input so AutoComplete can append dropdown relative to it
    if (dropdownRef.current === null) {
      dropdownRef.current = document.createElement("div");
      dropdownRef.current.classList.add('dropdown-container');
      inputRef.current?.before(dropdownRef.current);
    }

    // Add chip counter element
    if (chipCounterRef.current === null) {
      chipCounterRef.current = document.createElement("div");
      chipCounterRef.current.classList.add('chip-counter', 'hidden');
      chipCounterRef.current.setAttribute('alt', 'Press expand button to show all');
      dropdownRef.current.before(chipCounterRef.current);
    }
  }, []);

  const ulObserver = useRef(
    new ResizeObserver(() => {
      const ul = inputRef.current?.parentElement?.parentElement;
      setUlSize(ul?.scrollWidth ?? 0);
    })
  );

  const getPlaceholder = useCallback((): string => {
    if (isMobile) {
      if (isSearchExpanded || (!isSearchExpanded && chipCount === 0)) {
        return placeholder;
      } else {
        return '';
      }
    } else {
      return placeholder;
    }
  }, [chipCount, isMobile, isSearchExpanded, placeholder]);

  useEffect(() => {
    const ul = inputRef.current?.parentElement?.parentElement;

    checkChipOverflow();

    if (!ul) return;
    const observer = ulObserver.current;
    observer.observe(ul);

    return () => observer.unobserve(ul);
  }, [checkChipOverflow, chipCount, hiddenChipCount, isMobile, ulSize, isSearchExpanded]);

  return (
    <div className={clsx(
			'search-container p-inputgroup',
			isSearchExpanded && 'search-container-expanded'
			)}>

      {(useToggle && (hiddenChipCount > 0 || isSearchExpanded) && !(isMobile && hiddenChipCount === 0)) &&
        <div className="p-inputgroup-addon align-items--start">
					<Button
						icon={`pi ${isSearchExpanded ? 'iconoir-nav-arrow-down' : 'iconoir-nav-arrow-right'}`}
						text size="small"
						aria-label="Expand"
						className="expand"
						onClick={handleExpand}
					/>
				</div>
      }
      <AutoComplete
        appendTo={isMobile ? "self" : dropdownRef.current}
        autoFocus={autoFocus}
        className={clsx("autocomplete-container", className)}
        completeMethod={(e) => handleInputValueChange(e.query)}
        emptyMessage={isLoading ? "Loading results..." : "No matches found"}
        inputClassName="autocomplete-input"
        inputRef={inputRef}
        itemTemplate={(item) => ItemTemplate(item, item.mapper)}
        loadingIcon={<></>} // workaround to hide loading spinner
        multiple
        pt={{
          removeTokenIcon: {
            onMouseDown: () => setIsExpandActionActive(true)
          },
          item: {
            onMouseDown: ():void => setIsExpandActionActive(true)
          },
        }}
        onChange={handleChange}
        onClear={() => handleInputValueChange('')} // onClear is required as completeMethod is not triggered on empty query (input value)
        onUnselect={handleUnselect}
        optionGroupChildren="items"
        optionGroupLabel="role"
        optionGroupTemplate={GroupedItemTemplate}
        panelClassName={clsx(
          'autocomplete-entity dc-autocomplete autocomplete-entity-grouped',
          {
            'empty': !suggestions.length,
            'autocomplete-entity-with-sections': useSections
          }
        )}
        placeholder={getPlaceholder()}
        removeTokenIcon={RemoveChipIcon}
        selectedItemTemplate={(i): JSX.Element => (
          <ChipTemplate
            group={i.group}
            label={i.label}
            name={i.name}
            mapper={i.mapper}
          />
        )}
        showEmptyMessage
        suggestions={[...suggestions]} // force AutoComplete to rerender and show proper `suggestions` or `empty git message`
        // tabIndex={0} // Chip delete icon has 2
        value={selectedItems}
				scrollHeight="60dvh"
        {...otherProps}
      />
    </div>
  );
}
