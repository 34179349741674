import {
  forwardRef,
  MouseEventHandler,
  useImperativeHandle,
  useState,
} from 'react';
import { Button } from 'primereact/button';

export interface SurveillancePopupFooterReferenceProps {
  setIsLoading: (param: boolean) => void;
}
interface SurveillancePopupFooterProps {
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  onConfirm?: MouseEventHandler<HTMLButtonElement>;
  cancelValue?: string;
  confirmValue?: string;
}

export const SurveillancePopupFooter = forwardRef<
  SurveillancePopupFooterReferenceProps,
  SurveillancePopupFooterProps
>(
  (
    { onCancel, onConfirm, cancelValue = 'Cancel', confirmValue = 'Export' },
    ref
  ): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({ setIsLoading }));

    return (
      <div className="space-between">
        <Button size="small" text severity="danger" onClick={onCancel}>
          {cancelValue}
        </Button>
        <Button
          size="small"
          severity="success"
          autoFocus
          loading={isLoading}
          onClick={onConfirm}
        >
          {confirmValue}
        </Button>
      </div>
    );
  }
);

SurveillancePopupFooter.displayName = 'SurveillancePopupFooter';
