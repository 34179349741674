import { SeriesLineOptions } from 'highcharts';

import { ProductTenor } from './ArtisPrices';

export const productTenorsToChartData = (
  productId: string,
  productName: string,
  products?: ProductTenor[]
): Record<string, SeriesLineOptions> | undefined => {
  if (!products) {
    return;
  }

  return products.reduce((acc, value) => {
    const currentTypeResults = acc[value.type];
    const prices = value.prices[productId];

    if (prices?.length) {
      const point = [value.tenorName.display, prices[0]];

      if (!currentTypeResults) {
        acc[value.type] = {
          name: productName,
          type: 'line',
          data: [point],
          marker: { enabled: false },
        };
      } else {
        currentTypeResults.data = [...(currentTypeResults.data || []), point];
      }
    }

    return acc;
  }, {} as Record<string, SeriesLineOptions>);
};
