export enum PositionsModeEnum  {
	Active = 0,
	Compressed = 1,
}

export enum PositionTypeEnum {
	Clean = 1,
	Dirty = 2,
	Both = 3,
}

export enum PositionStatusEnum {
	NotSet = 0,
  Available = 1,
	OnSubs = 2,
	Fixed = 3
}

export enum PositionConfidentialityEnum {
	Open = 0,
	PNC = 1,
	VPNC = 2
}

export const enum ExportFormats {
  Excel = "Export",
  Email = "Email",
}

export enum AISWaypointType {
	Waypoint = 0,
	CurrentPosition,
	LoadingPort,
	DischargingPort
}