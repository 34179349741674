import type F from 'types/generic-type';

export const StrongContents = (data: F<string>, config: {field: string}) => {

    const { field } = config;

    return <div className="cell--bold-text">
        {data[field]}
    </div>
}
