import { Tooltip } from 'primereact/tooltip';
import { SyntheticEvent, useMemo, useRef } from 'react';
import { Button } from "primereact/button";
import { ContextMenu } from "primereact/contextmenu"
import { MenuItem, MenuItemOptions } from "primereact/menuitem";

import { useLoggedInUser } from '../../OBXUser/Services/ProfileHooks';
import { WorksheetResponse } from '../Models/WorksheetResponse';

interface IWorksheetActionParams {
  contextMenu: ContextMenu | null;
  setMenuItems: (items: MenuItem[]) => void;
  data: WorksheetResponse;
  actions: {
    deleteSheet: (id: string) => void;
    shareSheet: (worksheet: WorksheetResponse) => void;
    renameSheet: (id: string) => void;
  };
  preventDelete?: boolean;
  preventSharing?: boolean;
  preventSharingByNonOwners?: boolean;
}

export const WorksheetAction = (params: IWorksheetActionParams) => {
  const buttonInstance = useRef(null);
  const {
    contextMenu,
    setMenuItems,
    preventDelete = false,
    preventSharing = false,
    preventSharingByNonOwners = false,
  } = params;
  const { worksheetId, isPrivate, createdBy } = params.data;
	const { deleteSheet, shareSheet, renameSheet } = params.actions;

  const { obxuser } = useLoggedInUser();

  const isShareDisabled = useMemo(() => {
    const isCurrentUser = obxuser?.userId === createdBy;
    return preventSharingByNonOwners ? !isCurrentUser : !isPrivate;
  }, [createdBy, isPrivate, obxuser?.userId, preventSharingByNonOwners]);
  
  const sharedDisabledItemTemplate = (item: MenuItem, opts: MenuItemOptions):JSX.Element => {
    return (
      <>
        {opts.element}
        <Tooltip content="This option is only available to the person who created the report"
                 target=".menuitem-tooltip-icon"
                 position="right" />
        <i className="menuitem-tooltip-icon pi pi-question-circle"></i>
      </>
    );
  };

  const clickHandler = (e: SyntheticEvent): void => {
    const items = [];
    if (!preventDelete) {
      items.push({ label: 'Delete', command: () => deleteSheet(worksheetId), icon: 'iconoir-trash icon--small' });
    }
    items.push({ label: 'Rename', command: () => renameSheet(worksheetId), icon: 'iconoir-edit-pencil icon--small'});
    if (!preventSharing) {
      items.push({
        label: 'Share',
        disabled: isShareDisabled,
        command: () => shareSheet(params.data),
        icon: 'iconoir-share-android icon--small',
        template: isShareDisabled ? sharedDisabledItemTemplate : null,
      });
    }
    setMenuItems(items);
    contextMenu?.show(e);
  };
	
	return <>
		<Button 
      ref={buttonInstance}
			size="small"
			text 
      className={"transparent"}
			icon="iconoir-more-vert icon--tiny"
      onClick={clickHandler}
		>
		</Button>
	</>
}
