import Joi from 'joi';

import { PRICE_UNITS, QUANTITY_UNITS, TRADE_TYPES } from './Consts';

const VALIDATION_MESSAGES = {
  'string.equal': 'Buyer and Seller company can not be the same',
  'string.empty': 'Required field',
  'any.required': 'Required field'
};

const getCompanyValidator = (counterpartyKey: string): Joi.StringSchema<string> => Joi.string().required().custom((value, helpers) => {
  const { ancestors } = helpers.state;
  const company = ancestors[1]?.[counterpartyKey]?.company; // we know that leg object is under index === 1

  if (company && company === value) {
    return helpers.error('string.equal');
  }
  return value;
})

const brokerValidator = Joi.object({
  // company: companyValidator,
  contactName: Joi.string().required(),
  tradingAccount: Joi.string().required(),
  paysBrokerage: Joi.bool().required(),
  rate: Joi.when('paysBrokerage', {
    is: true,
    then: Joi.number().required(),
    otherwise: Joi.optional()
  }),
  obBroker: Joi.object({
    userId: Joi.when('/isImported', {
      is: true,
      then: Joi.string().optional(), // imported item has no `userId` defined
      otherwise: Joi.string().required()
    }),
    userName: Joi.string().required()
  }).required()
});

const tradeValidator = Joi.object({
  instrument: Joi.string().required(),
  dateTime: Joi.object().required(), // how to properly validate luxon.DateTime?
  type: Joi.string().valid(...TRADE_TYPES).required(),
  nextDayPriced: Joi.boolean(),
  legs: Joi.array().items(Joi.object({
    number: Joi.number().positive().required(),
    price: Joi.object({
      amount: Joi.number().required(),
      unit: Joi.string().valid(...PRICE_UNITS).required()
    }),
    buyer: brokerValidator.append({
      company: getCompanyValidator('seller')
    }),
    seller: brokerValidator.append({
      company: getCompanyValidator('buyer')
    })
  })).required(),
  quantity: Joi.object({
    amount: Joi.number().required(),
    unit: Joi.string().valid(...QUANTITY_UNITS).required().messages({ 'any.only': 'Selected option is invalid' })
  }),
  clearing: Joi.object({
    cleared: Joi.boolean().allow(null),
    house: Joi.string().when('/clearing.cleared', {
      is: true,
      then: Joi.string().required(),
      otherwise: Joi.string().when('/clearing.id', {
        is: '',
        then: Joi.string().optional().allow(''),
        otherwise: Joi.string().required(),
      }),
    }),
    id: Joi.string().when('/clearing.cleared', {
      is: true,
      then: Joi.string().required(),
      otherwise: Joi.string().when('/clearing.house', {
        is: '',
        then: Joi.string().optional().allow(''),
        otherwise: Joi.string().required(),
      }),
    }),
  }),
  comments: Joi.string().optional().allow('')
}).unknown(true).messages(VALIDATION_MESSAGES);

const tradeValidatorCompanyOnly = Joi.object({
  buyer: Joi.object({ company: getCompanyValidator('seller').optional().allow('') }).unknown(true),
  seller: Joi.object({ company: getCompanyValidator('buyer').optional().allow('') }).unknown(true),
}).unknown(true).messages(VALIDATION_MESSAGES);

export const getValidator = (validateAll: boolean): Joi.ObjectSchema<any> => {
  return validateAll ? tradeValidator : tradeValidatorCompanyOnly;
};