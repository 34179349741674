import { useMediaQuery } from "react-responsive";

import Ribbon from "../Ribbon";

import './MobileVersionInDevelopment.scss'

export default function MobileVersionInDevelopmentRibbon(): JSX.Element {
    const isMobile = useMediaQuery({ query: "(max-width: 960px)" });

    if (!isMobile) {
		//	TODO - we'll re-activate this later
        // return <></>;
    }

    return <Ribbon
        className="mobile-version-in-development-ribbon"
        icon="iconoir-tools"
        severity="info"
        text="This part of OBX is still under active development and hasn't yet be optimised for mobile devices"
    />
}
