import type { SuggestionItem } from 'components/Autocomplete';
import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';
import type {
  WorksheetMetaProps,
  WorksheetSearchField,
} from 'components/Worksheets/Models/WorksheetResponse';

import {
  DateTimeSearchRequest,
  SearchRequest,
  SearchRequestPaged,
} from '../../../Models/ReportsRequest';

import { notNil } from 'helpers/Utils/misc';
import { additionalSearchPropParser } from 'modules/CargoTracker/Components/CargoSearch/Models/Parsers';
import type { SuggestionResponse } from 'modules/CargoTracker/Components/GroupedSearch';
import type { SearchRequestFields } from 'modules/CargoTracker/Models/CargoTrackerRequest';

export type SuggestionResponseSurveillanceSearch = SuggestionResponse & {
  Source?: string;
  mapper?: (item: SuggestionResponseSurveillanceSearch) => SuggestionResponseSurveillanceSearch & SuggestionItem;
}

// fieldsParser and hydrator are part of required parsers for useLoadWorksheet, for now not used in this case so dummy f-n
export const emptyFieldsParser = (fields: WorksheetSearchField[]): WorksheetSearchField[] => fields;
export const emptyHydrator = (): WorksheetMetaProps[] => [];

export const searchWorksheetParsers = {
  propsParser: additionalSearchPropParser,
  fieldsParser: emptyFieldsParser,
  hydrator: emptyHydrator,
};

export const groupedEntityMapper = (group: string): ((item: SuggestionResponseSurveillanceSearch) => SuggestionItem) =>
  ((item: SuggestionResponseSurveillanceSearch): SuggestionItem => ({
    ...item,
    label: item.value,
    name: item.value,
    group: group,
  }));

export const selectedItemsMapper = (
  field: SearchRequestFields | SuggestionItem,
  group: string
): SuggestionItem => ({
  ...field,
  group: group,
  searchTerm: field.searchTerm || undefined,
  name: field.searchTerm || '',
  value: field.searchTerm || undefined,
  label: field.searchTerm,
});

// Check if search object fields empty except some unnecessary fields
export const isSearchRequestEmpty = (
  items: SearchRequestPaged | SearchRequest
): boolean => {
  const { pageNumber, pageSize, cldd, worksheetId, ...itemsFiltered } =
    items as SearchRequestPaged;
  return Object.values(itemsFiltered).every(
    el => !notNil(el) || (Array.isArray(el) &&
      el.filter(srf => srf.searchField !== EntitySearchFieldsEnum.SurveillanceReportState).length === 0)
  );
};

// Check if 2 search requests have same params
export const isSearchItemsEqual = (oldItems?: SearchRequest, newItems?: SearchRequest): boolean => {
  let isDateEqual = false;
  const isAdditionalFiltersEqual =
    oldItems?.onlyAttachments === newItems?.onlyAttachments &&
    oldItems?.onlyEmpty === newItems?.onlyEmpty;
  let isSrfEqual = false;

  // Check date fields
  if (oldItems?.date && newItems?.date) {
    isDateEqual = Object.entries(oldItems.date).every((el):boolean => {
      return el[1] === newItems?.date?.[el[0] as keyof DateTimeSearchRequest];
    });
  } else if (oldItems?.date === newItems?.date) {
    isDateEqual = true;
  }

  const oldSRF = oldItems?.searchRequestFields;
  const newSRF = newItems?.searchRequestFields;

  // Compare Search Request Fields (SRF) arrays
  if (oldSRF && newSRF && oldSRF.length === newSRF.length && oldSRF.length > 0) { // If same Array length -> compare content
    isSrfEqual = oldSRF.every((el, index):boolean => {
      return el.searchField === newSRF[index]?.searchField &&
             el.searchTerm === newSRF[index]?.searchTerm;
    });
  } else if (oldSRF === newSRF || (oldSRF?.length === 0 && newSRF?.length === 0)) { // Both undefined or both empty array
    isSrfEqual = true;
  } // else default false -> not equal (different arrays or one of them undefined)

  const isBatchEqual = oldItems?.batchId === newItems?.batchId;

  return isDateEqual && isAdditionalFiltersEqual && isSrfEqual &&  isBatchEqual;
};
