import clsx from 'clsx';
import { Button } from 'primereact/button';

import { MetadataViewProps } from '../../Models';
import { useStoreCopiedDetails } from '../../Services/SurveillanceDetailsAPI';

import styles from './MetadataView.module.scss';

const MetadataView = ({
  className,
  metadata,
  record,
  toastRef,
  topRibbon,
}: MetadataViewProps): JSX.Element => {
  const { trigger: storeCopiedDetails } = useStoreCopiedDetails();

  const copyToClipBoard = async (
    value: string,
    fieldName?: string
  ): Promise<void> => {
    await navigator.clipboard.writeText(value);

    toastRef?.current?.replace({
      title: 'Record ID copied to clipboard',
      message: 'You can now paste this into place you want',
    });

    if (fieldName) {
      const { id, partitionKey, provider: providerName } = record;

      storeCopiedDetails({
        fieldName,
        entity: { id, partitionKey, providerName },
      });
    }
  };

  return (
    <div className={clsx(styles.metadataContainer, className)}>
      {topRibbon}
      {metadata && (
        <div className={styles.metadataContent}>
          {metadata['record id'] && (
            <div className={styles.metadataOneContainerItem}>
              <div>
                <dt>Record ID</dt>
                <div className={styles.metadataRecordIdContentRow}>
                  <dd>{metadata['record id']}</dd>
                  <Button
                    className={styles.metadataCopyButton}
                    onClick={(): Promise<void> =>
                      copyToClipBoard(metadata['record id'], 'record id')
                    }
                    text
                    size='small'
                    icon='iconoir-multiple-pages icon--medium'
                  />
                </div>
              </div>
            </div>
          )}
          <div className={styles.metadataTwoContainerItems}>
            {metadata['utc offset'] && (
              <div>
                <dt>UTC offset</dt>
                <dd>{metadata['utc offset']}</dd>
              </div>
            )}
            {metadata.group && (
              <div>
                <dt>Group</dt>
                <dd>{metadata.group}</dd>
              </div>
            )}
            {metadata['call status'] && (
              <div>
                <dt>Call status</dt>
                <dd>{metadata['call status']}</dd>
              </div>
            )}
            {metadata['stop reason'] && (
              <div>
                <dt>Stop reason</dt>
                <dd>{metadata['stop reason']}</dd>
              </div>
            )}
            {metadata.region && (
              <div>
                <dt>Region</dt>
                <dd>{metadata.region}</dd>
              </div>
            )}
            {metadata.type && (
              <div>
                <dt>Type</dt>
                <dd>{metadata.type}</dd>
              </div>
            )}
            {metadata['counterpart country'] && (
              <div>
                <dt>Counterpart Country</dt>
                <dd>{metadata['counterpart country']}</dd>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export { MetadataView };
export default MetadataView;
