import { ReactNode } from 'react';
import clsx from 'clsx';
import { VirtualScroller, VirtualScrollerTemplateOptions } from 'primereact/virtualscroller';

import Loader from 'components/Loader/Loader';

import type { CargoMovementSearchResult } from 'modules/CargoFlows/Models/CargoMovements';
import type { ColumnBodyOptions } from 'primereact/column';
import type F from 'types/generic-type';
import type { DataConfig, DataConfigItem } from './CargoDataTable';

import styles from './CargoDataGridView.module.scss';

interface CargoDataGridViewProps {
	config: DataConfig;
    data: CargoMovementSearchResult[];
    isLoading: boolean;
	emptyMessage: string;
}

export default function CargoDataGridView (props: CargoDataGridViewProps): JSX.Element {
    const { config, data, emptyMessage, isLoading } = props;

	const itemTemplate = (item: CargoMovementSearchResult, options: VirtualScrollerTemplateOptions): ReactNode => {
		return <div key={options.index} className={styles.gridViewItems}>
			<GridViewItem className={styles.vessel} data={item} { ...config.vessel } />
			<GridViewItem className={styles.owner} data={item} { ...config.owner } />
			<GridViewItem className={styles.commodity} data={item} { ...config.category } />
			<GridViewItem className={styles.size} data={item} { ...config.quantity } />
			<GridViewItem className={styles.charterer} data={item} { ...config.charterer } />
			<GridViewItem className={styles.loadPort} data={item} { ...config.loadPort } />
			<GridViewItem className={styles.loadTime} data={item} { ...config.loadTime } />
			<GridViewItem className={styles.discPort} data={item} { ...config.dischargePort } />
			<GridViewItem className={styles.discTime} data={item} { ...config.dischargeTime } />
			<GridViewItem className={styles.arrival} data={item} { ...config.supplemental } />
			<GridViewItem className={styles.probability} data={item} { ...config.probability } />
		</div>
	};

	return <VirtualScroller
		contentTemplate={data.length ? undefined : <div>{emptyMessage}</div>} // use this to display empty message
		className={clsx(styles.gridView, 'overflow--y direction--column no--background')}
		items={data}
		itemSize={275}
		itemTemplate={itemTemplate}
		key={String(isLoading)} // not sure why but VirtualScroller doesn't update on "loading" nor "showLoader" props change
		showLoader={isLoading}
		loading={isLoading}
		loadingIcon={<Loader />}
		scrollHeight={isLoading ? '100%' : '100vh'}
	/>
}

interface GridViewItemProps extends DataConfigItem {
	className: string;
    data: CargoMovementSearchResult;
    renderer: (...args: any) => ReactNode;
    otherProps?: F<string | boolean>;
};

function GridViewItem(props: GridViewItemProps): JSX.Element {
	const { className, data, field, label, labelMobile, otherProps, renderer } = props;

	return <div className={clsx('direction--column', className)}>
		<label className={styles.label}>{ labelMobile ?? label }</label>
		{ renderer(data, { field } as ColumnBodyOptions, otherProps) }
	</div>
}