import { useEffect, useRef, useState, type FocusEvent } from 'react';
import {
  AutoComplete,
  type AutoCompleteChangeEvent,
  type AutoCompleteCompleteEvent,
  type AutoCompleteSelectEvent
} from 'primereact/autocomplete';
import clsx from 'clsx';

import { getAutocompleteValue } from 'components/Autocomplete/Helpers';
import RelativeAutocompleteItemTemplate from 'modules/Blotter/Components/TradeDetails/Templates/RelativeAutocompleteItem';

import type { SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';

export type RelativeAutocompleteSuggestionItem = SearchSuggestionsResponse & { IsLastUsed?: 'true'; };

interface RelativeAutocompleteProps {
  id: string;
  items: RelativeAutocompleteSuggestionItem[];
  label: string;
  onChange: (value: string | undefined) => void;
  onFocus: (e: FocusEvent<HTMLInputElement>) => void;
  placeholder: string;
  value: string;
  disabled?: boolean;
  error?: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

const RelativeAutocomplete = (props: RelativeAutocompleteProps): JSX.Element => {
  const {
    disabled,
    error,
    id,
    items,
    label,
    onBlur: onBlurMethod,
    onChange: onChangeMethod,
    onFocus: onFocusMethod,
    placeholder,
    value
  } = props;

  const [filteredItems, setFilteredItems] = useState<RelativeAutocompleteSuggestionItem[]>([]);
  const autocompleteRef = useRef<AutoComplete>(null);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  const onClear = (): void => {
    setFilteredItems(items);
    autocompleteRef.current?.show();
  };

  const onFocus = (e: FocusEvent<HTMLInputElement>): void => {
    onFocusMethod(e);
    autocompleteRef.current?.show();
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>): void => {
    onBlurMethod && onBlurMethod(e);
  };

  const onChange = (e: AutoCompleteSelectEvent | AutoCompleteChangeEvent): void =>
    onChangeMethod(getAutocompleteValue(e.value));

  const filter = (e: AutoCompleteCompleteEvent): void =>
    setFilteredItems(e.query.trim().length ?
      items.filter((item) => item.value.toLowerCase().includes(e.query.toLowerCase())) :
      items
    );

  return <div className='form-input__container'>
    <label htmlFor={id}>{label}</label>
    <AutoComplete
      ref={autocompleteRef}
      inputId={id}
      panelClassName={clsx({
        'autocomplete-entity': filteredItems.length,
        'empty': !filteredItems.length,
      })}
      appendTo='self'
      completeMethod={filter}
      delay={0}
      disabled={disabled}
      inputClassName={error ? 'p-invalid' : ''}
      itemTemplate={(item: RelativeAutocompleteSuggestionItem) => RelativeAutocompleteItemTemplate(item, value)}
      onChange={onChange}
      onClear={onClear}
      onFocus={onFocus}
      onBlur={onBlur}
      onSelect={onChange}
      placeholder={placeholder}
      suggestions={filteredItems}
      value={value}
    />
    {error && <small className='message-invalid'>{error || 'Required field'}</small>}
  </div>;
};

export default RelativeAutocomplete;