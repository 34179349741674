import LegHeader from './LegHeader';
import LegParameters from './LegParameters';
import LegFooter from './LegFooter';

import { WAYPOINT_TYPE_TO_GROUP_MAP } from 'modules/DistanceCalc/Models/Consts';

import type { CalculationWaypoint } from 'modules/DistanceCalc/Models/CalculationResponse';
import type { CalculationRequest } from 'modules/DistanceCalc/Models/CalculationRequests';
import type { CalcParamChange } from 'modules/DistanceCalc/Models/CalcParamsChange';

import './Leg.scss';

const getLegType = (type: CalculationWaypoint["type"]): string => {
    return WAYPOINT_TYPE_TO_GROUP_MAP[type];
}

interface LegProps {
    calcParams: CalculationRequest,
    callback: (args: CalcParamChange) => void;
    hideDetails?: boolean;
    waypoint: CalculationWaypoint;
}

export default function Leg (props: LegProps): JSX.Element | null {
    const { calcParams, callback, hideDetails, waypoint } = props;
    const { duration, legDistance, location, type } = waypoint;

    return <div className="leg__container">
        <LegHeader
            icon={location.countryCode}
            label={location.name}
            sublabel={getLegType(type)}
        />
        {!hideDetails && <>
          <LegParameters calcParams={calcParams} callback={callback} waypoint={waypoint} />
          <LegFooter distance={legDistance} time={duration} />
        </>}
    </div>
}