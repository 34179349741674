import type { SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';

/**
 * In Autocomplete component when 'allowCustomSelection' prop is enabled search suggestion might be a plain text.
 * Check the type and return string representation of value.
 * @param { SearchSuggestionsResponse | string | undefined } change Autocomplete suggestion
 * @returns { string | undefined } Value of selected item or undefined
 */
export const getAutocompleteValue = (change?: SearchSuggestionsResponse | string): string | undefined =>
  typeof change === 'string' ? change : change?.value;
