import { ReactNode } from 'react';
import { type Location, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from 'primereact/button';

import { type ProfileResult } from 'components/OBXUser/Model/ProfileResult';
import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';

import styles from './AccessWrapper.module.scss';

interface AccessWrapperParams {
  children?: ReactNode;
  hasAccess?: boolean;
  buttonLabel?: string;
  onButtonClick?: (location?: Location, user?: ProfileResult) => void;
}

const AccessWrapper = ({
  children,
  hasAccess,
  buttonLabel,
  onButtonClick,
}: AccessWrapperParams): JSX.Element => {
  const { obxuser } = useLoggedInUser();
  const location = useLocation();

  if (hasAccess === false) {
    return (
      <div className='grow-to-fill direction--row align-items--center'>
        <div className='grow-to-fill direction--column align-items--center'>
          <section
            className={clsx(styles.section, 'grow-to-fill overflow--hidden')}
          >
            <img alt='' src='/img/icons/page-warning.svg'></img>
            <h2 className={clsx(styles.header, 'no-background')}>
              You don&apos;t have access to this page. Please request
              authorization from the original author
            </h2>
            {onButtonClick && (
              <Button
                className={clsx(styles.button, 'p-button-filled')}
                onClick={(): void => onButtonClick(location, obxuser)}
              >
                {buttonLabel}
              </Button>
            )}
          </section>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export { AccessWrapper };
export default AccessWrapper;
