import { NavigateFunction, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { useSaveUserSetting, useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';
import { UISettings } from 'components/OBXUser/Model/Enums';

import BalticOrVortexa from '../Templates/BalticOrVortexa';
import { BalticOrVortexaApi } from '../Services/BalticOrVortexaRatesAPI';
import { GridProvider } from '../Grids/Models/GridProvider';

import type { RatesConfig } from 'index';
// import type { IAvailableRategrid } from '../Grids/Models/RateGrid';
import { AvailableRateGridsResponse } from '../Grids/Models/RateGridResponse';

function VortexaRates(args: { items: RatesConfig[] }) {

    const { items } = args;
    const { data } = useSWR(GridProvider.VortexaRatesClient, BalticOrVortexaApi.getAvailableFeeds);
    // TODO: handle loading & error state
    const navigate: NavigateFunction = useNavigate();
    const { trigger } = useSaveUserSetting();
    const { getSetting } = useLoadUserSettings();
    const lastVisitedGrid = getSetting(UISettings.LAST_VISITED_VORTEXA_FREIGHT_GRID) as AvailableRateGridsResponse | undefined;

    const switchDisplayedRate = (rate: AvailableRateGridsResponse) => {
        navigate(`/rates/vortexa/${rate.name}`);

        if (rate.name !== lastVisitedGrid?.name) {
            trigger({
                setting: UISettings.LAST_VISITED_VORTEXA_FREIGHT_GRID,
                data: rate,
            });
        }
    }

    return <BalticOrVortexa
        data={data}
        items={items}
        lastVisitedGrid={lastVisitedGrid}
        provider={GridProvider.VortexaRatesClient}
        switchDisplayedRate={switchDisplayedRate}
    />
}

export default VortexaRates;
