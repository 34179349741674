import styles from './LegHeader.module.scss';

interface LegHeaderProps {
  collapsed: boolean;
  title: string;
  toggle: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const LegHeader = (props: LegHeaderProps): JSX.Element => {
  const { collapsed, title, toggle } = props;

  return <div className={styles.legHeader}>
    <div onClick={toggle}>
      <i className={`iconoir-nav-arrow-${collapsed ? 'right' : 'down'} icon--medium`} />
      <span>{title}</span>
    </div>
  </div>;
};

export default LegHeader;