import { useState } from 'react';
import { Button } from 'primereact/button';
import clsx from 'clsx';

import WorkflowEmptyPage, { EmptyPageMode } from '../EmptyPage';

interface NoWorkflowSelectedProps {
  closePanel: () => void;
}

export default function NoWorkflowSelected(props: NoWorkflowSelectedProps): JSX.Element {
  const { closePanel } = props;
  const [selectedTab, setSelectedTab] = useState<'progress' | 'info'>('progress');

  return <div className='direction--column grow-to-fill'>
    <nav>
      <div>
        <Button
          size='small'
          className={clsx('p-button--tab-style', { 'active': selectedTab === 'progress' })}
          onClick={() => setSelectedTab('progress')}
        >
          Progress
        </Button>
        <Button
          size='small'
          className={clsx('p-button--tab-style', { 'active': selectedTab === 'info' })}
          onClick={() => setSelectedTab('info')}
        >
          Info
        </Button>
      </div>
      <Button text icon='iconoir-xmark icon--tiny p-button-icon-only'
        className='close-button'
        onClick={closePanel}
      />
    </nav>
    <WorkflowEmptyPage mode={EmptyPageMode.NoWorkflowSelected} />
  </div>;
}