import { useState, useEffect } from 'react';
import useSWR from 'swr';

import { DataTable, DataTableSelectionSingleChangeEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { BalticOrVortexaApi } from '../../../Services/BalticOrVortexaRatesAPI';
import { BalticRatesResponse, BalticRateDTO } from '../../../Grids/Models/BalticRatesListResponse';

import Loader from 'components/Loader';
import { Currency } from 'helpers/DataTable/Templates/ColumnTemplates';

import type { GridProvider } from 'modules/Rates/Grids/Models/GridProvider';

import './RateTable.scss';

interface IArgs {
  changeRate: Function;
  gridProvider: GridProvider;
  isMobile: Boolean;
  market?: string;
}

interface IBalticRatesSWRResponse {
  data: BalticRatesResponse | undefined,
  error: any,
  isLoading: Boolean
}

function RateTable(args: IArgs) {

  const { gridProvider, market, changeRate, isMobile } = args;
  // @ts-ignore
  const { data, error, isLoading }: IBalticRatesSWRResponse = useSWR({ market, provider: gridProvider }, BalticOrVortexaApi.getRatesByMarket);
  const [ selectedRate, setSelectedRate ] = useState<BalticRateDTO>();

  /*
  const handleSelectedItem = (r: string) => {
    changeRate(r);
  }
  */

  const onSelectionChange = (e: DataTableSelectionSingleChangeEvent<BalticRateDTO[]>) => {
    const { value } = e;
    setSelectedRate(value as BalticRateDTO);
  }

  useEffect(() => {
    if (selectedRate) changeRate(selectedRate.id);
    // eslint-disable-next-line
  }, [selectedRate]);


  if (error) {
    return <>
      <div className="error-message">
        It appears something has gone wrong. Please get in touch with the support team on <a href="mailto:support@obxlive.com">support@obxlive.com</a>
      </div>
    </>
  }

  if (isLoading) {
    return <Loader />
  }

  return <>
    { data &&
      <DataTable
        value={data.results}
        className="baltic-rates-table grow-to-fill"
        dataKey="id"
        removableSort
        scrollable scrollHeight="flex"
        selection={selectedRate}
        selectionMode="single"
        onSelectionChange={onSelectionChange}
      >
        <Column
          field="code"
          header="Index"
          sortable
        />
        <Column
          field="unit"
          header={isMobile ? "Curr" : "Currency"}
          sortable
        />
        <Column
          field="currentValueRaw"
          header="Price"
          sortable
          body={Currency}
        />
        <Column
          field="currentPriceTime"
          header="Last Updated"
        />
      </DataTable>
    }
  </>;
}

export default RateTable;
