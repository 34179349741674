import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'primereact/button';

import AdditionalFilters from 'components/AdditionalFilters';
import DateTimeRange from 'components/DateTimeRange';
import { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';
import QuantityParser from 'components/QuantityParser/QuantityParser';
import { ParsedRangeResult } from 'components/QuantityParser/Services/QuantityParserAPI';
import { WorksheetSignalMessageEventTypes, WorksheetStores } from 'components/Worksheets/Models/Enums';
import { WorksheetMetaProps } from 'components/Worksheets/Models/WorksheetResponse';
import {
  useLoadWorksheet,
  useMutateWorksheet,
  WorksheetMutationTypes
} from 'components/Worksheets/Services/WorksheetHooks';

import {
  AssignedUserCargoSearchRequest,
  CargoSearchRequest,
  CargoUpdateResponse,
  SearchRequestFields,
} from '../../Models/CargoTrackerRequest';
import {
  CargoTrackerResponse,
  PetroleumProductEnum
} from '../../Models/CargoTrackerResponse';
import { useSearchCargo } from '../../Services/CargoTrackerAPI';
import { CargoTrackerSignalEventTypes } from '../../Services/SignalRSocket';
import CargoSearchAssigneeFilter from '../CargoSearchAssigneeFilter';
import CargoSearchCdFilter from '../CargoSearchCdFilter';

import { DEFAULT_SEARCH_ITEMS } from './Models/Consts';
import {
  additionalSearchPropParser,
  cargoSearchWorksheetParsers,
  getAdditionalPropsAsSearchItems,
  getAdditionalPropsParsed,
} from './Models/Parsers';
import CargoSearchEntity from './CargoSearchEntity';

import { removeItemAt } from 'helpers/Utils/collections';
import { laycanStyleRange } from 'helpers/Utils/formatters';

import eventBus from 'server/EventBus';

import './CargoSearch.scss';

interface ICargoSearchProps {
  setSearchData: Dispatch<SetStateAction<CargoTrackerResponse[] | undefined>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  activeWorksheet: string;
}

const CargoSearch = (props: ICargoSearchProps):JSX.Element => {
  const { setSearchData, setIsLoading, activeWorksheet } = props;
  const [ searchItems, setSearchItems ] = useState<CargoSearchRequest>(DEFAULT_SEARCH_ITEMS);  
  const [ isClearDisabled, setIsClearDisabled ] = useState<boolean>(false);
  const [ isParsingData, setIsParsingData ] = useState<boolean>(false);
  const [ isWorksheetLoaded, setIsWorksheetLoaded ] = useState<boolean>(false);
  const [ isWorksheetMutated, setIsWorksheetMutated ] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  
  // Update search parameters from each search component - on mobile store it in state var and apply after "show results" click
  const mutateSearchItems = useCallback((mutation: Partial<CargoSearchRequest>): void => {
    setSearchItems(c => ({ ...c, ...mutation }));
  }, []);

  // Worksheet data load
  const { data, error: isLoadingWorksheetError, isLoading: isLoadingWorksheet } = useLoadWorksheet(
    WorksheetStores.CargoTracker,
    activeWorksheet,
    cargoSearchWorksheetParsers
  );

  // Worksheet mutate loader
  const { worksheet, mutateWorksheet, mutateAdditionalProps, isMutating } = useMutateWorksheet(
    WorksheetStores.CargoTracker,
    activeWorksheet
  );

  // Mutate worksheet with data
  useEffect(() => {
    if (!data || data.store !== WorksheetStores.CargoTracker) {
      if (data) {
        setIsWorksheetLoaded(true);
      }
      return;
    }
    mutateWorksheet({type: WorksheetMutationTypes.Full, payload: data});
    setIsWorksheetMutated(true);
    eventBus.dispatch(
      WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED,
      { worksheetId: data.worksheetId, name: data.name }
    );
  }, [data, mutateWorksheet, activeWorksheet]);

  // Load stored searchItems from worksheet
  useEffect(() => {
    // If there is no worksheet or data has been already restored -> exit
    if (!worksheet?.additionalSearchProperties) {
      if (!isLoadingWorksheet && isWorksheetMutated) {
        setIsWorksheetLoaded(true);
      }
      return;
    }

    // Directly after saving to worksheet value is JSON string so checking and parsing this
    const props: WorksheetMetaProps[] = additionalSearchPropParser(worksheet.additionalSearchProperties);

    // Load Additional Search Props Into searchItems
    const searchItemsFromWorksheet = getAdditionalPropsAsSearchItems(props);

    // Mutate searchItems with loaded Additional Search Props
    setSearchItems(c => ({ ...c, ...searchItemsFromWorksheet }));
    setIsWorksheetLoaded(true);

  }, [isLoadingWorksheet, isWorksheetMutated, isMobile, mutateSearchItems, worksheet]);
  // Handle actual search -> request API
  const { searchResults, searchError, searchIsLoading, searchMutate } = useSearchCargo(isWorksheetLoaded ? searchItems : null);

  // Update loading in CargoTrackerPage
  useEffect(() => {
    setIsLoading(searchIsLoading);
  }, [searchIsLoading, setIsLoading, setSearchData]);

  // Check results and send searchData to CargoTrackerPage
  useEffect(() => {
    if (searchResults === undefined) {
      setSearchData(searchIsLoading ? [] : undefined);
      return;
    }

    setSearchData(searchResults);
  }, [searchIsLoading, searchItems, searchResults, setSearchData]);

  const handleSignalRUpdate = useCallback((event: CustomEvent<CargoUpdateResponse>):void => {
    searchMutate(); // Invalidate useSearchCargo swr
  }, [searchMutate]);

  const handleSignalRDelete = useCallback((event: CustomEvent<{ cargoId: string; }>): void => {
    const { cargoId } = event.detail;
    const index = searchResults?.findIndex(i => i.id === cargoId) ?? -1;

    if (index > -1) {
      searchMutate(removeItemAt(searchResults ?? [], index)); 
    }
  }, [searchMutate, searchResults]);

  useEffect(() => {
    eventBus.on(
      CargoTrackerSignalEventTypes.CARGO_TRACKER_CHANGED,
      handleSignalRUpdate
    );
    eventBus.on(
      CargoTrackerSignalEventTypes.CARGO_TRACKER_DELETED,
      handleSignalRDelete
    );

    return () => {
      eventBus.remove(
        CargoTrackerSignalEventTypes.CARGO_TRACKER_CHANGED,
        handleSignalRUpdate
      );
      eventBus.remove(
        CargoTrackerSignalEventTypes.CARGO_TRACKER_DELETED,
        handleSignalRDelete
      );
    };

  }, [handleSignalRUpdate, handleSignalRDelete]);

  // Laycan search handler - after value is added in laycan input and parsed - store in searchItems and in Worksheet
  const handleLaycanParsed = (m: ParssedDateTimeResult):void => {
    const laycanValue = {
      original: laycanStyleRange(m.from, m.to),
      fromDate: m.fromString,
      toDate: m.toString
    };
    mutateSearchItems({ laycan: laycanValue });
    handleMutateAdditionalProps('laycan', laycanValue);
    setIsParsingData(false);
  };

  // Handle when Laycan input is cleared
  const handleLaycanEmpty = ():void => {
    mutateSearchItems({ laycan: undefined });
    handleMutateAdditionalProps('laycan', undefined);
    setIsParsingData(false);
  };

  // Quantity search handler - after value is added in quantity input and parsed - store in searchItems and in Worksheet
  const handleQuantityParsed = (m?: ParsedRangeResult):void => {
    const quantityValue = m ? {
      original: m.original,
      from: m.from,
      to: m.to,
    } : undefined;
    mutateSearchItems({ quantity: quantityValue });
    handleMutateAdditionalProps('quantity', quantityValue);
    setIsParsingData(false);
  };

  // Handle Additional filters change (C/D + Assignee)
  const onAdditionalFilterChange = (
    groupName: keyof CargoSearchRequest,
    value?: AssignedUserCargoSearchRequest | PetroleumProductEnum
  ): void => {
    let newValue;

    switch(groupName){
    case 'petroleumProductType':
      newValue = value as PetroleumProductEnum;
      break;
    case 'assignedUser':
      newValue = value as AssignedUserCargoSearchRequest;
      break;
    }

    mutateSearchItems({ [groupName]: newValue });
    handleMutateAdditionalProps(groupName, newValue);
    setIsParsingData(false);
  };

  // Handle main search field change (Cargo Code, Charterer, Load/Discharge, Commodity)
  const handleSearchEntityCallback = async (fields: SearchRequestFields[]): Promise<void> => {
    mutateSearchItems({ searchRequestFields: fields });
    handleMutateAdditionalProps('searchRequestFields', fields);
  };

  // Handle when external chip/token will be removed (C/D, Assignee, Laycan, Quantity)
  const handleSelectedExternalItemChange = (removedElement: keyof CargoSearchRequest): void => {
    mutateSearchItems({ [removedElement]: undefined } );
    handleMutateAdditionalProps(removedElement, undefined);
  };

  // Handle clear button press - clear all search items and also search data in CargoTrackerPage
  const handleClear = (): void => {
    setSearchItems(c => ({ ...c, ...DEFAULT_SEARCH_ITEMS }));
    setSearchData(undefined);
    mutateAdditionalProps([]);
  };

  // Handle Additional Props change
  const handleMutateAdditionalProps = useCallback((field: string, value: unknown): void => {
    mutateAdditionalProps(getAdditionalPropsParsed(field, value, worksheet?.additionalSearchProperties));
  }, [mutateAdditionalProps, worksheet]);

  // Signal to user if it's parsing / loading / mutating and prevent clear when action is ongoing
  const loadingOngoing: boolean = searchIsLoading || isParsingData || isLoadingWorksheet || isMutating || !!isLoadingWorksheetError || !!searchError;

  return <>
    <CargoSearchEntity
      callback={handleSearchEntityCallback}
      searchItems={searchItems}
      handleSelectedExternalItemChange={handleSelectedExternalItemChange}
      className={loadingOngoing && 'search-ongoing'}
    />
    <DateTimeRange
      placeholder='Laycan/Delivery Date'
      onEmptyValue={handleLaycanEmpty}
      onDateParseError={():void => setIsParsingData(false)}
      onDateParsed={handleLaycanParsed}
      onParsingStart={():void => setIsParsingData(true)}
      onFocus={():void => setIsClearDisabled(true)} // disable clear on input focus as there is issue when loosing input focus and clicking "clear" - #1622 - other option would be to handleClear on mouseDown and prevent other inputs saving content
      onBlur={():void => setIsClearDisabled(false)}
      defaultValue={searchItems.laycan?.original ?? ''}
      showErrorMessage={true}
      required={false}
    />
    <div className="form-input__container">
      <QuantityParser
        placeholder="Quantity/Vessel size"
        defaultValue={searchItems.quantity?.original ?? ''}
        onRangeParsed={handleQuantityParsed}
        onParsingStart={():void => setIsParsingData(true)}
        onDateParseError={():void => setIsParsingData(false)}
        onFocus={():void => setIsClearDisabled(true)}
        onBlur={():void => setIsClearDisabled(false)}
        addonText=""
        showError={true}
        required={false}
      />
    </div>
    <div className="cargo-search-buttons--container">
      <AdditionalFilters className="cargo-search-additional-filters" isMobileMode={false}>
        <CargoSearchCdFilter activeFilter={searchItems.petroleumProductType} onChange={onAdditionalFilterChange} />
        <CargoSearchAssigneeFilter activeFilter={searchItems.assignedUser} onChange={onAdditionalFilterChange} />
      </AdditionalFilters>
      <Button
        text
        size="small"
        disabled={isClearDisabled || loadingOngoing}
        onClick={handleClear}
      >
        Clear
      </Button>
    </div>
  </>;
};

export default CargoSearch;
