export async function convertSvgToImg(
  svg: string,
  type: string = 'image/png'
): Promise<Blob | null> {
  const svgUrl = URL.createObjectURL(
    new Blob([svg], { type: 'image/svg+xml' })
  );

  return new Promise(resolve => {
    const svgImage = new Image();

    svgImage.style.position = 'absolute';
    svgImage.style.top = '-200%';
    document.body.appendChild(svgImage);

    svgImage.onload = function () {
      const canvas = document.createElement('canvas');

      canvas.width = svgImage.width;
      canvas.height = svgImage.height;
      canvas.style.position = 'absolute';
      canvas.style.top = '-200%';
      document.body.appendChild(canvas);

      const canvasCtx = canvas.getContext('2d') as CanvasRenderingContext2D;
      canvasCtx.drawImage(svgImage, 0, 0);

      canvas.toBlob(resolve, type);

      URL.revokeObjectURL(svgUrl);
      document.body.removeChild(canvas);
      document.body.removeChild(svgImage);
    };

    svgImage.src = svgUrl;
  });
}
