import useSWR from 'swr';


import { asEncoded } from 'helpers/Utils/string';

import { CommoditiesDataAPI } from "./CommoditiesDataAPI";
import { 
	IEAModSources, 
	IEAModSourceData, 
	AvailableModGridResponse, 
	JodiSources } 
from 'modules/Rates/Commodities/Models/IEAMods';
import { HistoricalSearchRequest, HistoricalChartingValues } from 'modules/Rates/Commodities/Models/Historical';
import { optionalProductValidator, mandatoryProductValidator } from 'modules/Rates/Commodities/Models/Validators';


type Feed = 'iea' | 'jodi';

export const useHistoricalData = (feed: Feed, source: IEAModSources | JodiSources, request: HistoricalSearchRequest ) => {
	
	//	create name for SWR cache instance based on hash of request object
	const cachename: string = `historical-${feed}-${source}-${asEncoded(request)}`;

	// Conditional validation based on whether the product property
	// will be provided
	const { error: validRequest } = ( 
		[IEAModSources.BalancesCrude, IEAModSources.BalancesProduct, IEAModSources.BalancesSubProduct, JodiSources.Oil].includes(source) 
			? mandatoryProductValidator
			: optionalProductValidator
	).validate(request);

	//	request will only be processed IF the request object is valid
	let { data, error, isLoading } = useSWR<HistoricalChartingValues[]>(
		cachename,
		validRequest ? null : () => CommoditiesDataAPI.getHistoricalData(feed, source, request),
		{
			revalidateOnFocus: false,
			shouldRetryOnError: false,
		}
	)
	return { data, error, isLoading }
}

export const useAvailableGrids = (feed: Feed) => {
	let { data, error, isLoading } = useSWR<AvailableModGridResponse[]>(
		`${feed}-grids`, 
		() => CommoditiesDataAPI.getAvailableFeeds(feed),
		{
			revalidateOnFocus: false
		}
	);

	if (!data) { data = [] }
 
	return { data, error, isLoading };
}

/**
 * custom hook which will return a data property containing...
 * @param feed 
 * @param source 
 * @param query 
 * @returns { data: IEAModSourceDatap[], error, isLoading: boolean }
 */
export const useGetLatestModData = (feed: Feed, source: IEAModSources | JodiSources, query?: string) => {
	let { data, error, isLoading } = useSWR<IEAModSourceData>(
		`ieamods-${source}-latest${query ? `-${query}` : ''}`,
		() => CommoditiesDataAPI.getLatestModValues(feed, source, query),
		{
			revalidateOnFocus: false,
			errorRetryCount: 0
		}
	)

	return { data, error, isLoading }
}