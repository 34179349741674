import { forwardRef, useImperativeHandle, useRef } from 'react';
import { clsx } from 'clsx';
import { Toast } from 'primereact/toast';

import './ToastMessage.scss';

interface ToastMessageProps {
  ref: ToastMessageRef;
  className?: string;
}

export interface ReplaceFnProps {
  message: string;
  title?: string;
  severity?: ToastSeverity;
}

export enum ToastSeverity {
  SUCCESS = 'success',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error'
}

const TOAST_ICON_MAP: { [key in ToastSeverity]: string } = {
  [ToastSeverity.SUCCESS]: 'pi-check',
  [ToastSeverity.INFO]: 'pi-info-circle',
  [ToastSeverity.WARN]: 'pi-exclamation-triangle',
  [ToastSeverity.ERROR]: 'pi-times-circle'
};

const TOAST_TITLE_MAP: { [key in ToastSeverity]: string } = {
  [ToastSeverity.SUCCESS]: 'Success',
  [ToastSeverity.INFO]: 'Info',
  [ToastSeverity.WARN]: 'Warning',
  [ToastSeverity.ERROR]: 'Error'
};

const TOAST_LIFE_MAP: { [key in ToastSeverity]: number } = {
  [ToastSeverity.SUCCESS]: 1000 * 4, // 4 sec
  [ToastSeverity.INFO]: 1000 * 6, // 6 sec
  [ToastSeverity.WARN]: 1000 * 6, // 6 sec
  [ToastSeverity.ERROR]: 1000 * 6 // 6 sec
};

export type Ref = Toast;
export type ToastMessageRef = {
  replace: (props: ReplaceFnProps) => void;
}

const ToastMessage = forwardRef<ToastMessageRef, ToastMessageProps>((props, ref) => {
  const toast = useRef<Toast>(null);
  useImperativeHandle(ref, ():ToastMessageRef => ({
    replace({ message, title, severity }):void {
      toast.current?.replace({
        severity: severity ?? ToastSeverity.SUCCESS,
        life: TOAST_LIFE_MAP[severity ?? ToastSeverity.SUCCESS],
        content: (
          <>
            <div className={clsx('tm-toast-icon', `tm-toast-icon-${ severity ?? ToastSeverity.SUCCESS }`)}>
              <i className={clsx('pi', TOAST_ICON_MAP[severity ?? ToastSeverity.SUCCESS])}></i>
            </div>
            <div className="tm-toast-message">
              <div className={clsx('tm-toast-summary', `tm-toast-summary-${ severity ?? ToastSeverity.SUCCESS }`)}>
                {title ?? TOAST_TITLE_MAP[severity ?? ToastSeverity.SUCCESS]}
              </div>
              <div className="tm-toast-detail">{message}</div>
            </div>
          </>
        ),
      });
    }

  }));

  return <Toast ref={toast} className={clsx(props.className, 'tm-toast')} />;
});

ToastMessage.displayName = 'ToastMessage';

export default ToastMessage;
