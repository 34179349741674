import {
  FocusEventHandler,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primereact/autocomplete';

import {
  EntitySearchFieldsEnum,
  EntitySearchGroupEnum,
} from 'components/EntitySearch/Models/Enums';
import {
  SearchSuggestionBaseResponse,
  SearchSuggestionsResponse,
} from 'components/EntitySearch/Models/SearchEntities';
import { useSearchSuggestions } from 'components/EntitySearch/Services/SearchEntitiesAPI';
import NotePanel, { NotePanelType } from 'components/NotePanel/NotePanel';
import { UserEntity } from 'components/OBXUser/Model/ProfileResult';
import { useValidateEmails } from 'components/OBXUser/Services/ProfileHooks';

import { stringToEmailAddresses } from 'helpers/Utils/string';

import styles from './UserAutocomplete.module.scss';

type UserSuggestionResponse = SearchSuggestionBaseResponse & {
  EmailAddress?: string;
};
interface UserAutocompleteProps {
  onFilterSuggestions?: (
    item: UserEntity,
    index: number,
    arr: UserEntity[]
  ) => boolean;
  onSelect: (usersAdded: UserEntity[]) => void;
}

const MIN_LENGTH_SUGGESTIONS = 3;
const placeholder = 'Add by name or email';

const UserAutocomplete = ({
  onFilterSuggestions = (): boolean => true,
  onSelect,
}: UserAutocompleteProps): JSX.Element => {
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [autocompleteValue, setAutocompleteValue] = useState<string | null>(
    null
  );
  const [term, setTerm] = useState<string>('');
  const [suggestions, setSuggestions] = useState<UserEntity[]>([]);
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);
  const [invalidEmails, setInvalidEmails] = useState<string[] | undefined>(
    undefined
  );

  const { data: suggestedValues } = useSearchSuggestions<
    any,
    SearchSuggestionsResponse[]
  >({
    module: EntitySearchGroupEnum.Users,
    fields: EntitySearchFieldsEnum.User,
    term,
  });
  useEffect(() => {
    const suggestionsMap = (item: UserSuggestionResponse): UserEntity => ({
      name: item.value,
      id: item.searchEntityId,
      email: item.EmailAddress || '',
    });
    if (term && suggestedValues) {
      setSuggestions(
        suggestedValues.map(suggestionsMap).filter(onFilterSuggestions)
      );
      setTerm('');
    }
  }, [term, suggestedValues, onFilterSuggestions]);

  const emails = autocompleteValue
    ? stringToEmailAddresses(autocompleteValue)
    : null;
  const { data } = useValidateEmails(shouldValidate ? emails?.passed : null);

  useEffect(() => {
    if (shouldValidate) {
      if (autocompleteValue && data) {
        const validEmails = onFilterSuggestions
          ? data?.validEmailsDetails?.filter(onFilterSuggestions)
          : data?.validEmailsDetails;

        validEmails && onSelect(validEmails);

        setInvalidEmails(
          emails?.failed.length || data?.invalidEmailsDetails?.length
            ? [
              ...(emails?.failed || []),
              ...(data?.invalidEmailsDetails || []).map(
                details => details.email
              ),
            ]
            : undefined
        );

        setInputValue(null);
        setAutocompleteValue(null);
        setTerm('');
        setShouldValidate(false);
      }
    }
  }, [
    shouldValidate,
    autocompleteValue,
    data,
    emails,
    onFilterSuggestions,
    onSelect,
  ]);

  const getItemTemplate:
    | ReactNode
    | ((suggestion: UserEntity) => ReactNode) = suggestion => (
      <div className={styles.suggestionItem}>
        <div>{suggestion.name}</div>
        <div>{suggestion.email}</div>
      </div>
    );

  const onInputValueChange = ({
    target: { value },
  }: AutoCompleteChangeEvent): void => {
    setInputValue(value);
  };
  const onComplete = ({ query }: AutoCompleteCompleteEvent): boolean => {
    if (query?.includes('@') || query?.includes(',')) {
      setTerm('');
      setAutocompleteValue(query);
    } else {
      setAutocompleteValue('');
      setTerm(query.length >= MIN_LENGTH_SUGGESTIONS ? query : '');
    }
    return true;
  };
  const onItemSelected = ({ value }: AutoCompleteSelectEvent): void => {
    // clear input on item selected
    setInputValue(null);

    // trigger user selected
    onSelect([value]);
  };
  const onBlur: FocusEventHandler = () =>
    setShouldValidate(Boolean(autocompleteValue));
  const onKeyPress = ({ key }: KeyboardEvent<HTMLInputElement>): boolean => {
    if (key === 'Enter') {
      setShouldValidate(true);
    }
    return true;
  };

  return (
    <>
      {invalidEmails && (
        <NotePanel
          type={NotePanelType.Error}
          header={
            'These accounts don\'t exist, so you can\'t share the report with them'
          }
          body={invalidEmails.map((email, index) => (
            <div key={index}>{email}</div>
          ))}
        />
      )}
      <AutoComplete
        value={inputValue}
        minLength={MIN_LENGTH_SUGGESTIONS}
        suggestions={suggestions}
        itemTemplate={getItemTemplate}
        showEmptyMessage={true}
        emptyMessage='Sorry. No matches for that search…'
        scrollHeight='40dvh'
        placeholder={placeholder}
        onChange={onInputValueChange}
        completeMethod={onComplete}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        onSelect={onItemSelected}
        panelClassName={clsx(
          'autocomplete-entity',
          !suggestions?.length && 'empty'
        )}
      />
    </>
  );
};

export { UserAutocomplete };
export default UserAutocomplete;
