type MoveItemOpts = {
	placeBefore?: boolean;
	failOnInvalidIndex?: boolean;
}

const moveItemOptDefault: MoveItemOpts = {
	placeBefore: true,
	failOnInvalidIndex: false,
}

export const moveItem = <T>(collection: T[], from: number, to: number, opts?: MoveItemOpts): T[] => {
	opts = {...moveItemOptDefault, ...opts };

	if (opts.failOnInvalidIndex && (from < 0 || from > collection.length || to < 0 || to > collection.length)) throw Error('from/to values cant be outside of available range if the opts.failOnInvalidIndex parameter is true')

	if (from < 0) from = 0;
	if (from >= collection.length) from = collection.length - 1;
	if (to >= collection.length) {
		to = collection.length - 1;
		//	Force the new position to be at the very end
		opts.placeBefore = false;
	}
	if (to < 0) opts.placeBefore = true;

	to = (opts.placeBefore)
		? to < from ? to : to - 1
		: to
	
	const item: T = collection[from];

	return insertItemAt(removeItemAt(collection, from), to, item)
}



export const replaceItemAt = <T>(collection: T[], item: any, index: number, replace: boolean = true): T[] => {
    switch (true) {
      case index < 0:
        return [
          item,
          ...(replace ? collection.slice(1) : collection),
        ];
      case index >= 0 && index < collection.length:
        return [
          ...collection.slice(0, index),
          item,
          ...collection.slice(index + 1),
        ];
      default:
        return [
          ...collection,
          item,
        ];
    }
}

export const groupCollection = <T>(collection: T[], callback: (item: T) => any) => {
  return collection.reduce<Record<string, T[]>>((previous, current) => {
    const groupKey = callback(current);
    const group = previous[groupKey] || [];
    group.push(current);
    return { ...previous, [groupKey]: group };
  }, {})
}

/**
 * Given a passed array - return a new collection with the item at a given index
 * removed. If the passed index is greater than the available length then will 
 * remove last item. If index is less than zero then the first item will be removed
 * @param  {any[]} collection - The collection to be mutated
 * @param  {number} index - the index of the item to be removed
 * @return  {any[]} - new mutated collection
 */
export const removeItemAt = <T>(collection: T[], index:number): T[] => {
  switch (true) {
    case index < 0:
      return collection.slice(1);
    case index >= 0 && index < collection.length:
      return [
        ...collection.slice(0, index),
        ...collection.slice(index + 1),
      ];
    default:
      return [
        ...collection.slice(0, collection.length - 1)
      ];
  }
}

/**
 * Given a passed array collection, will return a new collection instance mutated
 * with an additional item inserted at a given index. Non destructive so all items
 * following the insertion point are retained
 * 
 * @param collection - array to be mutated
 * @param index - index in collection the item should be inserted at
 * @param item - item to be inserted
 */
export const insertItemAt = <T>(collection: T[], index: number, item: T): T[] => {
	switch (true) {
		case index === 0:
			return [item, ...collection];
		default:
			return [
				...collection.slice(0, index),
				item,
				...collection.slice(index)
			]
	}
 }

export const removeItemByKeyValue = <T, R>(collection: T[], key:keyof T, value: R): T[] => {
	return collection.filter( i => i[key] !== value);
}


export const convertMapToArray = (input: Map<string, string> | null): any[] => {
	let array: any[] = [];
  if (input) {
		array = Array.from(input.keys() ?? []).map(x => {
			return { key: x, value: input.get(x) };
		});
  }
  return array;
}


export const removeItemByValue = <T>(collection: T[], value: T): T[] => collection.filter( c => c !== value);


/**
 * Iterate over a collection, running an aync await method for each member of the array
 * 
 * @param array 
 * @param callback 
 * @param progress 
 */
export const asyncForEach = async <T>(array: T[], callback: (item:T, index:number, collection: T[]) => void, progress?: ({step, of}: {step: number, of: number}) => void) => {

  for (let i = 0; i < array.length; i++) {
    await callback(array[i], i, array);
    
    if (progress) {
      /** trigger the passed progress method */
      progress({step: i+1, of: array.length});
    }
  }
}


