import type { SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';
import type { TradeType } from 'modules/Blotter/Models/Enums';

import styles from './InstrumentSuggestion.module.scss';

export const InstrumentSuggestion = (item: SearchSuggestionsResponse & { Source: TradeType; }): JSX.Element => {
  return <div className={styles.instrumentSuggestion}>
    <span>{item.value}</span>
    <span>{item.Source}</span>
  </div>;
};

export default InstrumentSuggestion;