import { InputTextarea } from 'primereact/inputtextarea';

import type { ChangeEvent } from 'react';
import type { SectionProps } from 'modules/Blotter/Models/SectionProps';

export function CommentsSection(props: SectionProps): JSX.Element {
  const { mutate, request } = props;

  return <section>
    <header><h2>Comments</h2></header>
    <div className='section--comments position--relative'>
      <InputTextarea
        key={request.id}
        value={request.comments}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => mutate({ comments: e.target.value })}
        rows={3}
      />
    </div>
  </section>;
}