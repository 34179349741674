import clsx from 'clsx';

import AudioPlayer from 'components/AudioPlayer';

import {
  AttachmentItem,
  AttachmentSectionProps,
  AttachmentType,
} from '../../../../Models';

import AttachmentComponent from './AttachmentComponent';

import styles from './AttachmentsSection.module.scss';

const getAttachmentComponent = (
  attachment: AttachmentItem,
  index: number = 0
): React.ReactNode => {
  switch (attachment.type) {
  case AttachmentType.Audio:
    return (
      <AudioPlayer
        key={`${ attachment.fileName }-${ index }`}
        source={attachment.source}
      />
    );
  default:
    return (
      <AttachmentComponent
        key={`${ attachment.fileName }-${ index }`}
        {...attachment}
      />
    );
  }
};

const AttachmentsSection = ({
  className = '',
  header = '',
  data = [],
}: AttachmentSectionProps): JSX.Element => (
  <div className={clsx(styles.container, className)}>
    <label>{header}</label>
    {data && Array.isArray(data)
      ? data.map(getAttachmentComponent)
      : getAttachmentComponent(data)}
  </div>
);

export { AttachmentsSection };
export default AttachmentsSection;
