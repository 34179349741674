import { useCallback,useEffect, useRef, useState } from 'react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';

import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import SingleEntitySearch from 'components/EntitySearch/SingleEntitySearch';
import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';

import { AssignedUserCargoSearchRequest, CargoSearchRequest } from '../../Models/CargoTrackerRequest';
import { SuggestionResponse } from '../GroupedSearch';

import AssignToMe from 'components/AssignToMe';
import { formatName } from 'helpers/Utils/string';

const GROUP_NAME: keyof CargoSearchRequest = 'assignedUser';

interface CargoSearchCdFilterProps {
  activeFilter?: AssignedUserCargoSearchRequest;
  onChange: (groupName: keyof CargoSearchRequest, value?: AssignedUserCargoSearchRequest) => void;
}

const CargoSearchAssigneeFilter = (props: CargoSearchCdFilterProps): JSX.Element => {
  const { activeFilter, onChange } = props;
  const { obxuser } = useLoggedInUser();
  const [value, setValue] = useState<AssignedUserCargoSearchRequest | undefined>(undefined);
  const [inputValue, setInputValue] = useState<string>('-');
  const [tempValue, setTempValue] = useState<AssignedUserCargoSearchRequest | undefined>(undefined);
  const [checked, setChecked] = useState(false);
  const restorePreviousValue = useRef(false);

  useEffect(() => {
    if (activeFilter) {
      setChecked(true);
      setTempValue(activeFilter);
      setInputValue(activeFilter.userName ?? '');
    } else {
      setChecked(false);
      if (value === undefined) {
        setValue({ userName: obxuser?.name, userId: obxuser?.userId ?? '' });
      }
    }
    // eslint-disable-next-line
  }, [activeFilter]);

  useEffect(() => {
    // Pass value through temp value first to update initialTerm (issue when selecting same values but with different IDs)
    if (tempValue) {
      setValue(tempValue);
      setTempValue(undefined);
    }
  }, [tempValue]);

  const handleChange = useCallback((isChecked: boolean, newValue: AssignedUserCargoSearchRequest | undefined): void => {
    onChange(GROUP_NAME, newValue && isChecked ? newValue : undefined);
  }, [onChange]);

  const handleBlur = (): void => {
    // Fix for issue when user clears input or write smth without selecting item -> restore value to last selected
    if (value?.userName !== inputValue) {
      restorePreviousValue.current = true;
      setTimeout(() => {
        if (restorePreviousValue.current) {
          setTempValue(value);
        }
      }, 200);
    }
  };

  const handleCallback = (change?: SuggestionResponse): void => {
    // If there is a callback just after blur -> cancel restoring default value
    restorePreviousValue.current = false;
    const newValue = { userName: change?.value, userId: change?.searchEntityId ?? '' };
    setValue(newValue);
    handleChange(checked, newValue);
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent): void => {
    setChecked(!!e.checked);
    handleChange(!!e.checked, value);
  };

  return (
    <div className='additional-filters__container--internal assignee-filter__container'>
      <div className='additional-filters__checkbox'>
        <Checkbox inputId='assignee_filter_checkbox'
          checked={checked}
          onChange={handleCheckboxChange} />
        <label htmlFor='assignee_filter_checkbox'>
          Assignee
        </label>
      </div>

      <SingleEntitySearch
        customItems={[{
          value: obxuser?.name ?? '',
          searchEntityId: obxuser?.userId ?? '',
          template: AssignToMe
        }]}
        showCustomItem={!value || inputValue === '' || value?.userName !== obxuser?.name}
        label=''
        isDisabled={!checked}
        module={EntitySearchGroupEnum.Users}
        fields={EntitySearchFieldsEnum.User}
        initialTerm={tempValue ? '' : formatName(value?.userName ?? '')}
        itemTemplate={(i: SuggestionResponse):string => formatName(i.value)}
        onBlurMethod={handleBlur}
        completeMethod={(change):void => setInputValue(change ?? '')}
        onInputClear={():void => setInputValue('')}
        callback={handleCallback}
        onFocusMethod={(e):void => e.target.select()}
      />
    </div>
  );
};

export default CargoSearchAssigneeFilter;
