import {useEffect, useState} from 'react';
import clsx from 'clsx';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';

import styles from './ToggleSwitch.module.scss';


interface IToggleProps {
	label?: 'left' | 'right';
	align?: 'left' | 'right';
	checked: boolean;
	children?: any;
	callback: Function;
	slots?: string[]
}

const ToggleSwitch = (params:IToggleProps) => {
	const { label, checked, children, callback, align } = params;

	const [ left, right ] = params.slots ?? [];

	const [ state, setState ] = useState<boolean>(checked);

	useEffect(() => {
		setState(checked ?? '');
	}, [checked]);

	return <div className={clsx(
		styles.instance,
		label === 'right' && styles.reversed,
		align === 'left' && styles.justify,
		)}>
		{ children && 
			<label>{children}</label>
		}
		{ params.slots && <span>{left}</span> }
		<InputSwitch 
			checked={state} 
			onChange={(e: InputSwitchChangeEvent) => {
				setState(() => {
					callback(e.value);
					return e.value ?? false;
				})
			}}
		/>
	{ params.slots && <span>{right}</span> }
	</div>
	
}

export default ToggleSwitch;