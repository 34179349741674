import clsx from 'clsx';

import { SubscriptionStatus } from 'modules/DataModule/Models/Enums';
import { DATA_DEFAULT_VALUES } from 'modules/DataModule/Models/Consts';

import type { SubscriptionResponseFlat } from 'modules/DataModule/Models/SubscriptionResponse';

import styles from './Status.module.scss';

const Status = (data: SubscriptionResponseFlat): JSX.Element => {
  const statusConfig = {
    icon: 'iconoir-check',
    label: 'Active',
    className: styles.statusActive,
  };

  switch (data.status) {
    case SubscriptionStatus.Active:
      const range = data.isTrial ? DATA_DEFAULT_VALUES.trial.expiresInDays : DATA_DEFAULT_VALUES.full.expiresInDays;

      if (data.expireInDays <= range.danger) {
        statusConfig.icon = 'iconoir-priority-high';
        statusConfig.label = `Expires in ${data.expireInDays} days`;
        statusConfig.className = clsx(styles.statusExpiring, styles.statusExpiringDanger);
      } else if (data.expireInDays <= range.warn) {
        statusConfig.icon = 'iconoir-warning-triangle';
        statusConfig.label = `Expires in ${data.expireInDays} days`;
        statusConfig.className = clsx(styles.statusExpiring, styles.statusExpiringWarn);
      } else {
        statusConfig.icon = 'iconoir-check';
        statusConfig.label = 'Active';
        statusConfig.className = styles.statusActive;
      }
      break;
    case SubscriptionStatus.Expired:
      statusConfig.icon = 'iconoir-xmark';
      statusConfig.label = 'Expired';
      statusConfig.className = styles.statusExpired;
      break;
    case SubscriptionStatus.Pending:
      statusConfig.icon = 'iconoir-clock';
      statusConfig.label = 'Pending';
      statusConfig.className = styles.statusPending;
      break;
    default:
      return <div>{data.status}</div>;
  }

  return <div className={clsx(styles.status, statusConfig.className)}>
    <i className={`${statusConfig.icon} icon--small`} />
    <span>{statusConfig.label}</span>
  </div>;
};

export default Status;