import axios from "axios";

import { DateTime } from 'luxon';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

// import { IAvailableRategrid, IRateGrid } from "../Models/RateGrid";
import { RateGridAPIResponse, RateGridResponse, AvailableRateGridsResponse, ApiData } from '../Models/RateGridResponse';
import { apiRateGridValueResponse, apiRateGridResponse } from '../Models';

import { columnsFactory, routeFactory } from './Parsers';

export class RateGridApi {

	static getAvailableGrids = () => axios.request<null, {data: apiRateGridResponse[]}>({ url: "rates/grid", method: "GET" })
	.then( r => {
		const { data } = r;

		// return data.map( (g: AvailableRateGridsResponse) => {
		return data.map( (g) => {
			const { id, name } = g;
			return { id, name }
		})
	})
	.catch((e) => {
			ErrorToastService.handleError(e, [500, 503]);

			throw e; 
	});

    

	static getRateGrid = (rateGridId?: string) => axios.request<RateGridAPIResponse, ApiData, RateGridResponse>({ url: `Rates/v2/Grid/${rateGridId}`, method: "GET" })
	.then(r => {

		const { columns, dataAgeProfileInHours, provider, lastModified, routes, id } = r.data;

		//	Pass approppriate properties through the parsers RatesGrid DTOs expect
		return { 
			id,
			dataAgeProfileInHours, 
			provider,
			lastModified: DateTime.fromISO(lastModified, {zone: "utc"}),
			columns: columnsFactory(columns),
			routes: routeFactory(routes, columns, id),
		}
	})
	.catch((e) => {
			ErrorToastService.handleError(e, [500, 503]);

			throw e; 
	});

	static setRategridValue = (url: string, params: any) => {
			
		const { grid, data } = params.arg;

		return axios.request({ url: `rates/grid/value/${grid}`, data, method: "PUT" })
		.then(r => ({
				results: r.data as any
		}))
		.catch((e) => ErrorToastService.handleError(e, [500, 503]));
	}

}