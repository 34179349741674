import * as React from 'react';
import useSWR from "swr";
import { DateTime } from "luxon";
import { clsx } from "clsx";

import { Line } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TooltipPositionerMap,
  Legend,
  TimeScale,
  Filler,
  Plugin
} from 'chart.js';
import 'chartjs-adapter-luxon';

import { Button } from "primereact/button";

import { humanDate } from 'helpers/Utils/formatters';
import { chartFillGradient, positionAtTop, toolTipLine } from "helpers/Utils/charting";
import { AXIS_LABEL_COLOUR, AXIS_LINE_COLOUR } from "components/Charts/ChartProps";

import PriceHistorySummaryCard from './PriceHistorySummaryCard';
import { BalticOrVortexaApi } from '../../../Services/BalticOrVortexaRatesAPI';

import type { GridProvider } from 'modules/Rates/Grids/Models/GridProvider';
import type { dataPointDto } from 'modules/Rates/Models';

import "./PriceHistory.scss";
import { asEncoded } from 'helpers/Utils/string';

interface IArgs {
  gridProvider: GridProvider;
  indexid: string;
  market: string;
  setCurrentIndex: Function;
}

interface KeyValue {
  key: string;
  value: number;
}

const PRICE_UP_RENDERING_COLOR: string = "rgba(52, 168, 83, 1)";
const PRICE_DOWN_RENDERING_COLOR: string = "rgba(234, 67, 53, 1)";
// const AXIS_LABEL_COLOUR: string = "rgba(255, 255, 255, .68)";
// const AXIS_LINE_COLOUR: string = "rgba(255, 255, 255, .16)";

function PriceHistory(args: IArgs) {

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    Filler
  );

  //  TODO - refactor these periods as value is no longer being used…
  const periods: KeyValue[] = [
    { key: "5D", value: 5 },
    { key: "1M", value: 30 },
    { key: "6M", value: 180 },
    { key: "YTD", get value() { return DateTime.now().ordinal } },
    { key: "1Y", value: 365 }
  ]

  const { gridProvider, indexid, market, setCurrentIndex } = args;

  //  default period is 365…
  const [period, setPeriod] = React.useState<KeyValue>(periods[4]);
  const [renderColor, setRenderColor] = React.useState<string>(PRICE_UP_RENDERING_COLOR);
  const [first, setFirst] = React.useState<dataPointDto | undefined>();
  const [last, setLast] = React.useState<dataPointDto | undefined>();

	const cachekey: string = `historicalprices.${asEncoded({ market, period: period.key, indexid, provider: gridProvider }, true)}`

  const { data } = useSWR(cachekey, () =>
    BalticOrVortexaApi.getHistoricalPrices(cachekey, {
      market,
      period: +period.value,
      indexid,
      provider: gridProvider,
    })
  );

  // TODO: handle loading & error state
  const [historical, setHistorical] = React.useState<any>({
    datasets: [{
      data: [],
    }]
  });

  const options: any = {
    responsive: true,
    elements: {
      point: {
        pointStyle: false,
      },
      line: {
        borderColor: renderColor,
        borderWidth: 3,
        backgroundColor: (context: {chart: ChartJS}) => {

          const { chart }  = context;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          return chartFillGradient(ctx, chartArea, renderColor);

        },
        fill: {
          target: { value: -90000 }
        }
      },
    },
    parsing: {
      xAxisKey: "date",
      yAxisKey: "value"
    },
    scales: {
      y: {
        ticks: {
          color: AXIS_LABEL_COLOUR
        }
      },
      x: {
        type: "time",
        border: {
          color: AXIS_LINE_COLOUR,
          width: 2
        },
        ticks: {
          stepSize: () => {
            switch (period.key) {
              case "5D":
                return 24;
              case "1M":
                return 6;
              case "6M":
                return 32;
              case "1Y":
                return 3;
              default:
                return 12;
            }
          },
          callback: function (v: any) {
            switch (period.key) {
              case "5D":
              case "1M":
              case "YTD":
                return DateTime.fromMillis(v).toFormat("dd MMM")
              default:
                return DateTime.fromMillis(v).toFormat("MMM yy");
            }
          },
          color: AXIS_LABEL_COLOUR,
        },
        grid: {
          color: [AXIS_LINE_COLOUR]
        }
      }
    },
    interaction: {
      axis: "x",
      mode: "nearest",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: 'custom'
      },
    },
  };

  (Tooltip.positioners as TooltipPositionerMap & { custom: typeof positionAtTop }).custom = positionAtTop;

  const plugins: Plugin[] = [{
    id: "tooltipLine",
    afterDatasetsDraw: toolTipLine
  }]


  React.useEffect(() => {

    if (data) {
      //  use destructuring to get the first and last values
      let { 0: first, [data?.results.values.length - 1]: last } = data?.results.values;

      setFirst(first);
      setLast(last);

      //  depending on +/- movement in price. Set the rendering colour
      setRenderColor(first.value < last.value ? PRICE_UP_RENDERING_COLOR : PRICE_DOWN_RENDERING_COLOR);

      //  push the data into a format Chart.js will understand
      setHistorical({
        datasets: [{
          data: data?.results.values,
        }]
      });
    }

  }, [data]);

  return <>
    <div className="price-history__container">
      <header>
        <hgroup>
          <h2>{data?.results.code }</h2>
          <h3>{ data?.results.description }</h3>
        </hgroup>
        <Button
          icon="iconoir-xmark"
          rounded
          text
          onClick={() => setCurrentIndex(null)} />
      </header>
      <PriceHistorySummaryCard
        first={first}
        last={last}
        results={data?.results}
        market={market}
        period={period}
      />
      <div className="p-buttonset">
        {periods.map((item, index) => (
          <Button
            key={index}
            size="small"
            className={clsx(
              "p-button--tab-style",
              period.key === item.key && "active"
            )}
            onClick={() => setPeriod(item)}>
            {item.key}
          </Button>
        ))}
      </div>
      {historical &&
        <>
        <div className="price-history__chart">
          <Line
            options={options}
            data={historical}
            plugins={plugins}
          />
        </div>
        { first && last &&
        <time dateTime={data?.results.startDate}><strong>Time Frame</strong>: {humanDate(first?.date)} - {humanDate(last?.date)}</time>
        }
        </>
      }
    </div>
  </>;
}

export default PriceHistory;
