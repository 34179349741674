import { ColumnBodyOptions } from 'primereact/column';

import {
  ResultsResponse,
  SurveillanceMediaRecording,
  SurveillanceMediaText,
} from 'modules/Surveillance/Models/ReportsResponse';

import { DoubleLineSimple } from 'helpers/DataTable/Templates/ColumnTemplates';

export const UserCell = (data: ResultsResponse, config: ColumnBodyOptions) => {
  const [header, first, second] = config.field.split(',');
  const lower = ![
    SurveillanceMediaRecording.WhatsAppAudio,
    SurveillanceMediaText.WhatsAppMessage,
  ].includes(data.media)
    ? first
    : second;

  return DoubleLineSimple(data, {...config, field: `${header},${lower}`});
};
