import { apiLegalEntityResponse } from './apiResponse';
import { SanctionStatusEnum } from './Enums';

import { humanDate } from 'helpers/Utils/formatters';

export class LegalEntitySanctionResult {
  static generateTextPropMap(
    data: apiLegalEntityResponse
  ): Map<string, string | undefined> {
    const m = new Map<string, string | undefined>();
    m.set('Name', data.fullName ?? undefined);
    m.set('Short Name', data.shortCompanyName ?? undefined);
    m.set('Country Name', data.countryName ?? undefined);
    m.set('Registration', data.nationalityofRegistration ?? undefined);
    m.set('Telephone', data.telephone ?? undefined);
    m.set('Source', data.providerName ?? undefined);
    m.set(
      'Designated date',
      data.designatedDate
        ? humanDate(data.designatedDate, { dayName: false })
        : 'Unknown'
    );
    m.set(
      'Created On',
      data.createdOn ? humanDate(data.createdOn, { dayName: false }) : 'Unknown'
    );
    return m;
  }

  static generateStatusPropMap(data: apiLegalEntityResponse): Map<string, SanctionStatusEnum> {
    const m = new Map<string, SanctionStatusEnum>();
    m.set("Parent Company Compliance", data.parentCompanyComplianceRisk ?? SanctionStatusEnum.Unknown);
    m.set("FATF Jurisdiction", data.companyInFATFJurisdiction ?? SanctionStatusEnum.Unknown);
    m.set("OFAC Sanctioned Country", data.companyInOFACSanctionedCountry ?? SanctionStatusEnum.Unknown);
    m.set("Australian Sanction List", data.companyOnAustralianSanctionList ?? SanctionStatusEnum.Unknown);
    m.set("BES Sanction List", data.companyOnBESSanctionList ?? SanctionStatusEnum.Unknown);
    m.set("EU Sanction List", data.companyOnEUSanctionList ?? SanctionStatusEnum.Unknown);
    m.set("Canadian Sanction List", data.companyOnCanadianSanctionList ?? SanctionStatusEnum.Unknown);
    m.set("OFAC Non SDN Sanction List", data.companyOnOFACNonSDNSanctionList ?? SanctionStatusEnum.Unknown);
    m.set("OFAC SSI List", data.companyOnOFACSSIList ?? SanctionStatusEnum.Unknown);
    m.set("OFAC Sanction List", data.companyOnOFACSanctionList ?? SanctionStatusEnum.Unknown);
    m.set("Swiss SanctionL ist", data.companyOnSwissSanctionList ?? SanctionStatusEnum.Unknown);
    m.set("UAE Sanction List", data.companyOnUAESanctionList ?? SanctionStatusEnum.Unknown);
    m.set("UN Sanction List", data.companyOnUNSanctionList ?? SanctionStatusEnum.Unknown);
    m.set("UK Sanction List", data.companyOnUKSanctionList ?? SanctionStatusEnum.Unknown);
    return m;
  }
}
