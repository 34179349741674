import {
  Dispatch,
  RefObject,
  SetStateAction,
  SyntheticEvent, useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { ContextMenu } from 'primereact/contextmenu';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';

import { Frequency, State } from '../../Models/Enums';
import { SubscriptionsResponse } from '../../Models/ReportsResponse';

import { SUBSCRIBE_PERIODS } from './SubscribePanel';

type SubscribePanelRowProps = {
  frequencyTaken: Frequency[];
  isMutating: boolean;
  index: number;
  subscription?: SubscriptionsResponse;
  isDraft?: boolean;
  setContextMenuItems?: Dispatch<SetStateAction<MenuItem[]>>;
  contextMenu?: RefObject<ContextMenu>;
  draftFrequency?: Frequency;
  setDraftFrequency?: Dispatch<SetStateAction<Frequency | undefined>>;
  addSubscription?: (newFrequency: Frequency) => Promise<void>;
  updateSubscription?: (
    updatedSubscription: SubscriptionsResponse,
    index: number
  ) => Promise<void>;
};

const SubscribePanelRow = (props: SubscribePanelRowProps): JSX.Element => {
  const {
    frequencyTaken,
    isMutating,
    contextMenu,
    setContextMenuItems,
    index,
    draftFrequency,
    setDraftFrequency,
    subscription,
    addSubscription,
    updateSubscription,
    isDraft = false } = props;

  const [ loadingData, setLoadingData] = useState<boolean>(false);

  useEffect(() => {
    setLoadingData(false);
    setDraftFrequency && setDraftFrequency(undefined);
  }, [index]);

  const handleFrequencyChange = (newFrequency: Frequency): void => {
    if (subscription) {
      updateSubscription && index !== undefined && updateSubscription({
        ...subscription,
        frequency: newFrequency
      }, index);
    } else {
      setDraftFrequency && setDraftFrequency(newFrequency);
    }
  };

  const handleStateChange = (newState: State): void => {
    subscription && updateSubscription && index !== undefined && updateSubscription({
      ...subscription,
      state: newState
    }, index);
  };

  const onDeleteClick = async (): Promise<void> => {
    subscription && updateSubscription && index !== undefined && updateSubscription({
      ...subscription,
      state: State.Deleted
    }, index);
  };

  const onAddClick = async (): Promise<void> => {
    addSubscription && draftFrequency && addSubscription(draftFrequency);
    setLoadingData(true);
  };

  const isMuted = subscription?.state === State.Muted;

  const showMenu = (e: SyntheticEvent): void => {
    const contextMenuItems: MenuItem[] = [
      {
        label: isMuted ? 'Unmute' : 'Mute',
        command: () => handleStateChange(isMuted ? State.Unmuted : State.Muted),
        icon: 'iconoir-bell icon--small',
      },
      {
        label: 'Delete',
        command: onDeleteClick,
        icon: 'iconoir-trash icon--small',
      },
    ];
    setContextMenuItems && setContextMenuItems(contextMenuItems);
    contextMenu?.current?.show(e);
  };

  return (
    <div className="input-row">
      {!isDraft && (
        <Badge
          className={clsx(
            'icon--tiny',
            isMuted ? 'iconoir-xmark' : 'iconoir-check'
          )}
          severity={isMuted ? 'danger' : 'success'}
        />
      )}
      <Dropdown
        id="dl-side-panel-mode"
        loading={loadingData || isMutating}
        placeholder="Period"
        value={subscription?.frequency ?? draftFrequency}
        onChange={(e: DropdownChangeEvent): void => {
          handleFrequencyChange(e.value);
        }}
        options={SUBSCRIBE_PERIODS.map(sp => ({
          ...sp,
          disabled: frequencyTaken.includes(sp.value) || sp.value === draftFrequency
        }))}
        className='subscribe-panel-dropdown'
        panelClassName='subscribe-panel-dropdown-panel'
      />
      <InputText disabled placeholder="Email" />
      {isDraft ? (
        <Button
          className="p-button-icon-only"
          disabled={!draftFrequency}
          loading={isMutating}
          icon="iconoir-plus icon--small"
          onClick={onAddClick}
          size="small"
          text
        />
      ) : (
        <Button
          icon="iconoir-more-vert icon--tiny"
          onClick={showMenu}
          loading={isMutating}
          size="small"
          text
        />
      )}
    </div>
  );
};

export default SubscribePanelRow;