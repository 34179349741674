import { WorksheetSignalMessageEventTypes } from 'components/Worksheets/Models/Enums';

import { getValueCollection } from 'helpers/Utils/enum';

import { SignalRApi } from 'server/signalR-api';

export enum SurveillanceSignalEventTypes {
  SURVEILLANCE_ENTITY_UPDATES = 'surveillanceEntityUpdates',
  SURVEILLANCE_TRANSCRIPTION_UPDATED = 'transcriptionUpdated',
}

export class SurveillanceSocket {
  private static _instance: SurveillanceSocket;

  static get instance() {
    return this._instance || (this._instance = new this());
  }

  init = async (sr: SignalRApi): Promise<void> => {
    const events = [
      ...getValueCollection(WorksheetSignalMessageEventTypes, false),
      ...getValueCollection(SurveillanceSignalEventTypes, false),
    ];

    events.map(event => sr.dispatchEventOnMessage(event.key));
  };
}
