import { WaypointType } from './Enums';

import type { IAzureMapControls } from "react-azure-maps";
import type { ControlOptions } from "azure-maps-control";

const LNG = 0.00;
const LAT = 51.50;
const ZOOM_MOBILE = 0;
const ZOOM_DESKTOP = 1;
const ZOOM_WAYPOINT_VISIBLE = 9;
const MAP_STYLE_SETTINGS_KEY = 'dc-map-style';

const MAP_STYLES = [
    'night',
    'road',
    'satellite',
    'satellite_road_labels', // hybrid
    'grayscale_dark',
    'grayscale_light',
    'road_shaded_relief', // terra
    'high_contrast_dark',
    'high_contrast_light'
] as const;

const MAP_STYLE = MAP_STYLES[0];

const MAP_CONTROLS: IAzureMapControls[] = [
    {
        controlName: 'StyleControl',
        controlOptions: {
            mapStyles: MAP_STYLES
        },
        options: {position: 'top-right'} as ControlOptions,
    },
    {
        controlName: 'ZoomControl',
        options: {position: 'top-right'} as ControlOptions,
    },
    {
        controlName: 'CompassControl',
        controlOptions: {rotationDegreesDelta: 10, style: 'dark'},
        options: {position: 'bottom-right'} as ControlOptions,
    },
    {
        controlName: 'PitchControl',
        controlOptions: {pitchDegreesDelta: 5, style: 'dark'},
        options: {position: 'bottom-right'} as ControlOptions,
    }
];

const VESSEL_COLORS = {
    road: 'dark',
    grayscale_light: 'dark',
    road_shaded_relief: 'dark',
    night: 'light',
    grayscale_dark: 'light',
    satellite: 'orange',
    satellite_road_labels: 'orange',
    high_contrast_dark: 'orange',
    high_contrast_light: 'orange',
};

const LINE_OPTIONS = {
    strokeColor: 'black', // fallback value, will be updated on map init
    strokeWidth: 3,
    strokeDashArray: [1, 3]
};

const WAYPOINT_MARKER_MAP: { [key in WaypointType]: string } = {
    [WaypointType.Load]:  'load_main',
    [WaypointType.Discharge]: 'disch_main',
    [WaypointType.Via]: 'via_main',
    [WaypointType.Waypoint]: 'via_sec',
    [WaypointType.Vessel]: 'vessel',
    [WaypointType.OpenLocation]: 'open_loc',
}

const WAYPOINT_MARKER_OFFSET_MAP: { [x in WaypointType]: number } = {
    [WaypointType.Load]: 24,
    [WaypointType.Discharge]: 24,
    [WaypointType.Via]: 24,
    [WaypointType.Waypoint]: 12,
    [WaypointType.Vessel]: 8,
    [WaypointType.OpenLocation]: 0,
}

const WAYPOINT_MARKER_OFFSET_MAP_MOBILE: { [key in WaypointType]: number } = {
    [WaypointType.Load]:  12,
    [WaypointType.Discharge]: 12,
    [WaypointType.Via]: 12,
    [WaypointType.Waypoint]: 8,
    [WaypointType.Vessel]: 0,
    [WaypointType.OpenLocation]: 0,
}

const WAYPOINT_LABEL_OFFSET_MAP: { [key in WaypointType]: number } = {
    [WaypointType.Load]:  -28,
    [WaypointType.Discharge]: -28,
    [WaypointType.Via]: -28,
    [WaypointType.Waypoint]: -16,
    [WaypointType.Vessel]: 0,
    [WaypointType.OpenLocation]: 0,
}

const WAYPOINT_LABEL_OFFSET_MAP_MOBILE: { [key in WaypointType]: number } = {
    [WaypointType.Load]:  -16,
    [WaypointType.Discharge]: -16,
    [WaypointType.Via]: -16,
    [WaypointType.Waypoint]: -12,
    [WaypointType.Vessel]: 0,
    [WaypointType.OpenLocation]: 0,
}

const WAYPOINT_TYPE_MAP: { [key in WaypointType]: string } = {
    [WaypointType.Load]:  'LD',
    [WaypointType.Discharge]: 'DS',
    [WaypointType.Via]: 'VIA',
    [WaypointType.Waypoint]: '',
    [WaypointType.Vessel]: '',
    [WaypointType.OpenLocation]: '',
}

export {
    LNG,
    LAT,
    ZOOM_MOBILE,
    ZOOM_DESKTOP,
    ZOOM_WAYPOINT_VISIBLE,
    MAP_STYLES,
    MAP_STYLE,
    MAP_CONTROLS,
    LINE_OPTIONS,
    WAYPOINT_MARKER_MAP,
    WAYPOINT_MARKER_OFFSET_MAP,
    WAYPOINT_MARKER_OFFSET_MAP_MOBILE,
    WAYPOINT_LABEL_OFFSET_MAP,
    WAYPOINT_LABEL_OFFSET_MAP_MOBILE,
    WAYPOINT_TYPE_MAP,
    MAP_STYLE_SETTINGS_KEY,
    VESSEL_COLORS
};