import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { ContextMenu } from 'primereact/contextmenu';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import clsx from 'clsx';

import { useGetPriceChangeSubscriptionFormats } from 'modules/ArtisCharting/Services/hooks';
import { PriceChangeSubscriptionDirecton, PriceChangeSubscriptionStatus } from 'modules/ArtisCharting/Models/Enums';

import type { ActiveChartPrice, CreatePriceChangeSubscriptionPayload, PriceChangeSubscription as PriceChangeSubscriptionType } from 'modules/ArtisCharting/Models/ArtisPrices';

import './PriceChangeSubscription.scss';

type DirectionOption = {
  label: string;
  icon: string;
  value: PriceChangeSubscriptionType['direction'];
};

const DIRECTION_OPTIONS: DirectionOption[] = [
  { label: 'Above', icon: 'iconoir-arrow-up-circle', value: PriceChangeSubscriptionDirecton.Above },
  { label: 'Below', icon: 'iconoir-arrow-down-circle', value: PriceChangeSubscriptionDirecton.Below }
];

const EXPIRES_OPTIONS = ['Today', '1 Week', '30 Days', 'Indefinitely'];

type BasePriceChangeSubscriptionProps = {
  isLoading: boolean;
  price: ActiveChartPrice;
  data?: PriceChangeSubscriptionType;
};

type DraftPriceChangeSubscriptionProps = {
  create: (data: CreatePriceChangeSubscriptionPayload) => Promise<void>;
  isDraft: true;
  update?: never;
  deleteSubscription?: never;
};

type NonDraftPriceChangeSubscriptionProps = {
  update: (data: PriceChangeSubscriptionType) => Promise<void>;
  deleteSubscription: (data: PriceChangeSubscriptionType) => Promise<void>;
  create?: never;
  isDraft?: undefined;
};

type PriceChangeSubscriptionProps = BasePriceChangeSubscriptionProps & (DraftPriceChangeSubscriptionProps | NonDraftPriceChangeSubscriptionProps);

export default function PriceChangeSubscription(
  props: PriceChangeSubscriptionProps
): JSX.Element {
  const {
    data,
    isDraft,
    isLoading,
    create,
    update,
    deleteSubscription,
    price,
  } = props;
  const [direction, setDirection] = useState<PriceChangeSubscriptionType['direction']>(data?.direction ?? DIRECTION_OPTIONS[0].value);
  const [expires, setExpires] = useState<PriceChangeSubscriptionType['expires'] | undefined>(data?.expires);
  const [value, setValue] = useState<PriceChangeSubscriptionType['value'] | undefined>(data?.value);
  const [format, setFormat] = useState<PriceChangeSubscriptionType['format'] | undefined>(data?.format);
  const [isValid, setIsValid] = useState<boolean>(!!(direction && expires && format && typeof value === 'number'));
  
  const contextMenu = useRef<ContextMenu>(null);

  const { data: subscriptionFormats, isLoading: subscriptionFormatsLoading/* , error */ } = useGetPriceChangeSubscriptionFormats();

  useEffect(() => {
    if (!data) {
      return;
    }

    setDirection(data.direction);
    setExpires(data.expires);
    setValue(data.value);
    setFormat(data.format);
  }, [data]);

  const resetDraft = (): void => {
    setDirection(PriceChangeSubscriptionDirecton.Above);
    setExpires(undefined);
    setValue(undefined);
    setFormat(undefined);
  };

  const createSubscription = async (): Promise<void> => {
    if (isDraft && isValid) {
      try {
        await create({
          direction,
          expires: expires!,
          format: format!,
          packageId: price.artispackage!.source,
          productId: price.product!,
          status: PriceChangeSubscriptionStatus.Active,
          tenorCode: price.tenorCode,
          tenorName: price.tenorName.original,
          value,
        });
        resetDraft();
      } catch (e) {
        // handle if needed
      }
    }
  };

  const updateSubscription = async (param: Partial<PriceChangeSubscriptionType>): Promise<void> => {
    if (isValid && !isDraft && data) {
      try {
        await update({ ...data, ...param });
      } catch (e) {
        // restore previous values?
        setDirection(data.direction);
        setExpires(data.expires);
        setFormat(data.format);
        setValue(data.value);
      }
    }
  };

  const handleDirectionChange = (e: DropdownChangeEvent): void => {
    updateSubscription({ direction: e.value });
    setDirection(e.value);
  };

  const handleFormatChange = (e: DropdownChangeEvent): void => {
    updateSubscription({ format: e.value });
    setFormat(e.value);
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>): void =>
    setValue(e.target.value ? +e.target.value : null);

  const handleValueKeydownEvent = (e: KeyboardEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;

    if (e.key === 'Enter') {
      updateSubscription({ value: +target.value });
    }
  };

  const handleValueBlurEvent = (e: FocusEvent<HTMLInputElement, Element>): void => {
    const value = +e.target.value;

    if (e.target.value === '') {
      setValue(null);
      return;
    }

    // if the value has been updated already (ex. on 'Enter') then no need to send request again
    if (value !== data?.value) {
      updateSubscription({ value });
    }
  };

  const handleDurationChange = (e: DropdownChangeEvent): void => {
    updateSubscription({ expires: e.value });
    setExpires(e.value);
  };

  const handleStatusChange = (): Promise<void> =>
    updateSubscription({
      status: data?.status === PriceChangeSubscriptionStatus.Active ? PriceChangeSubscriptionStatus.Muted : PriceChangeSubscriptionStatus.Active
    });

  const onMenuClick = (e: SyntheticEvent): void => {
    contextMenu?.current?.show(e);
  };

  const onDeleteClick = async (): Promise<void> => {
    if (data && deleteSubscription) {
      await deleteSubscription(data);
    }
  };

  useEffect(() => {
    setIsValid(!!(direction && expires && format && typeof value === 'number'));
  }, [direction, expires, format, value]);

  const shouldShowError = value !== undefined && typeof value !== 'number';

  const contextMenuItems: MenuItem[] = [
    {
      label:
        data?.status === PriceChangeSubscriptionStatus.Muted
          ? 'Unmute'
          : 'Mute',
      command: handleStatusChange,
      icon: 'iconoir-bell icon--small',
    },
    {
      label: 'Delete',
      command: onDeleteClick,
      icon: 'iconoir-trash icon--small',
    },
  ];

  return <div className={clsx('price-change-subscription__container', { 'p-invalid': !isValid && shouldShowError })}>
    <div className='p-inputgroup grow-to-fill'>
      {!isDraft && (
        <div className='p-overlay-badge'>
          <Badge
            className={clsx(
              'icon--tiny',
              data?.status === PriceChangeSubscriptionStatus.Muted
                ? 'iconoir-xmark'
                : 'iconoir-check'
            )}
            severity={
              data?.status === PriceChangeSubscriptionStatus.Muted
                ? 'danger'
                : 'success'
            }
          />
        </div>
      )}
      <ContextMenu ref={contextMenu} model={contextMenuItems} />
      <Dropdown
        className='price-change-subscription-direction'
        itemTemplate={(value) => <div><i className={clsx('icon--small', value.icon)} />{value.label}</div>}
        onChange={handleDirectionChange}
        options={DIRECTION_OPTIONS}
        panelClassName='price-change-subscription__dropdown-panel--direction'
        value={direction}
        valueTemplate={(value) => <i className={clsx('icon--small', value?.icon)} />}
      />
      <div className='position--relative p-inputwrapper '>
        <InputText
          keyfilter='num'
          type='number'
          className={clsx({ 'p-invalid': shouldShowError }, 'align--right')}
          onBlur={handleValueBlurEvent}
          onChange={handleValueChange}
          onKeyDown={handleValueKeydownEvent}
          placeholder='Value'
          value={`${value ?? ''}`}
        />
        {shouldShowError && <small className='message-invalid'>Invalid value</small>}
      </div>
      <Dropdown
        onChange={handleDurationChange}
        options={EXPIRES_OPTIONS}
        placeholder='Duration'
        value={expires}
      />
      <Dropdown
        loading={subscriptionFormatsLoading}
        onChange={handleFormatChange}
        options={subscriptionFormats}
        placeholder='Format'
        value={format}
      />
    </div>
    {isDraft ? (
      <Button
        className='price-change-subscription__button--add p-button-icon-only'
        disabled={!isValid}
        icon='iconoir-plus icon--small'
        loading={isLoading}
        onClick={createSubscription}
        size='small'
        text
      />
    ) : (
      <Button
        icon='iconoir-more-vert icon--tiny'
        onClick={onMenuClick}
        size='small'
        text
      />
    )}
  </div>;
}
