import { getByNumber } from 'helpers/Utils/enum';

import { ItemTemplateProps } from 'components/Autocomplete/Templates/ItemTemplate';
import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';
//	TODO - this type is defined by what a Position List Vessel search looks like…

/**
 * function to map properties of a Position List vessel search to the approriate keys
 * required by a <ItemTemplate> instance
 *
 * @param {*} item
 * @returns {ItemTemplateProps}
 */
export const vesselEntityMapper = (item: any): ItemTemplateProps => ({
	...item,
	icon: item.FlagCode,
	subLabel: item.ShiptypeLevel2,
})

/**
 * function to map properties of a Position List port/location search to the approriate keys
 * required by a <ItemTemplate> instance
 *
 * @param {*} item
 * @returns {ItemTemplateProps}
 */
export const portLocationEntityMapper = (item: any): ItemTemplateProps => ({
	...item,
	icon: item.CountryCodeISO3
})

export const vesselClassMapper = (item: any): ItemTemplateProps => ({
	...item,
	id: item.searchEntityId,
	subLabel: item.VesselType
})

export const tokenMapper = (item: any) => ({
  ...item,
  group: getByNumber(EntitySearchFieldsEnum, item.searchField, true),
  name: item.searchTerm ?? item.value
})