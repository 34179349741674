import { isLetterFirst } from 'helpers/Utils/string';
import type { CargoTrackerResponse } from 'modules/CargoTracker/Models/CargoTrackerResponse';
import { UnitEnum } from 'modules/CargoTracker/Models/Enums';

const QuantityBody = (data: CargoTrackerResponse): JSX.Element => {
  const original = data.quantity?.original ?? '';
  const unit = data.quantity?.unit ?? UnitEnum.MT;

  return <div className='cell--unit'>
    <span>{original}</span>
    {!isLetterFirst(original) && <span>{UnitEnum[unit]}</span>}
  </div>;
};

export default QuantityBody;