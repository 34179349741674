import axios, { type AxiosError } from 'axios';
import { Key } from 'swr';
import useSWRMutation from 'swr/mutation';

import type { Reason } from '../Components/MarkAsReviewedPopup/Models/Enums';
import type { SurveillanceEntityStatus } from '../Models/Enums';
import type { ResultsResponse } from '../Models/ReportsResponse';

export const SURVEILLANCE_AUDIT_STATE_CACHE: string = 'surveillance-mar';

export type StateRequestItems = {
  id: string,
  partitionKey: string,
  siblings?: StateRequestItems[];
}

export type StateRequest = {
  items: StateRequestItems[],
  note: string,
  worksheetId: string;
  assignedUserId?: string | null,
  status?: SurveillanceEntityStatus,
  reason?: Reason | null,
};

export type StateRequestWithWs = StateRequest & {
  worksheetId: string;
};

export type EntityUpdateResponse = {
  items: ResultsResponse[],
  status: SurveillanceEntityStatus,
  worksheetId: string,
};

export const useUpdateAuditState = (worksheetId?: string | null): {
  trigger: (params: StateRequestWithWs) => Promise<StateRequest | undefined>;
  isMutating: boolean;
  error?: AxiosError;
} => {
  const { error, isMutating, trigger } = useSWRMutation<StateRequestWithWs, AxiosError | undefined, Key, StateRequestWithWs>(
    `${ SURVEILLANCE_AUDIT_STATE_CACHE }-${ worksheetId }`,
    AuditApiService.updateAuditState
  );

  return { trigger, isMutating, error };
};

export class AuditApiService
{
  static updateAuditState = async (url: string, params: { arg: StateRequestWithWs }): Promise<StateRequest> => axios.request({
    url: `audit/surveillance/${ params.arg.worksheetId }/state`,
    method: 'PATCH',
    data: params.arg
  })
    .then(response => response.data) // For now 202 and empty "data"
    .catch((e):void => {
      // re-throw error
      throw e;
    });
}
