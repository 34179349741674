import Joi from 'joi';

import { LocationType } from './CargoTrackerResponse';

// TODO: extend validation objects, specify strings, enums, numbers

export const spotCargoValidator = Joi.object({
  id: Joi.string().optional(),
  type: Joi.number().required(),
  status: Joi.number().required(),
  commodity: Joi.object().required(),
  quantity: Joi.object().required(),
  loading: Joi.object().required(),
  discharging: Joi.object().required(),
  charterer: Joi.string().required(),
  code: Joi.string().empty('').min(0).max(50).optional(),
  laycan: Joi.object({
    original: Joi.string().required(),
    fromDate: Joi.string().required(),
    toDate: Joi.string().required(),
    fromDateParsed: Joi.object().required(),
    toDateParsed: Joi.object().required(),
  }).unknown(true).required(),
}).unknown(true);

export const tcCargoValidator = Joi.object({
  id: Joi.string().optional(),
  type: Joi.number().required(),
  status: Joi.number().required(),
  loading: Joi.object({
    locationType: Joi.number().required(),
    portCountry: Joi.when('locationType', { is: LocationType.Search, then: Joi.when('portName', { not: undefined, otherwise: Joi.string().required(), then: Joi.optional() })}),
    portName: Joi.when('locationType', { is: LocationType.Search, then: Joi.when('portCountry', { not: undefined, otherwise: Joi.string().required(), then: Joi.optional() })}),
    zone: Joi.when('locationType', { is: LocationType.Text, then: Joi.object({
      value: Joi.string().required(),
    }).unknown(true).required()
    })
  }).unknown(true).required(),
  laycan: Joi.object({
    original: Joi.string().required(),
    fromDate: Joi.string().required(),
    toDate: Joi.string().required(),
    fromDateParsed: Joi.object().required(),
    toDateParsed: Joi.object().required(),
  }).unknown(true).required(),
  discharging: Joi.object({
    locationType: Joi.number().required(),
    portCountry: Joi.when('locationType', { is: LocationType.Search, then: Joi.when('portName', { not: undefined, otherwise: Joi.string().required(), then: Joi.optional() })}),
    portName: Joi.when('locationType', { is: LocationType.Search, then: Joi.when('portCountry', { not: undefined, otherwise: Joi.string().required(), then: Joi.optional() })}),
    zone: Joi.when('locationType', { is: LocationType.Text, then: Joi.object({
      value: Joi.string().required(),
    }).unknown(true).required()
    })
  }).unknown(true).required(),
  charterPeriod: Joi.string().required(),
  quantity: Joi.object().required(),
}).unknown(true);