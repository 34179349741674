import { useEffect, useState } from 'react';

import FacetBarChart from 'components/Charts/FacetBarChart';

import { ResultsModeEnum } from '../../Models/Enums';
import { useSanctionsApi } from '../../Services/SanctionsAPI';

import "./SanctionedEntityCharting.scss";

interface ISanctionsChartingParams {
  searchTerm: string
}

function SanctionedEntityCharting(props: ISanctionsChartingParams) {
  const { searchTerm } = props;

  const { data } = useSanctionsApi(searchTerm, ResultsModeEnum.LegalEntitySanctions);
  // TODO: handle loading & error state
  const [ statusFacets, setStatusFacets ] = useState<any[]>();
  const [ countriesFacets, setCountriesFacets ] = useState<any[]>();
  const [ registrationFacets, setRegistrationFacets ] = useState<any[]>();

  useEffect(() => {
    if (!data) return;

    let { searchFacets } = data;

    setRegistrationFacets(searchFacets.filter((item:any ) => item.field === "NationalityofRegistration").map(
      (item: any) => {
        //  TODO - should shift this transformation server-side
        if (item.value === "United States Of America") {
          item = {...item, value: "USA"};
        }
        return item;
      }
    ));
    
    setCountriesFacets(searchFacets.filter((item:any ) => item.field === "CountryName").map(
      (item: any) => {
        //  TODO - should shift this transformation server-side
        if (item.value === "United States Of America") {
          item = {...item, value: "USA"};
        }
        return item;
      }
    ));

    setStatusFacets(searchFacets.filter((item:any ) => item.field === "CompanyStatus"));
  }, [data])

  return <>
    { data && 
    <div className="sanctioned-entity-charting__container overflow--y">
      <figure>
        <figcaption>
          <strong>Company Status</strong>
          <span>Sanctioned legal entities counts over Company Status</span>
        </figcaption>
        <FacetBarChart title='Status' facets={statusFacets as any}/>
      </figure>

      <figure>
        <figcaption>
          <strong>Countries</strong>
          <span>Sanctioned legal entities counts over country</span>
        </figcaption>
        <FacetBarChart title='Registered Owner' facets={countriesFacets as any}/>
      </figure>
      
      <figure>
        <figcaption>
          <strong>Nationality of Registration</strong>
          <span>Sanctioned legal entities counts over Nationality of Registration</span>
        </figcaption>
        <FacetBarChart title='Registered Owner' facets={registrationFacets as any}/>
      </figure>
    </div>
    }
  </>
}

export default SanctionedEntityCharting;
