import { useEffect, useState, Fragment } from "react";


import { groupCollection } from "helpers/Utils/collections";
import { getByNumber } from "helpers/Utils/enum";
import { EntitySearchFieldsEnum } from "components/EntitySearch/Models/Enums";

import { ISearchFacet } from "../CargoFlows";
import SearchChip from "./SearchChip";


import "./SearchTokens.scss";

interface ISearchTokenParams {
    facets: ISearchFacet[];
    removeCallBack: Function;
    expandState: boolean;
    additionalFacets: ISearchFacet[];
    onAdditionalFacetRemove: (id: string) => void;
}

const getContext = (id: EntitySearchFieldsEnum):string => {
    switch(true) {
        case [EntitySearchFieldsEnum.LoadCountry, EntitySearchFieldsEnum.LoadRegion, EntitySearchFieldsEnum.LoadPort].includes(id):
            return "Loading";
        case [EntitySearchFieldsEnum.DischargeCountry, EntitySearchFieldsEnum.DischargePort, EntitySearchFieldsEnum.DischargRegion].includes(id):
            return "Discharge";
        case [EntitySearchFieldsEnum.Product, EntitySearchFieldsEnum.ProductGrade, EntitySearchFieldsEnum.ProductGroup, EntitySearchFieldsEnum.ProductCategory].includes(id):
            return "Commodity";
        case [EntitySearchFieldsEnum.Vessel, EntitySearchFieldsEnum.VesselSize].includes(id):
            return "Vessel";
        case [EntitySearchFieldsEnum.Charterer, EntitySearchFieldsEnum.Owner, EntitySearchFieldsEnum.ImoNumber].includes(id):
            return "Business"
        default:
            return "";
    }
}

//  TODO - revist this if we want to have tokens always appear
//  in a given context order
const contextOrder = [
    EntitySearchFieldsEnum.Product,
    EntitySearchFieldsEnum.ProductCategory,
    EntitySearchFieldsEnum.ProductGrade,
    EntitySearchFieldsEnum.ProductGrade,
    EntitySearchFieldsEnum.LoadPort,
    EntitySearchFieldsEnum.LoadCountry,
    EntitySearchFieldsEnum.LoadRegion,
    EntitySearchFieldsEnum.DischargePort,
    EntitySearchFieldsEnum.DischargeCountry,
    EntitySearchFieldsEnum.DischargRegion,
    EntitySearchFieldsEnum.Vessel,
    EntitySearchFieldsEnum.VesselSize,
    EntitySearchFieldsEnum.ImoNumber,
    EntitySearchFieldsEnum.Charterer,
    EntitySearchFieldsEnum.Owner,
]

const SearchTokens = (params: ISearchTokenParams) => {

    const contextIcons: {[index: string]: string} = {
        "loading": "arrow-down-circle",
        "discharge": "arrow-up-circle",
        "commodity": "gas-tank-droplet",
        "vessel": "sea-waves",
        "business": "city",
        "filter": "filter",
    }


    const { facets, removeCallBack, expandState, additionalFacets, onAdditionalFacetRemove } = params;
    const [ searchItems, setSearchItems ] = useState<any>({});

    useEffect(() => {
        setSearchItems(groupCollection(facets, ({searchField}) => getByNumber<typeof EntitySearchFieldsEnum>(EntitySearchFieldsEnum, searchField)));
    }, [facets])

    return <>
      <div className="search-tokens__container grow-to-fill">
      {(expandState
          ? Object.keys(searchItems)
          .sort((a, b) => contextOrder.indexOf(searchItems[a][0].searchField) - contextOrder.indexOf(searchItems[b][0].searchField))
          .map( (k: string, groupIndex: number, coll: string[]) => {
              return <Fragment key={groupIndex}>
              {
                searchItems[k].map((item: any, index: number, items: any[]) => {
                    const group: string = getContext(item.searchField).toLowerCase();
                    const icon: string = contextIcons[group];

                    return <Fragment key={index}>
                      {( index !== 0) &&
                        <div key={`or-${index}`}>
                          <i className="type--or">
                            { item.searchField === items[index === 0 ? index : index - 1].searchField ? "OR" : "AND" }
                          </i>
                        </div>
                      }
                      <div key={`chip-${index}`}>
                        <SearchChip
                          item={{...item, icon, group}}
                          callback={removeCallBack}
                        >
                          {item.searchTerm}
                        </SearchChip>
                      </div>
                    </Fragment>
                })
              }
              { (groupIndex !== coll.length - 1) &&
                <i className="type--and" key={`i-${groupIndex}`}>AND</i>
              }
              </Fragment>
            })
          : <>{(
            (facets.length) ? <i>custom string</i> :  <i></i>
          )}</>
      )}
      { additionalFacets.length && Object.keys(searchItems).length ? <i className="type--and" key={`i-${0}`}>AND</i> : <></> }
      { additionalFacets.map((item, index) => {
          const group: string = 'filter';
          const icon: string = contextIcons[group];

          return <div key={`chip-${index}`}>
            <SearchChip
              item={{...item, icon, group, key: item.key! }}
              callback={onAdditionalFacetRemove}
            >
              {item.searchTerm}
            </SearchChip>
          </div>
        })}
      </div>
    </>
}

export default SearchTokens;