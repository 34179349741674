import { Chip } from 'primereact/chip';
import { ColumnBodyOptions } from 'primereact/column';
import clsx from 'clsx';

import NotAvailable from 'components/NotAvailable';

export const TypeChip = <T,>(data: T, config: ColumnBodyOptions): JSX.Element => {
  const { field } = config;
  const typeData = data[field as keyof T] as string;

  return (
    (typeData && typeData !== 'Unknown') ?
      <Chip label={typeData} className={clsx(`chip--${typeData?.toLowerCase()}`)} /> :
      <NotAvailable label='Unknown' />
  );
};

export default TypeChip;