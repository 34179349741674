import {
  AttachmentComponentProps,
  AttachmentType,
} from '../../../../../Models';

import styles from './AttachmentComponent.module.scss';

const AttachmentComponent = ({
  type,
  source,
  fileName,
}: AttachmentComponentProps): JSX.Element => (
  <div className='direction--column'>
    {type === AttachmentType.Image && (
      <img
        className={styles.imageAttachment}
        alt='Attachment source'
        src={source}
      />
    )}
    <div className={styles.downloadPanel}>
      <i className='iconoir-attachment icon--tiny icon--sky-80' />
      <span className={styles.fileName}>{fileName}</span>
      <div className={styles.downloadButtonContainer}>
        <a
          href={source}
          target='_blank'
          rel='noreferrer'
          role='tab'
          download={fileName}
        >
          <i className='iconoir-download icon--medium' />
        </a>
      </div>
    </div>
  </div>
);

export { AttachmentComponent };
export default AttachmentComponent;
