import { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react';
import { Button } from 'primereact/button';

interface DeletionPopupFooterProps {
  onCancel: () => void;
  onDelete: () => void;
}

export interface DeletionPopupFooterReferenceProps {
  setIsDeleting: (arg: boolean) => void;
}

export const DeletionPopupFooter = forwardRef<DeletionPopupFooterReferenceProps, DeletionPopupFooterProps>(
  (props: DeletionPopupFooterProps, ref: ForwardedRef<DeletionPopupFooterReferenceProps>): JSX.Element => {
    const { onCancel, onDelete } = props;
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({ setIsDeleting }));

    return <div className='space-between'>
      <Button
        autoFocus
        text
        size='small'
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        outlined
        size='small'
        severity='danger'
        loading={isDeleting}
        onClick={onDelete}
      >
        Delete
      </Button>
    </div>;
  }
);