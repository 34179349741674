import type { DateTime } from 'luxon';

import styles from './LastUpdatedInfo.module.scss';

interface LastUpdatedInfoProps {
  lastUpdated: DateTime;
}

export default function LastUpdatedInfo(props: LastUpdatedInfoProps): JSX.Element {
  const { lastUpdated } = props;

  return <div className={styles.lastUpdatedInfo}>
    Last updated {lastUpdated.toFormat('hh:mm:ss LLL dd yyyy (ZZZZ)')}
  </div>;
}