import { clsx } from 'clsx';

import GridElement from 'components/GridElement';

import QuantityBody from '../QuantityBody';

import { SingleLineFlagged } from 'helpers/DataTable/Templates/ColumnTemplates';
import { laycanStyleRange } from 'helpers/Utils/formatters';
import { camelToSpace } from 'helpers/Utils/string';
import type { CargoTrackerResponse } from 'modules/CargoTracker/Models/CargoTrackerResponse';
import { StatusEnum } from 'modules/CargoTracker/Models/Enums';

interface TimeCharterItemProps {
  data: CargoTrackerResponse;
  handleRowClick: (value: CargoTrackerResponse) => void;
}

export default function TimeCharterItem(props: TimeCharterItemProps): JSX.Element {
  const { data, handleRowClick } = props;
  return <div className={clsx('cargo-element', data.isUrgent && 'cargo-element--urgent')} onClick={():void => handleRowClick(data)}>
    <GridElement header="Delivery" body={SingleLineFlagged(data.loading, {field: 'flagCode,portName,portCountry,zone.value'})} />
    <GridElement header="Delivery Date" body={laycanStyleRange(data.laycan?.fromDateParsed ?? '', data.laycan?.toDateParsed ?? '')}/>
    <GridElement header="Re-Delivery" body={SingleLineFlagged(data.discharging, {field: 'flagCode,portName,portCountry,zone.value'})} />
    <GridElement header="Charter Period"
      body={data.charterPeriod} />
    <GridElement header="Vessel Size" body={QuantityBody(data)} />
    <GridElement header="Status" body={camelToSpace(StatusEnum[data.status])} />
  </div>;
}