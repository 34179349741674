import type { RelativeAutocompleteSuggestionItem } from 'modules/Blotter/Components/RelativeAutocomplete';

import styles from './RelativeAutocompleteItem.module.scss';

const RelativeAutocompleteItemTemplate = (suggestion: RelativeAutocompleteSuggestionItem, currentValue: string): JSX.Element => {
  return <div className={styles.autocompleteItem}>
    <span>{suggestion.value}</span>
    {suggestion.value === currentValue ? <i className='iconoir-check icon--small icon--secondary' /> : null}
  </div>;
};

export default RelativeAutocompleteItemTemplate;