interface IComponentParams {
  name: string;
}

const ShareWorksheetDialogBody = ({ name }: IComponentParams): JSX.Element => (
  <>
    <strong>Share {name}</strong>
    Please note that if you choose to share this sheet, everyone in your office
    will have access to it.
  </>
);

export { ShareWorksheetDialogBody };
export default ShareWorksheetDialogBody;
