import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    ChartOptions,
    CategoryScale,
    LinearScale,
    BarElement,
    Title
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { SearchFacet } from "models/shared/search-facet";

import {
    AXIS_LABEL_COLOUR, AXIS_LINE_COLOUR
  } from "./ChartProps";

const randomcolor = require('randomcolor');

interface facetBarChartProperties {
    facets: SearchFacet[]
    title: string
}



export default function FacetBarChart(props: facetBarChartProperties) {
    ChartJS.register(CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend, ArcElement);

    const [facetChart, setFacetChart] = useState<any>(null);

    useEffect(() => {
        if (props.facets?.length > 0) {
            const facetMap = new Map<string, number>(
                props.facets.map((o) => {
                    let { value, count } = o;
                    return [value, count];
                })
            );

            const labels: string[] = [];
            const counts: number[] = [];

            facetMap.forEach((value: number, key: string) => {
                labels.push(key);
                counts.push(value);
            });

            const chartData = {
                labels: labels,
                datasets: [
                    {
                        label: null,
                        data: counts,
                        fill: true,
                        borderColor: "rgba(190, 56, 242, 1)",
                        backgroundColor: randomcolor({
                            count: labels.length + 1,
                            format: 'rgba',
                            luminosity: 'light'
                        }),
                        tension: 0.3
                    }
                ]
            }
            setFacetChart(chartData);
        } else {
            setFacetChart(null);
        }
    }, [props.facets])

    if (!props.facets) {
        return <></>
    }

    if (!facetChart) {
        return <></>
    }

    const options: ChartOptions<any> = {
        indexAxis: 'y' as const,
        plugins: {
            legend: { display: false },
        },
        scales: {
            y: { ticks: { color: AXIS_LABEL_COLOUR }},
            x: { 
                border: { color: AXIS_LINE_COLOUR },
                ticks: { color: AXIS_LABEL_COLOUR },
                grid: { color: [AXIS_LINE_COLOUR] }
            }
        }
    };

    return <Bar height={"240px"} data={facetChart} options={options} />



}

