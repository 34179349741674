import NotAvailable from 'components/NotAvailable';
import { getValueByPath } from 'helpers/Utils/misc';

import type { ReactNode } from 'react';
import type { ColumnBodyOptions } from 'primereact/column';

import styles from './SingleLineWithAddon.module.scss';

export function SingleLineWithAddon<T,>(data: T, config: ColumnBodyOptions, addon: ReactNode): JSX.Element {

  const { field } = config;
  const value = getValueByPath<T>(data, field);



  return <div className={styles.container}>
    {(value || value === 0) ? <>
      <span>{value}</span>
      <span className={styles.addon}>{addon}</span>
    </>
      : <NotAvailable label='Unknown' />}
  </div>;
};

export default SingleLineWithAddon;