import { SignalRApi } from 'server/signalR-api';
import { getValueCollection } from 'helpers/Utils/enum'

export enum WorkflowSignalEventTypes {
	WORKFLOW_CHANGED = "workflowUpdates"
}

export class WorkflowSocket {

	private static _instance: WorkflowSocket

	static get instance() {
		return this._instance || (this._instance = new this());
	}


	init = async (sr: SignalRApi): Promise<void> => {

		const events = [
			...getValueCollection(WorkflowSignalEventTypes, false)
		]

		//	Create eventbus dispatchers for all appropriate signalR 
		//	event types
		events.map(event => sr.dispatchEventOnMessage(event.key))
	}

	// private send = (event: PositionListSignalEventTypes | WorksheetSignalMessageEventTypes, data: any) => eventBus.dispatch(event, {...JSON.parse(data)});
}