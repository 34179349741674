import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import {
  Slider,
  SliderChangeEvent,
  SliderSlideEndEvent,
} from 'primereact/slider';

import {
  type ConditionalFilter,
  type ConditionalFilterChange,
  ProbabilityLevels,
} from 'modules/CargoFlows/Models/CargoMovements';

import './ProbabilityLevelFilter.scss';

// Could be 1, 2, 3, but Slider poorly handles clicking on (and near to) the "Low" option so
// it's better to use those numbers and snap to the closest option.
enum ProbabilityLevelMap {
  High = 0,
  Medium = 50,
  Low = 100,
}

const STEP = 50; // NOTE: update if ProbabilityLevelMap items changed
const GROUP_NAME = 'Probability';

interface ProbabilityLevelFilterProps {
  activeFilters: ConditionalFilter[];
  onChange: (arg: ConditionalFilterChange, groupName: string) => void;
}

export default function ProbabilityLevelFilter(
  props: ProbabilityLevelFilterProps
): JSX.Element {
  const { activeFilters, onChange } = props;
  const [value, setValue] = useState<number | null | undefined>(undefined);
  const [checked, setChecked] = useState(false);

  const infoText = useMemo(() => {
    switch (value) {
      case ProbabilityLevelMap.Low:
        return 'Will only INCLUDE results which have at LEAST a LOW probability rating';
      case ProbabilityLevelMap.Medium:
        return 'Will only INCLUDE results which have at LEAST a MEDIUM probability rating';
      case ProbabilityLevelMap.High:
        return 'Will only INCLUDE results which are rated as HIGH probability';
      default:
        return null;
    }
  }, [value]);

  useEffect(() => {
    const probabilityFilter = activeFilters.find(
      item => item.group === GROUP_NAME
    );

    if (probabilityFilter) {
      setChecked(true);
      setValue(
        ProbabilityLevelMap[
          ProbabilityLevels[
            probabilityFilter.value as number
          ] as keyof typeof ProbabilityLevelMap
        ]
      );
    } else {
      // no filter enabled
      setChecked(false);
      setValue(null);
    }
  }, [activeFilters]);

  const notifyValueChanged = (newValue: number | null) => {
    if (newValue === undefined) {
      // just don't call callback if value is not set yet
      return;
    }

    if (newValue === null) {
      onChange({ filters: [], tokens: [] }, GROUP_NAME);
      return;
    }

    let probability;

    switch (newValue) {
      case ProbabilityLevelMap.High:
      default:
        probability = ProbabilityLevels.High;
        break;
      case ProbabilityLevelMap.Medium:
        probability = ProbabilityLevels.Medium;
        break;
      case ProbabilityLevelMap.Low:
        probability = ProbabilityLevels.Low;
        break;
    }

    onChange(
      {
        filters: [
          {
            condition: '>',
            fieldName: 'Probability',
            value: probability,
            group: GROUP_NAME,
          },
        ],
        tokens: [
          {
            searchTerm: `Probability > ${probability * 100}`,
            context: 'Is',
            searchField: null as any,
            key: GROUP_NAME,
          },
        ],
      },
      GROUP_NAME
    );
  };
  const handleCheck = (e: CheckboxChangeEvent) => {
    const newValue = e.checked ? ProbabilityLevelMap.Low : null;
    setChecked(Boolean(e.checked));
    setValue(newValue);
    notifyValueChanged(newValue);
  };
  const sliderChanged = (e: SliderChangeEvent) => {
    const newValue = Math.round((e.value as number) / STEP) * STEP;
    setValue(newValue);
  };
  const slideEnd = (e: SliderSlideEndEvent) => {
    const newValue = Math.round((e.value as number) / STEP) * STEP;
    notifyValueChanged(newValue);
  };

  return (
    <div className="additional-filters__container--internal probability-level-filter__container">
      <div className="additional-filters__checkbox">
        <Checkbox
          inputId="probability-level-filter--checkbox"
          checked={checked}
          onChange={handleCheck}
        />
        <label htmlFor="probability-level-filter--checkbox">Probability</label>
      </div>
      <div
        className={clsx('probability-level-filter__slider', {
          disabled: !checked,
        })}
      >
        <label htmlFor="probability-level-filter--slider">
          <span>High</span>
          <span>Medium</span>
          <span>Low</span>
        </label>
        <Slider
          disabled={!checked}
          id="probability-level-filter--slider"
          min={ProbabilityLevelMap.High}
          max={ProbabilityLevelMap.Low}
          value={value ?? ProbabilityLevelMap.Low}
          onChange={sliderChanged} // snap to closest value
          onSlideEnd={slideEnd}
        />
      </div>
      <p className="additional-filters__info">{infoText}</p>
    </div>
  );
}
