import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

import styles from './ReportLinkExpiredPage.module.scss';

const ReportLinkExpiredPage = (): JSX.Element => {
  const navigate = useNavigate();

  const openSurveillance = (): void => {
    navigate('/surveillance');
  };

  return <main className={styles.container}>
    <img className={styles.image} alt='' src='/img/icons/surveillance-link-has-expired.svg'></img>
    <div className={styles.action}>
      <h2 className={styles.title}>
        Report download no longer available
      </h2>
      <h5 className={styles.subTitle}>
        The link you are trying to access has expired. You will need to return to the Surveillance module and request a new export
      </h5>
      <Button size='small' onClick={openSurveillance}>Open Surveillance</Button>
    </div>
  </main>;
};

export default ReportLinkExpiredPage;