import { useMemo } from 'react';
import { Button } from 'primereact/button';

import './EmptyPage.scss';

export enum EmptyPageMode {
  EmptyResults = 0,
  EmptyStatus,
  NoWorkflowSelected
}

interface WorkflowEmptyPageProps {
  mode: EmptyPageMode;
  onAddWorkflowButtonClick?: () => void;
}

export default function WorkflowEmptyPage(props: WorkflowEmptyPageProps): JSX.Element {
  const { onAddWorkflowButtonClick, mode } = props;

  const config = useMemo(() => {
    switch (mode) {
      case EmptyPageMode.NoWorkflowSelected:
        return {
          icon: 'iconoir-doc-magnifying-glass',
          label: 'Please select a workflow to see details'
        };
      case EmptyPageMode.EmptyResults:
        return {
          icon: 'iconoir-folder-plus',
          label: 'It looks like no workflows have been added yet'
        };
      case EmptyPageMode.EmptyStatus:
      default:
        return {
          icon: 'iconoir-folder-plus',
          label: 'There are currently no workflows'
        };
    }
  }, [mode]);

  return <div className='workflow-empty-page'>
    <div className='workflow-empty-page__icon-container'>
      <i className={config.icon} />
      <span>{config.label}</span>
    </div>
    {mode === EmptyPageMode.EmptyResults && <Button
      className='add-workflow__button'
      size='small'
      onClick={onAddWorkflowButtonClick}
    >
      Add workflow
    </Button>}
  </div>;
}