import { ReactNode, useEffect, useRef } from 'react';

import './Ribbons.scss';

interface RibbonsContainerProps {
    children: ReactNode;
};

export default function RibbonsContainer(props: RibbonsContainerProps): JSX.Element {
    const { children } = props;
    const ribbonsContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const hamburgerIcon = document.getElementsByClassName('left-menu__hamburger')[0] as HTMLDivElement;
        
        if (hamburgerIcon) {
            // Set marginTop to the manu hamburger icon so it doesn't overlap ribbons. 
            // In case of multiple Ribbons container height may vary.
            hamburgerIcon.style.marginTop = `${ribbonsContainerRef.current?.clientHeight ?? 0}px`
        }
    })

    return <div ref={ribbonsContainerRef} className="ribbons-container">
        {children}
    </div>
}
