const wait = (ms:number) => new Promise(r => setTimeout(r, ms));

/**
 * Pub/sub mechanic that provides the ability to dispatch and subscribe to events from other
 * decoupled components within the platform
 *
 *
 */
export const eventBus = {


	/**
   * Subscribes to desired events, triggering a passed callback function
   *
   * @param {string} event - Name of event to subscribe too. Can either be a primitive string value, or string enum
   * @param {*} callback - Method to be executed on receipt of the event.
   */
  on(event: string, callback: any) {
		document.addEventListener(event, callback)
	},

	async dispatch<T>(event: string, data: T, delay:number = 0, cancelable = false) {

		await wait(delay);

		return document.dispatchEvent(new CustomEvent(event, { detail: data, cancelable }))
	},

	remove(event: string, callback?: any) {
		document.removeEventListener(event, callback)
	}
}

export default eventBus;