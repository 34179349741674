import clsx from 'clsx';

import './NotePanel.scss';

enum NotePanelType {
  Info = 'Info',
  Error = 'Error',
}
interface INotePanelProps {
  type: NotePanelType;
  header?: string | JSX.Element;
  body?: string | JSX.Element | JSX.Element[];
  className?: string;
}

const getIconByType = (type: NotePanelType): string => {
  switch (type) {
  case NotePanelType.Error:
    return 'iconoir-priority-high';
  case NotePanelType.Info:
    return 'iconoir-info-circle';
  default:
    return '';
  }
};

const NotePanel = ({
  type,
  header,
  body,
  className,
}: INotePanelProps): JSX.Element => {
  const color = `note-panel-colors--${ type.toLowerCase() }`;
  const icon = getIconByType(type);

  return (
    <div className={clsx('note-panel--container', color, className)}>
      <div className='note-panel--header'>
        <div className={clsx(icon, 'icon--small')} />
        <div>{header}</div>
      </div>
      <div className='note-panel--body'>{body}</div>
    </div>
  );
};

export { NotePanel, NotePanelType };
export default NotePanel;
