import axios from 'axios';
import type { AxiosResponse } from 'axios';

import { apiPackage, apiPackageProduct } from '../Models/apiResponse'
import {
  ArtisPackage,
  ArtisProduct,
  ApiArtisPackageProduct,
  TenorFrequency,
  TenorPeriods,
  TenorWindowKey 
} from '../Models/Packages';

import { camelToSpace, capFirstLetter } from 'helpers/Utils/string';

export class ArtisPackagesAPI {

  static WINDOW_ORDER: (keyof TenorWindowKey)[] = [
    "today",
    "weekToDate",
    "last7Days", 
    "last14Days", 
    "monthToDate", 
    "last30Days", 
    "last90Days", 
    "yearToDate", 
    "oneYear", 
    "threeYears",
    "fiveYears" 
  ]

  static getPackages = () => axios.request<null, AxiosResponse<apiPackage[]>>({
    url: 'datafeed/user/packages',
    method: 'GET'
  }).then(
    ({data}) => data.map<ArtisPackage>(({label, id: source, groupable = true}) => ({label, source, groupable}))
  )

  static getProducts = (artispackage: string) => axios.request<null, AxiosResponse<apiPackageProduct[]>>({
    url: `datafeed/user/products/${artispackage}`,
    method: 'GET'
  }).then(
    (result) => result.data.map<ArtisProduct>( 
    ({label, unit: apiunt, id, windows: apiwindows}) => {

      const unit = apiunt?.replace("_", " / ");
      
      
      const windows: TenorFrequency[] = Object.keys(apiwindows)
      .reduce((a: TenorFrequency[], key: string) => {

        const periods: TenorPeriods[] = apiwindows[key as keyof TenorWindowKey];
        const label: string = capFirstLetter(camelToSpace(key, { lower: false, spaceAroundNumber: true }));

        return [
          ...a, 
          { label, frequency: key as keyof TenorWindowKey, periods }
        ];
        
      }, [])
      .sort(({frequency:a}, {frequency: b}) => {
        /** 
        * Sort results according to the sequential order. Makes for 
        * easier iteration over items upstream in the UI
        **/
        return ArtisPackagesAPI.WINDOW_ORDER.findIndex(p => p === a) - ArtisPackagesAPI.WINDOW_ORDER.findIndex(p => p === b);
      });

      return {label, id, name: id, unit, windows}
    })
  )
}
