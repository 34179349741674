import { KeyboardEvent, useState } from 'react';
import clsx from 'clsx';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Badge } from 'primereact/badge';

import './AdditionalFilters.scss';

const MAX_FILTERS_COUNT = 99;

interface AdditionalFiltersProps {
    children: JSX.Element[];
    className?: string;
    isMobileMode?: boolean;
  filterCount?: number;
}

// Please note, that it's used also in Cargo Tracker so any change here should be verified also there
const AdditionalFilters = (props: AdditionalFiltersProps): JSX.Element => {
  const { children, className, isMobileMode = false, filterCount } = props;
  const [ isPanelOpen, setIsPanelOpen ]= useState(isMobileMode);

  const togglePanel = ():void => {
    setIsPanelOpen(open => !open);
  };

  const handleKeyDown = (e: KeyboardEvent): void => {
    if (isPanelOpen && e.key === 'Escape') {
      togglePanel();
    }
  };

  return <div className={clsx('additional-filters__container', className)} onKeyDown={handleKeyDown}>
    <Button className='p-overlay-badge additional-filters__button-toggle' size='small' text icon="iconoir-filter icon--tiny" onClick={togglePanel}>
      {(filterCount || filterCount === 0) && <Badge value={filterCount > MAX_FILTERS_COUNT ? `${MAX_FILTERS_COUNT}+` : filterCount} />}
    </Button>
    {isPanelOpen && <div className='additional-filters__overlay-element' onClick={togglePanel} />}
    <Panel
      toggleable={false}
      header={ isMobileMode ? undefined : 'Additional Filters' }
      className={clsx({ 'hidden': !isPanelOpen })}
    >
      <>{children}</>
      <Button
        text
        icon="iconoir-xmark icon--tiny"
        className="additional-filters__close_button"
        onClick={togglePanel}
      />
    </Panel>
  </div>;
};

export default AdditionalFilters;