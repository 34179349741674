import type { WorksheetMetaProps, WorksheetSearchField } from 'components/Worksheets/Models/WorksheetResponse';

export const additionalSearchPropParser = (
  props: WorksheetMetaProps[]
): WorksheetMetaProps[] => props.map(p => {
  try {
    return typeof p.value === 'string' ? { ...p, value: JSON.parse(p.value as string) } : p;
  } catch (e) {
    return p;
  }
});

// TODO: update if needed
const fieldsParser = (fields: WorksheetSearchField[]): WorksheetSearchField[] => fields.map(field => {
  const { metaData, searchField, ...rest } = field;

  const props = metaData?.reduce((acc: Record<string, any>, cur: WorksheetMetaProps) =>
    ({ ...acc, [cur.key as string]: cur.value }), {});

  return {
    ...rest,
    ...props,
    searchField: +searchField,
    metaData
  };
}
)

const hydrator = (): WorksheetMetaProps[] => [];

export const tradeSearchWorksheetParsers = {
  propsParser: additionalSearchPropParser,
  fieldsParser,
  hydrator,
};