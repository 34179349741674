import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { usePackages, useProducts } from 'modules/ArtisCharting/Services/hooks';
import { ArtisPricesSocket } from 'modules/ArtisCharting/Services/Socket';
import { ArtisPricesAPI } from 'modules/ArtisCharting/Services/PricesApi';
import { useSignalR } from 'App';

import ToastMessage, { ToastMessageRef } from 'components/ToastMessage';

import NoAccess from '../NoAccess';
import CandleStickChart from '../CandleStickChart';
import PriceChangeSubscriptions from '../PriceChangeSubscriptions';

import type { ActiveChartPrice } from 'modules/ArtisCharting/Models/ArtisPrices';
import type { ArtisPackage, TenorPeriods, TenorWindowKey } from 'modules/ArtisCharting/Models/Packages';

import './ChartWindow.scss';

export default function ChartWindow(): JSX.Element {
  const [selectedPackage, setSelectedPackage] = useState<ArtisPackage | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const toastRef = useRef<ToastMessageRef>(null);

  const { artispackage, product, tenorIndex, tenorCode, frequency, period } = useParams();
  const { packages, error, isLoading: isPackagesLoading } = usePackages();
  const { products, isLoading: isProductsLoading } = useProducts(selectedPackage);

  const { signal } = useSignalR();

  // initiate socket so price change subscriptions can be updated
  useEffect(() => {
    let socket: ArtisPricesSocket;
    socket = ArtisPricesSocket.instance;
    socket.init(signal);
  }, [signal]);

  useEffect(() => {
    setIsLoading(isPackagesLoading || isProductsLoading);
  }, [isPackagesLoading, isProductsLoading]);

  useEffect(() => {
    setSelectedPackage(packages.find(p => p.source === artispackage) ?? null);
  }, [artispackage, packages]);

  if (!selectedPackage && packages && !isLoading) {
    return <>
      <main className='direction--column'>
        <NoAccess artispackage={artispackage} />
      </main>
    </>;
  }

  if (error || !tenorIndex || !tenorCode) {
    return <main>No result found.</main>;
  }

  const [tenorName, name] = window.name.split('|');
  const activePrice: ActiveChartPrice = {
    artispackage: selectedPackage,
    index: tenorIndex,
    name,
    product,
    tenorCode,
    tenorName: {
      display: ArtisPricesAPI.formatTenorName(tenorName),
      original: tenorName
    },
    windows: products?.find(p => p.id === product)?.windows ?? []
  };

  return <div className='chart-window__container'>
    <header className='no--tabs'>
      <span>
        <h2>{activePrice.name}</h2>
        <h3>{activePrice.tenorName.display}</h3>
      </span>
    </header>
    <main>
      <CandleStickChart
        isLoading={isLoading}
        price={activePrice}
        frequency={frequency as keyof TenorWindowKey}
        period={period as TenorPeriods}
        toastRef={toastRef}
      />
      <PriceChangeSubscriptions price={activePrice} />
      <ToastMessage ref={toastRef} />
    </main>
  </div>;
}