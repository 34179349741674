import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { CreatedOrUpdatedInfo } from 'modules/CargoTracker/Models/CargoTrackerResponse';

const BADGE_VISIBILITY_MS = 1000 * 60 * 10; // 10 minutes
const DEF_DIFF = BADGE_VISIBILITY_MS + 1;

export const  SingleLineTimeoutBadge = <T,>(data: T, config: {field: string}): JSX.Element => {
  const [showBadge, setShowBadge] = useState<boolean>(false);
  const [isBadgeTimeout, setIsBadgeTimeout] = useState<boolean>(false);

  const [ createdField, updatedField ] = config.field.split(',');
  const created = data[createdField as keyof T] as CreatedOrUpdatedInfo;
  const updated = data[updatedField as keyof T] as CreatedOrUpdatedInfo;
  const isNew = created.date === updated.date;
  const diff = !updated.dateParsed ? DEF_DIFF: -updated.dateParsed.diffNow('millisecond').milliseconds || DEF_DIFF;
  
  useEffect(() => {
    if (diff < BADGE_VISIBILITY_MS) {
      setShowBadge(true);
      if (!isBadgeTimeout) {
        setTimeout(() => {
          setShowBadge(false);
          setIsBadgeTimeout(false);
        }, BADGE_VISIBILITY_MS - diff);
      }      
      setIsBadgeTimeout(true);
    }
  }, [diff, isBadgeTimeout]);

  return <>
    {showBadge && <span className={clsx('time-badge', isNew && 'time-badge-new')}>{ isNew ? 'N' : 'U' }</span>}
  </>;
};
