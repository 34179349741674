import { Dispatch, SetStateAction } from 'react';
import { Button } from 'primereact/button';

import { CargoTrackerResponse } from '../../Models/CargoTrackerResponse';
import { useDeleteCargo } from '../../Services/CargoTrackerAPI';

import { GlobalDialogDisplayEvents } from 'models/shared/DialogDisplay';
import { eventBus } from 'server/EventBus';

interface ITableFooterProps {
  selectedCargo: CargoTrackerResponse[];
  setSelectedCargo: Dispatch<SetStateAction<CargoTrackerResponse[]>>;
  activeCargo: string | null;
  setActiveCargo: Dispatch<SetStateAction<string | null>>;
  showDeleteSuccess: (count: number) => void;
}

const TableFooter = (props: ITableFooterProps): JSX.Element => {
  const { selectedCargo, setSelectedCargo, activeCargo, setActiveCargo, showDeleteSuccess } = props;

  const TableFooterDialogBody = (params: { count: number }): JSX.Element => {
    const text = params.count > 1 ?
      `Are you sure you want to permanently delete these ${ params.count } cargos?` :
      'Are you sure you want to permanently delete this cargo?';
    return (
      <div className='table-footer-dialog-body'>
        {text}< br />
        You can&apos;t undo this
      </div>
    );
  };

  const TableFooterDialogFooter = (): JSX.Element => {
    const { remove, isMutating} = useDeleteCargo(selectedCargo);
    
    return <div className="space-between">
      <Button
        size='small'
        text
        onClick={():Promise<boolean> => eventBus.dispatch(GlobalDialogDisplayEvents.HIDE, null)}
      >
        Cancel
      </Button>
      <Button
        size='small'
        severity='danger'
        outlined
        autoFocus
        loading={isMutating}
        onClick={async ():Promise<void> => {
          const ids = selectedCargo.map(m => `${ m.id }`);
          await remove({ids: ids});
          eventBus.dispatch(GlobalDialogDisplayEvents.HIDE, null);
          showDeleteSuccess(selectedCargo.length);
          setSelectedCargo([]);
          if (activeCargo && ids.includes(activeCargo)) {
            setActiveCargo(null);
          }
        }}
      >
        Delete
      </Button>
    </div>;
  };

  return <footer>
    Selected: {selectedCargo.length} { selectedCargo.length > 1 ? 'items' : 'item' }
    <Button
      text size="small"
      severity='danger'
      onClick={ ():void => {
        eventBus.dispatch(
          GlobalDialogDisplayEvents.DISPLAY,
          {
            header: 'Delete cargo',
            body: <TableFooterDialogBody count={selectedCargo.length} />,
            footer: <TableFooterDialogFooter />,
            size: 'medium',
          }
        );
      }}
    >
      Delete Cargo
    </Button>
  </footer>;
};

export default TableFooter;