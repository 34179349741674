import { RefObject, useEffect, useMemo, useState } from 'react';
import { Button, ButtonProps } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import eventBus from 'server/EventBus';

interface CargoEditWarningDialogProps {
  containerRef: RefObject<HTMLElement>;
  handleClose: () => void;
  isMutating: boolean;
  isChanged: boolean;
}

export enum DistListWarningDialogEvents {
  ACTION = 'onDistListCloseEditWarningBeforeAction',
}

const CloseWarningDialog = (
  props: CargoEditWarningDialogProps
): JSX.Element => {
  const { containerRef,
    handleClose,
    isMutating,
    isChanged
  } = props;

  const [ isWarningDialogVisible, setIsWarningDialogVisible ] = useState<boolean>(false);

  const primaryButtonProps = useMemo(
    () => ({
      onClick: ():void => {
        setIsWarningDialogVisible(false);
        handleClose();
      },
      children: 'Close Editor',
      severity: 'danger' as ButtonProps['severity'],
      text: false,
      outlined: true,
      disabled: isMutating,
    }),
    [handleClose, isMutating, setIsWarningDialogVisible]
  );

  const secondaryButtonProps = useMemo(
    () => ({
      onClick: ():void => {
        setIsWarningDialogVisible(false);
      },
      children: 'Return to Editor',
      severity: undefined,
      text: true,
      outlined: true,
      disabled: isMutating,
    }),
    [isMutating, setIsWarningDialogVisible]
  );

  useEffect(() => {
    const onShowDialog = (event: CustomEvent): void => {
      if (isChanged) {
        setIsWarningDialogVisible(true);
      } else {
        event.detail.action();
      }

      event.preventDefault();
    };

    eventBus.on(DistListWarningDialogEvents.ACTION, onShowDialog);

    return () => {
      eventBus.remove(DistListWarningDialogEvents.ACTION, onShowDialog);
    };
  }, [isChanged]);

  return (
    <Dialog
      appendTo={containerRef.current}
      position='top'
      className="p-dialog--no-header"
      contentClassName="grow-to-fill"
      visible={isWarningDialogVisible}
      footer={
        <div className='grow-to-fill space-between'>
          <Button size="small" type="button" {...primaryButtonProps}></Button>
          <Button size="small" type="button" {...secondaryButtonProps}></Button>
        </div>
      }
      onHide={():void => setIsWarningDialogVisible(false)}
    >
      <h4>You have unsaved changes</h4>
      <div>
        If you Close Editor any changes you&apos;ve made will be lost.
      </div>
    </Dialog>
  );
};
export default CloseWarningDialog;
