import { SetStateAction, Dispatch } from 'react';

import { Button } from 'primereact/button';
import { removeItemByKeyValue } from 'helpers/Utils/collections';

import { type BasicRow } from "../Grids/SimpleGrid";

type SelectedRowsProps = {
	selectedRows: BasicRow[],
	setSelectedRows: Dispatch<SetStateAction<BasicRow[]>>
}

const SelectedRows = (props: SelectedRowsProps): JSX.Element => {

	const { selectedRows = [], setSelectedRows } = props;

	const handleTokenRemoval = (data: BasicRow) => {
		setSelectedRows(
			curr => removeItemByKeyValue<BasicRow, string>(curr, 'hash', data.hash)
		)
	}

  return <div>
		<label>Records:</label>
		<div className='act-as--input align-items--center'>
			{
				selectedRows.map((r, i) => <div
					key={`sr-${i}`} 
					className='p-autocomplete-token small'>
					{ r.shortName 
						? <><strong>{r.location}:</strong> { r.shortName }</>
						: <>{ r.location }</>
					}
					<Button
						text size="small"
						icon="iconoir-xmark icon--tiny icon--black"
						onClick={() => handleTokenRemoval(r)}
						className="no--background"
					/>
				</div>)
			}
		</div>
	</div>;
}

export default SelectedRows;
