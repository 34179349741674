import clsx from 'clsx';
import { IconOptions } from 'primereact/utils';
import { AutoCompleteProps } from 'primereact/autocomplete';
import { HTMLProps, KeyboardEvent } from 'react';

export default function RemoveChipIcon(props: IconOptions<any, AutoCompleteProps>): JSX.Element {
    function handleKeyDown(e: KeyboardEvent<HTMLDivElement>) {
      if (e.key !== "Enter") {
        return;
      }
      
      (e.target as HTMLDivElement).click()
    }

    return <div
        {...props.iconProps as HTMLProps<HTMLDivElement>}
        className={clsx(props.iconProps.className, "iconoir-xmark icon--tiny")}
        onClick={props.iconProps.onClick}
        onKeyDown={handleKeyDown}
        // tabIndex={2}
        data-testid="remove-chip-icon"
    />
}