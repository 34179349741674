import { ColumnBodyOptions } from "primereact/column";
import { clsx } from 'clsx';

type RenderingOptions = {
	unit?: string;
	alignment?: 'right' | 'left' | 'center';
	multiplier?: number;
	transformer?: (s: string) => string;
	decimalPlaces?: number
}

export const NumericalUnit = <T,>(data: T, config: ColumnBodyOptions, opts: RenderingOptions): JSX.Element => {

	const { field } = config;
	const { unit, transformer, decimalPlaces = 3 } = opts;
	const value = +data[field as keyof T];

	if (Number.isNaN(value)) return <></>

	return <div className={clsx(
		opts.alignment === 'right' && 'cell--aligned-right'
	)}>
		{ 
			Intl.NumberFormat(
				'en-US',
				{ minimumFractionDigits: decimalPlaces, style: 'decimal', }
			).format(opts.multiplier ? value * opts.multiplier : value).replace(/,/gi, ', ')
		}
		{ unit && 
			<var>{ transformer ? transformer(unit) : unit }</var>
		}
	</div>

}