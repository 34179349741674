import { useCallback } from 'react';

import { Flag } from 'components/Flag';
import { getValueByPath } from 'helpers/Utils/misc';


export const  SingleLineFlagged = <T,>(data: T, config: {field: string}) => {
    const [ isoCode, ...paths ] = config.field.split(',');

    const getValue = useCallback(() => {
      let value;

      for (let i = 0; i < paths.length; i++) {
        value = getValueByPath(data, paths[i]);

        if (value) break;
      }

      return value ?? 'N/A';
    }, [data, paths]);


    return <div className="space-between grow-to-fill flagged">
        <span>{getValue()}</span>
        { isoCode && data[isoCode as keyof T] !== 'UNK' &&
          <Flag code={data[isoCode as keyof T] as string} />
        }
    </div>
}
