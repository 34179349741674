import { useState, useEffect, useRef } from 'react';
import { NavigateFunction, useNavigate,  } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { clsx } from 'clsx';

import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';

import SecondaryNavigation from "components/SecondaryNavigation";
import { useSaveUserSetting, useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';
import { UISettings } from 'components/OBXUser/Model/Enums';
import { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';
import DateTimeRange from 'components/DateTimeRange';
import ToastMessage, { ToastMessageRef } from 'components/ToastMessage';
import ToggleSwitch from "components/ToggleSwitch";

import Chart, { type ChartReferenceProps } from './Components/Chart';
import {
	AvailableModGridResponse, 
	IEAModSources, 
	JodiSources,
	IEAModSearchParams
} from './Models/IEAMods';
import { Frequency } from './Models/Enums';
import TertiaryNavigation from '../Grids/Components/TertiaryNavigation';
import { useAvailableGrids } from './Services/hooks';
import SimpleGrid, { BasicRow } from "./Components/Grids/SimpleGrid";
import GroupableGrid from "./Components/Grids/GroupableGrid";
import ColumnLayoutSelector, { ChartingDisplayState } from '../../../components/ColumnLayoutSelector';
import SelectedRows from './Components/SelectedRows';
import SelectedColumns from './Components/SelectedColumns';


import styles from './IEAModsData.module.scss';
import BorealisBar from 'components/BorealisBar';

interface IModsDataParms {
	items: any[];
	feed: 'jodi' | 'iea';
}

export type Grouping = {
	label: string;
	key: "location" | "shortName";
}

type IEAMODSaveSetting = {
	lastVisited?: AvailableModGridResponse,
	unit?: boolean;
	search?: IEAModSearchParams;
	frequency?: Frequency
}

export type BasicColumn = {
	name: string,
	label: string,
	unit: string;
}

function IEAModsData(props: IModsDataParms) {

	const { items, feed } = props;
	const { source } = useParams();

	const setting: UISettings = feed === 'iea' ? UISettings.IEA_MOD_SETTINGS : UISettings.JODI_MOD_SETTINGS;

	//	Use custom hooks
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });
	
	const { trigger: saveGridSetting } = useSaveUserSetting();
	const { getSetting } = useLoadUserSettings();
	const userSetting = getSetting(setting) as IEAMODSaveSetting;

	const { data: availableGrids, isLoading: loadingNavigation } = useAvailableGrids(feed);
	const navigate: NavigateFunction = useNavigate();

	const [ selectedRateGrid, setSelectedRateGrid ] = useState<AvailableModGridResponse | null>(null)
	const [ grouping, setGrouping ] = useState<Grouping>({label: 'Product', key: 'shortName'});
	const [ groupingOptions, setGroupingOptions ] = useState< Grouping[] | null>(null)
	const [ filter, setFilter ] = useState<{collapsed: boolean}>({
		collapsed: isTabletOrMobile
	});
	const [ availableDimensions, setAvailableDimensions ] = useState<string>();
	const [ unitAsDecimal, setUnitAsDecimal ] = useState<boolean>(userSetting?.unit ?? true);
	const [ selectedRows, setSelectedRows ] = useState<BasicRow[]>([]);
	const [ selectedColumns, setSelectedColumns ] = useState<BasicColumn[] | null>(null);
	const [ showCharting, setShowCharting ] = useState<ChartingDisplayState>(ChartingDisplayState.Hidden);
	const [ layoutSettings, setLayoutSettings ] = useState<string>('');
	
	const [ searchParam, setSearchParam ] = useState<IEAModSearchParams>(userSetting?.search ?? {});
	const [ frequency, setFrequency ] = useState<Frequency>(userSetting?.frequency ?? Frequency.Monthly);

	const nodeRef = useRef<HTMLDivElement>(null);
	const chrt = useRef<ChartReferenceProps>();
	const toast = useRef<ToastMessageRef>(null);

	const switchGrid = (grid: AvailableModGridResponse) => {
		if (!grid) return;

		//	set passed value as current 
		setSelectedRateGrid(grid);

		navigate(`/rates/${feed}-mods/${grid.name}`);
	}

	useEffect(() => {
		if (!availableGrids) return;

		//	We've got the list of grids viewable so we can determine whether
		//	to show either:
		if (source) {
			//		…the one identified in the url
			const match = availableGrids.find(g => g.name === source);
			
			if (match) {
				setSelectedRateGrid(match);
				return;
			}
		}

		if (!userSetting?.lastVisited) {
			//	… the first item they've been - so default to what ever
			//	is first in the available grids
			switchGrid(availableGrids[0]);
			return;
		}

		if (availableGrids.some(g => g.id === userSetting.lastVisited?.id)) {
			// … the one the user was last looking at
			setSelectedRateGrid(userSetting.lastVisited);
		}

		
	}, [availableGrids])

	useEffect(() => {
		if (!selectedRateGrid) return;
		
		let opts:Grouping[] | null = null;

		//	Grouping for stock data is slightly different…
		switch(selectedRateGrid.name) {
			case 'SUPPLY':
			case 'OECDDE':
			case 'NOECDDE':
			case 'SUMMARY':
			case 'REFTHROUGHPUT':
				opts = null;
				break;
			case 'STOCKDAT':
				opts = [
					{label: 'Location', key:'location'}, 
					{label: 'Sector', key: 'shortName'}
				];
				break;
			default:
				opts = [
					{label: 'Location', key:'location'}, 
					{label: 'Product', key: 'shortName'}
				];
				break;
		}

		setGroupingOptions(opts);
		if (opts) setGrouping(opts[0]);

		//	selected grid changed - so save it to usersetting
		saveGridSetting({
				setting,
				data: {
					...userSetting,
					lastVisited: selectedRateGrid
				}
		});

		setAvailableDimensions(
			new Intl.ListFormat('en-US', {
				style: 'long',
				type: 'disjunction'
			}).format(selectedRateGrid.dimensions)
		)


		resetSelectedRowsAndColumns();

	}, [selectedRateGrid])

	useEffect(() => {
		if (!selectedRows.length) return;

		setShowCharting( curr => {
			return curr === ChartingDisplayState.Hidden ? ChartingDisplayState.Small : curr
		});

	}, [selectedRows])

	useEffect(() => {
		if (showCharting === ChartingDisplayState.Hidden) setLayoutSettings('12,0');

		switch(showCharting) {
			case ChartingDisplayState.Small:
				setLayoutSettings('9,3');
				break;
			case ChartingDisplayState.Medium:
				setLayoutSettings('6,6');
				break;
			case ChartingDisplayState.Full:
				setLayoutSettings('0,12')
				break;
		}

	}, [showCharting])

	useEffect(() => {
		saveGridSetting({
			setting,
			data: {
				...userSetting,
				unit: unitAsDecimal
			}
			// data: selectedRateGrid
		});
	}, [unitAsDecimal])

	useEffect(() => {
		
		if (!searchParam) return;

		saveGridSetting({
			setting: setting,
			data: {
				...userSetting,
				search: searchParam,
				frequency
			}
		})
		resetSelectedRowsAndColumns();

	}, [searchParam])

	useEffect(() => {

		if (chrt.current) {
			//	TODO - shouldn't have to force this to be any
			(chrt.current as any).resetRequest();
		}
		
		// setSelectedRows([]);
		// setSelectedColumns(null);
		resetSelectedRowsAndColumns();

	}, [source])

	const renderGird = (grid: IEAModSources | JodiSources): JSX.Element => {
		if (!selectedRateGrid) return <></>;

		const props = {
			query: searchParam.query,
			source: grid,
			dimensions: availableDimensions,
			unitAsDecimal: unitAsDecimal,
			feed: feed, 
			selectedRows,
			setSelectedRows,
			changeFrequency
		}

		switch(grid) {
			case IEAModSources.Supply:
			case IEAModSources.DemandOECDDE:
			case IEAModSources.DemandNonOECDDE:
			case IEAModSources.Summary:
			case IEAModSources.Throughputs:
			
				return <SimpleGrid {...props} />

			case IEAModSources.Stocks:
			case IEAModSources.BalancesCrude:
			case IEAModSources.BalancesProduct:
			case IEAModSources.BalancesSubProduct:
			case IEAModSources.TradeImports:
			case IEAModSources.TradeExport:
			case IEAModSources.Electricty:
			case IEAModSources.Jodi:
			case JodiSources.Oil:
			
				return <GroupableGrid {...props} group={grouping} />
			
			default:
				return <div>We dont have data for that source…</div>
			
		}
	}

	const handleParsedDateResult = (result: ParssedDateTimeResult) => {
		const { duration, to, original } = result;

		let query: string | undefined;

		if (duration.quarters === 1) {
			//	duration of the parsed result is a quarter, 
			query = `${to.quarter}Q/${to.year}`;
		}

		if (duration.months === 1) {
			//	duration was a single month - make sure we only ever
			//	pass a 3 char short name for the month
			query = `${to.monthShort?.substring(0,3)}/${to.year}`;
		}

		if (duration.years === 1) {
			//	duration was a single year
			query = `Y/${to.year}`;
		}

		/* When users hit ENTER key to run the parser, any other bluring of the input
			 filed (eg clicking on a row) will re-run the parsing causing the search param
			 to be set again; triggering a potential race condition on row selection. To
			 prevent this, stop executing any further updates if the search param has no
			 changes from current
		*/
		if (searchParam.query === query) return;
		
		setSearchParam({original, query});

	}

	const resetSelectedRowsAndColumns = () => {
		setSelectedRows([]);
		setSelectedColumns([]);
	}

	const changeFrequency = (freq: Frequency) => {
		setFrequency(c => {
			//	changed - so reset any selections
			if (c !== freq) resetSelectedRowsAndColumns();
			return freq;
		});
	}


  return <>
		<nav className='tabbed-navigation-set__container'>
			<SecondaryNavigation items={items}/>
			{ loadingNavigation && 
				<div className={styles.loader}>
					<BorealisBar />
				</div>
			}
			{ availableGrids?.length > 1 && 
				<TertiaryNavigation
					items={availableGrids} 
					changeCallback={switchGrid}
					currentSelection={selectedRateGrid}
					labelKey='description'
					isMobile={isTabletOrMobile}
				/>
			}
		</nav>
		<div className={clsx('direction--column', styles.panel)}>
			{ isTabletOrMobile && 
				<Button 
					text
					size='small'
					icon={clsx(
						'icon--small',
						filter.collapsed ? 'iconoir-nav-arrow-right' : 'iconoir-nav-arrow-down',
					)}
					onClick={(e) => setFilter((c: any) => {
						const { collapsed } = c;
						return { collapsed: !collapsed }
					})}
				>
					{ filter.collapsed ? 'Show' : 'Hide'} Filters
				</Button>
			}
			<div className={clsx(
				styles.search,
				isTabletOrMobile && filter.collapsed && 'hidden'
			)}>
				<div>
					<div className={styles.dimension}>
						<label>Date Period:</label>
						{ selectedRateGrid?.dimensions && 
							<span>{`Search ${availableDimensions}`}</span>
						}
					</div>
					<div>
						<DateTimeRange 
							onDateParsed={handleParsedDateResult}
							defaultValue={searchParam?.original ?? ''}
							showErrorMessage={false}
							onDateParseError={(e) => {
								// handle the parser barfing on a YYYY object
								const [ year ] = e.match(/\d{4}/g) ?? [ '2023' ];
								setSearchParam({original: year, query: `Y/${year}`});
							}}
						/>
						<Button
							text
							size='small'
							onClick={() => setSearchParam({original: '',query: ''}) }
						>
							clear
						</Button>
					</div>
					
				</div>
				<div className={styles.unit}>
					<div>
						<label>Units:</label>
						<span>
							Show as kilo units or metric tonnes / barrels
						</span>
					</div>
					<ToggleSwitch
						checked={unitAsDecimal}
						slots={["Simple",'Expanded']}
						callback={(e: any) => {
							setUnitAsDecimal(e);
						}}>
					</ToggleSwitch>
				</div>
				{ 
					groupingOptions &&
					<div className={styles.grouping}>
						<label>Group Results by:</label>
						<Dropdown
							value={grouping}
							className='direction--row'
							options={groupingOptions}
							onChange={(e:DropdownChangeEvent) => setGrouping(e.value)}
							optionLabel='label'
						/>
					</div>
				}
			</div>
		</div>
		<main
			className={clsx(
				'grow-to-fill',
				{ 'drawer--active': isTabletOrMobile &&  selectedRows.length }
			)}
			data-cols={layoutSettings}
			data-drawer-style="over"
			data-drawer-position="bottom"
		>
			{ selectedRateGrid &&
				<>
					<section className={clsx(
						"grow-to-fill overflow--hidden",
					)}>
						{ renderGird(selectedRateGrid.source) } 
					</section>
					{ showCharting !== ChartingDisplayState.Hidden && 
						<>
							<CSSTransition
								in={isTabletOrMobile && selectedRows.length > 0 }
								nodeRef={nodeRef}
								timeout={260}
								classNames="underlay"
								unmountOnExit
							>
								<div key={1} ref={nodeRef} className="menu-underlay"></div>
							</CSSTransition>
							<aside>
								<header className="align--right">
									<ColumnLayoutSelector 
										state={showCharting}
										changeState={setShowCharting}
									/>
								</header>
								<div className="direction--column">
									<SelectedRows 
										selectedRows={selectedRows}
										setSelectedRows={setSelectedRows}
									/>
									<SelectedColumns 
										source={selectedRateGrid.source}
										feed={feed}
										query={searchParam.query}
										columns={selectedColumns}
										setSelectedColumns={setSelectedColumns}
									/>
									<Chart 
										ref={chrt}
										toastRef={toast}
										source={selectedRateGrid.source}
										sourceName={selectedRateGrid.description}
										columns={selectedColumns}
										feed={feed} 
										frequency={frequency}
										rows={selectedRows}
										size={showCharting}
									/>
								</div>
							</aside>
						</>
					}
				</>
			}
      <ToastMessage ref={toast} />
		</main>
	</>
}

export default IEAModsData;
