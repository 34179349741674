import { useEffect, Dispatch, SetStateAction } from 'react';

import { MultiSelect } from 'primereact/multiselect';

import { useGetLatestModData } from 'modules/Rates/Commodities/Services/hooks';
import { IEAModSources } from 'modules/Rates/Commodities/Models/IEAMods';
import { BasicColumn } from 'modules/Rates/Commodities/CommoditiesData';


type SelectedColumProps = {
	feed: 'iea' | 'jodi';
	source: IEAModSources;
	query: string | undefined;
	columns: BasicColumn[] | null,
	setSelectedColumns: Dispatch<SetStateAction<BasicColumn[] | null>>
}

const SelectedColumns = (props: SelectedColumProps): JSX.Element => {

	const { feed, source, query, columns, setSelectedColumns } = props

	const { data, isLoading } = useGetLatestModData(feed, source, query);
	
	useEffect(() => {
		if (!data) return;

		if (data.columns.length === 1) {
			//	If there is only one available column to choose from - we might as well
			//	just set it as selected
			setSelectedColumns(data.columns);
		}
	}, [data])
	
  return <div>
		<label>Columns:</label>
		{ isLoading && 
			<div className='act-as--input align-items--center'>…</div>
		}
		{ data &&
			<>
				<MultiSelect 
					value={columns} 
					options={data.columns}
					optionLabel='name'
					onChange={ (e) => setSelectedColumns(e.value) }
					placeholder="Select Columns"
					display='chip'
					pt={{
						token: { className: 'small'}
					}}
					removeIcon='iconoir-xmark icon--tiny'
					panelHeaderTemplate={<></>}
				/>
			</>
		}
	</div>;
}

export default SelectedColumns;
