import { Button } from 'primereact/button';

import eventBus from 'server/EventBus';

import { useDeleteWorkflow } from 'modules/Workflows/Services/hooks';
import { Workflow } from 'modules/Workflows/Models';
import { GlobalDialogDisplayEvents } from 'models/shared/DialogDisplay';


type ComponentProps = {
		workflow: Workflow,
		callback: (id?: string) => void;
}


const DeleteWorkflowDialogFooter = (props: ComponentProps): JSX.Element => {
	
	const { workflow, callback } = props;

	const { deleteWorkflow, isDeleting } = useDeleteWorkflow();

	const handleDeletion = async () => {
		await deleteWorkflow(workflow);

		nukeDialog();
	}

	const nukeDialog = () => {

		callback(workflow.id);

		eventBus.dispatch(GlobalDialogDisplayEvents.HIDE, null);
	}

	return <div className='grow-to-fill space-between'>
			<Button 
				size='small'
				text 
				onClick={nukeDialog}
			>
				Cancel
			</Button>
			<Button
				disabled={isDeleting}
				loading={isDeleting}
				onClick={handleDeletion}
				severity='danger'
				size='small'
				outlined
			>
				Delete
			</Button>
	</div>
}

export default DeleteWorkflowDialogFooter;