import { Button } from 'primereact/button';

type MapErrorProps = {
    tryAgainCallback: () => void;
    message?: string;
}

const DEFAULT_MESSAGE = 'IT SEEMS THAT THERE IS AN ISSUE LOADING THE MAP';

export default function MapError(props: MapErrorProps): JSX.Element {
    const { message, tryAgainCallback } = props;

    return <div className="map-error">
        <i className="map-error-icon iconoir-map-xmark"></i>
        <div>{ message || DEFAULT_MESSAGE }</div>
        <Button className="map-error-button p-button-filled" onClick={tryAgainCallback}>TRY TO RELOAD</Button>
    </div>
}