import { RefObject, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';

import BorealisBar from 'components/BorealisBar';
import { type ToastMessageRef, ToastSeverity } from 'components/ToastMessage';

import SubscriptionFormItems from './SubscriptionFormItems';
import SubscriptionProducts from './SubscriptionProducts';

import { SubscriptionDataset } from 'modules/DataModule/Models/Enums';
import type { SubscriptionRequest } from 'modules/DataModule/Models/SubscriptionRequest';
import type { SubscriptionResponseFlat } from 'modules/DataModule/Models/SubscriptionResponse';
import { getValidator } from 'modules/DataModule/Models/Validators';
import {
  DataModuleApi,
  useGetSubscriptionDetails,
  useSaveSubscription,
} from 'modules/DataModule/Services/DataModuleAPI';

import type F from 'types/generic-type';

import type { ValidationError } from 'joi';

import './SubscriptionDetails.scss';

interface SubscriptionDetailsProps {
  selectedSubscription: SubscriptionResponseFlat | null;
  closePanel: () => void;
  toastRef: RefObject<ToastMessageRef>;
  onSubscriptionUpdate: () => void;
}

const SubscriptionDetails = (props: SubscriptionDetailsProps): JSX.Element => {
  const { selectedSubscription, closePanel, toastRef: toast, onSubscriptionUpdate } = props;

  const [selectedDataset, setSelectedDataset] = useState<SubscriptionDataset | null>(null);
  const [request, setRequest] = useState<SubscriptionRequest | null>(null);
  const [validationErrors, setValidationErrors] = useState<F<string> | null>(null);
  const [validationEnabled, setValidationEnabled] = useState<boolean>(false);

  const { data, isLoading, isValidating } = useGetSubscriptionDetails({ id: selectedSubscription?.id, partitionKey: selectedSubscription?.partitionKey });
  const { trigger: save } = useSaveSubscription();

  const mutateSubscription = (mutation: Partial<SubscriptionRequest>): void => {
    setRequest(c => ({ ...c!, ...mutation }));
  };

  useEffect(() => {
    // reset request if selectedSubscription is unchecked (ex. on 'Add Subscription' button click)
    if (!selectedSubscription) {
      setSelectedDataset(null);
      setRequest(null);
    }
  }, [selectedSubscription]);

  useEffect(() => {
    if (data) {
      // calculate and add `numberOfDays` based on `startData` and `expiryDate`
      setRequest({ ...data, numberOfDays: Math.floor(data.expiryDate.diff(data.startDate, ['days']).days) });
      setSelectedDataset(data.dataSet);
    }
  }, [data]);

  useEffect(() => {
    if (validationEnabled) {
      validate();
    }
  }, [request, validationEnabled]);

  const onDataSetChange = (e: DropdownChangeEvent): void => {
    setSelectedDataset(e.value);
    if (!selectedSubscription) {
      setRequest(DataModuleApi.createEmptySubscription(e.value));
    }
  };

  const validate = async (): Promise<boolean> => {
    try {
      const validator = getValidator(selectedDataset!);

      if (validator) {
        await validator.validateAsync(request, { abortEarly: false });
        setValidationErrors(null);

        return true;
      }

      return false;
    } catch (e) {
      const errors = (e as ValidationError).details?.reduce((acc, er) => ({ ...acc, [er.path.join('.')]: er.message }), {});
      setValidationErrors(errors);
      console.warn('[Data|Subscription] errors:', errors);

      return false;
    }
  };

  const saveSubscription = async (): Promise<void> => {
    setValidationEnabled(true);
    const valid = await validate();

    if (valid) {
      try {
        await save(request!);

        onSubscriptionUpdate();
        closePanel();

        toast.current?.replace({
          title: 'Subscription added',
          message: 'A new subscription has been successfully added',
          severity: ToastSeverity.SUCCESS
        });
      } catch (e) {
        toast.current?.replace({
          title: 'Error',
          message: 'Sorry, something has gone wrong. Please try again later.',
          severity: ToastSeverity.ERROR
        });
      }
    }
  };

  const resendAccessToken = (): void => {
    if (selectedSubscription) {
      DataModuleApi.resendAccessToken('', {
        arg: {
          id: selectedSubscription.id,
          partitionKey: selectedSubscription.partitionKey,
        },
      })
        .then(() =>
          toast.current?.replace({
            message: 'The access token has been successfully resent',
            severity: ToastSeverity.SUCCESS,
          })
        )
        .catch(() =>
          toast.current?.replace({
            title: 'Error',
            message: 'Sorry, something has gone wrong. Please try again later.',
            severity: ToastSeverity.ERROR,
          })
        );
    }
  };

  return <div className='subscription-details__container direction--column'>
    <header>
      {selectedSubscription ? 'Edit Subscription' : 'Add Subscription'}
      <Button
        text
        icon='iconoir-xmark icon--tiny p-button-icon-only'
        className='close-button'
        onClick={closePanel}
      />
    </header>
    <div className='overflow--y direction--column grow-to-fill position--relative' >
      {(isLoading || isValidating) ? <BorealisBar /> :
        <form className='direction--column'>
          <div className='subscription-details__form'>
            <div className='form-input__container'>
              <label htmlFor='rate-grid__dataset'>Data set*</label>
              <Dropdown
                options={[
                  { label: 'Derivatives Exchange', value: SubscriptionDataset.DerivativesExchanges },
                  { label: 'Rates', value: SubscriptionDataset.RateGridsPackage },
                  { label: 'Artis', value: SubscriptionDataset.ArtisPackage, disabled: true }
                ]}
                onChange={onDataSetChange}
                value={selectedDataset}
                disabled={!!selectedSubscription}
                placeholder='Select data set'
              />
            </div>
          </div>
          {request &&
            // TODO: remove this cond once other datasets are implmented
            (request.dataSet === SubscriptionDataset.RateGridsPackage ||
              request.dataSet === SubscriptionDataset.DerivativesExchanges) && <>
            <SubscriptionFormItems
              key={request.dataSet}
              isEditMode={!!selectedSubscription}
              request={request}
              mutate={mutateSubscription}
              errors={validationErrors}
            />
            <SubscriptionProducts
              key={request.id}
              selectedSubscription={selectedSubscription!}
              errors={validationErrors}
              request={request}
              mutate={mutateSubscription}
            />
          </>}
        </form>}
    </div>
    <footer className={selectedSubscription ? 'space-between' : 'align--right'}>
      {selectedSubscription && (
        <Button onClick={resendAccessToken} size='small' text>
          Resend access token
        </Button>
      )}
      <Button size='small' severity='success' onClick={saveSubscription}>
        {selectedSubscription
          ? 'Update'
          : 'Save and send email with access token'}
      </Button>
    </footer>
  </div>;
};

export default SubscriptionDetails;
