import { useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Timeline } from 'primereact/timeline';

import Loader from 'components/Loader';
import { ToastSeverity } from 'components/ToastMessage';

import {
  SURV_ENT_STATUS_TEXT_MAP,
} from '../../../Models/Enums';
import {
  EntityUpdateResponse,
  useUpdateAuditState
} from '../../../Services/AuditService';
import { SurveillanceSignalEventTypes } from '../../../Services/SignalRSocket';
import { SURV_REASON_TEXT_MAP } from '../../MarkAsReviewedPopup/Models/Enums';
import { useActivity } from '../Services/SurveillanceDetailsAPI';

import { notNil } from 'helpers/Utils/misc';

import eventBus from 'server/EventBus';

import type { ActivityViewProps, SurveillanceActivityResponse } from '../Models';

import './ActivityView.scss';

const ActivityView = ({
  activeWorksheetId,
  record,
  toastRef,
  topRibbon,
}: ActivityViewProps): JSX.Element => {
  const { id, partitionKey } = record;
  const { activity, isLoading, mutate } =
    useActivity({ id, partitionKey }, activeWorksheetId);
  const [newNote, setNewNote] = useState('');

  const { trigger: triggerUpdate } = useUpdateAuditState(activeWorksheetId);

  const onUpdateClick = (): void => {
    triggerUpdate({
      items: [{
        id: id,
        partitionKey: partitionKey,
      }],
      note: newNote,
      worksheetId: activeWorksheetId || '',
    }).then((e):void => {
      toastRef?.current?.replace({
        title: 'Success',
        message: 'Activity Note Updated',
        severity: ToastSeverity.SUCCESS
      });
      setNewNote('');
    }).catch(():void => {
      toastRef?.current?.replace({
        title: 'Error',
        message: 'Sorry, something has gone wrong. Please try again later.',
        severity: ToastSeverity.ERROR
      });
    });
  };

  const onEntityUpdated = useCallback((e: CustomEvent<EntityUpdateResponse>):void => {
    const {items, worksheetId} = e.detail;
    if (worksheetId === activeWorksheetId) {
      const indexCurrent: number = items.findIndex(d =>
        d.id === id && d.partitionKey === partitionKey);
      // If current item found -> update activity
      if (indexCurrent !== -1) {
        mutate();
      }
    }
  }, [activeWorksheetId, id, mutate, partitionKey]);

  useEffect(() => {
    eventBus.on(SurveillanceSignalEventTypes.SURVEILLANCE_ENTITY_UPDATES, onEntityUpdated);

    return () => {
      eventBus.remove(SurveillanceSignalEventTypes.SURVEILLANCE_ENTITY_UPDATES, onEntityUpdated);
    };
  }, [onEntityUpdated]);

  if (isLoading) {
    return (
      <div className='activity-loading-container'>
        <div className='activity-loading-content'>
          <Loader className='small' />
        </div>
      </div>
    );
  }

  return (
    <div className='activity-wrap'>
      {topRibbon}
      <div className='activity-container'>
        <div className='activity-content'>
          <InputTextarea
            className='activity-note-input'
            onFocus={(e): void => e.target.select()}
            onChange={(e): void => setNewNote(e.target.value)}
            placeholder='Please add an optional note/comment'
            rows={3}
            value={newNote}
          />
          <Timeline
            content={(item: SurveillanceActivityResponse): JSX.Element => (
              <>
                {!notNil(item.status) || item.status === item.previousStatus ? (
                  <div className='activity-item'>
                    <div className='activity-note-header'>
                      {item.updatedByName}
                    </div>
                    <div className='activity-note-content'>{item.note}</div>
                    <div className='activity-note-footer'>
                      {item.updatedAt.toFormat('dd LLL yyyy, hh:mm')}
                    </div>
                  </div>
                ) : (
                  <Accordion>
                    <AccordionTab
                      header={
                        <>
                          <div>
                            Moved to {item.status ? SURV_ENT_STATUS_TEXT_MAP[item.status] : 'Unknown'}
                          </div>
                          <div className="activity-accordion-date">
                            {item.updatedAt.toFormat('dd LLL yyyy, HH:mm')}
                          </div>
                        </>
                      }
                    >
                      <div className='activity-accordion-content'>
                        {item.previousStatus && (
                          <div>
                            <dt>Previous</dt>
                            <dd>{SURV_ENT_STATUS_TEXT_MAP[item.previousStatus]}</dd>
                          </div>
                        )}
                        {item.status && (
                          <div>
                            <dt>Current</dt>
                            <dd>{SURV_ENT_STATUS_TEXT_MAP[item.status]}</dd>
                          </div>
                        )}
                        {item.updatedByName && (
                          <div>
                            <dt>Updated by</dt>
                            <dd>{item.updatedByName}</dd>
                          </div>
                        )}
                        {item.reason && (
                          <div>
                            <dt>Reason</dt>
                            <dd>{SURV_REASON_TEXT_MAP[item.reason]}</dd>
                          </div>
                        )}
                        <div>
                          <dt>Note</dt>
                          <dd>{item.note ? item.note : <div className='placeholder'>Have not been added</div>}</dd>
                        </div>
                      </div>
                    </AccordionTab>
                  </Accordion>
                )}
              </>
            )}
            value={activity}
          />
        </div>
      </div>
      <footer>
        <Button
          size='small'
          severity='success'
          loading={false}
          disabled={newNote.trim() === ''}
          onClick={onUpdateClick}
        >
          Update
        </Button>
      </footer>
    </div>
  );
};

export default ActivityView;
