import axios from 'axios';
import { DateTime } from 'luxon';
import useSWR, { MutatorCallback, State, useSWRConfig } from 'swr';
import useSWRMutation, {SWRMutationResponse} from 'swr/mutation';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import { isSearchRequestEmpty } from '../Components/CargoSearch/Models/Parsers';
import { CargoSearchRequest, CargoUpdateRequest, CargoUpdateResponse } from '../Models/CargoTrackerRequest';
import { CargoTrackerResponse, LocationType, PetroleumProductEnum } from '../Models/CargoTrackerResponse';

import { replaceItemAt } from 'helpers/Utils/collections';
import { notNil } from 'helpers/Utils/misc';

import { ResponseError } from 'models/shared/error';

export const CARGO_CACHE: string = 'CargoTracker-Cargo';

export const useGetCargo = (): { data: CargoTrackerResponse[] | undefined, error: ResponseError, isLoading: boolean } => {
  const {data, error, isLoading} = useSWR(CARGO_CACHE,
    () => CargoTrackerAPI.getCargo(),
    { revalidateOnFocus: false });
  return {data, error, isLoading};
};

export const useUpdateCargo = (id: string | null): {
  trigger: SWRMutationResponse['trigger'],
  result?: CargoUpdateResponse,
  isMutating: SWRMutationResponse['isMutating'] } => {

	const { mutate } = useSWR(CARGO_CACHE);
	const { cache } = useSWRConfig();

	const { trigger, data, isMutating } = useSWRMutation(
		`cargo/${ id }`,
		CargoTrackerAPI.updateCargo,
		{
			onSuccess: c => {
				//	Mutate the cache
				const { data } = cache.get(CARGO_CACHE) as State<CargoTrackerResponse[]>

				if (!data || !c) return;

				if (!notNil(id)) {
					//	Created a new record, so we can just add into the start  of the collection
					mutate([CargoTrackerAPI.updateCargoTrackerResponse(c), ...data], { revalidate: false });
					return;
        } else {
					//	its an exsiting item - so we need to find it in the collection and replace with the updated
					//	data recieved in the response
					const index: number = data.findIndex(i => i.id === c.id);
					mutate(replaceItemAt(data, CargoTrackerAPI.updateCargoTrackerResponse(c), index), { revalidate: false });
				}
			}
		}
	);

  return { trigger, result: data, isMutating };
};

export const useDeleteCargo = (selectedCargos: CargoTrackerResponse[]): {
  remove: (data: {ids: Array<string>}) => Promise<void>,
  result?: CargoUpdateResponse,
  isMutating: SWRMutationResponse['isMutating'] } => {

  const { mutate } = useSWR(CARGO_CACHE);
  const { cache } = useSWRConfig();

  const { trigger, data, isMutating } = useSWRMutation(
    CARGO_CACHE,
    CargoTrackerAPI.deleteCargo,
    {
      onSuccess: () => {
        const { data } = cache.get(CARGO_CACHE) as State<CargoTrackerResponse[]>;

				if (!data) return;

        // filter out items that are selected
        mutate(data.filter(item => selectedCargos.findIndex(cargo => cargo.id === item.id) === -1), { revalidate: false });
      },
    }
  );
  // @ts-ignore // TODO: Fix Typescript
  return { remove: trigger, result: data, isMutating };
};

export const useSearchCargo = (csr: CargoSearchRequest | null):
  { searchResults: CargoTrackerResponse[] | undefined,
    searchError: ResponseError,
    searchIsLoading: boolean,
    searchMutate: MutatorCallback<CargoTrackerResponse[]> } => {
  let arg: CargoSearchRequest | null | string = csr;
  let csrData: CargoSearchRequest = csr ?? {};

  // When csr is null -> arg for useSWR should be null to not fetch data (worksheet is still loading)
  // When csr is loaded but empty -> arg for useSWR should be CARGO_CACHE so it can be obtained/updated from CACHE
  // By default send CargoSearchRequest data as above
  if (arg !== null && isSearchRequestEmpty(arg)) {
    arg = CARGO_CACHE;
    csrData = {};
  }

  const { data, error, isLoading, mutate } =
    useSWR(arg,
      () => CargoTrackerAPI.searchCargo(csrData),
      { revalidateOnFocus: false });
  return {
    searchResults: data,
    searchError: error,
    searchIsLoading: isLoading,
    searchMutate: mutate,
  };
};

export class CargoTrackerAPI {

  static getCargo = ():Promise<CargoTrackerResponse[]> =>
    axios.post('cargotracker/cargo/search', {})
      .then(results => this.updateCargoData(results.data))
      .catch(e => {
        ErrorToastService.handleError(e, [400, 403, 500, 503]);
  
        throw e;
      });

  static updateCargoData = (data: CargoTrackerResponse[]):CargoTrackerResponse[] => data.map(this.updateCargoTrackerResponse);

  static updateCargoTrackerResponse =
    (r:CargoTrackerResponse):CargoTrackerResponse => ({
      ...r,
      laycan: {
        ...r.laycan,
        original: r.laycan?.original ?? '',
        fromDate: r.laycan?.fromDate ?? '',
        toDate: r.laycan?.toDate ?? '',
        fromDateParsed: DateTime.fromISO(`${ r.laycan?.fromDate }`).toUTC(),
        toDateParsed: DateTime.fromISO(`${ r.laycan?.toDate }`).toUTC()
      },
      createdInfo: {
        ...r.createdInfo,
        dateParsed: DateTime.fromISO(`${ r.createdInfo?.date }`).toUTC()
      },
      updatedInfo: {
        ...r.updatedInfo,
        dateParsed: DateTime.fromISO(`${ r.updatedInfo?.date }`).toUTC()
      },
      code: r.code ?? '',
    });

  static updateCargo = (url: string, params: any ) => {

    const { data }: { data: CargoUpdateRequest } = params.arg;

    const method: string = 'PUT';

    return axios.request({
      url: 'cargotracker/cargo',
      data, method
    })
      .catch(e => {
        console.error('Error', e);
        ErrorToastService.handleError(e, [400, 403, 500, 503]);
        return;
      })
      .then(r => r?.data as CargoUpdateResponse);
  };

  static deleteCargo = (url: string, params: any ) => {
    const data: { ids: Array<string> } = params.arg;
    const method: string = 'DELETE';

    return axios.request({
      url: 'cargotracker/cargo',
      data, method
    })
      .catch(e => {
        console.error('Error', e);
        ErrorToastService.handleError(e, [400, 403, 500, 503]);
        return;
      })
      .then(r => r?.data);
  };

  static searchCargo = (data: CargoSearchRequest): Promise<CargoTrackerResponse[]> => axios.request({
    url: 'cargotracker/cargo/search',
    data,
    method: 'POST'
  })
    .then(results => this.updateCargoData(results.data))
    .catch(e => {
      ErrorToastService.handleError(e, [500, 503]);

      throw e;
    });

  static createEmptyRequest = (initialProps: CargoUpdateRequest = {}): CargoUpdateRequest => ({
    id: undefined,
    type: undefined,
    status: undefined,
    charterPeriod: undefined,
    charterer: undefined,
    commodity: undefined,
    discharging: undefined,
    isUrgent: false,
    laycan: undefined,
    loading: {
      locationType: LocationType.Search,
    },
    notes: undefined,
    quantity: undefined,
    vesselType: undefined,
    assignedUser: undefined,
    confidentialityIndicator: undefined,
    userRights: undefined,
    petroleumProductType: PetroleumProductEnum.NA,
    chartererSearchId: null,
    code: '',
    ...initialProps
  });
}
