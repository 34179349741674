import { useMemo } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { SelectItemOptionsType } from 'primereact/selectitem';

import { AccessLevel } from '../Models';

import styles from './ShareAccessLevelDropdown.module.scss';

interface ShareAccessLevelDropdownProps {
  hasRemoveOption: boolean;
  accessLevel: AccessLevel;
  accessLevelChanged: (accessLevel: AccessLevel) => void;
}

function mapAccessLevelLabel(accessType: AccessLevel): string {
  switch (accessType) {
    case AccessLevel.Edit:
      return 'Can edit';
    case AccessLevel.None:
      return 'No access';
  }
}
const removeOptionItem = {
  label: 'Remove from list',
  value: 'Remove',
  className: styles.shareAccessRemoveItem,
};

export function ShareAccessLevelDropdown({
  hasRemoveOption,
  accessLevel,
  accessLevelChanged,
}: ShareAccessLevelDropdownProps): JSX.Element {
  const allowedOptions: SelectItemOptionsType = useMemo(() => {
    const defaultOptions = Object.values(AccessLevel).map(accessLevel => ({
      label: mapAccessLevelLabel(accessLevel),
      value: accessLevel,
    }));

    if (hasRemoveOption) {
      return [...defaultOptions, removeOptionItem];
    }

    return defaultOptions;
  }, [hasRemoveOption]);

  return (
    <Dropdown
      onChange={({ value }) => accessLevelChanged(value)}
      value={accessLevel}
      options={allowedOptions}
    />
  );
}
