import { useRef } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import clsx from 'clsx';

import type { ArtisProduct } from '../Models/Packages';

import styles from './Styles.module.scss';

type ArtisPriceHeaderParams = {
  moveToLeftDisabled: boolean;
  moveToRightDisabled: boolean;
  product: ArtisProduct;
  onShowForwardChart?: (product: ArtisProduct) => void;
  onMoveToLeft: (product: ArtisProduct) => void;
  onMoveToRight: (product: ArtisProduct) => void;
};

export const ArtisPriceHeader = ({
  moveToLeftDisabled,
  moveToRightDisabled,
  product,
  onShowForwardChart = () => {},
  onMoveToLeft,
  onMoveToRight,
}: ArtisPriceHeaderParams): JSX.Element => {
  const menuRef = useRef<Menu>(null);

  const { label, unit } = product;

  const contextMenuItems = [
    {
      label: 'Open forward charting',
      command: () => onShowForwardChart(product),
      icon: 'iconoir-graph-up icon--small',
    },
    {
      label: 'Move to left',
      command: () => onMoveToLeft(product),
      icon: 'iconoir-nav-arrow-left icon--small',
      disabled: moveToLeftDisabled
    },
    {
      label: 'Move to right',
      command: () => onMoveToRight(product),
      icon: 'iconoir-nav-arrow-right icon--small',
      disabled: moveToRightDisabled
    },
  ];

  return (
    <>
      <Tooltip target=".header-cell" />
      <Menu
        popup
        className={styles.contextMenu}
        model={contextMenuItems}
        ref={menuRef}
      />
      <div
        className={clsx('header-cell', styles.priceHeader)}
        data-pr-tooltip={`${label}`}
        data-pr-position="top"
        onContextMenu={(e) => {
          e.preventDefault();
          menuRef.current?.show(e);
        }}
        onClick={menuRef.current?.show}
      >
        <div>
          <div>{label}</div>
          <div className={styles.unit}>{unit}</div>
        </div>
        <i className='iconoir-nav-arrow-down icon--small icon--secondary' />
      </div>
    </>
  );
};
