import { AliasStrength } from 'modules/Sanctions/Models/Enums';

import type { apiNameSearchModel } from 'modules/Sanctions/Models';


import './AliasQuality.scss';

export default function AliasQuality (data: apiNameSearchModel): JSX.Element {
	return <div className="alias-quality">
		{AliasStrength[data.aliasStrength] ?? <span className='alias-quality--na'>N/A</span>}
	</div>;
}