import { ReactNode, useEffect, useState } from 'react';
import { Button } from 'primereact/button';

import './EditableContent.scss';

interface EditableContentProps {
  children: ReactNode; // anything: input, dropdown, etc.
  onModeChange?: (arg: boolean) => void;
  save: () => Promise<boolean>;
  saveButtonDisabled?: boolean;
  saveButtonLoading?: boolean;
  value: string;
}

export default function EditableContent(props: EditableContentProps): JSX.Element {
  const { children, onModeChange, save, saveButtonDisabled, saveButtonLoading, value } = props;

  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (typeof onModeChange === 'function') {
      onModeChange(editMode);
    }
    // eslint-disable-next-line
  }, [editMode]);

  const handleSave = async (): Promise<void> => {
    const saved = await save();

    if (saved) {
      setEditMode(false);
    }
  }

  return <>
    {editMode && <div className='editable-content__container--overlay' onClick={(e) => setEditMode(false)}></div>}
    <div className='editable-content__container'>
      {editMode 
        ?
          <>
            <form className='form-input__container' onKeyDown={(e) => {
              if (e.key === 'Escape') {
                setEditMode(false);
              }

              if (e.key === 'Enter') {
                e.preventDefault();
                handleSave();
              }
            }}>
              {children}
            </form>
            <Button
              text
              className='button--save'
              disabled={saveButtonDisabled}
              loading={saveButtonLoading}
              onClick={handleSave}
              severity='success'
              size='small'
            >
              Update
            </Button>
          </>
        : <Button
            className='button--edit no--background no--padding'
            icon='iconoir-edit-pencil icon--tiny icon--right'
            iconPos='right'
            onClick={() => setEditMode(true)}
            size='small'
            text
          >
            {value}
          </Button>}
    </div>
  </>;
}