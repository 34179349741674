import { useCallback, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import Map from 'components/Map';
import {
  WAYPOINT_LABEL_OFFSET_MAP_MOBILE,
  WAYPOINT_LABEL_OFFSET_MAP,
  WAYPOINT_MARKER_MAP,
  WAYPOINT_MARKER_OFFSET_MAP_MOBILE,
  WAYPOINT_MARKER_OFFSET_MAP,
  WAYPOINT_TYPE_MAP,
  ZOOM_WAYPOINT_VISIBLE } from 'components/Map/Models/MapSettings';
import { WaypointType } from 'components/Map/Models/Enums';

import { WAYPOINT_GROUP_TO_TYPE_MAP } from '../../Models/Consts';
import { Group } from '../../Models/Enums';

import type { MapWaypoint } from 'components/Map/Models/MapWaypoint';
import type { CalculationRequest } from '../../Models/CalculationRequests';
import type { CalculationResult } from '../../Models/CalculationResponse';

import './Map.scss';

interface DistCalcMapProps {
    calcResult:  CalculationResult | undefined;
    calcParams: CalculationRequest;
}

export default function DistCalcMap(props: DistCalcMapProps): JSX.Element {
  const { calcResult, calcParams } = props;
   const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const coordinates: number[][] | null = useMemo(() => calcResult?.waypoints?.map(
    waypoint => waypoint.location.position ? [waypoint.location.position.long, waypoint.location.position.lat]
      : []
  ) ?? null, [calcResult]);

  const mapWaypoints: MapWaypoint[] = useMemo(() => {
    if (calcResult) {
      return calcResult?.waypoints?.map(
        waypoint => ({
          type: waypoint.type,
          long: waypoint.location.position?.long,
          lat: waypoint.location.position?.lat,
        })
      );
    } else {
      return calcParams.locations?.filter(location => location.group !== Group[Group['Vessel Class']])
        .map(location => ({
          type: WAYPOINT_GROUP_TO_TYPE_MAP[location.group] ?? WaypointType.Via,
          long: Number(location.Longitude),
          lat: Number(location.Latitude),
        })
        );
    }
  }, [calcParams, calcResult]);

  const hasLoadAndDischarge: boolean = useMemo(() => (calcResult?.waypoints?.filter(
    waypoint => waypoint.type === WaypointType.Load || waypoint.type === WaypointType.Discharge
  ).length ?? 0) > 1 , [calcResult]);

  const checkWaypointVisibility = useCallback((type: WaypointType, zoom: number): string => {
    if (type === WaypointType.Waypoint && zoom < ZOOM_WAYPOINT_VISIBLE) {
      return 'hidden'; // Hide waypoints on certain zoom out level
    } else {
      return 'visible';
    }
  }, []);

  const getMarkerClass = useCallback((type: WaypointType): string => {
    if (hasLoadAndDischarge && type === WaypointType.Via) {
      // If the path starts or ends with VIA, then we use the main marker.
      // If VIA is in the middle (between loading and discharging), then the secondary (so waypoint one).
      type = WaypointType.Waypoint;
    }
    const className = WAYPOINT_MARKER_MAP[type];
    const deviceType = isTabletOrMobile ? 'mob' : 'desk';
    return `${ className }_${ deviceType }`;
  }, [hasLoadAndDischarge, isTabletOrMobile]);

  const getMarkerOffset = useCallback((type: WaypointType): [number, number] => {
    if (hasLoadAndDischarge && type === WaypointType.Via) {
      // If the path starts or ends with VIA, then we use the main marker.
      // If VIA is in the middle (between loading and discharging), then the secondary (so waypoint one).
      type = WaypointType.Waypoint;
    }
    if (isTabletOrMobile) {
      return [0, WAYPOINT_MARKER_OFFSET_MAP_MOBILE[type]];
    } else {
      return [0, WAYPOINT_MARKER_OFFSET_MAP[type]];
    }
  }, [hasLoadAndDischarge, isTabletOrMobile]);

  const getLabel = useCallback((type: WaypointType): string => {
    return WAYPOINT_TYPE_MAP[type];
  }, [])

  const getLabelOffset = useCallback((type: WaypointType): [number, number] => {
    if (hasLoadAndDischarge && type === WaypointType.Via) {
      // If the path starts or ends with VIA, then we use the main marker.
      // If VIA is in the middle (between loading and discharging), then the secondary (so waypoint one).
      type = WaypointType.Waypoint;
    }
    if (isTabletOrMobile) {
      return [0, WAYPOINT_LABEL_OFFSET_MAP_MOBILE[type]];
    } else {
      return [0, WAYPOINT_LABEL_OFFSET_MAP[type]];
    }
  }, [hasLoadAndDischarge, isTabletOrMobile]);

  return <Map
      lineCoordinates={coordinates}
      waypoints={mapWaypoints}
      getLabel={getLabel}
      getLabelOffset={getLabelOffset}
      getMarkerClassName={getMarkerClass}
      getMarkerOffset={getMarkerOffset}
      getMarkerVisibilityClassName={checkWaypointVisibility}
    />
}