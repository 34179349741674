import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import { DataTable, DataTableSelectionSingleChangeEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';

import Loader from "components/Loader";
import ArrayValueCell from 'modules/Sanctions/Templates/ArrayValueCell';
import DOBCell from 'modules/Sanctions/Templates/DOBCell';
import { sortBySelectedField } from 'helpers/DataTable/SortingFunctions';
import { sortByCustomFieldInArray } from 'helpers/DataTable/SortingFunctions/by-custom-field-in-array';
import { sortByDateObjectInArray } from 'helpers/DataTable/SortingFunctions/by-date-object-in-array';
import { DEFAULT_GRID_ROW_HEIGHT } from 'models/shared/consts';

import { ResultsModeEnum } from "../../Models/Enums";
import { useSanctionsApi } from "../../Services/SanctionsAPI";

import type { ISanctionsDataTableParams } from 'modules/Sanctions/Models/SanctionsDataTable';
import type { apiIndividualSanctionSearchModel } from 'modules/Sanctions/Models/';


import "./SanctionedIndividualDataTable.scss";

function SanctionedIndividualDataTable(props: ISanctionsDataTableParams): JSX.Element {

  const { setSelectedItem, searchTerm, setDisplayLoader, selectedItem } = props;
  const { data, isLoading } = useSanctionsApi(searchTerm, ResultsModeEnum.IndividualSanctions);
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  useEffect(() => {
      setDisplayLoader(isLoading)
    },
    // eslint-disable-next-line
    [isLoading]
  );

  if (isLoading) {
    return <Loader />;
  }

  return <>
    { data &&
        <DataTable
          value={data.results as apiIndividualSanctionSearchModel[]}
          className="sanctions-table--individual grow-to-fill"
          dataKey="id"
          emptyMessage="Sorry, no results match that term. Only sanctioned individuals will display in the search results"
          onSelectionChange={(e: DataTableSelectionSingleChangeEvent<apiIndividualSanctionSearchModel[]>) => setSelectedItem(e.value)}
          scrollable
          scrollHeight="flex"
          selectionMode="single"
          selection={selectedItem as apiIndividualSanctionSearchModel}
          virtualScrollerOptions={{
              className: "grow-to-fill",
              itemSize: DEFAULT_GRID_ROW_HEIGHT // itemSize is required to display proper amount of items
          }}
        >
          {/* TODO: uncomment if needed */}
          {/* <Column
            field="title"
            header="Title"
            // sortable
          /> */}
          <Column
            field="names"
            header="Names"
            body={(data: apiIndividualSanctionSearchModel) => ArrayValueCell(data.names, 'value')}
            sortable
            sortFunction={(e) => sortByCustomFieldInArray(e, 'names')}
          />
          {!isTabletOrMobile && <Column
            field="aliases"
            header="Aliases"
            body={(data: apiIndividualSanctionSearchModel) => ArrayValueCell(data.aliases, 'value')}
            sortable
            sortFunction={(e) => sortByCustomFieldInArray(e, 'aliases')}
          />}
          <Column
            field="nationality"
            header="Nationality"
            body={(data: apiIndividualSanctionSearchModel) => ArrayValueCell(data.nationalities, 'value')}
            sortable
            sortFunction={(e) => sortByCustomFieldInArray(e, 'nationalities')}
          />
          {!isTabletOrMobile && <Column
            field="dobs"
            header="DOB"
            body={(data: apiIndividualSanctionSearchModel) => DOBCell(data.doBs)}
            sortable
            sortFunction={(e) => sortByDateObjectInArray(e, 'doBs')}
          />}
          <Column
            field="listName"
            header="Source"
            sortable
            sortFunction={(e) => sortBySelectedField(e, 'listName')}
          />
        </DataTable>
    }
  </>;
}

export default SanctionedIndividualDataTable;
