import { useEffect } from "react";

import { DataTable, DataTableSelectionSingleChangeEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';

import Loader from 'components/Loader';

import { DoubleLineFlagged, SingleLineFlagged, } from "helpers/DataTable/Templates/ColumnTemplates";

import SanctionStatus from 'modules/Sanctions/Templates/SanctionStatus';
import { ResultsModeEnum } from "modules/Sanctions/Models/Enums";
import { useSanctionsApi } from "modules/Sanctions/Services/SanctionsAPI";
import { DEFAULT_GRID_ROW_HEIGHT } from 'models/shared/consts';

import type { IVesselSanctionResult } from "modules/Sanctions/Models/VesselSanctionResult";
import type { ISanctionsDataTableParams } from 'modules/Sanctions/Models/SanctionsDataTable';

import "./SanctionedVesselDataTable.scss";

function SanctionedVesselDataTable(props: ISanctionsDataTableParams) {

  const { setSelectedItem, searchTerm, setDisplayLoader, selectedItem } = props;
  const { data, isLoading } = useSanctionsApi(searchTerm, ResultsModeEnum.VesselSanctions);

  useEffect(() => { setDisplayLoader(isLoading) }, [isLoading]); // eslint-disable-line

  // TODO: handle error state

  if (isLoading) {
    return <><Loader /></>
  }

  return <>
    { data &&
      <DataTable
        value={data.results}
        className="sanctions-table--vessel grow-to-fill"
        dataKey="id"
        emptyMessage="Sorry, no results match that term. Only sanctioned vessels will display in the search results"
        onSelectionChange={(e: DataTableSelectionSingleChangeEvent<IVesselSanctionResult[]>) => setSelectedItem(e.value)}
        // removableSort
        scrollable scrollHeight="flex"
        selectionMode="single"
        selection={selectedItem}
        virtualScrollerOptions={{
          itemSize: DEFAULT_GRID_ROW_HEIGHT // itemSize is required to display proper amount of items
        }}
      // sortField="serverindex"
      // sortOrder={1}
    >
      <Column
        field="shipName,imoNumber"
        header="Vessel"
        body={DoubleLineFlagged<IVesselSanctionResult>}
        className="vessel-sanctions__vessel"
        // sortable
        // sortFunction={(e: ColumnSortEvent) => sortBySelectedField<IVesselSanctionResult>(e, "shipName")}
      />
      <Column
        field="registeredOwner"
        header="Registered Owner"
        // sortable
        className="column__registered-owner"
				headerClassName="column__registered-owner"
      />
      <Column
        field="flagCode,flagName"
        body={SingleLineFlagged<IVesselSanctionResult>}
        header="Flag"
        // sortable
        // sortFunction={(e: ColumnSortEvent) => sortBySelectedField<IVesselSanctionResult>(e, "flagName")}
      />
      <Column
        field="shipStatus"
        header="Status"
        className="column__status"
				headerClassName="column__status"
        // sortable
      />
      <Column
        field="legalOverall"
        body={SanctionStatus<any>}
        header="Risk Level"
        className="sanction-status"
        // sortable
      />
    </DataTable>
    }
  </>
}

export default SanctionedVesselDataTable;
