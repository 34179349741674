import type { ColumnSortEvent } from 'primereact/column';
import type F from 'types/generic-type';

export const sortByCustomFieldInArray = (event: ColumnSortEvent, sortByField: string, key = 'value'): F<{ [key: string]: string; }>[] => {

    const { data, order } = event;

    return data.sort((a: F<{ [key: string]: string; }[]>, b: F<{ [key: string]: string; }[]>): number => {

        const first = a[sortByField]?.[0]?.[key] ?? '';
        const second = b[sortByField]?.[0]?.[key] ?? '';

        // empty strings goes to the end of the list
        if (!first) {
            return 1;
        } else if (!second) {
            return -1;
        }

        return first.localeCompare(second) * (order as number);
    });
};