import axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import {
  type ProfileCredentials,
  ProfileResult,
  type ValidateEmailsResult,
} from '../Model/ProfileResult';

export const PROFILE_KEY: string = 'user-profile';

export class ProfileApi {

	static getProfile = () => axios.request({
		url: 'profile/me',
		method: 'GET',
		headers: { utcoffset: ProfileApi._hoursDiff()
	}})
	.then( profile => profile.data as ProfileResult)
	.catch((e) => {
			ErrorToastService.handleError(e);

			throw e;
	});

	static toggleSubscription = (url: string, params: any) => {
		return axios.request({
				url: `profile/notifications/${params.arg.key}`,
				method: params.arg.state ? 'DELETE' : 'PUT'
		})
		.then(d => d.data as ProfileResult)
		.catch((e) => {
				ErrorToastService.handleError(e, [500, 503]);

				throw e;
		});
	};

	static saveSetting = (url: string, params: any): Promise<AxiosResponse> => {
		
		let { setting, data, method = 'PUT' } = params.arg;

		data = JSON.stringify(data);

		return axios.request({
			url: `profile/uiSetting/${setting}`,
			data,
			method: method
		})
		.then(r => r)
	}

	static getCreds = async (name: string): Promise<ProfileCredentials> => {
		
		const creds: AxiosResponse<ProfileCredentials> = await axios.request({
			url: `profile/credentials`,
			method: 'GET',
		})

		return Promise.resolve({...creds.data, name});
	}

  private static _hoursDiff = () => DateTime.now().offset / 60;

  static validateEmails = (emails: string[]): Promise<ValidateEmailsResult> =>
    axios
      .request({
        url: 'profile/validateemails',
        method: 'POST',
        data: {
          emails,
        },
      })
      .then(r => r.data);
}
