import { clsx } from "clsx";
import { Button } from "primereact/button";

import { EntitySearchFieldsEnum } from "components/EntitySearch/Models/Enums";
import { getByNumber } from "helpers/Utils/enum";

import styles from "./SearchChip.module.scss";


interface ISearchChip {
    children: any;
    item: {
        searchTerm: string,
        searchField: EntitySearchFieldsEnum,
        key: string,
        icon: string,
        group: string,
        context?: string;
    }
    callback: Function;
}

const SearchChip = (params: ISearchChip) => {

    const { item, callback } = params;

    //  TODO - better destructuring guards if the string isn't splitable…
    let group, context;
    if (item.context) {
        context = item.context;
    } else {
        [ group, context ] = getByNumber<typeof EntitySearchFieldsEnum>(EntitySearchFieldsEnum, item.searchField, true).split(" ");
    }

	//	TODO - better way to do an exception for IMO
	if (item.searchField === EntitySearchFieldsEnum.ImoNumber) context = "IMO Number";

    return <>
        <div
            className={clsx(styles.searchchip)}
            data-item-group={item.group}
            data-key={item.key}>
            <i className={`iconoir-${item.icon} icon--tiny icon--black`}/>
            <label>{context ?? group}:</label>
            {params.children}
            <Button
                text size="small"
                icon="iconoir-xmark icon--tiny icon--black"
                onClick={() => callback(item.key)}
                className="no--background"
                onKeyDown={(e) => {
                    if (["Space", "Enter"].includes(e.code)) e.preventDefault();

                    if(["Backspace", "Delete"].includes(e.code)) callback(item.key);

                    console.log("Event", e);
                }}
            />
        </div>
    </>
}

export default SearchChip;