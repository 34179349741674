import { useState, useEffect, useRef, MutableRefObject } from "react";
import useSWRMutation from "swr/mutation";
import clsx from "clsx";

import { ProfileApi } from "components/OBXUser/Services/ProfileApi";
import { useLoggedInUser } from "components/OBXUser/Services/ProfileHooks";
import { Button } from "primereact/button";

import styles from "./NotificationSubscription.module.scss";

interface INotificationProps {
  type: string;
  name: string;
}

function NotificationSubscription(props: INotificationProps) {

  const { type, name } = props;
  const { obxuser, isLoading /*, error */ } = useLoggedInUser();
  const [ isSubscribed, setIsSubscribed ] = useState<boolean>(false);
  const subs: MutableRefObject<string[]> = useRef<string[]>([]);

  const compareSubscriptions = (type: string, name: string) => setIsSubscribed(subs.current.includes(`${type}_${name}`) ?? subs.current.includes(`${type}_companyLevel`));

  const { trigger, isMutating }: { trigger: any, isMutating: boolean,} = useSWRMutation(`notfications/${name}`, ProfileApi.toggleSubscription, {
    onSuccess: (d, k) => subs.current = d.notifications,
  })

  useEffect(() => {
    if (!obxuser) return;
    subs.current = obxuser.notifications;
  }, [obxuser]);

  useEffect(() => {
    compareSubscriptions(type, name);
  })


  return <div className={styles.container}>
    { obxuser &&
    <>
      <Button 
        icon="iconoir-bell icon--tiny"
        iconPos="right"
        onClick={(e) => {
          trigger({key: `${type}_${name}`, state: isSubscribed});
          //  TODO - can probably be stricter with the typing here…
          let { target } : { target: any } = e;
          if (target.nodeName !== "BUTTON") { target = target.parentNode; }

          target.blur()
        }}
        text />
      <span className={clsx(
        styles.badge,
        "icon--tiny",
				isSubscribed && `${styles.subscribed} iconoir-check`,
				(isLoading || isMutating) && `${styles.processing} iconoir-refresh`,
				{
          "iconoir-xmark ": !isLoading && !isSubscribed,
        }
			)}></span>
    </>
    }
  </div>;
 }


export default NotificationSubscription;
