import { useCallback } from 'react';
import * as React from 'react';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';

interface CreateNewButtonProps {
  activeDlId?: string;
  className?: string;
  isDisabled?: boolean;
}

const CreateNewButton = (props: CreateNewButtonProps): JSX.Element => {
  const { activeDlId, className, isDisabled = false } = props;

  const sendMessage = useCallback((): void => {
    window.open(`mailto:${ activeDlId }@${ window.location.host }`);
  }, [activeDlId]);


  return (
    <Button
      size="small"
      className={clsx('dl-new-message no-background', className)}
      onClick={sendMessage}
      disabled={isDisabled}
    >
      Create new message
    </Button>);
};


export default CreateNewButton;