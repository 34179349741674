import { useRef } from 'react';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { EnumKeys, getKeyValuePairs } from '../../helpers/Utils/enum';

import './TimeZonePicker.scss';

interface TimeZonePickerProps {
  initialValue: TimeZonesEnum;
  onValueUpdate: (value: TimeZonesEnum) => void;
}

export enum TimeZonesEnum {
  'UTC' = 1,
  'Local to User' = 2,
  // 'Local at Port' = 3, // TODO: uncomment when Local at port BE part will be done and we will add FE implementation
}

const TimeZonePicker = (props: TimeZonePickerProps): JSX.Element => {
  const { initialValue, onValueUpdate } = props;
  const elements: EnumKeys[]  = getKeyValuePairs(TimeZonesEnum);
  const menu = useRef<Menu>(null);

  let items = [
    { label: 'Time zone', items: [] },
    {
      template: () => {
        return (
            <div className="menu--radio-buttons">
              { elements.map(t => {
                const { key, value } = t;
                return <div key={`${key}-${value}`}>
                  <RadioButton
                    inputId={`radio-${key}`}
                    value={value}
                    name="type"
                    checked={value === initialValue}
                    onChange={(e: RadioButtonChangeEvent) => onValueUpdate(e.value)}
                    tabIndex={0}
                  />
                  <label htmlFor={`radio-${key}`}>{key}</label>
                </div>
              })}
            </div>
        );
      }
    }
  ];

  return (
    <span className="time-zone__container">
      <Button
        icon="pi iconoir-time-zone"
        className="p-button-icon-only"
        onClick={(event) => {
          menu.current?.toggle(event);
        }}
      />
      <Menu model={items}
            className="time-zone-menu__container"
            popup
            ref={menu}
            popupAlignment="right" />
    </span>
  );
};

export default TimeZonePicker;