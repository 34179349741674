import {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Column, ColumnSortEvent } from 'primereact/column';
import {
  DataTable,
  DataTableRowClickEvent,
  DataTableSelectionMultipleChangeEvent
} from 'primereact/datatable';
import { DataView } from 'primereact/dataview';

import { CargoTrackerSignalEventTypes } from '../../Services/SignalRSocket';
import QuantityBody from '../../Templates/QuantityBody';
import TimeCharterItem from '../../Templates/TimeCharterItem';
import { CargoEditWarningDialogEvents, deferNextAction } from '../CargoEditWarningDialog';
import { getFirstItemsInGroups } from '../SpotTable';
import TableFooter from '../TableFooter';

import { sortByDateTime } from 'helpers/DataTable/SortingFunctions';
import {
  sortBySelectedFieldOptional
} from 'helpers/DataTable/SortingFunctions/by-custom-field-optional';
import {
  DoubleLineUpdatedAuthorAndDate,
  SingleLineFlagged,
  SingleLineOptional,
  SingleLineTruncated,
} from 'helpers/DataTable/Templates/ColumnTemplates';
import { SingleLineTimeoutBadge } from 'helpers/DataTable/Templates/ColumnTemplates/SingleLineTimeoutBadge';
import { laycanStyleRange } from 'helpers/Utils/formatters';
import {camelToSpace, formatName} from 'helpers/Utils/string';
import type { CargoTrackerResponse } from 'modules/CargoTracker/Models/CargoTrackerResponse';
import { PetroleumProductEnum, PP_TEXT_MAP } from 'modules/CargoTracker/Models/CargoTrackerResponse';
import { CargoTrackerGroupBy, CargoTrackerGroupByFieldName, StatusEnum } from 'modules/CargoTracker/Models/Enums';

import { SELECTED_ROW_CLASS } from 'models/shared/consts';
import eventBus from 'server/EventBus';
import F from 'types/generic-type';

interface TimeCharterTableProps {
  cargo: CargoTrackerResponse[];
  activeCargo: string | null;
  setActiveCargo: Dispatch<SetStateAction<string | null>>;
  showDeleteSuccess: (count: number) => void;
  groupBy: string;
}

export default function TimeCharterTable(
  props: TimeCharterTableProps
): JSX.Element {
  const { cargo, activeCargo, setActiveCargo, showDeleteSuccess, groupBy } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const [selectedCargo, setSelectedCargo] = useState<CargoTrackerResponse[]>([]);
  const [expandedRows, setExpandedRows] = useState<CargoTrackerResponse[]>([]);
  const [groupByProps, setGroupByProps] = useState({});
  const tableRef = useRef<DataTable<CargoTrackerResponse[]>>(null);

  if (isMobile) {
    // Sort by created date on mobile
    cargo.sort((a, b) =>
      `${ b.createdInfo.date }`.localeCompare(a.createdInfo.date)
    );
  }

  const selectItem = (value: CargoTrackerResponse[]):void => {
    setSelectedCargo(value);
  };

  const editItem = (value: CargoTrackerResponse):void => {
    setActiveCargo(value?.id);
  };

  const handleSelection = async (e:  DataTableSelectionMultipleChangeEvent<CargoTrackerResponse[]>): Promise<void> => {
    e.originalEvent.stopPropagation(); // Don't propagate to onRowClick/handleRowClick on unselect
    if (selectedCargo && await deferNextAction(CargoEditWarningDialogEvents.BEFORE_ACTION, CargoEditWarningDialogEvents.SET_CONTINUE_ACTION, () => selectItem(e.value as CargoTrackerResponse[]))) {
      return;
    }

    selectItem(e.value as CargoTrackerResponse[]);
  };

  const handleRowClick = async (value: CargoTrackerResponse): Promise<void> => {
    if (activeCargo && await deferNextAction(CargoEditWarningDialogEvents.BEFORE_ACTION, CargoEditWarningDialogEvents.SET_CONTINUE_ACTION, () => editItem(value))) {
      return;
    }

    editItem(value);
  };

  const handleSignalRDelete = useCallback((event: CustomEvent<{ cargoId: string; }>): void => {
    const { cargoId } = event.detail;

    // Remove selection if item has been removed
    if (selectedCargo.findIndex(el => el.id === cargoId) > -1) {
      setSelectedCargo(selectedCargo.filter(item => item.id !== cargoId));
    }
  }, [selectedCargo]);

  const handleValueChange = (data:CargoTrackerResponse[]):void => {
    if (groupBy !== CargoTrackerGroupBy[CargoTrackerGroupBy['None']]) {
      setExpandedRows(getFirstItemsInGroups(data));
    }
  };

  useEffect(() => {
    eventBus.on(
      CargoTrackerSignalEventTypes.CARGO_TRACKER_DELETED,
      handleSignalRDelete
    );

    return () => {
      eventBus.remove(
        CargoTrackerSignalEventTypes.CARGO_TRACKER_DELETED,
        handleSignalRDelete
      );
    };
  }, [handleSignalRDelete]);

  useEffect(() => {
    if (groupBy !== CargoTrackerGroupBy[CargoTrackerGroupBy['None']]) {
      const groupRowsBy = CargoTrackerGroupByFieldName[CargoTrackerGroupBy[groupBy as keyof typeof CargoTrackerGroupBy]];
      // Groups are collapsed by default and to expand them we must provide with current first item in each group
      const firstItemsInGroups = getFirstItemsInGroups(cargo);
      setExpandedRows(firstItemsInGroups);
      tableRef.current?.filter(null,'','in');
      setGroupByProps({
        rowGroupMode: 'subheader',
        expandableRowGroups: true,
        onRowToggle: (e: {data: CargoTrackerResponse[]}) => setExpandedRows(e.data),
        groupRowsBy,
        sortField: groupRowsBy,
        // If there will be need for other default sorting for grouping:
        // multiSortMeta: [{field: 'status', order: -1},{field: 'userName,dateParsed,created', order: -1}],
        rowGroupHeaderTemplate: (data: CargoTrackerResponse) => (
          <>
            {camelToSpace(StatusEnum[data.status])} (
            {cargo.filter(c => c.status === data.status).length})
          </>
        ),
      });
    } else {
      setExpandedRows([]);
      setGroupByProps({});
    }
    // eslint-disable-next-line
  }, [cargo, groupBy]);

  return (
    <>
      {isMobile ?
        <DataView value={cargo}
          itemTemplate={(data: CargoTrackerResponse):JSX.Element => TimeCharterItem({data, handleRowClick})}
          className="cargo-tracker__view grow-to-fill no-background" />
        :
        <DataTable
          ref={tableRef}
          className="cargo-tracker__table--time-charter"
          dataKey="id"
          // rowClassName={(rowData):string => (rowData.isUrgent ? 'row--urgent' : '')} // Disabled marking as Urgent
          value={cargo}
          sortField="userName,dateParsed,created"
          sortOrder={-1}
          onValueChange={handleValueChange}
          expandedRows={expandedRows}
          {...groupByProps}
          removableSort
          scrollable
          selectionMode='checkbox'
          selection={selectedCargo}
          onRowClick={(e:DataTableRowClickEvent):Promise<void> => handleRowClick(e.data as CargoTrackerResponse)}
          onSelectionChange={(e): Promise<void> => handleSelection(e)}
          metaKeySelection={false}
          rowClassName={(d): undefined | string => {
            if (!activeCargo) {
              return;
            }

            if (d.id === activeCargo) {
              //	When a row has been clicked on and the edit/add panel is active
              //	we want the row to render in it's selected state
              return SELECTED_ROW_CLASS;
            }
          }}
          footer={
            selectedCargo?.length
              ?	<TableFooter
                selectedCargo={selectedCargo}
                setSelectedCargo={setSelectedCargo}
                activeCargo={activeCargo}
                setActiveCargo={setActiveCargo}
                showDeleteSuccess={showDeleteSuccess} />
              : <></>
          }
        >
          <Column selectionMode="multiple" frozen></Column>
          <Column
            header="Delivery"
            frozen
            sortable
            bodyClassName="no-border"
            field="flagCode,portName,portCountry,loading,zone.value"
            body={(data, config):JSX.Element => SingleLineFlagged(data.loading, config)}
            sortFunction={(e: ColumnSortEvent):F<string>[] => sortBySelectedFieldOptional(e, 'loading', 'portName', 'portCountry')}
          />
          <Column
            header="" // Updated / Newly created row badges
            frozen
            field="createdInfo,updatedInfo"
            body={(data, config):JSX.Element => SingleLineTimeoutBadge(data, config)}
          />
          <Column
            header="Delivery Date"
            field="laycan.original"
            body={(data):string => laycanStyleRange(data?.laycan?.fromDateParsed ?? '', data?.laycan?.toDateParsed ?? '')}
            sortable
            sortFunction={(e: ColumnSortEvent):ReturnType<typeof sortByDateTime> => sortByDateTime(e, 'laycan', 'fromDateParsed')}
          />
          <Column
            header="Re-Delivery"
            sortable
            field="flagCode,portName,portCountry,discharging,zone.value"
            body={(data, config):JSX.Element => SingleLineFlagged(data.discharging, config)}
            sortFunction={(e: ColumnSortEvent):F<string>[] => sortBySelectedFieldOptional(e, 'discharging', 'portName', 'portCountry')}
          />
          <Column
            header="C/D"
            sortable
            field="petroleumProductType"
            body={(data):string => PP_TEXT_MAP[data.petroleumProductType as PetroleumProductEnum]}
          />
          <Column header="Charter Period" sortable field="charterPeriod" />
          <Column
            header="Vessel Size"
            field="quantity"  // mapped to quantity
            body={QuantityBody}
            sortable
            sortFunction={(e: ColumnSortEvent):F<string>[] => sortBySelectedFieldOptional(e, 'quantity', 'fromMt', 'original')}
          />
          <Column
            header="Vessel Type"
            field="type,subType"
            sortable
            body={(data, config):JSX.Element => SingleLineOptional(data.vesselType, config)}
            sortFunction={(e: ColumnSortEvent):F<string>[] => sortBySelectedFieldOptional(e, 'vesselType', 'type', 'subType')}
          />
          <Column header="Charterer" field="charterer" sortable body={SingleLineTruncated} />
          <Column
            header="Status"
            field="status"
            body={(data):string => camelToSpace(StatusEnum[data.status])}
            sortable
          />
          <Column
            header="Assignee"
            field="assignedUser.userName"
            body={(data):string => formatName(data.assignedUser?.userName)}
            sortable
            sortFunction={(e: ColumnSortEvent): F<string>[] => sortBySelectedFieldOptional(e, 'assignedUser', 'userName')}
          />
          <Column header="Comments" field="notes" sortable />
          <Column
            header="Last Update"
            field="userName,dateParsed,updated"
            body={(data, config):JSX.Element =>
              DoubleLineUpdatedAuthorAndDate(data.updatedInfo, config)
            }
            sortable
            sortFunction={(e: ColumnSortEvent):ReturnType<typeof sortByDateTime> => sortByDateTime(e, 'updatedInfo', 'dateParsed')}
          />
          <Column
            header="Created"
            field="userName,dateParsed,created"
            body={(data, config):JSX.Element =>
              DoubleLineUpdatedAuthorAndDate(data.createdInfo, config)
            }
            sortable
            sortFunction={(e: ColumnSortEvent):ReturnType<typeof sortByDateTime> => sortByDateTime(e, 'createdInfo', 'dateParsed')}
          />
        </DataTable>
      }
    </>
  );
}
