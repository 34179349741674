import { getValueCollection } from 'helpers/Utils/enum';

import { SignalRApi } from 'server/signalR-api';

export enum DistListSignalEventTypes {
  DIST_LIST_UPDATED = 'DistributionListUpdated',
  DIST_LIST_EMAIL_UPDATED = 'EmailProjectionChange'
}
export class DistributionListSocket {

  private static _instance: DistributionListSocket;

  static get instance():DistributionListSocket {
    return this._instance || (this._instance = new this());
  }
  init = async (sr: SignalRApi): Promise<void> => {

    const events = [
      ...getValueCollection(DistListSignalEventTypes, false)
    ];

    events.map(event => sr.dispatchEventOnMessage(event.key));
  };
}