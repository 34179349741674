import { clsx } from 'clsx';
import { Button } from 'primereact/button';

import styles from './NoData.module.scss';

type NoDataProps = {
  header: string,
  icon: string,
  isButton?: boolean,
  buttonText?: string,
  buttonAction?: () => void,
}

const NoData = (props: NoDataProps): JSX.Element => {

  const { header, icon, isButton, buttonText, buttonAction } = props;

  return <section className={clsx(styles.section, 'grow-to-fill overflow--hidden')}>
    <i className={clsx(icon, styles.icon, 'no-background')}></i>
    <h2 className={clsx(styles.header, 'no-background')}>
      {header}
    </h2>
    {isButton && <Button
      icon="iconoir-plus icon--tiny icon--ob-orange icon--primary"
      className={clsx(styles.button, 'no-background')}
      text
      size="small"
      onClick={buttonAction}
    >
      {buttonText}
    </Button>}
  </section>;
};

export default NoData;
