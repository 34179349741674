import { DateTime } from 'luxon';
import { 
	RateGridApiColumn,
	IRateGridApiRouteValue,
	IRateGridRouteValueResponse,
	RateGridRoute,
} from '../Models/RateGridResponse';

import { formatName } from 'helpers/Utils/string';
import { replaceItemAt } from 'helpers/Utils/collections';


/**
 * Parser method which is used by the RateGridApi.getRateGrid method to
 * generate an array of column headings for the grid, dependent on what values
 * are available
 *
 * @param {RateGridApiColumn[]} columns
 * @returns {RateGridColumn[]}
 */
export const columnsFactory = (columns: RateGridApiColumn[]): RateGridApiColumn[] => {

	return columns.sort((a: RateGridApiColumn, b: RateGridApiColumn) => a.displayOrder > b.displayOrder ? 1 : -1).map((c:RateGridApiColumn) => {
		// const { name, columnGrouping, displayOrder } = c;

		return c;
	})
}


/**
 * Parser function which takes the contents returned from the Rates/Grid
 * end point sanitising values where required. Converts any ISO date 
 * strings to Luxon.DateTime instances, as well as ensuring the collection
 * index for a price is correctly aligned to the column it applies too
 *  
 * @param {RateGridRoute<IRateGridApiRouteValue>[]} routes
 * @param {RateGridApiColumn[]} cols
 * @returns {RateGridRoute<IRateGridRouteValueResponse>[]}
 */
export const routeFactory = (routes: RateGridRoute<IRateGridApiRouteValue>[], cols: RateGridApiColumn[], grid: string): RateGridRoute<IRateGridRouteValueResponse>[] => {
	
	return routes.map(r => {
		const { name, values: passedValues } = r;


		const values: IRateGridRouteValueResponse[] = passedValues.reduce(
			(a: IRateGridRouteValueResponse[], current) => {
				//	destructure the props we need
				const { columnName, columnGroup, asOf, dataType, value, valueSetByDisplayName, behaviour } = current;
				const { unit } = behaviour;

				//	Whats the index for this data 
				const colIndex = cols.findIndex(c => c.columnGrouping === columnGroup && c.name === columnName );

				return replaceItemAt<IRateGridRouteValueResponse>(
					a,
					{
						dataType,
						value,
						unit,
						valueSetByDisplayName: formatName(valueSetByDisplayName),
						behaviour,
						asOf: DateTime.fromISO(asOf, { zone: 'utc' }),
						columnName,
						columnGroup,
						grid
					},
					colIndex
				);
			},
			Array.from({length: cols.length}) as IRateGridRouteValueResponse[]
		)

		return { name, values }
	})
}