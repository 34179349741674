import clsx from 'clsx';

import './GridElement.scss';

interface GridElementProps {
  header: string;
  body: string | JSX.Element | undefined;
  className?: string;
}

const GridElement = (props: GridElementProps): JSX.Element => {
  const { className, header, body } = props;

  return (
    <div className={clsx('grid-element', className)}>
      <div className='grid-element__header'>{header}</div>
      <div className='grid-element__body'>{body ?? '-'}</div>
    </div>
  );
};
export default GridElement;