import { clsx } from 'clsx';

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import { AvailableRateGridsResponse } from 'modules/Rates/Grids/Models/RateGridResponse';

import styles from './Tertiary.module.scss';

// type GridKey = 

interface ITertiaryNavigationPops {
	items: AvailableRateGridsResponse[];
	changeCallback: (value: any) => void,
	currentSelection: any,
	isMobile: boolean;
	labelKey?: 'name' | 'description';
}

type NavigationGrid = Omit<AvailableRateGridsResponse, 'dimensions'>;

function TertiaryNavigation(props: ITertiaryNavigationPops) {

	const { items, changeCallback, currentSelection, isMobile, labelKey } = props;

	if (isMobile) {
		return <div>
			<Dropdown
				value={currentSelection}
				className='grow-to-fill'
				onChange={ (e) => changeCallback(e.value)}
				options={items ?? []}
				optionLabel={labelKey}
			/>
		</div>
	}

	return <div className={styles.container}>
		{ items.map((item, index) => (
			<Button
				onClick={() => changeCallback(item)}
				key={`${item.name}-${index}`}
				size='small'
				className={clsx(
					'p-button--tab-style',
					currentSelection?.name?.toLowerCase() === item.name.toLowerCase() && 'active'
			)}>
			{item[labelKey as keyof NavigationGrid]}
		</Button>
		))}
	</div>
}

export default TertiaryNavigation;
