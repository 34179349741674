import { DateTime } from 'luxon';

import { AvailableRateGridsResponse } from 'modules/Rates/Grids/Models/RateGridResponse';

export type AvailableModGridResponse = AvailableRateGridsResponse & {
	label: string,
	dimensions: string[],
	source: IEAModSources
}

//	
export type AvailableGridsResponse = {
	data: AvailableModGridResponse[];
}

export type ApiModsDataResponse = {
	data: IEAModsSourceResponse | undefined
} 


export enum IEAModSources {
	Supply = 'SUPPLY',
	DemandOECDDE = 'OECDDE',
	DemandNonOECDDE = 'NOECDDE',
	Summary = 'SUMMARY',
	BalancesCrude = 'CRUDEDAT',
	BalancesProduct = 'PRODDAT',
	BalancesSubProduct = 'SPLITDAT',
	Stocks = 'STOCKDAT',
	TradeImports = 'IMPORDAT',
	TradeExport = 'EXPORDAT',
	Electricty = 'ELECTRICITY',
	Jodi = 'JODI',
	Throughputs = 'REFTHROUGHPUT'
}

export enum JodiSources {
	Oil = 'OIL'
}

export enum IEAModFrequency {
	Monthly = 'monthly',
	Quarterly = 'quarterly',
	Yearly = 'yearly'
}

export type IEAModsSourceResponse = {
	source: IEAModSources,
	frequency: IEAModFrequency,
	columns: {name: string, label: string, unit: string}[];
	items: IEAModItemRaw[]
}

export type IEAModItemRaw = {
	location: string,
	shortName: string,
	period: string,
	columns: {key: string, value: number, unit: string}[],
	lastUpdated: string,
	hash: string;
}

export type IEAModSearchParams = {
	original?: string,
	query?: string
}


//	Refined type definitions for instances that have been processed by
//	service end points
export type IEAModItem = Omit<IEAModItemRaw, 'columns'> & {
	lastUpdated: DateTime
}

export type IEAModSourceData = Omit<IEAModsSourceResponse, 'items'> & {
	rows: Omit<IEAModItem, 'columns'>[],
}

