export enum RatesGridSignalRMessages {
	RATE_PROJECTION_GRID_CHANGE = 'RateGridProjectionChange',
	RATE_PROJECTION_VALUE_CHANGE = 'RateGridValueProjectionChange',
}

export enum SignalRGridMutationType {
	CellValue = 0,
	FullGrid = 1,
}


