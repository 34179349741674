import { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import clsx from 'clsx';

import EditableContent from 'modules/Workflows/Components/EditableContent';

// import type { ApiWorkflowResponse } from 'modules/Workflows/Models/ApiWorkflowResponse';
import { useRenameWorkflow } from 'modules/Workflows/Services/hooks';
import { Workflow } from 'modules/Workflows/Models';
import { WorkflowEvents } from 'modules/Workflows/Models/Enums';

import eventBus from 'server/EventBus';

import './WorkflowName.scss';

interface WorkflowNameProps {
    workflow: Workflow;
};

export default function WorkflowName(props: WorkflowNameProps): JSX.Element {

    const { workflow } = props;

		const { renameWorkflow, isMutating } = useRenameWorkflow();

    const [ title, setTitle ] = useState<string>(workflow.title);

    const rename = async () => {
			try {
				await renameWorkflow({ workflowid: workflow.id, title })
				return true;
			} catch (e) {
				return false;
			}
    };

		useEffect(() => { setTitle(workflow.title); }, [workflow])

		useEffect(() => {
			eventBus.dispatch(WorkflowEvents.Mutation, {isMutating});
		}, [isMutating])

    return <div className='workflow-name__container'>
			<EditableContent
				save={rename}
				saveButtonDisabled={!title}
				saveButtonLoading={isMutating}
				value={title}
			>
				<InputText
					autoFocus
					className={clsx({ 'p-invalid': !title })}
					defaultValue={workflow.title}
					onChange={(e) => setTitle(e.target.value.trim())}
				/>
				{/* TODO: could use `Joi.valideAsync` to setup errors but for now it's would be overkill  */}
				{!title && <small className='message-invalid'>Required field</small>}
      </EditableContent>
    </div>
}