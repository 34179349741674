import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';

import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';
import { RATES_ROUTE_CONFIG } from 'index';

export default function Rates(): JSX.Element {
  const { obxuser } = useLoggedInUser();
  const outletContext = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Redirect to 1st available item (Baltic, Vortexa, etc.) on entering the `/rates` page.
    // This component shouldn't be rendered at all if no access code has been found, but if you see
    // an empty page, see if '/rates' has all the possible `accessCode`s (for each sub-module) included.
    const found = obxuser?.assignedSecurityRights.find(asr => RATES_ROUTE_CONFIG.some(r => r.accessCode === asr));

    if (found && location.pathname === '/rates') {
      navigate(`/rates/${RATES_ROUTE_CONFIG.find(r => r.accessCode === found)?.path ?? ''}`);
    }
    // eslint-disable-next-line
  }, [obxuser?.assignedSecurityRights]);

  // render children routes
  return <Outlet context={outletContext} />;
}
