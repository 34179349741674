import { DateTime } from 'luxon';
import { notNil } from './misc';

export const currencyFormatter = (value: number, dp?: number) => {
    let nf = Intl.NumberFormat(
        "en-US",
        {
            style: "decimal",
            minimumFractionDigits: dp ?? 2
        }
    )
    return nf.format(value);
}

export const distanceFormatter = (value: number, dp?: number) => {
	let nf = Intl.NumberFormat(
			"en-US",
			{
					style: "decimal",
					maximumFractionDigits: 0
			}
	)
	return nf.format(value);
}

interface humanDateOpts {
    time?: boolean;
    toUTC?: boolean;
    dayName?: boolean
}

export const humanDate = (date: string | DateTime, opts: humanDateOpts = {time: false, toUTC: false, dayName: true}): string => {
	let dt: DateTime;

	if (typeof date === "string") {
		dt = opts.toUTC ? DateTime.fromISO(date).toUTC() : DateTime.fromISO(date, {setZone: true});
	} else {
		dt = date;
	}

  return dt.toFormat(`${opts.dayName ? "ccc " : "" }DD${opts.time ? ", T" : ""}`);
}

export const laycanStyleRange = (from: DateTime | string, to: DateTime | string ): string => {

	//	guard against one of the values being null
	if (!notNil(from) || !notNil(to)) return "";

	if (typeof from === "string" ) {
		from = DateTime.fromISO(from, {zone: 'utc'});
	}

	if (typeof to === "string" ) {
		to = DateTime.fromISO(to, {zone: 'utc'});
	}

	if (!from.isValid || !to.isValid) return '';

	const sameDay: boolean = from.hasSame(to, 'day');
	const sameMonth: boolean = from.hasSame(to, 'month');
	const sameYear: boolean = from.hasSame(to, 'year');

	if (sameDay) {
		return to.toFormat("d LLL y")
	} else {
		const fromFormat: string = sameYear ? sameMonth ? "d" : "d LLL" : "d LLL y";
		return `${from.toFormat(fromFormat)}-${to.toFormat("DD")}`
	}
}

export const numberFormatter = (number: number, options?: Intl.NumberFormatOptions): string =>
  	new Intl.NumberFormat('en-GB', { style: 'decimal', maximumFractionDigits: 0, ...options }).format(number);
