import { ReactNode } from 'react';
import JsonFormatter from 'react-json-formatter';
import { Button } from 'primereact/button';

import './CodeSnippet.scss';

interface CodeSnippetProps {
    code: string;
    title?: ReactNode;
    showCopyButton?: boolean;
}

export default function CodeSnippet(props: CodeSnippetProps): JSX.Element {
    const { code, title, showCopyButton = true } = props;

    const copyToClipBoard = async (): Promise<void> => {
        await navigator.clipboard.writeText(code);

        console.log("copied to clip board…")
    }
    
    return <code className="code-snippet__container">
        {title}
        <JsonFormatter json={code} tabWith={4} />
        {showCopyButton && <Button
            className="code-snippet__copy-button"
            onClick={copyToClipBoard} 
            text 
            icon="iconoir-multiple-pages icon--small"
        />}
    </code>
}