import { clsx } from "clsx";
import { ColumnBodyOptions } from "primereact/column";

import  styles from "./DoubleLine.module.scss";

interface IAdditionalProps {
	quantityTop?: boolean;
}

/**
 * General custom cell rendering function for the <DataTable> instances. Used to display
 * 2 points of related data over two lines. Assumed that second line is a quantity so
 * the numerical value will be auto formatted
 *
 * @param {T} data 
 * @param {ColumnBodyOptions} config
 * @param {IAdditionalProps} props
 *
 */
export const DoubleLineQuantity = <T,>(data: T, config: ColumnBodyOptions, props?: IAdditionalProps) => {

  const [ upper, lower ] = config.field.split(",");

	let topRow: string, bottomRow: string, value: number;
	
	//	TODO - clean this up. Probably more elegant way of doing this…
	//	is upper item is what needs formaating
	if (props?.quantityTop) {
		value = +data[upper as keyof T];
		topRow = Number.isInteger(value) ? Intl.NumberFormat('en', {notation: "compact"}).format(value) : "Unknown";
		bottomRow = `${data[lower as keyof T]}`;
	} else {
		value = +data[lower as keyof T]
		topRow = `${data[upper as keyof T]}`;
		bottomRow = Number.isInteger(value) ? Intl.NumberFormat('en', {notation: "compact"}).format(value) : "Unknown";
	}
	

    return <div className={clsx(
        "direction--column align-items--start",
        styles.cell
    )}>
        <span className={clsx("upper", styles.upper, !Number.isInteger(value) && props?.quantityTop && `unknown ${styles.unknown}` )}>{topRow}</span>
        <span className={clsx("lower", styles.lower, !Number.isInteger(value) && !props?.quantityTop && "unknown")}>{bottomRow}</span>
    </div>
}

