import {useEffect,useState} from 'react';
import { useMediaQuery } from 'react-responsive';

import type { Suggestion, SuggestionGroup, SuggestionItem } from 'components/Autocomplete';
import Autocomplete from 'components/Autocomplete';
import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import type { SearchSuggestionsGroupedResponse, SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';
import { useSearchSuggestions } from 'components/EntitySearch/Services/SearchEntitiesAPI';

import { Group } from '../../Models/Enums';

import { INPUT_PLACEHOLDER, INPUT_PLACEHOLDER_MOBILE,TOKENS_LIMIT_NUMBER } from 'modules/DistanceCalc/Models/Consts';

interface IDistanceCalcEntitySearchProps {
  callback: Function;
  externalSearchParams?: SuggestionItem[];
  externalSuggestions?: Suggestion[];
  handleSelectedExternalItemChange?: (items: SuggestionItem[], removedItem?: SuggestionItem) => void;
}

export default function DistanceCalcEntitySearch (param: IDistanceCalcEntitySearchProps): JSX.Element {
  const { callback, externalSearchParams = [], externalSuggestions, handleSelectedExternalItemChange } = param;
  const isMobile = useMediaQuery({ query: "(max-width: 960px)" });

  const [selectedItems, setSelectedItems] = useState<SuggestionItem[]>([]);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [value, setValue] = useState("");

  const { data, /* error,  */isLoading, isValidating } = useSearchSuggestions({
    module: EntitySearchGroupEnum.DistanceCalculator,
    fields: [EntitySearchFieldsEnum.VesselSize, EntitySearchFieldsEnum.Port],
    term: value,
    chunkSize: 10
  });

  /**
   * Method checks if suggestion item should be shown in the suggestions list, ex. prevent adding the same item twice.
   * @param suggestionItem Item to be checked if should be displayed.
   * @returns True if item should be shown in the list. False otherwise.
   */
  function isSuggestionItemAvailable(suggestionItem: SuggestionItem): boolean {
    return selectedItems.findIndex(item => suggestionItem.group === item.group && suggestionItem.name === item.name) === -1 ||
      externalSearchParams.findIndex(item => suggestionItem.group === item.group && suggestionItem.name === item.name) === -1;
  }

  /**
   * Method checks if suggestion (group) should be shown in the suggestions list.
   * Doesn't allow to add more items from group if limit (max number of items from group) is reached.
   * @param suggestion Suggestion to be checked if should be displayed.
   * @returns True if suggestion (whole group) should be shown in the list. False otherwise.
   */
  function isSuggestionAvailable (suggestion: Suggestion): boolean {
    return !!(suggestion.items.length && selectedItems.filter(selectedItem =>
        // add group only if token limit is not reached yet
        selectedItem.group === suggestion.group).length < TOKENS_LIMIT_NUMBER[Group[suggestion.group as keyof typeof Group]]) &&
        // do not propose discharging as first element
        !(selectedItems.length === 0 && suggestion.group === Group[Group.Discharging])
  }

  /**
   * Adds "group" property to each item to allow selecting item (and setting the label) from proper group
   * @param items Suggestion items but without group property.
   * @param group Group name to be added as a property to each item.
   * @returns New list of suggestion items that each element contains also "group" property.
   */
  function setGroup(items: Omit<SuggestionItem, "group">[], group: SuggestionGroup): SuggestionItem[] {
    return items.map(item => ({ ...item, group }));
  }

  function handleSelectedItemsChange(items: SuggestionItem[], removedItem?: SuggestionItem) {
    const newItems = Object.entries(items)
      .map( e => {
        const [ , loc ] = e;
        return loc;
      });

    const locations = newItems.filter(item => !item.external);
    const externalItems = newItems.filter(item => item.external);

    // Handle only internal items. External items are handled outside independently.
    // NOTE: remember to update here if there will be more types!
    setSelectedItems(locations);
    //  pass this up to our parent callback handler so a new calc can be run
    callback({ name: 'locations', value: locations });

    if (typeof handleSelectedExternalItemChange === 'function') {
      handleSelectedExternalItemChange(externalItems, removedItem);
    }
  }

  useEffect(() => {
    let itemsWithGroup: Suggestion[] = [];
    const d = data as SearchSuggestionsGroupedResponse[];
    const ports: (SearchSuggestionsResponse & { CountryCodeISO3?: string })[] = [];
    const vessels: (SearchSuggestionsResponse & { FlagCode?: string; Id?: string, VesselType?: string; })[] = [];

    if (data) {
      d.forEach(item => {
        if (item.searchFieldId === EntitySearchFieldsEnum.Country || item.searchFieldId === EntitySearchFieldsEnum.Port) {
          ports.push(...item.values);
        } else if (item.searchFieldId === EntitySearchFieldsEnum.VesselSize) {
          vessels.push(...item.values);
        }
      });

      //  TODO - these can use our mappers

      const portItems = ports.map((port, index) => ({
          ...port,
          icon: port.CountryCodeISO3,
          label: port.value,
          name: port.value,
        })
      );

      const vesselItems = vessels.map((vessel, index) => ({
        ...vessel,
        label: vessel.value ?? vessel.Id,
        subLabel: vessel.VesselType,
        name: vessel.value ?? vessel.Id,
      }));

      itemsWithGroup = [
        {
          group: Group[Group.Loading],
          items: setGroup(portItems, Group[Group.Loading])
        },
        {
          group: Group[Group.Via],
          items: setGroup(portItems, Group[Group.Via])
        },
        {
          group: Group[Group.Discharging],
          items: setGroup(portItems, Group[Group.Discharging])
        },
        {
          group: Group[Group["Vessel Class"]],
          items: setGroup(vesselItems, Group[Group["Vessel Class"]])
        },
      ];

      if (externalSuggestions) {

        externalSuggestions.forEach((extSug) => {
          const externalSuggestionItems = extSug.items.reduce((suggestions, suggestion, index) => {
            // filter out suggestions that contains inputted phrase as it's not comming from 'Search/' endpoint and won't be filtered by the BE
            if (suggestion.name.toLowerCase().includes(value.toLowerCase())) {
              return [
                ...suggestions,
                {
                  ...suggestion,
                  label: suggestion.name,
                }
              ];
            }

            return suggestions;
          }, [] as SuggestionItem[]);

          itemsWithGroup.push(
            {
              group: extSug.group,
              items: externalSuggestionItems
            }
          )
        })
      }
    }

    setSuggestions(itemsWithGroup.reduce((newSuggestions, currSuggestion) => {

      if (isSuggestionAvailable(currSuggestion)) {
        return [
					...newSuggestions,
					{...currSuggestion, items: currSuggestion.items.filter(isSuggestionItemAvailable)}
				];
      }

      return newSuggestions;
    }, [] as Suggestion[]));

  // eslint-disable-next-line
  }, [data]);

  return <Autocomplete
    handleInputValueChange={setValue}
    handleSelectedItemsChange={handleSelectedItemsChange}
    isLoading={isLoading || isValidating}
    selectedItems={[...selectedItems, ...externalSearchParams]}
    suggestions={suggestions}
    placeholder={isMobile ? INPUT_PLACEHOLDER_MOBILE : INPUT_PLACEHOLDER}
  />
}