import { useCallback, useEffect, useState } from 'react';

import Ribbon from '../Ribbon';

import './NoInternetConnection.scss';

export default function NoInternetConnection(): JSX.Element {
    const [isOnline, setIsOnline] = useState(true);

    const handleBrowserConnectionChange = useCallback(() => {
        const { onLine } = window.navigator;

        // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
        // (...)If the browser is not able to connect to a local area network (LAN) or a router, it is offline;
        // all other conditions return true. So while you can assume that the browser is offline when it returns
        // a false value, you cannot assume that a true value necessarily means that the browser can access the internet.(...)
        setIsOnline(onLine);
        console.log(`NoInternetConnectionRibbon.hasConnection ${onLine ? 'onLine' : 'offLine'}`);
    }, []);

    useEffect(() => {
        window.addEventListener('offline', handleBrowserConnectionChange);
        window.addEventListener('online', handleBrowserConnectionChange);

        return () => {
            window.removeEventListener('offline', handleBrowserConnectionChange);
            window.removeEventListener('online', handleBrowserConnectionChange);
        };
    }, [handleBrowserConnectionChange]);

    if (isOnline) {
        return <></>;
    }

    return <Ribbon
        className="no-internet-connection-ribbon"
        closable={false}
        icon="iconoir-wifi-off"
        severity="error"
        text="No internet connection"
    />;
}
