import { CalculationDistanceUnit } from 'modules/DistanceCalc/Models/Enums';
import { distanceFormatter } from 'helpers/Utils/formatters';

import type { CalculationDistance, CalculationDuration } from 'modules/DistanceCalc/Models/CalculationResponse';

import './LegFooter.scss';

export interface LegFooterProps {
    distance: CalculationDistance;
    time: CalculationDuration;
}

export default function LegFooter (props: LegFooterProps): JSX.Element {
    const { distance, time } = props;

    return <div className="leg-footer__container">
        <div className="leg-footer__distance">
            {distance && <>
                <label htmlFor="leg-footer-distance">Distance</label>
                <div id="leg-footer-distance">
                    {`${distanceFormatter(distance.value)} ${CalculationDistanceUnit[distance.unit]}`}
                </div>
            </>}
        </div>
        <div className="leg-footer__time">
            {time && <>
                <label htmlFor="leg-footer-time">Time</label>
                <div id="leg-footer-time">
                    {`${time.days} day${time.days === 1 ? '' : 's'} ${time.hours} hr${time.hours === 1 ? '' : 's'}`}
                </div>
            </>}
        </div>
    </div>
}