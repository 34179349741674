import clsx from "clsx";

import { ProbabilityLevels } from '../Models/CargoMovements';

import styles from "./Probability.module.scss";

export const Probability = <T, >(data: T, config: {field: string}) => {

	const { field }: { field: string } = config;
	const value: number = data[field as keyof T] as number;

	const band = (n: number): [string, number] => {
		if (!n) return ['', 0];

		switch(true) {
			case n < ProbabilityLevels.Medium:
				return [styles.low, 1]
			case n < ProbabilityLevels.High:
				return [styles.medium, 2];
			default:
				return [styles.high, 3];
		}

	}

	const [style, rank] = band(value);

	return <div className={clsx(styles.probability, style)}>
		{[...Array(rank)].map((i, ind) =>
			<img src="/img/sentiment-badging.svg" key={ind} alt={`Bullet ${ind} of ${rank}`}/>
		)}
	</div>

}