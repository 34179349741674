import clsx from 'clsx';

import './UserRecord.scss';

const UserRecord = ({
  name,
  email,
  className,
  children,
}: {
  name: string;
  email?: string;
  className?: string;
  children?: JSX.Element | JSX.Element[];
}): JSX.Element => (
  <div className={clsx('user-record--container', className)}>
    <div>{name}</div>
    {email && (
      <div className={'user-record--email'}>
        <i className='iconoir-mail icon--tiny' />
        <div>{email}</div>
      </div>
    )}
    {children}
  </div>
);

export { UserRecord };
export default UserRecord;
