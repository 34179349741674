import Joi from "joi";

import { COMISSION_RATE_OPTIONS, COMMISSION_RATE_FIELD, COMMISSION_RATE_VALUE_FIELD, CommissionRateType } from './CommissionRate';

const MESSAGES = {
    'string.empty': 'Required field',
    'string.pattern.base': 'Invalid format',
    'string.email': 'Invalid format',
    'string.min': 'Field must have min {#limit} characters',
    'string.max': 'Field must have max {#limit} characters',
	'any.only': 'Required field'
};

export const commissionRateValidator = Joi.object({
	[COMMISSION_RATE_FIELD]: Joi.string().valid(...COMISSION_RATE_OPTIONS).required().messages(MESSAGES),
	[COMMISSION_RATE_VALUE_FIELD]: Joi.when(COMMISSION_RATE_FIELD, {
		is: CommissionRateType.Custom,
		then: Joi.string().required().max(10).messages(MESSAGES),
		otherwise: Joi.optional().allow('').messages(MESSAGES)
	})
});
