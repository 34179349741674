import useSWR, { Key, State, mutate, useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import axios, { type AxiosError } from 'axios';
import fileDownload from 'js-file-download';
import { DateTime } from 'luxon';

import { parsePropsToDateTime } from 'helpers/Utils/misc';
import { removeItemAt, replaceItemAt } from 'helpers/Utils/collections';
import { asEncoded } from 'helpers/Utils/string';
import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';

import { DEFAULT_PRICE, QUANTITY_UNITS, TRADES_DATA_KEY } from '../Models/Consts';
import { TradeType } from '../Models/Enums';

import type {
  BlotterDataResponse,
  ExportTradesPayload,
  GetTradesDataPayload,
  TradeDetailsRequest,
  TradeDetailsResponse,
  TradesDataResponse,
  TradeSide,
} from '../Models/BlotterResponse';

export const useGetTrades = (arg: GetTradesDataPayload | undefined): {
  data: BlotterDataResponse | undefined;
  error: AxiosError;
  isLoading: boolean;
  isValidating: boolean;
  // updateCacheOnTradeDelete: (id: string) => void;
  // updateCacheOnTradeUpdate: (trade: TradesDataResponse) => void;
} => {
  // const { cache } = useSWRConfig();
  let cacheKey: string | null;

  switch (true) {
    case !arg:
      // no args -> don't fetch anything
      cacheKey = null;
      break;
    case !arg?.searchRequestFields?.length && !arg?.dateTime && !arg?.prices?.length && !arg?.quantities?.length && !arg?.clearingIds?.length && !arg?.orderByFields:
      // no search items nor date -> all items cache
      cacheKey = `${TRADES_DATA_KEY}-${arg.pageNumber}`;
      break;
    default:
      arg = {
        ...arg,
        // special treatment for 'OBBroker'
        // because we are searching by 'userId' and not 'userName' - hence use entityId instead of regular 'searchTerm'
        searchRequestFields: arg.searchRequestFields?.map(item =>
          item.searchField === EntitySearchFieldsEnum.OBBroker ? { ...item, searchTerm: item.searchEntityId! } : item),
        prices: (arg?.prices ?? []).map(({ from, to }) => ({ from, to })), // send only necessary fields
        quantities: (arg?.quantities ?? []).map(({ from, to }) => ({ from, to })),
        clearingIds: (arg.clearingIds ?? []).map((v) => typeof v === 'string' ? v : v.searchTerm)
      }
      // otherwise -> use arg as an unique key
      cacheKey = `${TRADES_DATA_KEY}-${asEncoded(arg as object, false)}`;
      break;
  }

  const { data, error, isLoading, isValidating } = useSWR(
    cacheKey,
    () => BlotterDataApi.getTradesData(arg!),
    {
      revalidateOnFocus: false
    }
  );

  // // TODO: How to update cache with pagination functionality?
  // // The key has to be unique to fetch new chunk of data but in the same time it means that the new cache entry is created
  // // and it's not easy way (possible?) to update it.
  // const getTradeStateData = (id: string): { key: string; state: State<BlotterDataResponse> | undefined; trades: TradesDataResponse[] | undefined, index: number; } => {
  //   const key = cacheKey ?? TRADES_DATA_KEY;

  //   const state = cache.get(key) as State<BlotterDataResponse> | undefined;
  //   const trades = state?.data?.results;
  //   const index = trades?.findIndex(t => t.id === id) ?? -1;

  //   return { key, state, trades, index };
  // };

  // const updateCacheOnTradeDelete = (tradeId: string): void => {
  //   const { trades, index, key, state } = getTradeStateData(tradeId);

  //   if (!trades) {
  //     return;
  //   }

  //   if (index > -1) {
  //     const newData = { ...state?.data, results: removeItemAt(trades, index) };

  //     cache.set(key, { ...state, data: newData });
  //     mutate(key, newData, { revalidate: false });
  //   }
  // };

  // // TODO: How to update cache with pagination functionality
  // const updateCacheOnTradeUpdate = (trade: TradesDataResponse): void => {
  //   const { trades, index, key, state } = getTradeStateData(trade.id);

  //   if (!trades) {
  //     return;
  //   }

  //   const newData = { ...state?.data, results: replaceItemAt(trades, parsePropsToDateTime(trade, ['dateTime']), index, false) };

  //   cache.set(key, { ...state, data: newData });
  //   mutate(key, newData, { revalidate: false });
  // };

  return {
    data,
    error,
    isLoading,
    isValidating,
    // updateCacheOnTradeDelete,
    // updateCacheOnTradeUpdate
  };
};

export const useGetTradeDetails = (tradeId: string | null): { data: TradeDetailsResponse | undefined, error: AxiosError, isLoading: boolean, isValidating: boolean; } => {
  const { data, error, isLoading, isValidating } = useSWR(
    tradeId ? `blotter-trade-details-${tradeId}` : null,
    () => BlotterDataApi.getTradeDetails(tradeId!),
    {
      revalidateOnFocus: false
    }
  );

  return { data, error, isLoading, isValidating };
};

export const useSaveTrade = (): { trigger: (arg: TradeDetailsRequest) => Promise<TradeDetailsResponse | undefined>; isMutating: boolean; error?: AxiosError; } => {
  const { cache } = useSWRConfig();

  const { trigger, isMutating, error } = useSWRMutation<TradeDetailsResponse, AxiosError, Key, TradeDetailsRequest>(
    'blotter-save-trade',
    BlotterDataApi.saveTrade,
    {
      onSuccess(data) {
        const state = cache.get(TRADES_DATA_KEY) as State<TradesDataResponse[]>;
        const trades = state?.data;
        const itemMappedToTradesData: TradesDataResponse = BlotterDataApi.mapItemToTradeGridData(data);

        if (!trades) {
          cache.set(TRADES_DATA_KEY, { ...state, data: [itemMappedToTradesData] });
          return;
        }

        const index = trades.findIndex(t => t.id === data.id);
        const newData = replaceItemAt(trades, itemMappedToTradesData, index, false);

        cache.set(TRADES_DATA_KEY, { ...state, data: newData });

        mutate(TRADES_DATA_KEY, newData, { revalidate: false });
      },
    }
  );

  return { trigger, isMutating, error };
};

export const useDeleteTrade = (): { trigger: (id: string) => Promise<string | undefined>; error?: AxiosError, isMutating: boolean; } => {
  const { cache } = useSWRConfig();

  const { trigger, error, isMutating } = useSWRMutation<string, AxiosError, Key, string>(
    'delete-trade',
    BlotterDataApi.deleteTrade,
    {
      onSuccess(removedTradeId) {
        const state = cache.get(TRADES_DATA_KEY) as State<TradesDataResponse[]>;
        const trades = state?.data;

        if (!trades) {
          return;
        }

        const index = trades.findIndex(t => t.id === removedTradeId);

        if (index > -1) {
          const newData = removeItemAt(trades, index);

          cache.set(TRADES_DATA_KEY, { ...state, data: newData });

          mutate(TRADES_DATA_KEY, newData, { revalidate: false });
        }
      },
    }
  );

  return { trigger, error, isMutating };
};

export const useExportTrades = (): { trigger: (arg: ExportTradesPayload) => Promise<Blob | undefined>; error?: AxiosError, isMutating: boolean; } => {
  const { trigger, error, isMutating } = useSWRMutation<Blob, AxiosError, Key, ExportTradesPayload>(
    'export-trades',
    BlotterDataApi.exportTrades
  );

  return { trigger, error, isMutating };
}


export class BlotterDataApi {
  static getTradesData = async (arg: GetTradesDataPayload): Promise<BlotterDataResponse> => {
    return axios.request<GetTradesDataPayload, { data: BlotterDataResponse; }>({
      method: 'POST',
      url: '/blotter/trades/search',
      data: arg
    })
      .then(res => ({ ...res.data, results: res.data.results?.map(d => parsePropsToDateTime(d, ['dateTime'])) }))
      .catch(e => {
        // TODO: handle if needed
        throw e;
      });
  };

  static getTradeDetails = async (tradeId: string): Promise<TradeDetailsResponse> => {
    return axios.get(`/blotter/trades/${tradeId}`)
      .then(res => parsePropsToDateTime(res.data, ['dateTime']))
      .catch(e => {
        // TODO: handle if needed
        throw e;
      });
  };

  static saveTrade = async (url: string, params: { arg: TradeDetailsRequest; }): Promise<TradeDetailsResponse> => {
    return axios.request<TradeDetailsRequest, { data: TradeDetailsResponse; }>({
      method: params.arg.id ? 'PUT' : 'POST',
      data: params.arg,
      url: '/blotter/trades'
    })
      .then(res => parsePropsToDateTime(res.data, ['dateTime']))
      .catch((e) => { throw e; });
  };

  static deleteTrade = async (url: string, params: { arg: string; }): Promise<string> => {
    return axios.request<TradeDetailsRequest, string>({
      method: 'DELETE',
      url: `/blotter/trades/${params.arg}`
    })
      .then(() => params.arg)
      .catch((e) => { throw e; });
  }

  static exportTrades = async (url: string, params: { arg: ExportTradesPayload; }): Promise<Blob> => {
    return axios.request<ExportTradesPayload, { data: Blob; }>({
      method: 'POST',
      url: 'blotter/trades/export',
      data: params.arg,
      responseType: 'blob'
    })
      .then((response) => {
        fileDownload(response.data, `Trades ${DateTime.now().toISODate()}.xlsx`, 'application/vnd.ms-excel');

        return response.data;
      })
      .catch((e) => { throw e; });
  }

  static mapItemToTradeGridData = (data: TradeDetailsResponse): TradesDataResponse => ({
    id: data.id,
    instrument: data.instrument,
    type: data.type,
    dateTime: data.dateTime as DateTime,
    buyerCompany: data.legs[0].buyer.company,
    buyerContactName: data.legs[0].buyer.contactName,
    buyerObBroker: data.legs[0].buyer.obBroker,
    sellerCompany: data.legs[0].seller.company,
    sellerContactName: data.legs[0].seller.contactName,
    sellerObBroker: data.legs[0].seller.obBroker,
    quantity: data.quantity,
    price: data.legs[0].price,
    clearingId: data.clearing.id ?? '',
    isImported: data.isImported
  });

  private static get counterparty(): TradeSide {
    return {
      company: '',
      contactName: '',
      obBroker: {
        userId: '',
        userName: ''
      },
      paysBrokerage: false,
      rate: undefined,
      tradingAccount: '',
    };
  };

  static createEmptyTrade = (): TradeDetailsRequest => ({
    id: null,
    instrument: '',
    legs: [{
      number: 1,
      buyer: this.counterparty,
      seller: this.counterparty,
      price: DEFAULT_PRICE,
    }],
    quantity: {
      amount: null,
      unit: QUANTITY_UNITS[0]
    },
    dateTime: null,
    clearing: {
      cleared: false,
      house: 'ICEB', // TODO: for now hardcode this one
      id: '',
    },
    comments: '',
    isImported: false,
    type: TradeType.Outright,
    nextDayPriced: false
  });
}