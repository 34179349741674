import './NotAvailable.scss';

interface NotAvailableProps {
    label?: string;
}

export default function NotAvailable (props: NotAvailableProps): JSX.Element {
    const { label } = props;

    return <span className='not-available__container'>{label ?? 'N/A'}</span>
}