import { RefObject, useCallback,useEffect, useMemo, useState } from 'react';
import { Dialog } from 'primereact/dialog';

import { notNil } from 'helpers/Utils/misc';
import CargoEditDialogFooter from 'modules/CargoTracker/Templates/CargoEditDialogFooter';

import eventBus from 'server/EventBus';

import type { ButtonProps } from 'primereact/button';

export enum CargoEditDialogMode {
    NotAllRequiredFilled = 1,
    UnsavedChanges = 2,
}

export enum CargoEditWarningDialogEvents {
    BEFORE_ACTION = 'onCargoEditWarningBeforeAction',
    SET_CONTINUE_ACTION = 'onCargoEditWarningDialogSetContinueAction',
}

/**
 * Fires the custom event to check if the current action should be prevented. If action should be prevented,
 * emit another event to set the deferred action to be fired later (or handled any other way).
 * @param action Method to be called
 * @returns Promise that resolves to true if action has been deferred (event's default prevented).
 */
export const deferNextAction = async (beforeEvent: string, continueEvent?: string | null, action?: Function): Promise<boolean> => {
    const result = await eventBus.dispatch(beforeEvent, { action }, undefined, true);

    if (!result && continueEvent && notNil(continueEvent)) {
        await eventBus.dispatch(continueEvent, { action });

        return true;
    }

    return !result;
};

interface CargoEditWarningDialogProps {
    containerRef: RefObject<HTMLElement>;
    handleCancel: (force?: boolean) => void;
    handleSave: () => Promise<void>;
    isMutating: boolean;
    isValidationVisible: boolean;
    mode: CargoEditDialogMode;
}

export default function CargoEditWarningDialog (props: CargoEditWarningDialogProps): JSX.Element {
    const { containerRef, handleSave, isMutating, isValidationVisible, mode } = props;
    const notAllRequiredFilled = mode === CargoEditDialogMode.NotAllRequiredFilled;

    const [ isWarningDialogVisible, setIsWarningDialogVisible ] = useState<boolean>(false);
    const [ continueAction, setContinueAction ] = useState<Function | null>(null);

    const handleContinueAction = useCallback(async () => {
        typeof continueAction === 'function' && await continueAction()
        setContinueAction(() => null);
    }, [continueAction]);

    useEffect(() => {
        const onShowDialog = (event: CustomEvent) => {
            if (isValidationVisible) {
                setIsWarningDialogVisible(true);
                // show dialog - prevent other actions
                event.preventDefault();
            } else {
                setIsWarningDialogVisible(false);
            }
        };

        const setAction = (event: CustomEvent) => {
            setContinueAction(() => event.detail.action);
        }

        eventBus.on(CargoEditWarningDialogEvents.BEFORE_ACTION, onShowDialog);
        eventBus.on(CargoEditWarningDialogEvents.SET_CONTINUE_ACTION, setAction);

        return () => {
            eventBus.remove(CargoEditWarningDialogEvents.BEFORE_ACTION, onShowDialog);
            eventBus.remove(CargoEditWarningDialogEvents.SET_CONTINUE_ACTION, setAction);
        }
    }, [isValidationVisible]);


    const primaryButtonProps = useMemo(() => ({
        onClick: () => {
            setIsWarningDialogVisible(false);
            handleContinueAction();
        },
        children: notAllRequiredFilled ? 'Continue' : 'No',
        severity: 'danger' as ButtonProps["severity"],
        text: false,
        outlined: true
    }), [handleContinueAction, notAllRequiredFilled]);

    const secondaryButtonProps = useMemo(() => ({
        onClick: notAllRequiredFilled ?
          () => {
            setIsWarningDialogVisible(false);
          } : async () => {
            await handleSave();
            setIsWarningDialogVisible(false);
            handleContinueAction();
          },
        loading: isMutating,
        children: notAllRequiredFilled ? 'Return' : 'Save',
        severity: notAllRequiredFilled ? undefined : 'success' as ButtonProps['severity'],
        text: true,
        outlined: true
    }), [handleContinueAction, handleSave, isMutating, notAllRequiredFilled]);

    const body = useMemo(() => {
      return notAllRequiredFilled ?
        <div>If you CONTINUE any changes you've made will be lost. Hit RETURN too complete.</div> :
        <div>Would you like to save your changes before continuing?</div>
    }, [notAllRequiredFilled]);

    return <Dialog
        appendTo={containerRef.current}
        position="top"
        className="p-dialog--margin-less p-dialog--no-header grow-to-fill"
        contentClassName="grow-to-fill"
        visible={isWarningDialogVisible}
        footer={<CargoEditDialogFooter primaryButtonProps={primaryButtonProps} secondaryButtonProps={secondaryButtonProps} />}
        onHide={() => setIsWarningDialogVisible(false)}
    >
        <h4>You have unsaved changes</h4>
        {body}
    </Dialog>
}