
import { WorkflowStatusTypeEnum } from 'modules/Workflows/Models/Enums';

import './TaskHistoryInfoStatus.scss';

interface StatusProps {
  status: WorkflowStatusTypeEnum;
	label: string;
}

export default function Status(props: StatusProps): JSX.Element {
  const { status, label } = props;

  return <div className={`task-history-info-status status--${WorkflowStatusTypeEnum[status]}`}>
    { label }
  </div>;
}