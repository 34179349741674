import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import type { Suggestion, SuggestionItem } from 'components/Autocomplete';
import Autocomplete from 'components/Autocomplete';
import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import { SearchSuggestionsParsedResponse } from 'components/EntitySearch/Models/SearchEntities';
import { useSearchSuggestions } from 'components/EntitySearch/Services/SearchEntitiesAPI';

import { CargoSearchRequest, SearchRequestFields, SearchRequestFieldsMetaData } from '../../Models/CargoTrackerRequest';
import { PetroleumProductEnum, PP_TEXT_MAP } from '../../Models/CargoTrackerResponse';
import { SuggestionResponse } from '../GroupedSearch';

import { CSR_TO_GROUP_MAP, ESF_TO_SECTION_MAP, GROUP_TO_CSR_MAP } from './Models/Consts';
import { Section } from './Models/Enums';

import { formatName } from 'helpers/Utils/string';

interface ICargoSearchEntityProps {
  callback: (data: SearchRequestFields[]) => void;
  searchItems: CargoSearchRequest;
  handleSelectedExternalItemChange: (removedElement: keyof CargoSearchRequest) => void;
  className?: string;
}

type SuggestionResponseCargoSearch = SuggestionResponse & {
  Source?: string;
  mapper?: (item: SuggestionResponseCargoSearch) => SuggestionResponseCargoSearch & SuggestionItemCargoSearch;
  CountryCodeISO3?: string;
  SignalOceanPortId?: string;
  Latitude?: string;
  Longitude?: string;
  PortName?: string;
  TimeZone?: string;
  IsAlias?: string;
  FullName?: string;
}

export type SuggestionItemCargoSearch = SuggestionItem & {
  IsAlias?: string;
  FullName?: string;
}

const CargoSearchEntity = (param: ICargoSearchEntityProps): JSX.Element => {
  const {
    callback,
    className,
    searchItems,
    handleSelectedExternalItemChange,
  } = param;
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [value, setValue] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const EMPTY_LABEL = isMobile ? '' : ' '; // For desktop in should be not empty string for grid view

  const { data, /* error,  */isLoading, isValidating } = useSearchSuggestions({
    module: EntitySearchGroupEnum.CargoTracker,
    fields: [
      EntitySearchFieldsEnum.Charterer,
      EntitySearchFieldsEnum.Product,
      EntitySearchFieldsEnum.ProductCategory,
      EntitySearchFieldsEnum.ProductGrade,
      EntitySearchFieldsEnum.ProductGroup,
      EntitySearchFieldsEnum.Port,
      EntitySearchFieldsEnum.Country,
      EntitySearchFieldsEnum.Zone,
    ],
    term: value,
    chunkSize: 10
  });

  const handleSelectedItemsChange = (items: SuggestionItemCargoSearch[], removedItem?: SuggestionItem): void => {
    if (!removedItem) {
      const newItems = Object.entries(items)
        .map( e => {
          const [ , item ] = e;
          return item;
        });
      const internalItems = newItems.filter(item => !item.external);
      updateItems(internalItems);
    } else if (removedItem.external) {
      handleSelectedExternalItemChange(GROUP_TO_CSR_MAP[removedItem.group]);
    }
  };

  const updateItems = (data: SuggestionItemCargoSearch[]): void => {
    const fields: SearchRequestFields[] = data.map(el => {
      const metaData: SearchRequestFieldsMetaData[] = [];
      switch (el.group) {
      case Section[Section['Load/Delivery']]:
        metaData.push({ key: 'operationLocation', value: 'loading' });
        break;
      case Section[Section['Discharge/Re-delivery']]:
        metaData.push({ key: 'operationLocation', value: 'discharging' });
        break;
      case Section[Section['Commodity']]:
        metaData.push({ key: 'IsAlias', value: el.IsAlias ?? 'false' }, { key: 'FullName', value: el.FullName ?? '' });
        break;
      }
      return {
        searchTerm: el.searchTerm ?? '',
        searchField: el.searchField ?? EntitySearchFieldsEnum.Port,
        metaData
      };
    });
    callback(fields);
  };

  useEffect(() => {
    const itemsWithSection: Suggestion[] = [];
    const sectionOrder: Section[] = [Section['Cargo Code']];
    const d = data as SearchSuggestionsParsedResponse<SuggestionResponseCargoSearch>[];
    const portCountryZone: (SuggestionResponseCargoSearch)[] = [];
    const commodity: (SuggestionResponseCargoSearch)[] = [];
    const charterer: (SuggestionResponseCargoSearch)[] = [];

    if (data) {
      d.forEach(item => {
        const section = ESF_TO_SECTION_MAP[item.searchFieldId] as Section;
        // Store section order based on BE response (EntitySearchFieldsEnum order)
        if (!sectionOrder.includes(section)) {
          sectionOrder.push(section);
          if (section === Section['Discharge/Re-delivery']) {
            sectionOrder.push(Section['Load/Delivery']); // "Load" section should have duplicated "Discharge" items
          }
        }

        if ([EntitySearchFieldsEnum.Country, EntitySearchFieldsEnum.Port, EntitySearchFieldsEnum.Zone].includes(item.searchFieldId)) {
          portCountryZone.push(...item.values);
        } else if ([EntitySearchFieldsEnum.Product, EntitySearchFieldsEnum.ProductCategory, EntitySearchFieldsEnum.ProductGrade, EntitySearchFieldsEnum.ProductGroup].includes(item.searchFieldId)) {
          commodity.push(...item.values);
        } else if ([EntitySearchFieldsEnum.Charterer].includes(item.searchFieldId)) {
          charterer.push(...item.values);
        }
      });

      const getGroupLabel = (section: Section, items: SuggestionResponseCargoSearch[], index: number, searchField: EntitySearchFieldsEnum): string => {
        switch(section) {
          case Section['Load/Delivery']:
          case Section['Discharge/Re-delivery']:
          case Section['Commodity']:
            return searchField !== items[index - 1]?.searchField ? EntitySearchFieldsEnum[searchField] : EMPTY_LABEL; // check if it's same group as previous
          case Section['Charterer']:
          case Section['Cargo Code']:
            return index === 0 ? Section[section] : EMPTY_LABEL; // only on first item
        }
      };

      const getItemsMapped = (section: Section, items: SuggestionResponseCargoSearch[]): SuggestionItemCargoSearch[]  => items.map((el, index) => ({
        ...el,
        icon: el.CountryCodeISO3, // for Port/Country/Zone
        label: el.value,
        subLabel: el.Source || EMPTY_LABEL,
        groupLabel: getGroupLabel(section, items, index, el.searchField),
        captionLabel: el.IsAlias === 'true' ? `alias for ${ el.FullName }` : undefined,  // for Commodity
        name: el.value,
        group: Section[section]
      }));

      itemsWithSection.push({
        group: Section[Section['Cargo Code']],
        items: [{
          label: value,
          subLabel: EMPTY_LABEL,
          groupLabel: Section[Section['Cargo Code']],
          name: value,
          group: Section[Section['Cargo Code']],
          searchTerm: value,
          searchField: EntitySearchFieldsEnum.CargoCode
        }]
      });

      if (charterer.length) {
        itemsWithSection.push({
          group: Section[Section['Charterer']],
          items: getItemsMapped(Section['Charterer'], charterer)
        });
      }

      if (commodity.length) {
        itemsWithSection.push(
          {
            group: Section[Section['Commodity']],
            items: getItemsMapped(Section['Commodity'], commodity)
          }
        );
      }

      if (portCountryZone.length) {
        itemsWithSection.push(
          {
            group: Section[Section['Discharge/Re-delivery']],
            items: getItemsMapped(Section['Discharge/Re-delivery'], portCountryZone)
          },
          {
            group: Section[Section['Load/Delivery']],
            items: getItemsMapped(Section['Load/Delivery'], portCountryZone)
          }
        );
      }

      itemsWithSection.sort(
        (a, b) =>
          sectionOrder.indexOf(Section[a.group as keyof typeof Section]) -
          sectionOrder.indexOf(Section[b.group as keyof typeof Section])
      );
      setSuggestions(itemsWithSection);
    }

  }, [EMPTY_LABEL, data, value]);

  const selectedItems: SuggestionItem[] = useMemo(() => searchItems.searchRequestFields?.map(
    field => {
      let group = '';
      let isAlias = '';
      let fullName = '';
      switch(field.searchField) {
      case EntitySearchFieldsEnum.Country:
      case EntitySearchFieldsEnum.Port:
      case EntitySearchFieldsEnum.Zone:
        if (field.metaData[0]?.key === 'operationLocation' && field.metaData[0]?.value === 'discharging') {
          group = Section[Section['Discharge/Re-delivery']];
        } else {
          group = Section[Section['Load/Delivery']];
        }
        break;
      case EntitySearchFieldsEnum.Product:
      case EntitySearchFieldsEnum.ProductCategory:
      case EntitySearchFieldsEnum.ProductGrade:
      case EntitySearchFieldsEnum.ProductGroup:
        group = Section[Section['Commodity']];
        isAlias = field.metaData[0]?.key === 'IsAlias' ? field.metaData[0]?.value : '';
        fullName = field.metaData[1]?.key === 'FullName' ? field.metaData[1]?.value : '';
        break;
      case EntitySearchFieldsEnum.Charterer:
        group = Section[Section['Charterer']];
        break;
      case EntitySearchFieldsEnum.CargoCode:
        group = Section[Section['Cargo Code']];
        break;
      }

      return {
        group: group,
        name: field.searchTerm ?? '',
        searchTerm: field.searchTerm ?? '',
        searchField: field.searchField,
        value: field.searchTerm ?? '',
        label: field.searchTerm,
        IsAlias: isAlias,
        FullName: fullName,
      };
    }) ?? [], [searchItems.searchRequestFields]);

  const externalSearchParams: SuggestionItem[] = useMemo(() =>
    Object.keys(searchItems).reduce((acc: SuggestionItem[], key: string):SuggestionItem[] => {
      let value;
      switch (key) {
      case 'petroleumProductType':
        value = searchItems[key] !== undefined ?
          PP_TEXT_MAP[searchItems[key as keyof CargoSearchRequest] as PetroleumProductEnum] : undefined;
        break;
      case 'assignedUser':
        value = searchItems[key] && formatName(searchItems[key]?.userName ?? '');
        break;
      case 'quantity':
      case 'laycan':
        // Don't show tokens as they have separate fields. In future maybe it should show on mobile collapsed state:
        // value = searchItems[key] && (searchItems[key]?.original ?? '');
        value = undefined;
        break;
      }

      if (value === undefined) {
        return acc;
      }

      const searchParam: SuggestionItem = {
        external: true,
        group: CSR_TO_GROUP_MAP[key as keyof CargoSearchRequest] ?? '?',
        label: value,
        name: value,
      };

      return [ ...acc, searchParam ];
    }, [])
  , [searchItems]);

  return <Autocomplete
    handleInputValueChange={setValue}
    handleSelectedItemsChange={handleSelectedItemsChange}
    isLoading={isLoading || isValidating}
    selectedItems={[...selectedItems, ...externalSearchParams]}
    suggestions={suggestions}
    className={className}
    placeholder="Search by сargo code, charterer, commodity, discharge/re-delivery, load/delivery"
    autoExpandOnFocus={isMobile}
    useToggle={!isMobile}
    useSections
  />;
};

export default CargoSearchEntity;