import { useState } from 'react';
import { Button } from 'primereact/button';

import { GlobalDialogDisplayEvents } from 'models/shared/DialogDisplay';
import { eventBus } from 'server/EventBus';

interface IShareWorksheetDialogFooterProps {
  onShare?: () => void;
  onCancel?: () => void;
}

const ShareWorksheetDialogFooter = ({
  onShare,
  onCancel,
}: IShareWorksheetDialogFooterProps): JSX.Element => {
  const [mutating, setMutating] = useState<boolean>(false);

  return (
    <div className='space-between'>
      <Button
        size='small'
        text
        severity='danger'
        onClick={(e): void => {
          eventBus.dispatch(GlobalDialogDisplayEvents.HIDE, e);
          onCancel && onCancel();
        }}
      >
        Cancel
      </Button>
      <Button
        size='small'
        severity='success'
        autoFocus
        loading={mutating}
        onClick={async (e): Promise<void> => {
          setMutating(true);
          onShare && (await onShare());
          setMutating(false);
          eventBus.dispatch(GlobalDialogDisplayEvents.HIDE, e);
        }}
      >
        Share
      </Button>
    </div>
  );
};

export { ShareWorksheetDialogFooter };
export default ShareWorksheetDialogFooter;
