import { Dispatch, SetStateAction } from 'react';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';

export const SIDE_PANELS = ['Message', 'Recipients'];

interface CommonFieldsProps {
  setActiveDetailTab: Dispatch<SetStateAction<number>>;
  dlName?: string;
  activeDetailTab?: number;
}

const CommonFields = (props: CommonFieldsProps): JSX.Element => {
  const { dlName, activeDetailTab, setActiveDetailTab } = props;

  return (
    <>
      <h1 className="title"> {dlName ?? '-'}</h1>
      <div className="form-input__container">
        <Dropdown
          id="dl-side-panel-mode"
          value={activeDetailTab ?? 1}
          onChange={(e: DropdownChangeEvent): void =>
            setActiveDetailTab(e.value)
          }
          options={
            SIDE_PANELS.map((el, index) => ({
              label: el,
              value: index,
            }))
          }
        />
      </div>
    </>);
  
};

export default CommonFields;