import { Button, ButtonProps } from 'primereact/button';

interface CargoEditDialogFooterProps {
    primaryButtonProps: ButtonProps;
    secondaryButtonProps: ButtonProps;
}

export default function CargoEditDialogFooter(props: CargoEditDialogFooterProps): JSX.Element {
    const { primaryButtonProps, secondaryButtonProps } = props;

    return <div className='grow-to-fill space-between'>
        <Button size='small' {...primaryButtonProps}></Button>
        <Button size='small' text {...secondaryButtonProps}></Button>
    </div>
}