import { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import { useSignalR } from 'App';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';

import Loader from 'components/Loader';
import { UISettings } from 'components/OBXUser/Model/Enums';
import { useLoadUserSettings, useSaveUserSetting } from 'components/OBXUser/Services/ProfileHooks';
import SecondaryNavigation from 'components/SecondaryNavigation';
import ToastMessage, {ToastMessageRef, ToastSeverity} from 'components/ToastMessage';
import { WorksheetStores } from 'components/Worksheets/Models/Enums';
import { WorksheetMetaProps } from 'components/Worksheets/Models/WorksheetResponse';
import { useCreateNewWorksheet } from 'components/Worksheets/Services/WorksheetHooks';

import CargoEdit from './Components/CargoEdit';
import { CargoEditWarningDialogEvents, deferNextAction } from './Components/CargoEditWarningDialog';
import CargoGroupBy from './Components/CargoGroupBy';
import CargoSearch from './Components/CargoSearch';
import CargoSearchMobile from './Components/CargoSearch/CargoSearchMobile';
import SpotTable from './Components/SpotTable';
import TimeCharterTable from './Components/TimeCharterTable';
import { CargoTrackerGroupBy, CargoTrackerModeEnum } from './Models/Enums';
import { CargoTrackerSocket } from './Services/SignalRSocket';

import type { CargoTrackerResponse } from './Models/CargoTrackerResponse';
import type { CargoTrackerConfig } from 'index';

import './CargoTrackerPage.scss';

interface CargoTrackerProps {
  items: CargoTrackerConfig[];
  resultsMode: CargoTrackerModeEnum;
}

const CargoTracker = (props: CargoTrackerProps): JSX.Element => {
  const { items, resultsMode } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const [activeCargo, setActiveCargo] = useState<string | null>(null);
  const [rightColumn, setRightColumn] = useState<boolean>(false);
  const [mainParams, setMainParams] = useState({});
  const [searchData, setSearchData] = useState<CargoTrackerResponse[] | undefined>();
  const [groupBy, setGroupBy] = useState<string>(CargoTrackerGroupBy[CargoTrackerGroupBy['None']]);
  const { signal } = useSignalR();
  const toast = useRef<ToastMessageRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { getSetting } = useLoadUserSettings();
  const { trigger } = useSaveUserSetting();
  const { newWorksheet } = useCreateNewWorksheet(WorksheetStores.CargoTracker);
  const activeWorksheet = useRef<string>(getSetting(UISettings.CARGO_TRACKER_ACTIVE_WORKSHEET));

  const gestures = useSwipeable({
    onSwipedRight: ():void => {
      handleRightColumnClose();
    },
  });

  const itemsWithCounter = items.map(el => ({
    ...el,
    label: `${ el.label } (${
      searchData?.filter(d => d.type === el.resultsMode).length ?? 0 })`,
  }));

  const addCargo = (): void => {
    toggleRightColumn(true);
    setActiveCargo(null);
  };

  const handleAddCargo = async (): Promise<void> => {
    if (await deferNextAction(CargoEditWarningDialogEvents.BEFORE_ACTION,
      CargoEditWarningDialogEvents.SET_CONTINUE_ACTION,
      addCargo)) {
      return;
    }

    addCargo();
  };

  const toggleRightColumn = useCallback((state: boolean): void => {
    if (!state) {
      setRightColumn(false);
      setMainParams({});
      return;
    }

    if (!rightColumn) {
      setRightColumn(true);
      setMainParams({
        'data-cols': '9,3',
        'data-drawer-style': 'slide',
        'data-drawer-position': 'alongside-right',
      });
    }
    // eslint-disable-next-line
  }, [rightColumn]);

  useEffect(() => {
    const socket: CargoTrackerSocket = CargoTrackerSocket.instance;
    socket.init(signal);
  }, [signal]);

  useEffect(() => {
    if (!activeCargo) {
      return;
    }

    toggleRightColumn(true);
  }, [activeCargo, toggleRightColumn]);

  const handleRightColumnClose = ():void => {
    toggleRightColumn(false);
    setActiveCargo(null);
  };
  
  const addNewWorksheet = useCallback(async ():Promise<void> => {
    activeWorksheet.current = '-';
    const data = await newWorksheet({
      store: WorksheetStores.CargoTracker,
      hydrator: (): WorksheetMetaProps[] => [], // update default values later
      name: 'Cargo Tracker Worksheet'
    });

    if (!data || !data.worksheetId) {
      return;
    }

    try {
      trigger({
        setting: UISettings.CARGO_TRACKER_ACTIVE_WORKSHEET,
        data: data.worksheetId
      });
    } catch(e) {
      toast.current?.replace({
        title: 'Issue occurred',
        message: 'Issue when storing active worksheet',
        severity: ToastSeverity.WARN
      });
    }
    console.info('Cargo Tracker worksheet added:', data.worksheetId);
    activeWorksheet.current = data.worksheetId;
  }, [newWorksheet, trigger]);

  useEffect(() => {
    if (activeWorksheet.current) {
      console.info('Cargo Tracker active worksheet:', activeWorksheet.current);
    } else {
      addNewWorksheet();
    }
  }, [addNewWorksheet]);

  const handleSuccess = (isNew: boolean):void => {
    toast.current?.replace({
      title: isNew ? 'Cargo Added' : 'Changes Saved',
      message: isNew ? 'A new cargo has been successfully added' : 'The changes have been successfully saved',
      severity: ToastSeverity.SUCCESS
    });
  };

  const handleDeleteSuccess = (count: number):void => {
    toast.current?.replace({
      title: 'Cargo Deleted',
      message: count > 1 ? `${ count } cargos have been successfully deleted` : 'Cargo have been successfully deleted',
      severity: ToastSeverity.SUCCESS
    });
  };

  return (
    <>
      <div className="cargo-tracker-add-button__container">
        <Button
          size="small"
          onClick={():Promise<void> => handleAddCargo()}
        >
          Add cargo
        </Button>
      </div>
      <header className="cargo-tracker__header">
        {!isMobile ?
          <CargoSearch
            setSearchData={setSearchData}
            setIsLoading={setIsLoading}
            activeWorksheet={activeWorksheet.current}
          /> :
          <CargoSearchMobile
            setSearchData={setSearchData}
            setIsLoading={setIsLoading}
            activeWorksheet={activeWorksheet.current}
          />
        }
      </header>
      <nav className="cargo-tracker__nav">
        { !(isMobile && rightColumn) &&
          <SecondaryNavigation items={itemsWithCounter} />
        }
        { !isMobile &&
          <CargoGroupBy
            activeWorksheet={activeWorksheet.current}
            groupBy={groupBy}
            setGroupBy={setGroupBy}
          /> }
      </nav>
      <main
        ref={containerRef}
        className={clsx(
          'cargo-tracker__main',
          'grow-to-fill',
          { 'drawer--active': rightColumn }
        )}
        {...mainParams}>
        <section>
          {isLoading ? <Loader /> : (<>
            {resultsMode === CargoTrackerModeEnum.Spot && (
              <SpotTable 
                cargo={searchData?.filter(d => d.type === CargoTrackerModeEnum.Spot) ?? []}
                activeCargo={activeCargo}
                setActiveCargo={setActiveCargo}
                showDeleteSuccess={handleDeleteSuccess}
                groupBy={groupBy}
              />
            )}
            {resultsMode === CargoTrackerModeEnum.TC && (
              <TimeCharterTable
                cargo={searchData?.filter(d => d.type === CargoTrackerModeEnum.TC) ?? []}
                activeCargo={activeCargo}
                setActiveCargo={setActiveCargo}
                showDeleteSuccess={handleDeleteSuccess}
                groupBy={groupBy} />
            )}</>)}
        </section>
        {rightColumn &&
          <aside className="position--relative" {...gestures}>
            <CargoEdit
              activeCargo={activeCargo}
              setActiveCargo={setActiveCargo}
              closeEdit={handleRightColumnClose}
              showSuccess={handleSuccess}
              cargoData={searchData}
              resultsMode={resultsMode}
            />
            { !isMobile && <Button text icon="iconoir-xmark icon--tiny p-button-icon-only"
              className="close-button"
              onClick={async (): Promise<void> => {
                if (await deferNextAction(CargoEditWarningDialogEvents.BEFORE_ACTION,
                  CargoEditWarningDialogEvents.SET_CONTINUE_ACTION,
                  handleRightColumnClose)) {
                  return;
                }

                handleRightColumnClose();
              }}
            >
            </Button> }
          </aside>
        }
      </main>
      <ToastMessage
        ref={toast}
      />
    </>
  );
};

export default CargoTracker;
