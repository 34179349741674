export const enum WorksheetStores {
	PositionList = 'PositionList',
	Fixtures = 'Fixtures',
	CargoTracker = 'CargoTracker',
	CargoFlows = 'CargoFlows',
	Surveillance = 'Surveillance',
	Blotter = 'Blotter'
}

export enum WorksheetInternalEventTypes {
  WORKSHEET_SHOW_SHARE_POPUP = 'worksheetShowSharePopup',
}

export enum WorksheetSignalMessageEventTypes {
	WORKSHEET_UPDATED = 'worksheetUpdated',
	WORKSHEET_LOADED = 'worksheetLoaded',
	WORKSHEET_CLOSED = 'worksheetClosed',
	WORKSHEET_START_NAME_CHANGE = 'worksheetStartNameChange',
	WORKSHEET_RENAMED = 'worksheetNameChanged',
  WORKSHEET_DELETED = 'worksheetBurntInHades',
	WORKSHEET_DENIED = 'worksheetNaughtyNaughty'
}


export enum WorksheetPanelDisplayEvent {
	SHOW_PANEL = 'onShowWorksheetPanel',
	HIDE_PANEL = 'onHideWorksheetPanel',
}

export enum MutationType {
	Open = 0,
	Close = 1,
	Change = 2,
  Delete = 3,
	Create = 4,
	Error = -1,
}