import { type PointerEvent } from 'react';
import { PointerSensor } from '@dnd-kit/core';

export default class CustomPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as 'onPointerDown', // funny that eventName has to be of type 'onPointerDown', otherwise the TS shouts ¯\_(ツ)_/¯
      handler: ({ nativeEvent }: PointerEvent) => {
        if ((nativeEvent.target as HTMLElement).tagName === 'INPUT') {
          return false;
        }

        return true;
      },
    },
  ];
}