import { UserEntity } from 'components/OBXUser/Model/ProfileResult';

export enum AccessLevel {
  None = 'None',
  Edit = 'Edit',
}

export interface UserShareAccess extends UserEntity {
  access: AccessLevel;
  isExternal?: boolean;
}
