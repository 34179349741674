import { SubscriptionDataset } from './Enums';

import type { FieldsConfig } from './SubscriptionRequest';

export const DATA_DEFAULT_VALUES = {
  trial: {
    expiresInDays: {
      danger: 7,
      warn: 14,
    },
    numberOfDays: 14,
    maxProducts: 20,
    maxHistoryDays: 30
  },
  full: {
    expiresInDays: {
      danger: 30,
      warn: 90,
    },
    numberOfDays: 365,
    maxProducts: 60,
    maxHistoryDays: 3650
  }
};

export const FIELDS_CONFIG: FieldsConfig = {
  id: {},
  partitionKey: {},
  subscriptionName: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true
    },
  },
  clientName: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true
    },
  },
  clientEmail: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true
    },
  },
  startDate: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true
    },
  },
  marketClose: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true,
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true
    },
  },
  subscriptions: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true,
      options: ['Ohlc', 'LIve'],
      min: 1,
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true,
      options: ['Ohlc', 'LIve'],
      min: 1,
    },
  },
  regions: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true,
      min: 1,
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true,
      min: 1,
    },
  },
  callsPerMinute: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true,
      min: 1,
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true,
      min: 1,
    },
  },
  dataSet: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true
    },
  },
  expiryDate: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true
    },
  },
  groupable: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true,
      options: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: false
    },
  },
  isTrial: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true,
      options: [{ label: 'Trial', value: true }, { label: 'Full', value: false }]
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true,
      options: [{ label: 'Trial', value: true }, { label: 'Full', value: false }]
    },
  },
  maxHistoryDays: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true,
      min: 1,
      max: 9999
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true,
      min: 1,
      max: 9999
    },
  },
  maxProducts: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true,
      min: 0,
      max: 60
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true,
      min: 0,
      max: 60
    },
  },
  numberOfDays: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true,
      min: 1,
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true,
      min: 1
    },
  },
  resources: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true,
      min: 1,
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true,
      min: 1,
    },
  },
  timeWindow: {
    [SubscriptionDataset.RateGridsPackage]: {
      display: true,
      options: ['D', 'W', 'MN'],
      min: 1,
    },
    [SubscriptionDataset.DerivativesExchanges]: {
      display: true,
      options: ['M1', 'M5', 'M15', 'H1', 'H4', 'D', 'W', 'MN'],
      min: 1,
    },
  },
};
