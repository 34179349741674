import { ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { InputText } from 'primereact/inputtext';

import { ParsedRangeResult, QuantityParserApi } from './Services/QuantityParserAPI';

interface QuantityParserProps {
  defaultValue: string | null;
  addonText?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  required?: boolean;
  onRangeParsed?: (arg?: ParsedRangeResult) => void;
  showError?: boolean;
  id?: string;
  placeholder?: string;
  onParsingStart?: () => void;
  onDateParseError?: (value: string) => void;
}

const QuantityParser = (props: QuantityParserProps): JSX.Element => {
  const {
    defaultValue,
    showError,
    onRangeParsed,
    onFocus,
    onBlur,
    addonText,
    id,
    placeholder,
    onParsingStart,
    onDateParseError,
    required = true
  } = props;
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState<string | null>(null);

  const handleUpdate = useCallback(async (newValue: string) => {
    const trimmed = `${ newValue }`.trim();
    if (trimmed && onRangeParsed) {
      onParsingStart && onParsingStart();
      QuantityParserApi.getParsedRange(trimmed).then(data => {
        onRangeParsed(data?.results);
      }).catch(error => {
        setError(`${ error.response?.data.error.errorObject }`);
        onDateParseError && onDateParseError(newValue);
      });
    } else if (trimmed === '' && onRangeParsed) {
      onRangeParsed(undefined);
      required && setError('Required field');
    }
  }, [onDateParseError, onParsingStart, onRangeParsed, required]);

  useEffect(() => {
    setError((!value || value === '') && required ? 'Required field' : null);
  }, [required, value]);

  useEffect(() => {
    if (defaultValue !== null) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleFocus = (e: FocusEvent<HTMLInputElement>): void => {
    e.target.select();
    onFocus && onFocus();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>): void => {
    handleUpdate(e.target.value);
    onBlur && onBlur();
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  return (<>
    <div
      className={clsx('quantityparser__container p-inputgroup', {'p-invalid': showError && error !== null})}>
      <InputText
        id={id}
        value={value ?? ''}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        placeholder={placeholder}
      />
      {addonText && <span className="p-inputgroup-addon">{addonText}</span>}
    </div>
    {showError && error && <small className="message-invalid">{error || 'Invalid input'}</small>}
  </>
  );
};

export default QuantityParser;