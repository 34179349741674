import { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { Tooltip } from 'primereact/tooltip';

import useDimensions from 'helpers/Hooks/Dimensions';
import { spaceToDash, uniqueId } from 'helpers/Utils/string';

import type { SuggestionItem } from '../Autocomplete';

interface ChipTemplateProps extends SuggestionItem {
  mapper?: (props: ChipTemplateProps) => ChipTemplateProps;
}

export default function ChipTemplate(props: ChipTemplateProps): JSX.Element {

  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);
  const [random] = useState(uniqueId());
  const valueRef = useRef<HTMLDivElement>(null);
  const { width, scrollWidth } = useDimensions(valueRef);

  const { mapper } = props;

  if (typeof mapper === 'function') {
    //	We've been passed a function that will help map the label + icon keys…
    props = mapper(props);
  }

  const { group, name, searchTerm, external } = props;

  useEffect(() => {
    setIsOverflowed(width < scrollWidth);
  }, [scrollWidth, width]);

  const valueAsId = spaceToDash(name ?? searchTerm);

  return <div className={clsx('token-template', {'token-template--external': external, 'token-template-truncated': isOverflowed})}>
    {isOverflowed && <Tooltip content={name} target={`.token-template-${ random }`} position="bottom" />}
      <label className="token-template-label" htmlFor={`token-template-${ valueAsId }`}>
        {group}&#58;&nbsp;
      </label>
      <div className={`token-template-value token-template-${ random }`}
           id={`token-template-${ valueAsId }`}
           ref={valueRef}>{name}
      </div>
    </div>;
  }