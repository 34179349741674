import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';

import { CargoSearchRequest } from '../../../Models/CargoTrackerRequest';

import { Section } from './Enums';

export const DEFAULT_SEARCH_ITEMS: CargoSearchRequest = {
  searchRequestFields: [],
  laycan: undefined,
  quantity: undefined,
  petroleumProductType: undefined,
  assignedUser: undefined
};

export const CSR_TO_GROUP_MAP: { [key in keyof CargoSearchRequest]: string }  = {
  petroleumProductType: 'C/D',
  assignedUser: 'Assignee',
  laycan: 'Laycan',
  quantity: 'Quantity',
  searchRequestFields: '-'
};

export const GROUP_TO_CSR_MAP: { [index: string]: keyof CargoSearchRequest }  = {
  'C/D': 'petroleumProductType',
  'Assignee': 'assignedUser',
  'Laycan': 'laycan',
  'Quantity': 'quantity',
};

export const ESF_TO_SECTION_MAP: Partial<{ [key in EntitySearchFieldsEnum]: Section }> = {
  [EntitySearchFieldsEnum.CargoCode]: 0,
  [EntitySearchFieldsEnum.Charterer]: 1,
  [EntitySearchFieldsEnum.Product]: 2,
  [EntitySearchFieldsEnum.ProductCategory]: 2,
  [EntitySearchFieldsEnum.ProductGrade]: 2,
  [EntitySearchFieldsEnum.ProductGroup]: 2,
  [EntitySearchFieldsEnum.Port]: 3,
  [EntitySearchFieldsEnum.Country]: 3,
  [EntitySearchFieldsEnum.Zone]: 3,
};
