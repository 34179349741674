import { ColumnSortEvent, ColumnSortMetaData } from 'primereact/column';

import { notNil } from 'helpers/Utils/misc';

import F, { FF } from 'types/generic-type';

export const sortBySelectedFieldOptional = (event: ColumnSortEvent, sortByField: string, ...keys: Array<string>): F<string>[] => {
  const { data, order, multiSortMeta } = event;

  return data.sort((a: FF<string | number>, b: FF<string | number>) => {
    const foundFirst = keys.find(key => notNil(a[sortByField]?.[key]));
    const foundSecond = keys.find(key => notNil(b[sortByField]?.[key]));

    const first: string | number = foundFirst ? a[sortByField]?.[foundFirst] :  '';
    const second: string | number = foundSecond ? b[sortByField]?.[foundSecond]  :  '';

    if (multiSortMeta?.length) { // With grouping, first check group order
      return groupCompare(a, b, multiSortMeta) || singleCompare(first, second, multiSortMeta[1].order);
    }

    return singleCompare(first, second, order);
  });
};

const singleCompare = (first: string | number, second: string | number, order: 1 | 0 | -1 | undefined | null ): number =>
  `${ first }`.localeCompare(`${ second }`,
    'en',
    { numeric: typeof first === 'number' && typeof second === 'number' }) * (order as number);

const groupCompare = (a: FF<string | number>, b:FF<string | number>, multiSortMeta: ColumnSortMetaData[] ): number =>
  `${ a[multiSortMeta[0].field] }`.localeCompare(`${ b[multiSortMeta[0].field] }`) * (multiSortMeta[0].order as number);
